<template>
    <div class="sales-formula-matrix">
        <ds-sales-formula-matrix-grid
                :data-func="getGlobal"></ds-sales-formula-matrix-grid>
        <md-list>
            <md-list-item md-expand>
                <md-icon>language</md-icon>
                <span class="md-list-item-text">{{ $t('SALES_FORMULA_MATRIX.UNIVERS') }}</span>
                <div slot="md-expand">
                    <ds-sales-formula-matrix-grid
                            :data-func="getUnivers"></ds-sales-formula-matrix-grid>
                </div>
            </md-list-item>
            <md-list-item md-expand>
                <md-icon>shopping_cart</md-icon>
                <span class="md-list-item-text">{{ $t('SALES_FORMULA_MATRIX.SHELVES') }}</span>
                <div slot="md-expand">
                    <ds-sales-formula-matrix-grid
                            :data-func="getShelves"></ds-sales-formula-matrix-grid>
                </div>
            </md-list-item>
            <md-list-item md-expand>
                <md-icon>shopping_basket</md-icon>
                <span class="md-list-item-text">{{ $t('SALES_FORMULA_MATRIX.MARKETS') }}</span>
                <div slot="md-expand">
                    <ds-sales-formula-matrix-grid
                            :data-func="getMarkets"></ds-sales-formula-matrix-grid>
                </div>
            </md-list-item>

            <md-list-item md-expand>
                <md-icon>store</md-icon>
                <span class="md-list-item-text">{{ $t('SALES_FORMULA_MATRIX.STORES') }}</span>
                <div slot="md-expand">
                    <ds-sales-formula-matrix-grid
                            :data-func="getStores" :isStore="true"></ds-sales-formula-matrix-grid>
                </div>
            </md-list-item>
        </md-list>
    </div>
</template>

<script>
import salesFormulaService from '@/services/salesFormulaService';
import DsSalesFormulaMatrixGrid from './components/SalesFormulaMatrixGrid.vue';

export default {
  name: 'DsSalesFormulaMatrix',
  components: { DsSalesFormulaMatrixGrid },
  methods: {
    getGlobal() {
      const query = this.$route.query;
      return salesFormulaService.getMatrix(1, query.idMonth, query.sidSite, query.sidSalesChannel, query.idLivingArea);
    },
    getUnivers() {
      const query = this.$route.query;
      return salesFormulaService.getMatrix(2, query.idMonth, query.sidSite, query.sidSalesChannel, query.idLivingArea);
    },
    getShelves() {
      const query = this.$route.query;
      return salesFormulaService.getMatrix(3, query.idMonth, query.sidSite, query.sidSalesChannel, query.idLivingArea);
    },
    getMarkets() {
      const query = this.$route.query;
      return salesFormulaService.getMatrix(4, query.idMonth, query.sidSite, query.sidSalesChannel, query.idLivingArea);
    },
    getStores() {
      const query = this.$route.query;
      return salesFormulaService.getMatrix(5, query.idMonth, undefined, query.sidSalesChannel, query.idLivingArea);
    },
  },
};
</script>
