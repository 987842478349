<template>
  <div class="google-maps-full-screen-control">
    <md-button class="md-icon-button md-raised" @click="toggleFullScreen">
      <md-icon>fullscreen</md-icon>
    </md-button>
  </div>
</template>

<script>
  export default {
    name: 'GoogleMapsFullScreenControl',
    props: {
      map: {
        type: Object,
        default: null
      }
    },
    data() {
      return {
        isFullScreen: false
      }
    },
    emits: ['updateFullScreen'],
    methods: {
      toggleFullScreen() {
        const mapDiv = this.map.getDiv().closest('.map-ctnr')
        if (this.isFullScreen) {
          document.exitFullscreen()
        } else {
          mapDiv.requestFullscreen()
        }
        this.isFullScreen = !this.isFullScreen
        this.$emit('updateFullScreen', this.isFullScreen)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .google-maps-full-screen-control .md-icon-button {
    border-radius: 1rem;
    font-weight: bold;

    &.md-button.md-theme-default.md-raised:not([disabled]) {
      color: black;
    }

    &::before {
      border-radius: 1rem;
    }
  }
</style>
