<template>
    <div class="animation-detail">
        <div v-for="(anim, idx) in animationDetail" :key="'anim_'+ idx" class="item">
            <p>
                <b>{{ $t('ANIMATION_DETAIL.' + anim.name) }}: </b>
                <span v-if="anim.value === 'ALL'">{{ $t('ANIMATE_TOP_CLIENT.ALL') }}</span>
                <span v-else>{{ anim.value }}</span>

            </p>
            <md-tooltip>{{anim.value}}</md-tooltip>
            <md-icon>check</md-icon>
        </div>
    </div>
</template>

<script>
import AppLoading from '@/components/app-loading/AppLoading.vue';

export default {
  name: 'DsAnimationDetail',
  components: { AppLoading },
  props: {
    animationDetail: {
      type: Array,
      default: () => ([]),
    },
  },
};
</script>

<style scoped lang="scss">
    @import "~@/styles/_colors";
    @import "~@/styles/_mixins";

    .animation-detail {
        width: 100%;
        padding-left: 1em;
        .item {
            @include display-flex(center, space-between);
            border-bottom: 1px solid $color-grey-light;
            width: 100%;
            i {
                margin: 0 .5em 0 0;
            }
            p {
                margin: .5em 0;
                font-size: .8em;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                max-width: 75%;
            }
        }
    }
</style>
