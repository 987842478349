<template>
    <div class="app-navigation">
        <div :key="`menu_${idx}`"
             :style="{ 'color': colorFont }"
             :class="isActive(menu) ? 'active' : 'transparent'"
             class="menu"
             v-for="(menu, idx) in menus">
            <button @click="goTo(menu)"
                    v-if="menu.to">
                {{ menu.label }}
            </button>
            <button @click.stop="toggleMenu(menu)"
                    v-else>
                {{ menu.label }}
            </button>
            <transition name="toggle-menu">
                <div class="sub-menu"
                     v-show="activeMenuId === menu.id">
                    <ul>
                        <li :key="`sub-menu_${idx}`"
                            @click="goTo(child)"
                            v-for="(child, idx) in menu.children">
                            <md-icon>{{child.icon}}</md-icon>
                            <span>{{ child.label }}</span>
                        </li>
                    </ul>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
export default {
  name: 'AppNavigation',
  props: {
    colorFont: {
      type: String,
      default: 'white',
    },
    menus: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
    return {
      activeMenuId: null,
    };
  },
  created() {
    document.addEventListener('click', () => this.activeMenuId = null)
  },
  methods: {
    isActive(menu) {
      return this.$route.name !== null && this.$route.name.startsWith(menu.id)
    },
    toggleMenu(menu) {
      this.activeMenuId = this.activeMenuId === null ? menu.id : null
    },
    goTo(menu) {
      if(menu.id === 'animate') delete this.$route.query.displayAnimate
      if (menu.withCurrentQuery) {
        menu.to.query = this.$route.query
      }

      this.$router.push(menu.to)
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/styles/_colors";
@import "~@/styles/_mixins";

.app-navigation {
    display: flex;
    height: 100%;

    .menu {
        padding: 0 1rem;
        border-bottom: 2px solid;
        position: relative;
        border-color: transparent;
        button {font-weight: 500;}

        &.active {
            border-color: white;
            button {font-weight: bold;}
        }

        &:before {
            content: "";
            display: inline-block;
            height: 100%;
            vertical-align: middle;
        }

        a,
        button {
            font-size: 15px;
            text-transform: uppercase;
        }

        a {
            color: inherit;
            text-decoration: none;

            &:hover {
                color: inherit;
            }
        }

        button {
            background-color: transparent;
            border: none;
            color: inherit;

            &:hover {
                cursor: pointer;
            }
        }

        .toggle-menu-enter-active,
        .toggle-menu-leave-active {
            transition: all 300ms ease-in-out;
        }

        .toggle-menu-enter,
        .toggle-menu-leave-to {
            transform: translateY(-10px);
            opacity: 0;
        }

        .sub-menu {
            position: absolute;
            width: 250px;
            left: 0;
            z-index: 100;
            background-color: white;
            border-radius: 4px;
            box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);

            ul {
                padding: 0;
                margin: 0;
                list-style-type: none;

                li {
                    @include display-flex(center, start);
                    color: black;
                    padding: 0.5rem 1.5rem;
                    border-radius: 2px;
                    border-bottom: 1px solid $color-grey-light;

                    i {
                        margin: .2em .5em .2em 0;
                    }

                    &:hover {
                        background-color: $color-grey-with-opacity;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}
</style>
