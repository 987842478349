<template>
  <div class="animate-market">
    <app-loading v-if="isSaving" />
    <h4>{{ $t('ANIMATE_MARKET.TITLE') }}</h4>

    <app-nomenclature-selector v-if="!isProxy"
                               v-model="nomenclatures"
                               :init-selected-id-markets="initSelectedMarkets" />

    <p v-else style="text-align: center;">
      {{ $t('ANIMATE_MARKET.NOM_PROXY') }}
    </p>

    <div class="actions">
      <app-button
        :disabled="isSaving"
        @click.native="back">
        {{ $t('ACTIONS.BACK') }}
      </app-button>

      <app-button
        :disabled="!nomenclatures || isSaving"
        @click.native="refreshTarget">
        {{ $t('ACTIONS.REFRESH_TARGET') }}
      </app-button>

      <app-button
        class="primary"
        :disabled="isSaving"
        @click.native="goToTopClient">
        {{ $t('ACTIONS.GO_TO_TOP_CLIENT') }}
      </app-button>

      <app-button
        :disabled="!nomenclatures || isSaving"
        class="primary"
        @click.native="next">
        {{ $t('ACTIONS.NEXT') }}
      </app-button>
    </div>

    <md-snackbar
      :md-active.sync="message.active"
      :md-duration="Infinity"
      md-persistent
      md-position="left">
      {{ message.value }}
    </md-snackbar>
  </div>
</template>

<script>
import AppButton from '@/components/app-button/AppButton.vue'
import AppNomenclatureSelector
  from '@/components/app-nomenclature-selector/AppNomenclatureSelector.vue'
import animationService from '@/services/animationService'
import AppLoading from '@/components/app-loading/AppLoading.vue'

export default {
  name: 'AnimateMarket',
  components: {
    AppLoading,
    AppNomenclatureSelector,
    AppButton,
  },
  data() {
    return {
      isSaving: false,
      animation: {},
      nomenclatures: [],
      initSelectedMarkets: [],
      message: {
        active: false,
        value: null,
      },
    }
  },
  computed: {
    isProxy() {
      return +this.$route.query.sidSalesChannel === 5
    },
  },
  watch: {
    nomenclatures() {
      this.animation.nomenclatures = this.nomenclatures.map(nomenclature => nomenclature.sid).join(',')
    },
  },
  async created() {
    animationService
      .findById(this.$route.params.sid)
      .then(animation =>  {
        this.animation = animation
        this.$emit('init', this.animation)

        if (animation.nomenclatures) {
          this.nomenclatures = animation.nomenclatures.split(',').map(sid => ({ sid }))
          this.initSelectedMarkets = animation.nomenclatures.split(',').map(sid => ({ sid }))
        }
      })
  },
  methods: {
    next() {
      this.isSaving = true
      this.message.active = false

      if (this.nomenclatures.length > 0) {
        this.animation.nomenclatures = this.nomenclatures.map(nomenclature => nomenclature.sid).join(',')
      } else {
        this.animation.nomenclatures = '999'
      }

      animationService
        .save(this.animation.sid, this.animation)
        .then(() => this.$router.push({
          name: 'animate-segmentation',
          params: this.$route.params,
          query: this.$route.query,
        }))
        .catch(() => {
          this.message.active = true
          this.message.value = this.$t('ANIMATE_MARKET.ERROR.SAVING')
        })
        .finally(() => this.isSaving = false)
    },
    back() {
      this.$router.push({ name: 'animate-media', params: this.$route.params, query: this.$route.query })
    },
    refreshTarget() {
      this.$emit('update', this.animation)
    },
    goToTopClient() {
      this.isSaving = true
      this.message.active = false

      if (this.nomenclatures.length > 0) {
        this.animation.nomenclatures = this.nomenclatures.map(nomenclature => nomenclature.sid).join(',')
      } else {
        this.animation.nomenclatures = '999'
      }

      animationService
        .save(this.animation.sid, this.animation)
        .then(() => this.$router.push({
          name: 'animate-top-client',
          params: this.$route.params,
          query: this.$route.query,
        }))
        .catch(() => {
          this.message.active = true
          this.message.value = this.$t('ANIMATE_MARKET.ERROR.SAVING')
        })
        .finally(() => this.isSaving = false)
    },
  },
}
</script>

<style scoped lang="scss">
    @import "~@/styles/_colors";

    .animate-market {
        border-top: 1px solid $color-grey-light;
        flex: 1;
        background: white;
        border-radius: 2px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        h4 {
            padding: 2rem;
            margin: 0;
        }

        .app-nomenclature-selector {
            height: auto;
            border-bottom: 1px solid $color-grey-light;
        }

        .actions {
            padding: 2rem;
            justify-content: flex-end;
            display: flex;

            button:not(:last-child) {
                margin-right: 1em;
            }
        }
    }
</style>
