<template>
  <div class="animate-store">
    <app-loading v-if="isSaving || isLoading" />
    <h4>{{ $t('ANIMATE_STORE.TITLE') }}</h4>

    <div v-if="!isLoading" class="content">
      <app-geographic-selector v-model="site" :hide-arf="true" :hide-select-living-area="true" />
    </div>

    <div class="actions">
      <app-button :disabled="false" @click.native="back">
        {{ $t('ACTIONS.BACK') }}
      </app-button>
      <app-button :disabled="isSaving || !site" class="primary" @click.native="next">
        {{ $t('ACTIONS.NEXT') }}
      </app-button>
    </div>

    <md-snackbar :md-active.sync="message.active" :md-duration="Infinity" md-persistent md-position="left">
      {{ message.value }}
    </md-snackbar>
  </div>
</template>

<script>
  import AppButton from '@/components/app-button/AppButton.vue'
  import AppLoading from '@/components/app-loading/AppLoading.vue'
  import animationService from '@/services/animationService'
  import AppGeographicSelector from '@/components/app-geographic-selector/AppGeographicSelector.vue'
  import nomenclatureGeographicService from '@/services/nomenclatureGeographicService'

  export default {
    name: 'AnimateStore',
    components: {
      AppGeographicSelector,
      AppButton,
      AppLoading
    },
    data() {
      return {
        isSaving: false,
        isInError: false,
        isModification: false,
        isLoading: true,
        site: null,
        animation: {},
        message: {
          active: false,
          value: null
        }
      }
    },
    watch: {
      site() {
        this.message.active = false
        const query = Object.assign({}, this.$route.query)

        if (this.site !== null) {
          query.sidSite = this.site.sid

          // SCHIEVER
          if (this.site.concept.id === 18) query.sidSalesChannel = 6
          else query.sidSalesChannel = this.site.concept.id === 5 ? 5 : 99

          this.$router.push({ name: this.$route.name, params: this.$route.params, query })
        }

        this.isHyperOrProxy = +this.site.concept.id === 5 || +this.site.concept.id === 1 || +this.site.concept.id === 2
        this.animation.sidSite = this.site.sid

        // SCHIEVER
        if (this.site.concept.id === 18) this.animation.sidSalesChannel = 6
        else this.animation.sidSalesChannel = this.site.concept.id === 5 ? 5 : 99

        this.$emit('update', this.animation)
      }
    },
    async created() {
      if (this.$route.params && this.$route.params.sid) {
        animationService
          .findById(this.$route.params.sid)
          .then(animation => {
            this.animation = animation
            this.isModification = true
            nomenclatureGeographicService.getSiteBySid(to.query.sidSite).then(site => {
              this.site = site

              // SCHIEVER
              if (+site.concept.id === 18) this.animation.sidSalesChannel = 6
              else this.animation.sidSalesChannel = this.site.concept.id === 5 ? 5 : 99

              this.$emit('init', this.animation)
            })
          })
          .catch(() => this.$router.push({ name: 'animate-store' }))
      } else {
        this.$emit('init', null)
      }

      if (this.$route.query.sidSite) {
        nomenclatureGeographicService.getSiteBySid(this.$route.query.sidSite).then(site => {
          this.site = site

          // SCHIEVER
          if (+site.concept.id === 18) this.animation.sidSalesChannel = 6
          else this.animation.sidSalesChannel = this.site.concept.id === 5 ? 5 : 99

          this.isLoading = false
        })
      } else {
        this.site = null
        this.animation.sidSalesChannel = 99
        this.isLoading = false
      }
    },
    methods: {
      next() {
        this.isSaving = true
        this.message.active = false

        if (this.$route.params.sid) {
          animationService
            .save(this.$route.params.sid, this.animation)
            .then(animation => {
              this.$router.push({ name: 'animate-media', params: { sid: animation.sid }, query: this.$route.query })
            })
            .catch(() => {
              this.message.active = true
              this.message.value = this.$t('ANIMATE_STORE.ERROR.SAVING')
            })
            .finally(() => (this.isSaving = false))
        } else {
          this.$router.push({ name: 'animate-media', params: this.$route.params, query: this.$route.query })
        }
      },
      back() {
        delete this.$route.query.mode
        this.$router.push({ name: 'animate-home', params: this.$route.params, query: this.$route.query })
      }
    }
  }
</script>

<style scoped lang="scss">
  @import '~@/styles/_colors';

  .animate-store {
    border-top: 1px solid $color-grey-light;
    flex: 1;
    background: white;
    border-radius: 2px;
    overflow: auto;
    display: flex;
    flex-direction: column;

    h4 {
      padding: 2rem;
      margin: 0;
    }

    .content {
      overflow: auto;
      flex-direction: column;
      padding: 0 2rem;
      flex: 1;
      display: flex;
    }
    .actions {
      padding: 2rem;
      justify-content: flex-end;
      display: flex;

      button:not(:last-child) {
        margin-right: 1em;
      }
    }
  }
</style>
