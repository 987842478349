<template>
    <div class="app-search">
        <i class="material-icons">search</i>
        <input :placeholder="placeholder"
               type="text"
               v-model.trim="inputTerm"
               class="search-input">
    </div>
</template>

<script>
export default {
  name: 'AppSearch',
  props: {
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      active: false,
      inputTerm: this.value,
    };
  },
  watch: {
    inputTerm(val) {
      this.$emit('input', val);
    },
  },

  methods: {
    setValue(value) {
      this.$emit('input', value);
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/styles/_colors";

.app-search {
    position: relative;
    padding: 0 1em;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    border-radius: 40px;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
    background: #f7f7f7;

    .search-input {
        padding-left: .5em;
        font-size: 14px;
        background: none;
        color: $color-grey-dark;
        width: 100%;
        height: 20px;
        border: none;
        outline: none;
    }
}
</style>
