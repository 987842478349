
const isCentral = user => {
  return user.site?.siteType === 'HO'
}

const cleanupJob = user => {
  if (!user) return user
  const jobs = user.jobs
  if (jobs && jobs.length === 1)  {
    user.job = jobs[0].label
    }
  return user
}

const cleanupSite = user => {
  if (!user) return user
  user.site ??= {}
  user.format = user.site.siteFormat === 'PRO' ? 2 : 1 // Formats: hyper (1), proxy (2)
  user.siteCode = user.site.idHr
  user.idSiteAllocation = user.site.site
  return user
}


module.exports = {
  isCentral,
  cleanupJob,
  cleanupSite
}
