<template>
  <div/>
</template>

<script>
import nomenclatureGeographicService from '@/services/nomenclatureGeographicService';

export default {
  name: 'DolmenView',
  created() {
    nomenclatureGeographicService.getSiteById(this.$route.params.site)
      .then(site => {
        const query = {
          sidSite: site.sid,
          sidSalesChannel: 99,
        };

        if (site.concept.id === 18) {
          query.sidSalesChannel = 6;
        } else if (site.format.id === 4) {
          query.sidSalesChannel = 5;
        }

        this.$router.push({ name: 'analyze-sales-formula-pyramid', query });
      });
  },
};
</script>
