<template>
  <div class="animate-segmentation">
    <app-loading v-if="isSaving || isLoading" />
    <h4>{{ $t('ANIMATE_SEGMENTATION.TITLE') }}</h4>

    <div class="segmentation-selector">
      <md-list>
        <md-list-item v-if="isHyper" :class="{ active: showFormat }" @click="showFormat = !showFormat">
          <span>Format</span>
          <md-button class="md-icon-button md-list-action">
            <md-icon class="md-primary">keyboard_arrow_right</md-icon>
          </md-button>
        </md-list-item>
        <md-divider class="md-inset" />

        <div v-for="(segmentation, idx) in segmentations" :key="'segmentation_' + idx">
          <md-list-item
            :class="selectedSegmentation && selectedSegmentation.id === segmentation.id && !showFormat ? 'active' : ''"
            @click="selectSegmentation(segmentation)">
            <span>{{ segmentation.label }}</span>

            <div class="list-actions">
              <md-badge
                v-if="numberSelectedSegmentsIcon(segmentation.id)"
                :md-content="numberSelectedSegmentsIcon(segmentation.id)"
                class="md-accent" />
              <md-button class="md-icon-button md-list-action">
                <md-icon class="md-primary">keyboard_arrow_right</md-icon>
              </md-button>
            </div>
          </md-list-item>
          <md-divider class="md-inset" />
        </div>
      </md-list>

      <div v-if="showFormat && isHyper" class="container-cards">
        <div class="cards formats">
          <div
            v-for="(format, idx) in formats"
            :key="'format' + idx"
            :class="{ active: selectedFormats.length === 2 || selectedFormats[0] === format.id || selectedFormats[1] === format.id }"
            class="card"
            @click="selectFormat(format)">
            <p class="card-title">{{ format.label }}</p>
          </div>
        </div>
      </div>

      <div v-if="selectedSegmentation && !showFormat" class="container-cards">
        <div class="cards">
          <div
            v-for="(children, idx) in childrensSegmentation.childrens"
            :key="'segmentation_children' + idx"
            :class="children.active ? 'active' : ''"
            class="card"
            @click="selectCardSegmentation(children)">
            <div class="left">
              <p class="card-title">{{ children.label }}</p>
              <p class="card-description">{{ children.description }}</p>
            </div>
            <div class="right">
              <md-progress-spinner v-if="weightIsLoading" :md-diameter="10" :md-stroke="2" md-mode="indeterminate" />
              <p v-if="!weightIsLoading" class="weight">{{ children.weight }}%</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="actions">
      <div class="left">
        <app-button class="link" :disabled="isSaving" @click.native="ignore">
          {{ $t('ACTIONS.IGNORE') }}
        </app-button>
      </div>
      <div class="right">
        <app-button :disabled="isSaving" @click.native="back()">
          {{ $t('ACTIONS.BACK') }}
        </app-button>

        <app-button class="primary" :disabled="isSaving" @click.native="next()">
          {{ $t('ACTIONS.NEXT') }}
        </app-button>
      </div>
    </div>
  </div>
</template>

<script>
  import AppButton from '@/components/app-button/AppButton.vue'
  import segmentationService from '@/services/segmentationService'
  import AppLoading from '@/components/app-loading/AppLoading.vue'
  import animationService from '@/services/animationService'
  import salesChannelService from '@/services/salesChannelService'
  import targetCountService from '@/services/targetCountService'
  import { debounce, remove } from 'lodash'

  export default {
    name: 'AnimateSegmentation',
    components: {
      AppButton,
      AppLoading
    },
    data() {
      return {
        selectedSegmentation: null,
        animation: {},
        isSaving: false,
        weightIsLoading: true,
        showFormat: true,
        formats: null,
        selectedFormats: [],
        segmentations: [],
        selectedSegmentsOly: [],
        selectedSegmentsCdv: [],
        communities: [],
        message: {
          active: false,
          value: null
        }
      }
    },
    computed: {
      childrensSegmentation() {
        return this.segmentations.find(segmentation => segmentation.id === this.selectedSegmentation.id)
      },
      isHyper() {
        return +this.$route.query.sidSalesChannel === 1 || +this.$route.query.sidSalesChannel === 99
      }
    },
    async created() {
      await animationService.findById(this.$route.params.sid).then(animation => {
        this.animation = animation
        this.$emit('init', this.animation)
        if (this.animation.sidSalesChannel) {
          this.selectedFormats = []
          if (+animation.sidSalesChannel === 99) {
            this.selectedFormats.push(1, 3)
          } else {
            this.selectedFormats.push(+animation.sidSalesChannel)
          }
        }
        if (this.animation.segmentsOly) {
          this.selectedSegmentsOly = animation.segmentsOly.split(',')
        }
        if (this.animation.segmentsCdv) {
          this.selectedSegmentsCdv = animation.segmentsCdv.split(',')
        }
      })

      this.getDatas()
      this.formats = salesChannelService.getAllDistinct()
    },
    methods: {
      getDatas() {
        this.isLoading = true
        const params = {
          siteSid: this.$route.query.sidSite
        }
        segmentationService.getAllSegmentation(params).then(segmentations => {
          this.isLoading = false
          this.segmentations = segmentations.map(segmentation => ({
            ...segmentation,
            childrens: segmentation.segmentationChildren
              .map(children => ({
                ...children,
                active: this.childrenIsActive(segmentation, children)
              }))
              .sort((a, b) => a.orderBy - b.orderBy)
          }))
        })
      },
      childrenIsActive(segmentation, children) {
        if (segmentation.id === 1 && this.selectedSegmentsOly.length > 0) {
          return this.selectedSegmentsOly.find(sid => +sid === children.sid)
        }
        if (segmentation.id === 2 && this.selectedSegmentsCdv.length > 0) {
          return this.selectedSegmentsCdv.find(sid => +sid === children.sid)
        }
        return false
      },
      selectSegmentation(selectedSegmentation) {
        this.selectedSegmentation = selectedSegmentation
        this.showFormat = false
        this.weightIsLoading = true

        targetCountService
          .getTargetCount(
            this.$route.query.mode === 'community',
            this.animation.groupTypes,
            this.animation.sidSite,
            this.animation.sidSalesChannel,
            this.animation.nomenclatures,
            selectedSegmentation.id,
            this.animation.segmentsOly || '0',
            this.animation.segmentsCdv || '0',
            this.animation.activationEasinessAreas || null,
            this.animation.neighborhoodTypes || null,
            this.animation.shape || null,
            this.animation.startDate || null,
            this.animation.endDate || null,
            this.animation.startRankTopCustomers || null,
            this.animation.sid,
            0,
            this.animation.ouiPubZone
          )
          .then(count => {
            this.weightIsLoading = false
            const currentSeg = this.segmentations.find(segmentation => segmentation.id === this.selectedSegmentation.id)
            currentSeg.childrens.forEach(segmentation => {
              segmentation.weight = 0

              const currentCount = count.find(dim => {
                if (dim) return +dim.dimension === segmentation.sid
                return 0
              })

              if (currentCount) {
                segmentation.weight = ((currentCount.weightNumberClients / currentCount.numberTargetClients) * 100).toFixed(1)
              }
            })
          })
      },
      selectCardSegmentation(children) {
        children.active = !children.active
        this.getSelectedCardBySegmentation()
      },
      selectFormat(format) {
        const formatExist = this.selectedFormats.find(selectedFormat => format.id === selectedFormat)
        if (formatExist && this.selectedFormats.length === 2) {
          this.selectedFormats = remove(this.selectedFormats, selectedFormat => formatExist === selectedFormat)
        }

        if (!formatExist) this.selectedFormats.push(format.id)
        this.animation.sidSalesChannel = this.selectedFormats.length === 2 ? 99 : +format.id
        this.$emit('update', this.animation)
      },
      getSelectedCardBySegmentation() {
        this.selectedSegmentsCdv = []
        this.selectedSegmentsOly = []
        this.communities = []

        // TODO: REFACTOR
        this.segmentations.forEach(segmentation => {
          segmentation.childrens.forEach(children => {
            if (children.active) {
              if (segmentation.id === 1) {
                this.selectedSegmentsOly.push(children.sid)
              } else if (segmentation.id === 2) {
                this.selectedSegmentsCdv.push(children.sid)
              } else if (segmentation.id === 999) {
                this.communities.push(children.sid)
              }
            }
          })
        })

        this.updateTarget()
      },
      updateTarget: debounce(function() {
        this.animation.segmentsOly = this.selectedSegmentsOly.length > 0 ? this.selectedSegmentsOly.join(',') : null
        this.animation.segmentsCdv = this.selectedSegmentsCdv.length > 0 ? this.selectedSegmentsCdv.join(',') : null
        this.animation.groupTypes = this.communities.length > 0 ? this.communities : null
        this.$emit('update', this.animation)
      }, 1000),
      numberSelectedSegmentsIcon(segmentationId) {
        if (+segmentationId === 1) {
          return this.selectedSegmentsOly.length
        }
        if (+segmentationId === 2) {
          return this.selectedSegmentsCdv.length
        }
        return null
      },
      next() {
        this.isSaving = true
        this.message.active = false

        animationService
          .save(this.animation.sid, this.animation)
          .then(() =>
            this.$router.push({
              name: 'animate-geographic',
              params: this.$route.params,
              query: this.$route.query
            })
          )
          .catch(() => {
            this.message.active = true
            this.message.value = this.$t('ANIMATE_SEGMENTATION.ERROR.SAVING')
          })
          .finally(() => (this.isSaving = false))
      },
      back() {
        this.$router.push({ name: 'animate-market', params: this.$route.params, query: this.$route.query })
      },
      ignore() {
        this.$router.push({ name: 'animate-geographic', params: this.$route.params, query: this.$route.query })
      }
    }
  }
</script>

<style scoped lang="scss">
  @import '~@/styles/_colors';
  @import '~@/styles/_variables';

  .animate-segmentation {
    border-top: 1px solid $color-grey-light;
    flex: 1;
    background: white;
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    h4 {
      padding: 2rem;
      margin: 0;
    }

    .segmentation-selector {
      display: flex;
      flex: 1;
      border-bottom: 1px solid $color-grey-light;
      .selected {
        background-color: $primary-color-with-opacity;
      }

      .list-actions {
        display: flex;
        align-items: center;
      }

      .md-list-action:last-of-type {
        margin-left: 0;
      }

      .md-list {
        border-right: 1px solid $color-grey-light;
        width: 40%;
        .active {
          background-color: $primary-color-with-opacity;
          span {
            color: $primary-color;
            font-weight: bold;
          }
        }
      }
    }

    .container-cards {
      padding: 1em;
      width: 100%;
      height: 100%;
      overflow: auto;
      .definition {
        margin: 0 0 1em 0;
        font-size: 12px;
        color: $color-grey-dark;
      }

      .cards {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 1vw;
        &.formats {
          grid-template-columns: repeat(3, 1fr);
        }
        .card {
          height: 4em;
          cursor: pointer;
          display: flex;
          align-items: center;
          padding: 1em;
          box-shadow: $box-shadow-hight;
          transition: 0.25s ease all;
          justify-content: space-between;
          &:hover {
            background-color: $color-grey-light-2;
          }

          &.active {
            background-color: $primary-color-with-opacity;
          }

          p {
            margin: 0;
            text-transform: lowercase;
          }

          p:first-letter {
            text-transform: uppercase;
          }

          .card-title {
            font-size: 14px;
            font-weight: bold;
          }
          .card-description {
            font-size: 12px;
            color: $color-grey-dark;
          }

          .right {
            height: 100%;
            display: flex;
            align-items: center;
            width: 3.1em;
            text-align: right;
            justify-content: flex-end;
          }

          .weight {
            font-weight: bold;
            color: $primary-color;
            font-size: 0.8em;
          }
        }
      }
    }

    .actions {
      padding: 2rem;
      justify-content: space-between;
      display: flex;

      .md-checkbox {
        margin: 0;
      }

      .right {
        display: flex;
        button:first-child {
          margin-right: 1em;
        }
      }
    }
  }
</style>
