<template>
  <div class="animate-top-client">
    <app-loading v-if="isSaving" />

    <div class="content">
      <h4>{{ $t('ANIMATE_TOP_CLIENT.TITLE') }}</h4>
      <div class="container-btn">
        <app-button-toggle :inline="true" :value="selectedClients" :values="clients" @input="setClients" />
      </div>

      <div v-if="selectedClients.id && selectedClients.id === 'all'">
        <h4>{{ $t('ANIMATE_TOP_CLIENT.CHOOSE_CLIENT_NUMBER') }}</h4>
        <div class="container-btn">
          <app-loading v-if="isLoadingCount" />
          <app-button-toggle v-else :value="selectedNumberClients" :values="actifsClients" @input="setNumberClients" />
        </div>
      </div>

      <div v-if="selectedClients.id && selectedClients.id === 'custom'">
        <app-datepicker v-model="selectedPeriod" :active-helpers="false" :max="maxDate" :min="minDate" />

        <h4>
          {{ $t('ANIMATE_TOP_CLIENT.CHOOSE_CLIENT_NUMBER') }} :
          <span>{{ selectedPeriod.start | date('DD MMM YYYY') }}</span>
          -
          <span>{{ selectedPeriod.end | date('DD MMM YYYY') }}</span>
        </h4>
        <div class="container-btn">
          <app-loading v-if="isLoadingCount" />
          <app-button-toggle v-else :value="selectedNumberClients" :values="customClients" @input="setNumberClients" />
        </div>
      </div>
    </div>

    <div class="actions">
      <div class="left">
        <app-button class="link" :disabled="isSaving" @click.native="ignore">
          {{ $t('ACTIONS.IGNORE') }}
        </app-button>
      </div>
      <div class="right">
        <app-button :disabled="isSaving" @click.native="back()">
          {{ $t('ACTIONS.BACK') }}
        </app-button>

        <app-button class="primary" :disabled="isSaving" @click.native="next()">
          {{ $t('ACTIONS.NEXT') }}
        </app-button>
      </div>
    </div>
  </div>
</template>

<script>
  import AppButton from '@/components/app-button/AppButton.vue'
  import AppLoading from '@/components/app-loading/AppLoading.vue'
  import AppDatepicker from '@/components/app-datepicker/AppDatepicker.vue'
  import AppButtonToggle from '@/components/app-button-toggle/AppButtonToggle.vue'
  import animationService from '@/services/animationService'
  import targetCountService from '@/services/targetCountService'
  import moment from 'moment'
  import { debounce } from 'lodash'

  export default {
    name: 'AnimateTopClient',
    components: {
      AppButtonToggle,
      AppDatepicker,
      AppButton,
      AppLoading
    },
    data() {
      return {
        isSaving: false,
        isLoadingCount: true,
        selectedNumberClients: {},
        selectedClients: {},
        animation: {},
        countTarget: null,
        selectedPeriod: {
          start: moment()
            .subtract(1, 'months')
            .hours(0)
            .minutes(0)
            .seconds(0)
            .toDate(),
          end: moment()
            .subtract(1, 'days')
            .hours(23)
            .minutes(59)
            .seconds(59)
            .toDate()
        },
        maxDate: moment()
          .subtract(1, 'days')
          .hours(0)
          .minutes(0)
          .seconds(0)
          .toDate(),
        minDate: moment()
          .subtract(1, 'year')
          .hours(0)
          .minutes(0)
          .seconds(0)
          .toDate(),
        clients: [
          {
            id: 'all',
            label: this.$t('ANIMATE_TOP_CLIENT.ALL_CLIENT')
          },
          {
            id: 'custom',
            label: this.$t('ANIMATE_TOP_CLIENT.CUSTOM_CLIENT')
          }
        ],
        actifsClients: [],
        customClients: [],
        message: {
          active: false,
          value: null
        }
      }
    },
    watch: {
      selectedPeriod() {
        this.updateTarget()
      },
      selectedNumberClients() {
        this.updateTarget()
      }
    },
    async created() {
      animationService.findById(this.$route.params.sid).then(animation => {
        this.animation = animation
        this.getTargetCount(animation)
        this.$emit('init', this.animation)
        if (animation.startRankTopCustomers) {
          this.selectedPeriod = { start: moment(animation.startDate).toDate(), end: moment(animation.endDate).toDate() }
          this.selectedClients = this.clients.find(client => {
            const type = animation.startDate ? 'custom' : 'all'
            return client.id === type
          })
        }
      })
    },
    methods: {
      updateTarget: debounce(function() {
        this.animation.startRankTopCustomers = this.selectedNumberClients.id
        if (this.selectedClients.id === 'custom') {
          this.animation.startDate = moment(this.selectedPeriod.start)
            .format('YYYY-MM-DD')
            .toString()
          this.animation.endDate = moment(this.selectedPeriod.end)
            .format('YYYY-MM-DD')
            .toString()
        } else {
          this.animation.startDate = null
          this.animation.endDate = null
        }

        this.$emit('update', this.animation)
      }, 1000),
      getTargetCount(animation) {
        targetCountService
          .getTargetCount(
            this.$route.query.mode === 'community',
            this.animation.groupTypes,
            animation.sidSite,
            animation.sidSalesChannel,
            animation.nomenclatures,
            animation.idSegType || null,
            animation.segmentsOly || null,
            animation.segmentsCdv || null,
            animation.activationEasinessAreas || null,
            animation.neighborhoodTypes || null,
            animation.shape || null,
            animation.startDate || null,
            animation.endDate || null,
            animation.startRankTopCustomers || null,
            animation.sid,
            0,
            this.animation.ouiPubZone
          )
          .then(count => {
            this.countTarget = count[0]
            this.isLoadingCount = false
            this.actifsClients.push(
              {
                id: 'all',
                label: `${this.$options.filters.number(this.countTarget.numberTargetClients, 0)} ${this.$t('ANIMATE_TOP_CLIENT.CLIENT')}`
              },
              {
                id: '500',
                label: `${this.$t('ANIMATE_TOP_CLIENT.TOP')} <b>${500}</b>`,
                disabled: this.countTarget.numberTargetClients < 500
              },
              {
                id: '1000',
                label: `${this.$t('ANIMATE_TOP_CLIENT.TOP')} <b>${1000}</b>`,
                disabled: this.countTarget.numberTargetClients < 1000
              },
              {
                id: '2000',
                label: `${this.$t('ANIMATE_TOP_CLIENT.TOP')} <b>${2000}</b>`,
                disabled: this.countTarget.numberTargetClients < 2000
              }
            )

            this.customClients.push(
              {
                id: 'inactive',
                label: this.$t('ANIMATE_TOP_CLIENT.INACTIVE')
              },
              {
                id: 'all',
                label: this.$t('ANIMATE_TOP_CLIENT.ACTIVE_PERIOD')
              },
              {
                id: '500',
                label: `${this.$t('ANIMATE_TOP_CLIENT.TOP')} <b>${500}</b>`,
                disabled: this.countTarget.numberTargetClients < 500
              },
              {
                id: '1000',
                label: `${this.$t('ANIMATE_TOP_CLIENT.TOP')} <b>${1000}</b>`,
                disabled: this.countTarget.numberTargetClients < 1000
              },
              {
                id: '2000',
                label: `${this.$t('ANIMATE_TOP_CLIENT.TOP')} <b>${2000}</b>`,
                disabled: this.countTarget.numberTargetClients < 2000
              }
            )
          })
      },
      setNumberClients(number) {
        this.selectedNumberClients = number
      },
      setClients(clients) {
        this.selectedClients = clients
      },
      next() {
        this.isSaving = true
        this.message.active = false
        this.animation.startRankTopCustomers = this.selectedNumberClients.id

        if (this.selectedClients.id === 'custom') {
          this.animation.startDate = moment(this.selectedPeriod.start)
            .format('YYYY-MM-DD')
            .toString()
          this.animation.endDate = moment(this.selectedPeriod.end)
            .format('YYYY-MM-DD')
            .toString()
        } else {
          this.animation.startDate = null
          this.animation.endDate = null
        }

        animationService
          .save(this.animation.sid, this.animation)
          .then(() => this.$router.push({ name: 'animate-resume', params: this.$route.params, query: this.$route.query }))
          .catch(() => {
            this.message.active = true
            this.message.value = this.$t('ANIMATE_TOP_CLIENT.ERROR.SAVING')
          })
          .finally(() => (this.isSaving = false))
      },
      back() {
        this.$router.push({ name: 'animate-geographic', params: this.$route.params, query: this.$route.query })
      },
      ignore() {
        this.$router.push({ name: 'animate-resume', params: this.$route.params, query: this.$route.query })
      }
    }
  }
</script>

<style scoped lang="scss">
  @import '~@/styles/_colors';
  @import '~@/styles/_variables';

  .animate-top-client {
    border-top: 1px solid $color-grey-light;
    flex: 1;
    background: white;
    display: flex;
    flex-direction: column;

    .app-loading {
      width: 100%;
    }

    .app-datepicker {
      margin-top: 2em;
    }

    :global(.calendar.popover-container) {
      border: 2px solid $primary-color;
      padding: 0 1em;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 16em;
      height: 3em;
    }
    :global(input) {
      transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1);
      border: none;
      border-radius: 0px;
      font-size: 14px;
      outline: none;
      width: 100%;
      background: transparent;
      padding: 0;

      &:focus {
        border-color: $primary-color;
      }
    }

    .content {
      padding: 0 2em 2em 2em;
      border-bottom: 1px solid $color-grey-light;
      overflow: auto;
      flex: 1;
      h4 {
        font-weight: 500;
        span {
          color: $primary-color;
        }
        &:not(:first-child) {
          margin: 3em 0 1em 0;
        }
      }
      .container-btn {
        display: flex;
        button {
          margin-right: 1em;
        }
      }
    }

    .actions {
      padding: 2rem;
      justify-content: space-between;
      display: flex;

      .md-checkbox {
        margin: 0;
      }

      .right {
        display: flex;
        button:first-child {
          margin-right: 1em;
        }
      }
    }
  }
</style>
