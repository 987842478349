<template>
    <app-context-container :image-id="imageId"
                           class="app-context-nomenclature">
        <template slot="title">{{ $t('APP_CONTEXT_NOMENCLATURE.LABEL') }}</template>
        <template slot="main">
            <app-nomenclature-selector
                    v-model="selectedMarkets"
                    :init-selected-id-markets="initSelectedIdMarkets"
                    :is-only-one-market="isOnlyOneMarket">
            </app-nomenclature-selector>
        </template>
        <template slot="actions">
            <app-button @click.native="cancel"
                        class="link">
                {{ $t('ACTIONS.CANCEL') }}
            </app-button>
            <app-button @click.native="valid"
                        class="primary">
                {{ $t('ACTIONS.VALID') }}
            </app-button>
        </template>
    </app-context-container>
</template>

<script>
import AppButton from '../../app-button/AppButton.vue';
import AppLoading from '../../app-loading/AppLoading.vue';
import AppNomenclatureSelector from '../../app-nomenclature-selector/AppNomenclatureSelector.vue';
import AppContextContainer from '../container/AppContextContainer.vue';

export default {
  name: 'AppContextNomenclature',
  components: {
    AppNomenclatureSelector,
    AppContextContainer,
    AppButton,
    AppLoading,
  },
  props: {
    value: {
      type: Array,
      default: () => ([]),
    },
    initSelectedIdMarkets: {
      type: Array,
      default: () => ([]),
    },
    isOnlyOneMarket: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedMarkets: this.value,
    };
  },
  computed: {
    imageId() {
      const id = this.value.length === 0 ? 'default' : this.value[0].id;
      return `nomenclature/${id}`;
    },
  },
  methods: {
    cancel() {
      this.$emit('cancel');
    },
    valid() {
      this.$emit('input', this.selectedMarkets);
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/styles/_colors";

.app-context-nomenclature {
    .global {
        padding: 1rem;
        text-align: center;
    }

    .or {
        text-align: center;
        color: $color-grey;
        text-transform: uppercase;
    }
}
</style>
