<template>
  <div class="animate-media">
    <app-loading v-if="isSaving" />
    <h4>{{ $t('ANIMATE_MEDIA.TITLE') }}</h4>

    <div class="content">
      <app-button-toggle :value="selectedMedia" :values="medias" @input="setMedia" />

      <div class="legend-media">
        <p class="info">{{ $t('ANIMATE_MEDIA.PRICE_FRANCE') }}</p>
      </div>

      <app-text-field v-model.trim="animation.name" :label="$t('ANIMATE_MEDIA.CAMPAIGN_NAME')" />

      <p class="warning">
        <md-icon>error</md-icon>
        {{ $t('ANIMATE_MEDIA.WARNING_NAME') }}
      </p>
    </div>

    <div class="actions">
      <app-button :disabled="isSaving" @click.native="back">
        {{ $t('ACTIONS.BACK') }}
      </app-button>
      <app-button :disabled="!animation.name || !animation.communicationChannel || isSaving" class="primary" @click.native="next">
        {{ $t('ACTIONS.NEXT') }}
      </app-button>
    </div>

    <md-snackbar :md-active.sync="message.active" :md-duration="Infinity" md-persistent md-position="left">
      {{ message.value }}
    </md-snackbar>
  </div>
</template>

<script>
  import AppButtonToggle from '@/components/app-button-toggle/AppButtonToggle.vue'
  import AppButton from '@/components/app-button/AppButton.vue'
  import AppLoading from '@/components/app-loading/AppLoading.vue'
  import AppTextField from '@/components/app-text-field/AppTextField.vue'
  import animationService from '@/services/animationService'

  export default {
    name: 'AnimateMedia',
    components: {
      AppButtonToggle,
      AppTextField,
      AppButton,
      AppLoading
    },
    data() {
      return {
        isSaving: false,
        isInError: false,
        animation: {},
        medias: [
          {
            id: 'sms',
            label: this.$t('ANIMATE_MEDIA.SMS'),
            icon: 'sms'
          },
          {
            id: 'br',
            label: this.$t('ANIMATE_MEDIA.BR'),
            icon: 'receipt'
          }
        ],
        selectedMedia: {},
        message: {
          active: false,
          value: null
        }
      }
    },
    async created() {
      if (this.$route.params.sid) {
        animationService
          .findById(this.$route.params.sid)
          .then(animation => {
            this.animation = animation
            this.selectedMedia = { id: animation.communicationChannel }
            this.$emit('init', this.animation)
          })
          .catch(() => this.$router.push({ name: 'animate-media' }))
      } else {
        this.$emit('init', null)
      }
    },
    methods: {
      setMedia(media) {
        this.selectedMedia = media
        this.animation.communicationChannel = media.id
      },
      next() {
        let promise
        this.isSaving = true
        this.message.active = false
        this.animation.sidSite = this.$route.query.sidSite
        this.animation.isCommunityTarget = this.$route.query.mode === 'community'

        if (this.isModification) {
          promise = animationService.save(this.$route.params.sid, this.animation)
        } else {
          promise = animationService.create(this.animation)
        }

        promise
          .then(animation => {
            this.$router.push({
              name: this.$route.query.mode === 'customers' ? 'animate-market' : 'animate-community',
              params: { sid: animation.sid },
              query: this.$route.query
            })
          })
          .catch(() => {
            this.message.active = true
            this.message.value = this.$t('ANIMATE_MEDIA.ERROR.SAVING')
          })
          .finally(() => (this.isSaving = false))
      },
      back() {
        this.$router.push({ name: 'animate-store', params: this.$route.params, query: this.$route.query })
      }
    }
  }
</script>

<style scoped lang="scss">
  @import '~@/styles/_colors';

  .animate-media {
    border-top: 1px solid $color-grey-light;
    flex: 1;
    background: white;
    border-radius: 2px;
    overflow: auto;

    h4 {
      padding: 2rem;
      margin: 0;
    }

    .content {
      padding: 2rem;

      .legend-media {
        margin: 0.5em 0 2em 0;

        .info {
          margin: 0;
          font-size: 12px;
          color: $color-grey;
        }
      }

      .warning {
        margin-top: 3rem;
        font-size: 13px;
        color: $color-grey-dark;
      }
    }

    .actions {
      padding: 2rem;
      justify-content: flex-end;
      display: flex;

      button:not(:last-child) {
        margin-right: 1em;
      }
    }
  }
</style>
