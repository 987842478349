<template>
    <div class="app-geographic-selector">
        <app-loading v-if="isLoading"></app-loading>

        <div class="app-geographic-selector-main" v-else>
            <div class="omnibox">
                <app-search :placeholder="$t('APP_GEOGRAPHIC_SELECTOR.PLACEHOLDER')"
                            v-model="term">
                </app-search>
            </div>
            <div v-if="term.length > 0" class="result-search">
                <p>{{ $t('APP_GEOGRAPHIC_SELECTOR.RESULTS_SITES') }}:</p>
                <span v-if="resultsByTerm.length === 0">{{ $t('ACTIONS.NO_RESULTS') }}</span>
                <md-chip md-clickable
                         :class="{ 'md-primary' : value !== null && value.sid === result.sid, 'living-area': result.level === 32}"
                         :key="`result_${idx}`"
                         v-for="(result, idx) in resultsByTerm"
                         @click="setContextGeo(result)">
                    <span v-if="result.level === 32">{{ result.label }}</span>
                    <span v-else>({{ result.id }}) {{ result.label }}</span>
                </md-chip>
            </div>

            <div v-if="term.length === 0" class="site-selector">
                <md-list :class="selectedTerritory ? 'slim': ''">

                    <md-list-item v-if="!hideArf" md-expand :md-expanded.sync="expandContext">
                        <md-icon>store</md-icon>
                        <span class="md-list-item-text">{{ $t('APP_GEOGRAPHIC_SELECTOR.ALL_FRANCE') }} :</span>
                        <md-list slot="md-expand">
                            <md-list-item @click="all = 100">
                                {{ $t('APP_GEOGRAPHIC_SELECTOR.ARF') }}
                                <md-radio v-model="all" :value="100"/>
                            </md-list-item>
                            <md-list-item @click="all = 99">
                                {{ $t('APP_GEOGRAPHIC_SELECTOR.NATIONAL') }}
                                <md-radio v-model="all" :value="99"/>
                            </md-list-item>
                            <md-list-item @click="all = 5">
                                {{ $t('APP_GEOGRAPHIC_SELECTOR.SUPER') }}
                                <md-radio v-model="all" :value="5"/>
                            </md-list-item>
                            <md-list-item @click="all = 1">
                                {{ $t('APP_GEOGRAPHIC_SELECTOR.HYPER') }}
                                <md-radio v-model="all" :value="1"/>
                            </md-list-item>
                            <md-list-item @click="all = 3">
                                {{ $t('APP_GEOGRAPHIC_SELECTOR.DRIVE') }}
                                <md-radio v-model="all" :value="3"/>
                            </md-list-item>
                        </md-list>
                    </md-list-item>
                    <md-divider class="md-inset"></md-divider>
                    <div :key="`territory_${territory.id}`"
                         v-for="territory in territories">
                        <md-list-item @click="selectTerritory(territory)"
                                      :class="selectedTerritory && selectedTerritory.id === territory.id ? 'selected' : ''">
                            {{ territory.label }}
                            <div class="actions">
                                <md-button class="md-icon-button md-list-action">
                                    <md-icon class="md-primary">keyboard_arrow_right</md-icon>
                                </md-button>
                            </div>
                        </md-list-item>
                        <md-divider class="md-inset"></md-divider>
                    </div>
                </md-list>

                <md-list v-if="selectedTerritory" :class="selectedTerritory ? 'slim': ''">
                    <div :key="`livingArea_${livingArea.id}`"
                         v-for="livingArea in filteredLivingAreas">
                        <md-list-item @click="selectLivingArea(livingArea)"
                                      :class="selectedLivingArea && selectedLivingArea.id === livingArea.id ? 'selected' : ''">
                            <p>{{ livingArea.label }}</p>
                            <div class="actions">
                                <div class="radio" v-if="!hideSelectLivingArea">
                                    <md-radio v-model="selectedContextGeo" :value="livingArea"/>
                                    <md-tooltip v-if="livingArea.concept.id !== 18"
                                                md-direction="left">
                                        {{ $t('APP_GEOGRAPHIC_SELECTOR.SELECT_LIVING_AREA')}}
                                    </md-tooltip>
                                </div>

                                <md-button class="md-icon-button md-list-action">
                                    <md-icon class="md-primary">keyboard_arrow_right</md-icon>
                                </md-button>
                            </div>
                        </md-list-item>
                        <md-divider class="md-inset"></md-divider>
                    </div>
                </md-list>

                <md-list v-if="selectedLivingArea" :class="selectedLivingArea ? 'slim': ''">
                    <div :key="`site_${site.id}`"
                         v-for="site in filteredSites">
                        <md-list-item>
                            <p>
                                {{ site.label }}
                                <span>{{ site.format.label }}</span>
                            </p>

                            <div class="actions">
                                <md-radio v-model="selectedContextGeo" :value="site"/>
                                <md-tooltip md-direction="left">
                                    {{ $t('APP_GEOGRAPHIC_SELECTOR.SELECT_SITE')}}
                                </md-tooltip>
                            </div>
                        </md-list-item>
                        <md-divider class="md-inset"></md-divider>
                    </div>
                </md-list>
            </div>
        </div>

        <md-snackbar
                :md-active.sync="message.active"
                :md-duration="Infinity"
                md-persistent
                md-position="left">
            {{ message.value }}
        </md-snackbar>
    </div>
</template>

<script>
import AppSearch from '@/components/app-search/AppSearch.vue';
import nomenclatureGeographicService from '@/services/nomenclatureGeographicService';
import AppLoading from '../app-loading/AppLoading.vue';

export default {
  name: 'AppGeographicSelector',
  components: { AppLoading, AppSearch },
  props: {
    value: {
      type: [Object, Number],
      default: () => ({}),
    },
    hideArf: {
      type: Boolean,
      default: false,
    },
    hideSelectLivingArea: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedContextGeo: this.value || {},
      territories: null,
      selectedTerritory: null,
      livingAreas: null,
      selectedLivingArea: null,
      isLoading: false,
      expandContext: false,
      all: null,
      sites: [],
      selectedSite: null,
      resultsByTerm: [],
      term: '',
      message: {
        active: false,
        value: null,
      },
    };
  },
  created() {
    this.buildGeographic();
  },
  watch: {
    all(value) {
      if (value) {
        this.$emit('input', +value);
      }
    },
    selectedContextGeo() {
      this.$emit('input', this.selectedContextGeo);
    },
    term() {
      this.filterByTerm();
    },
  },
  computed: {
    filteredLivingAreas() {
      if (this.selectedTerritory === null) {
        return [];
      }

      return this.livingAreas.filter(livingArea => livingArea.parent.id === this.selectedTerritory.id);
    },
    filteredSites() {
      if (this.selectedLivingArea === null) {
        return [];
      }
      return this.sites.filter(site => {
        return site.parent.sid === this.selectedLivingArea.sid
      });
    },
  },
  methods: {
    buildGeographic() {
      this.isLoading = true;
      Promise
        .all([
          nomenclatureGeographicService.getAllTerritories(),
          nomenclatureGeographicService.getAllLivingAreas(),
          nomenclatureGeographicService.getAllSites(),
        ])
        .then(datas => {
          this.territories = datas[0];
          this.livingAreas = datas[1];
          this.sites = datas[2];
        })
        .catch(() => {
          this.message.active = true;
          this.message.value = this.$t('APP_GEOGRAPHIC_SELECTOR.ERROR');
        })
        .finally(() => {
          if (this.value) {
            const siteExist = this.sites.find(site => site.sid === +this.value.sid);
            if (siteExist) this.selectedContextGeo = siteExist;
            if (this.value.level === 40) {
              const selectedLivingArea = this.livingAreas.find(livingArea => livingArea.id === this.value.parent.id);
              if (selectedLivingArea) {
                this.selectedLivingArea = selectedLivingArea;
                const selectedTerritory = this.territories.find(territory => territory.id === selectedLivingArea.parent.id);
                if (selectedTerritory) this.selectedTerritory = selectedTerritory;
              }
            } else if (this.value.level === 32) {
              const selectedTerritory = this.territories.find(territory => territory.id === this.value.parent.id);
              if (selectedTerritory) this.selectedTerritory = selectedTerritory;
            }
          }
          this.isLoading = false;
        });
    },
    selectTerritory(selectedTerritory) {
      this.selectedTerritory = selectedTerritory;
      this.selectedLivingArea = null;
    },
    selectLivingArea(selectedLivingArea) {
      this.selectedLivingArea = selectedLivingArea;
    },
    setContextGeo(resultSearch) {
      this.selectedContextGeo = resultSearch;
    },
    filterByTerm() {
      this.term = this.term.toLowerCase();
      const allLivingAreasAndSites = [...this.sites, ...this.livingAreas];
      this.resultsByTerm = allLivingAreasAndSites.filter(result => result.label.toLowerCase().search(new RegExp(this.term)) !== -1 || result.id === +this.term);
    },
  },
};
</script>

<style scoped lang="scss">
    @import "~@/styles/_colors";

    $height-search: 5em;

    :global(.md-tooltip) {
        z-index: 99;
    }

    .app-geographic-selector {
        overflow: hidden;
        height: 100%;
        &-main {
            overflow: hidden;
            height: 100%;
            .site-selector {
                display: flex;
                height: calc(100% - #{$height-search});
                .selected {
                    background-color: $primary-color-with-opacity;
                }

                .md-list {
                    flex: 1;
                    &:not(:last-child) {
                        border-right: 1px solid $color-grey-light;
                    }
                    &.slim {
                        width: 30%;
                    }
                    .md-list-item-content > .md-radio:first-child {
                        margin-right: 0;
                    }
                    .md-list-action:last-of-type {
                        margin-left: 0;
                    }
                    .actions {
                        display: flex;
                        align-items: center;
                        position: relative;
                        :global(.md-radio) {
                            margin: 0;
                            :global(.md-radio-label) {
                                padding-left: 0;
                            }
                        }
                    }
                    p {
                        margin: 0 .5em 0 0;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        span {
                            color: $color-grey-dark;
                            font-size: .9rem;
                        }
                    }
                }
            }
        }

        .omnibox {
            padding: 1rem;
            height: $height-search;
            display: flex;
            align-items: center;
        }

        .result-search {
            padding: 0 1em 1em 1em;
            height: calc(100% - #{$height-search});
            overflow: auto;
            .md-chip {
                margin: .2em;
                &.living-area {
                    background-color: $primary-color-with-opacity;
                    &:hover {
                        background-color: $primary-color;
                    }
                }
                &:hover {
                    span {
                        color: #fff;
                    }
                }
                &.md-primary {
                    background-color: $primary-color;
                    span {
                        color: #fff;
                    }
                }
            }
            span {
                font-size: 12px;
                color: $color-grey-dark;
            }
        }
    }
</style>
