<template>
    <app-context-container :image-id="imageId"
                           class="app-context-geographic">
        <template slot="title">{{ $t('APP_CONTEXT_GEOGRAPHIC.LABEL') }}</template>
        <template slot="main">
            <app-geographic-selector v-model="selectedContextGeo"></app-geographic-selector>
        </template>
        <template slot="actions">
            <app-button @click.native="cancel"
                        class="link">
                {{ $t('ACTIONS.CANCEL') }}
            </app-button>
            <app-button :disabled="selectedContextGeo === null"
                        @click.native="valid"
                        class="primary">
                {{ $t('ACTIONS.VALID') }}
            </app-button>
        </template>
    </app-context-container>
</template>

<script>
import AppButton from '../../app-button/AppButton.vue';
import AppGeographicSelector from '../../app-geographic-selector/AppGeographicSelector.vue';
import AppContextContainer from '../container/AppContextContainer.vue';

export default {
  name: 'AppContextGeographic',
  components: {
    AppGeographicSelector,
    AppContextContainer,
    AppButton,
  },
  props: {
    value: {
      type: [Object, Number],
      default: null,
    },
  },
  data() {
    return {
      selectedContextGeo: this.value,
    };
  },
  computed: {
    imageId() {
      const id = this.value === null ? 'default' : this.value.sid;
      return `geographic/${id}`;
    },
  },
  methods: {
    cancel() {
      this.$emit('cancel');
    },
    valid() {
      this.$emit('input', this.selectedContextGeo);
    },
  },
};
</script>

<style scoped lang="scss">
    @import "~@/styles/_colors";

    .app-context-geographic {
        .national {
            padding: 1rem;
            text-align: center;
        }

        .or {
            text-align: center;
            color: $color-grey;
            text-transform: uppercase;
        }
    }
</style>
