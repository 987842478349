<template>
    <md-menu class="dp-app-selector"
             md-size="huge">
        <md-button class="md-icon-button"
                   md-menu-trigger>
            <md-icon :style="{ 'color': colorFont }">apps</md-icon>
            <md-tooltip>{{ $t('APP_SELECTOR.LABEL') }}</md-tooltip>
        </md-button>

        <md-menu-content>
            <md-menu-item :key="`app_${app.id}`"
                          @click="goTo(app.url)"
                          v-for="app in apps">
                <div>
                    <div v-html="app.name"></div>
                    <div style="font-size:13px; color:grey;">{{ app.description }}</div>
                </div>
                <img :src="app.logo"
                     alt=""
                     style="height:32px; max-width:100%"/>
            </md-menu-item>
        </md-menu-content>
    </md-menu>

</template>

<script>
import logoDataCamp from './assets/logo-datacamp.svg';
import logoDataPerf from './assets/logo-dataperf.svg';
import logoDataStore from './assets/logo-datastore.svg';

export default {
  name: 'AppSiteSelector',
  props: {
    colorFont: {
      type: String,
      default: 'white',
    },
  },
  data() {
    return {
      apps: [
        {
          id: 'datastore',
          logo: logoDataStore,
          name: this.$t('APP_SELECTOR.DATASTORE.LABEL'),
          description: this.$t('APP_SELECTOR.DATASTORE.DESCRIPTION'),
          url: process.env.VUE_APP_DATASTORE_URL,
        },
        {
          id: 'datacamp',
          logo: logoDataCamp,
          name: this.$t('APP_SELECTOR.DATACAMP.LABEL'),
          description: this.$t('APP_SELECTOR.DATACAMP.DESCRIPTION'),
          url: process.env.VUE_APP_DATACAMP_URL,
        },
        {
          id: 'dataperf',
          logo: logoDataPerf,
          name: this.$t('APP_SELECTOR.DATAPERF.LABEL'),
          description: this.$t('APP_SELECTOR.DATAPERF.DESCRIPTION'),
          url: process.env.VUE_APP_DATAPERF_URL,
        },
      ],
    };
  },
  methods: {
    goTo(url) {
      window.open(url, '_blank');
    },
  },
};
</script>
