import axios from 'axios'
import Vue from 'vue'

class CustomerProfileService {
  constructor() {
    this.httpClient = axios.create({
      baseURL: `${process.env.VUE_APP_BACKEND}/customer-profile`,
    });
  }

  async getCustomerWeight(sidSite = 9999, sidSalesChannel = 99, idLivingArea = '9999', sidDepartments = 'ALL') {
    const params = {
      sidSalesChannel,
      idLivingArea,
      sidDepartments: sidDepartments === 'ALL' ? 999 : sidDepartments,
      sidSite,
    };

    const token = await Vue.prototype.$user.getIdToken()
    return this.httpClient.get('/customer-weight', { params , headers: { Authorization: `Bearer ${token}` }}).then(res => res.data);
  }
}

export default new CustomerProfileService();
