import axios from 'axios'
import Vue from 'vue'

class GeoMarketingService {
  constructor() {
    this.httpClient = axios.create({
      baseURL: `${process.env.VUE_APP_BACKEND}/geo-marketing`,
    });
  }

  async getActivationEasiness(sidSite = 9999, sidSalesChannel = 99, idLivingArea = '9999', sidDepartments = 999) {
    const params = {
      sidSalesChannel,
      sidDepartments,
      idLivingArea,
      sidSite,
    };

    const token = await Vue.prototype.$user.getIdToken()
    return this.httpClient.get('/activation-easiness', { params, headers: { Authorization: `Bearer ${token}` }}).then(res => res.data);
  }

  async getActivationEasinessArea(sidSite = 9999, sidSalesChannel = 99, idLivingArea = '9999', sidDepartments = 999) {
    const params = {
      sidSalesChannel,
      sidDepartments,
      idLivingArea,
      sidSite,
    };

    const token = await Vue.prototype.$user.getIdToken()
    return this.httpClient.get('/activation-easiness-area', { params, headers: { Authorization: `Bearer ${token}` }}, ).then(res => res.data);
  }

  async getNeighborhoodType(sidSite = 9999, sidSalesChannel = 99, idLivingArea = '9999', sidDepartments = 999) {
    const params = {
      sidSalesChannel,
      sidDepartments,
      idLivingArea,
      sidSite,
    };

    const token = await Vue.prototype.$user.getIdToken()
    return this.httpClient.get('/neighborhood-type', { params, headers: { Authorization: `Bearer ${token}` }}).then(res => res.data);
  }
}

export default new GeoMarketingService();
