<template>
    <div class="analyze-layout">
        <app-context :disable-period="$route.meta.isWithoutPeriod"></app-context>
        <router-view/>
    </div>
</template>

<script>
import AppContext from '../../components/app-context/AppContext.vue';

export default {
  name: 'analyze-layout',
  components: { AppContext },
};
</script>

<style scoped lang="scss">
    .analyze-layout {
        flex: 1;
        display: flex;
        flex-direction: column;
    }
</style>
