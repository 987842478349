<template>
    <md-button :href="siteUrl"
               class="md-icon-button"
               target="_blank">
        <md-icon :style="{ 'color': colorFont }">help</md-icon>
        <md-tooltip>{{ $t('ACTIONS.DOCUMENTATION') }}</md-tooltip>
    </md-button>
</template>

<script>
export default {
  name: 'AppGoogleSiteBtn',
  props: {
    siteUrl: {
      type: String,
      default: null,
    },
    colorFont: {
      type: String,
      default: '#fff',
    },
  },
};
</script>
