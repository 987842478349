import axios from 'axios'
import Vue from 'vue'

class NomenclatureReferentialService {
  constructor() {
    this.httpClient = axios.create({
      baseURL: process.env.VUE_APP_NOMENCLATURE_REFERENTIAL_URI,
    });

  }

  async getUniverses() {
    const token = await Vue.prototype.$user.getIdToken()
    return this.httpClient.get('/levels?groupId=3&withVirtualNomenclature=0', { headers: { Authorization: `Bearer ${token}`, gcloud_project: process.env.VUE_APP_FIREBASE_PROJECT_ID }})
      .then(nomenclature => {
        const nomenclatureItems = nomenclature.data[0].nomenclatureItems;
        const nomUniverse = nomenclatureItems.find((nom => nom.label === 'UNIVERS'));
        return this.httpClient.get(`?outputNomLevel=${nomUniverse.level}&outputNomVersion=${nomUniverse.version}`, { headers: { Authorization: `Bearer ${token}`, gcloud_project: process.env.VUE_APP_FIREBASE_PROJECT_ID }})
          .then((universes) => {
            return ({
            universe: universes.data,
            nomenclature,
          })});
      });
  }

  async getDepartmentsWithParent(parent, nomenclature) {
    const token = await Vue.prototype.$user.getIdToken()
    const nomenclatureItems = nomenclature.data[0].nomenclatureItems;
    const nomDepartment = nomenclatureItems.find((nom => nom.label === 'RAYON'));
    return this.httpClient.get(`?outputNomLevel=${nomDepartment.level}&outputNomVersion=${nomDepartment.version}&parentNomLevel=${parent.level}&parentNomVersion=${parent.version}`, { headers: { Authorization: `Bearer ${token}`, gcloud_project: process.env.VUE_APP_FIREBASE_PROJECT_ID }})
      .then((departments) => departments.data);
  }

  async getMarketsWithParents(parent, nomenclature) {
    const token = await Vue.prototype.$user.getIdToken()
    const nomenclatureItems = nomenclature.data[0].nomenclatureItems;
    const nomMarket = nomenclatureItems.find((nom => nom.label === 'MARCHE'));
    return this.httpClient.get(`?outputNomLevel=${nomMarket.level}&outputNomVersion=${nomMarket.version}&parentNomLevel=${parent.level}&parentNomVersion=${parent.version}`, { headers: { Authorization: `Bearer ${token}`, gcloud_project: process.env.VUE_APP_FIREBASE_PROJECT_ID }})
      .then((markets) => markets.data);
  }

  getUniverseDepartmentMarket() {
    return new Promise((resolve) => {
      this.getUniverses().then(data => {
        this.getDepartmentsWithParent(data.universe[0], data.nomenclature).then(dataDepartments => {
          this.getMarketsWithParents(dataDepartments[0], data.nomenclature).then(markets => {
            resolve({
              universes: data.universe,
              departments: dataDepartments,
              markets: markets,
            });
          });
        });
      });
    });
  }

  getAllMarketsBySid(sids) {
    return this.getMarkets()
      .then(markets => markets.filter(market => sids.find(sid => +sid === market.sid)));
  }

  async getMarkets() {
    const token = await Vue.prototype.$user.getIdToken()
    return this.httpClient.get('/levels?groupId=3', { headers: { Authorization: `Bearer ${token}`, gcloud_project: process.env.VUE_APP_FIREBASE_PROJECT_ID }})
      .then((nomenclature) => {
        const nomenclatureItems = nomenclature.data.result[0].nomenclatureItems;
        const nomMarket = nomenclatureItems.find((nom => nom.label === 'MARCHE'));
        return this.httpClient.get(`?outputNomLevel=${nomMarket.level}&outputNomVersion=${nomMarket.version}`, { headers: { Authorization: `Bearer ${token}`, gcloud_project: process.env.VUE_APP_FIREBASE_PROJECT_ID }})
          .then((markets) => markets.data.result);
      });
  }
}

export default new NomenclatureReferentialService();
