<template>
    <span class="app-monthpicker">
        {{ period.from | date('MMMM YYYY') }}
        &nbsp;
        ({{ $tc('APP_MONTHPICKER.DURATION.LABEL', period.duration, {count: period.duration}) }})
        <md-menu v-if="nbOfAvailableMonths !== 0">
            <md-button class="md-icon-button md-dense"
                       md-menu-trigger>
                <md-icon>arrow_drop_down</md-icon>
            </md-button>

            <md-menu-content>
                <md-menu-item
                        :disabled="value.isSame(month, 'month') && value.isSame(month, 'year')"
                        :key="`month_${idx}`"
                        @click="selectMonth(month)"
                        v-for="(month, idx) in months">
                    {{ month | date('MMMM YYYY') }}
                </md-menu-item>
            </md-menu-content>
        </md-menu>
    </span>
</template>

<script>
import moment from 'moment';

export default {
  name: 'AppPeriodpicker',
  props: {
    value: {
      type: Object,
      default: null,
    },
    nbOfAvailableMonths: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      months: [],
    };
  },
  computed: {
    period() {
      const period = {};
      period.from = moment(this.value);
      period.to = moment();
      period.duration = Math.abs(period.from.diff(period.to, 'months'));

      return period;
    },
  },
  created() {
    this.initMonths();
  },
  methods: {
    initMonths() {
      for (let idx = 1; idx <= (this.nbOfAvailableMonths + 1); idx++) {
        this.months.push(moment().subtract(idx, 'months'));
      }
    },
    selectMonth(month) {
      this.$emit('input', month);
    },
  },
};
</script>

<style scoped lang="scss">
.app-monthpicker {
    display: flex;
    align-items: center;
}
</style>
