<template>
    <div
            class="google-maps-drawing-manager-control"
            id="drawing-manager-control">
        <div>
            <md-button
                    :disabled="currentDrawingMode === 'polygon'"
                    @click="drawPolygon"
                    class="md-icon-button md-raised md-primary">
                <md-icon>edit</md-icon>
                <md-tooltip md-direction="right">{{
                  $t('GOOGLE_MAPS_DRAWING_MANAGER_CONTROL.POLYGON') }}
                </md-tooltip>
            </md-button>
        </div>
        <div>
            <md-button
                    @click="clearDraw"
                    class="md-icon-button md-raised md-accent"
                    v-if="currentDrawingMode !== null">
                <md-icon>clear</md-icon>
                <md-tooltip md-direction="right">{{ $t('GOOGLE_MAPS_DRAWING_MANAGER_CONTROL.CLEAR') }}</md-tooltip>
            </md-button>
        </div>
    </div>
</template>

<script>
/* global google */

export default {
  name: 'GoogleMapsDrawingManagerControl',
  props: {
    map: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      drawingManager: null,
      currentDrawingMode: null,
      overlay: null,
      listener: null,
    };
  },
  watch: {
    map() {
      this.drawingManager.setMap(this.map);
    },
  },
  created() {
    this.initDrawingManager();
  },
  methods: {
    initDrawingManager() {
      this.drawingManager = new google.maps.drawing.DrawingManager({
        drawingControl: false,
        polygonOptions: {
          fillColor: '#000000',
          fillOpacity: 0.1,
          strokeWeight: 3,
          clickable: false,
          editable: false,
          zIndex: 1,
        },
      });

      google.maps.event.addListener(this.drawingManager, 'overlaycomplete', event => {
        if (this.overlay) this.overlay.setMap(null);
        this.overlay = event.overlay;
      });
    },
    drawPolygon() {
      this.currentDrawingMode = 'polygon';
      this.drawingManager.setDrawingMode(google.maps.drawing.OverlayType.POLYGON);

      this.drawingManager.addListener('polygoncomplete', polygon => {
        const path = polygon.getPath();
        const polygonCoordinates = [];
        for (let i = 0; i < path.getLength(); i++) {
          const xy = path.getAt(i);
          polygonCoordinates.push([xy.lng(), xy.lat()]);
        }

        this.$emit('update', polygonCoordinates, this.overlay);
      });
    },
    clearDraw() {
      this.currentDrawingMode = null;
      this.drawingManager.setDrawingMode(null);
      google.maps.event.clearInstanceListeners(this.drawingManager);
      if (this.overlay) this.overlay.setMap(null);
      this.overlay = null;
    },
  },
};
</script>

<style scoped lang="scss">
    .google-maps-drawing-manager-control {
        margin: 13px;

        div + div {
            margin-top: 8px;
        }
    }
</style>

