<template>
  <div class="animate-layout">
    <div class="target-selectors">
      <md-steppers v-if="!isHomeView"
                   :md-active-step="activeStep"
                   md-alternative>
        <md-step id="animate-store"
                 :md-label="$t('ANIMATE_LAYOUT.STORE')"
                 @click="goToStep('animate-store')" />
        <md-step id="animate-media"
                 :md-label="$t('ANIMATE_LAYOUT.MEDIA')"
                 @click="goToStep('animate-media')" />
        <md-step v-if="isCustomersMode"
                 id="animate-market"
                 :md-label="$t('ANIMATE_LAYOUT.MARKETS')"
                 @click="goToStep('animate-market')" />
        <md-step v-if="isCustomersMode"
                 id="animate-segmentation"
                 :md-label="$t('ANIMATE_LAYOUT.SEGMENTATION')"
                 @click="goToStep('animate-segmentation')" />
        <md-step v-if="isCustomersMode"
                 id="animate-geographic"
                 :md-label="$t('ANIMATE_LAYOUT.GEOGRAPHIC')"
                 @click="goToStep('animate-geographic')" />
        <md-step v-if="isCustomersMode"
                 id="animate-top-client"
                 :md-label="$t('ANIMATE_LAYOUT.TOP_CLIENT')"
                 @click="goToStep('animate-top-client')" />
        <md-step v-if="!isCustomersMode"
                 id="animate-community"
                 :md-label="$t('ANIMATE_LAYOUT.COMMUNITY')"
                 @click="goToStep('animate-community')" />
      </md-steppers>

      <router-view @update="updateAnimation" @init="initAnimationAndCount" />
    </div>


    <div v-if="selectedSite && displayAnimate" class="estimated-target">
      <h3>
        {{ $t('ANIMATE_LAYOUT.YOUR_TARGET') }}
        <md-button class="md-icon-button md-raised md-accent md-dense" @click="deleteAnimate()">
          <md-icon>delete_forever</md-icon>
          <md-tooltip>{{ $t('ANIMATE_LAYOUT.DELETE_ANIMATE') }}</md-tooltip>
        </md-button>
      </h3>

      <app-loading v-if="isLoadingCount" />
      <div v-else>
        <app-progress-funny v-model="progression" />
        <ds-estimated-target-values :count-target="countTarget" />
      </div>

      <app-loading v-if="isLoadingAnimation" :text="$t('ACTIONS.ANIMATE_LOADING')" />
      <ds-animation-detail v-else :animation-detail="animationDetail" />
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import AppProgressFunny from '@/components/app-progress-funny/AppProgressFunny.vue'
import targetCountService from '@/services/targetCountService'
import animationService from '@/services/animationService'
import AppLoading from '@/components/app-loading/AppLoading.vue'
import DsEstimatedTargetValues
  from './components/DsEstimatedTargetValues.vue'
import DsAnimationDetail
  from './components/DsAnimationDetail.vue'

export default {
  name: 'DsAnimateLayout',
  components: {
    AppLoading,
    DsEstimatedTargetValues,
    DsAnimationDetail,
    AppProgressFunny,
  },
  data() {
    return {
      activeStep: 'animate-store',
      isLoadingCount: true,
      isLoadingAnimation: false,
      animation: null,
      countTarget: null,
      progression: null,
      animationDetail: null,
      selectedSite: this.$route.query.sidSite,
      displayAnimation: this.$route.query.displayAnimate
    }
  },
  computed: {
    isHomeView() {
      return this.$route.name === 'animate-home'
    },
    isCustomersMode() {
      return this.$route.query.mode === 'customers'
    }
  },
  watch: {
    $route() {
      this.selectedSite = this.$route.query.sidSite
      this.displayAnimate = this.$route.query.displayAnimate
      this.initStep()
    },
  },
  created() {
    this.initStep()

    // TEMPORARY: CAN'T ANIMATE SUPER
    const query = Object.assign({}, this.$route.query)
    if (query.sidSalesChannel === 5 || query.sidSite === undefined) {
      query.sidSite = this.$userInfos.site.siteSid !== 1 ? this.$userInfos.site.siteSid : null
      query.sidSalesChannel = 99
      this.$router.push({ name: this.$route.name, params: this.$route.params, query })
    }
  },
  methods: {
    deleteAnimate() {
      this.$router.push({ name: 'animate-store', query: this.$route.query })
    },
    getTargetCount() {
      this.isLoadingCount = true
      if (this.animation) {
        targetCountService.getTargetCount(
          this.$route.query.mode === 'community',
          this.animation.groupTypes,
          this.animation.sidSite || 9999,
          this.animation.sidSalesChannel,
          this.animation.nomenclatures,
          this.animation.idSegType || null,
          this.animation.segmentsOly || null,
          this.animation.segmentsCdv || null,
          this.animation.activationEasinessAreas || null,
          this.animation.neighborhoodTypes || null,
          this.animation.shape || null,
          this.animation.startDate || null,
          this.animation.endDate || null,
          this.animation.startRankTopCustomers || null,
          this.animation.sid,
          0,
          this.animation.ouiPubZone
        )
          .then(count => {
            this.countTarget = count[0] || 0
            this.isLoadingCount = false

            if (this.$route.query.mode === 'customers' && this.countTarget && this.countTarget.numberTargetClients && this.countTarget.numberTargetClientsTotal) {
              const prog = this.countTarget.numberTargetClients / this.countTarget.numberTargetClientsTotal

              /* eslint-disable */
                if (prog < 0.05) return this.progression = 0.9;
                if (0.10 > prog >= 0.05) return this.progression = 0.7;
                if (0.15 > prog >= 0.10) return this.progression = 0.5;
                if (0.2 > prog >= 0.15) return this.progression = 0.3;
                if (prog >= 0.2) return this.progression = 0.1;

              } else if(this.$route.query.mode === 'community' && this.animation && this.animation.groupTypes && this.animation.groupTypes.length > 0) {
              const countGroupType = this.animation.groupTypes.length
              if(countGroupType === 3) return this.progression = 0.3
              if(countGroupType === 2) return this.progression = 0.5
              if(countGroupType === 1) return this.progression = 0.9
              else return 0
            }
              else return this.progression = 0
            })
        } else {
          targetCountService.getTargetCount(this.$route.query.mode === 'community', this.animation?.groupTypes || null,this.$route.query.sidSite || 9999, this.$route.query.sidSalesChannel).then((count) => {
            this.countTarget = count[0]

            this.progression = 0
            this.isLoadingCount = false
          });
        }
      },
      getAnimationDetail() {
        if (this.animation) {
          this.isLoadingAnimation = true
          animationService.findDetailById(this.animation.sid).then(animationDetail => {
            this.animationDetail = [];
            this.isLoadingAnimation = false;
            Object.keys(animationDetail).forEach(key => {
              if (key === 'DTS') {
                this.animationDetail.push({name: key, value: moment(animationDetail['DTS'][0]).format('DD/MM/YYYY') + ' - ' + moment(animationDetail['DTE'][0]).format('DD/MM/YYYY')})
              } else if (key !== 'DTE') {
                this.animationDetail.push({name: key, value: animationDetail[key].join(', ')})
              }
            });
          });
        }
      },
      updateAnimation(animation) {
        this.animation = animation;
        this.getTargetCount();
      },
      initAnimationAndCount(animation) {
        this.animation = animation;
        this.getTargetCount();
        this.getAnimationDetail();
      },
      initStep() {
        this.activeStep = this.$route.name;
      },
      goToStep(id) {
        this.$router.push({name: id, params: this.$route.params, query: this.$route.query});
      }
    },
  };
</script>

<style scoped lang="scss">
    @import "~@/styles/_variables";

    .animate-layout {
        display: grid;
        grid-template-columns: 80% auto;
        height: calc(100vh - #{$toolbar-height});
        overflow: hidden;

        .app-loading {
            margin-top: 2em;
        }

        .target-selectors {
            flex-direction: column;
            display: flex;
            overflow: hidden;

            .md-steppers {
                /deep/ .md-steppers-navigation {
                    box-shadow: none;
                }

                /deep/ .md-steppers-wrapper {
                    .md-steppers-container {
                        height: 100%;

                        .md-stepper {
                            padding: 0;
                        }
                    }
                }
            }
        }

        .estimated-target {
            background-color: white;
            border-left: 1px solid var(--md-theme-demo-light-divider, rgba(0, 0, 0, .12));
            overflow-x: hidden;

            h3 {
                padding: 0 1rem 0 1.5rem;
                display: flex;
                justify-content: space-between;
            }
        }
    }
</style>
