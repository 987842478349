<template>
    <div class="app-datepicker">
        <div class="helpers" v-if="activeHelpers">
            <app-button :class="helper.active ? 'active' : ''"
                        :key="`helper_${helper.id}`"
                        @click.native="computeDateFromHelper(helper)"
                        v-for="helper in helpers">
                {{ helper.label }}
            </app-button>
        </div>
        <div class="calendar">
            <v-date-picker :attributes="attributes"
                           :from-page="fromPage"
                           :is-double-paned="true"
                           :mode="mode"
                           :show-caps="true"
                           :theme-styles="theme"
                           :to-page="toPage"
                           :value="value"
                           :max-date="max"
                           :min-date="min"
                           @input="selectPeriod"
                           tint-color="var(--md-theme-default-primary, #1e8bc3)"
                           class="calendar">
            </v-date-picker>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import VCalendar from 'v-calendar';
import Vue from 'vue';
import AppButton from '../app-button/AppButton.vue';

Vue.use(VCalendar, {
  locale: 'fr-FR',
  formats: {
    title: 'MMMM YYYY',
    weekdays: 'W',
    navMonths: 'MMM',
    dayPopover: 'L',
    input: ['L', 'YYYY-MM-DD', 'YYYY/MM/DD'],
    data: ['L', 'YYYY-MM-DD', 'YYYY/MM/DD'],
  },
  firstDayOfWeek: 2,
  titlePosition: 'left',

});

export default {
  name: 'AppDatepicker',
  components: { AppButton, VCalendar },
  props: {
    value: {
      type: Object,
      default: () => ({
        start: new Date(),
        end: new Date(),
      }),
    },
    helpers: {
      type: Array,
      default: () => ([]),
    },
    activeHelpers: {
      type: Boolean,
      default: true,
    },
    min: {
      type: Date,
      default: null,
    },
    max: {
      type: Date,
      default: null,
    },
    mode: {
      type: String,
      default: 'range',
    },
  },
  data() {
    return {
      attributes: [
        {
          dot: {
            backgroundColor: 'var(--md-theme-default-primary, #3D95A5)',
          },
          dates: [
            new Date(),
          ],
        },
      ],
      theme: {
        wrapper: {
          background: 'white',
          color: 'rgba(0, 0, 0, 0.54)',
          border: '1px solid rgba(0, 0, 0, 0.12)',
          borderRadius: '4px',
        },
        header: {
          textTransform: 'uppercase',
          borderRadius: '4px 4px 0 0',
        },
        dayPopoverContent: {
          borderRadius: '4px',
          background: 'white',
          border: 'none',
          boxShadow: '0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12)',
        },
      },
      fromPage: {},
      toPage: {},
    };
  },
  created() {
    if (this.helpers.length === 0 && this.activeHelpers) {
      this.initDefaultHelpers();
    }

    this.initPages();
  },
  methods: {
    initDefaultHelpers() {
      this.helpers.push({
        id: 'yesterday',
        label: this.$t('APP_DATEPICKER.HELPERS.YESTERDAY'),
        func: () => {
          const period = {};
          period.start = moment().subtract(1, 'days').hours(0).minutes(0)
            .seconds(0);
          period.end = moment().subtract(1, 'days').hours(23).minutes(59)
            .seconds(59);
          return period;
        },
        active: false,
      });

      this.helpers.push({
        id: 'last-week',
        label: this.$t('APP_DATEPICKER.HELPERS.LAST_WEEK'),
        func: () => {
          const period = {};
          period.start = moment().subtract(1, 'weeks').startOf('week').hours(0)
            .minutes(0)
            .seconds(0);
          period.end = moment().subtract(1, 'weeks').endOf('week').hours(23)
            .minutes(59)
            .seconds(59);
          return period;
        },
        active: false,
      });

      this.helpers.push({
        id: 'sliding-week',
        label: this.$t('APP_DATEPICKER.HELPERS.SLIDING_WEEK'),
        func: () => {
          const period = {};
          period.start = moment().subtract(1, 'weeks').hours(0).minutes(0)
            .seconds(0);
          period.end = moment().subtract(1, 'days').hours(23).minutes(59)
            .seconds(59);
          return period;
        },
        active: false,
      });

      this.helpers.push({
        id: 'last-month',
        label: this.$t('APP_DATEPICKER.HELPERS.LAST_MONTH'),
        func: () => {
          const period = {};
          period.start = moment().subtract(1, 'months').startOf('month').hours(0)
            .minutes(0)
            .seconds(0);
          period.end = moment().subtract(1, 'months').endOf('month').hours(23)
            .minutes(59)
            .seconds(59);
          return period;
        },
        active: false,
      });

      this.helpers.push({
        id: 'sliding-month',
        label: this.$t('APP_DATEPICKER.HELPERS.SLIDING_MONTH'),
        func: () => {
          const period = {};
          period.start = moment().subtract(1, 'months').hours(0).minutes(0)
            .seconds(0);
          period.end = moment().subtract(1, 'days').hours(23).minutes(59)
            .seconds(59);
          return period;
        },
        active: false,
      });
    },
    initPages() {
      const start = moment(this.value.start);
      const end = moment(this.value.end);
      let month = moment(this.value.start).month() + 1;
      let year = moment(this.value.start).year();

      if (start.isSame(end, 'month') && start.isSame(end, 'year')) {
        if (month === 1) {
          month = 12;
          year -= 1;
        } else {
          month -= 1;
        }
      }

      this.fromPage = {
        month,
        year,
      };

      this.toPage = {
        month: moment(this.value.end).month() + 1,
        year: moment(this.value.end).year(),
      };
    },
    computeDateFromHelper(helper) {
      this.helpers.forEach(helper => helper.active = false);
      helper.active = true;
      this.$emit('input', helper.func());
    },
    selectPeriod(period) {
      this.helpers.forEach(helper => helper.active = false);
      this.$emit('input', period);
    },
  }
  ,
};
</script>

<style scoped lang="scss">
    @import '~v-calendar/lib/v-calendar.min.css';
    @import "~@/styles/_colors";

    .calendar {
        :global(div) {
            font-size: 14px !important;
        }
        :global(.c-day-content-wrapper) {
            padding: .4em;
        }
    }
    .app-datepicker {
        display               : grid;
        grid-template-columns : 20% auto;

        .helpers {
            button + button {
                margin-top : 1rem;
            }
        }

        :global(.calendar.popover-container) {
            border          : 2px solid $primary-color;
            padding         : 0 1em;
            border-radius   : 4px;
            display         : flex;
            flex-direction  : column;
            justify-content : center;
            width           : 16em;
            height          : 2.5em;
        }
        :global(input) {
            transition    : all 450ms cubic-bezier(0.23, 1, 0.32, 1);
            border        : none;
            border-radius : 0px;
            font-size     : 14px;
            outline       : none;
            width         : 100%;
            background    : transparent;
            padding       : 0;

            &:focus {
                border-color : $primary-color;
            }
        }

        &.inline {
            grid-template-columns : unset;

            .helpers {
                display       : flex;
                margin-bottom : 1rem;

                button + button {
                    margin-top  : 0;
                    margin-left : 1rem;
                }
            }
        }
    }
</style>
