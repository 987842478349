<template>
  <div class="hypothesis-size">
    <span class="size" data-size="s">
      {{ $t('TRACT_ZONES.HYPOTHESIS.SMALL') }}
    </span>
    <span class="selected-size">
      {{ selectedSize }}
    </span>
    <span class="size right" data-size="l">
      {{ $t('TRACT_ZONES.HYPOTHESIS.LARGE') }}
    </span>
    <span class="size top" data-size="m">
      {{ $t('TRACT_ZONES.HYPOTHESIS.MEDIUM') }}
    </span>

    <img class="half-circle" :src="halfCircle" />
    <img :class="['circle-cursor', selectedSize, `${selectedSize}-from-${sizeFrom}`]" :src="circleCursor" />
  </div>
</template>

<script>
  export default {
    name: 'HypothesisSize',
    props: {
      selectedSize: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        sizeFrom: 'm'
      }
    },
    emits: ['selectSize'],
    computed: {
      halfCircle() {
        return require('@/assets/tractZones/half-circle.svg')
      },
      check() {
        return require('@/assets/tractZones/check.svg')
      },
      circleCursor() {
        return require('@/assets/tractZones/circle-cursor.svg')
      }
    },
    methods: {
      selectSize(ev) {
        const size = ev.target.getAttribute('data-size')
        this.sizeFrom = this.selectedSize
        this.selectedSize = size
        this.$emit('selectSize', this.selectedSize)
      },
      halfCircleClick(ev) {
        const clientPos = { x: ev.x, y: ev.y }
        const targetBoundingRect = ev.target.getBoundingClientRect()
        const targetPos = { x: targetBoundingRect.x + ev.target.clientWidth / 2, y: targetBoundingRect.y + ev.target.clientHeight }
        const angle = Math.abs((Math.atan2(clientPos.y - targetPos.y, clientPos.x - targetPos.x) * 180) / Math.PI)

        this.sizeFrom = this.selectedSize
        this.selectedSize = angle < 60 ? 'l' : angle < 120 ? 'm' : 's'
        this.$emit('selectSize', this.selectedSize)
      }
    }
  }
</script>

<style scoped lang="scss">
  .hypothesis-size {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    position: relative;
    min-height: 8rem;
    font-size: 1.4rem;

    .size {
      width: 40%;
      display: flex;
      align-items: center;
      gap: 0.3rem;

      &-check {
        width: 1.5rem;
      }

      &.right {
        justify-content: flex-end;
      }

      &.top {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        justify-content: center;
      }
    }

    .selected-size {
      width: 20%;
      text-align: center;
      font-size: 2.5rem;
      font-weight: bold;
      color: $primary-color;
      text-transform: uppercase;
    }

    .half-circle {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0;
      height: 5rem;
    }

    .circle-cursor {
      position: absolute;
      width: 1.5rem;
      transform: translate(-50%, 50%);

      &.m {
        left: 50%;
        bottom: calc(5rem - 0.4rem);
      }
      &.m-from-s {
        animation: xLeft 0.3s linear, yBottom 0.3s cubic-bezier(0, 1, 1, 1);
      }
      &.m-from-l {
        animation: xRight 0.3s linear, yBottom 0.3s cubic-bezier(0, 1, 1, 1);
      }

      &.s {
        left: calc(50% - 4.5rem);
        bottom: 0.5rem;
      }
      &.s-from-m {
        animation: xMiddle 0.3s linear, yTop 0.3s cubic-bezier(1, 0, 1, 1);
      }
      &.s-from-l {
        animation: xRight 0.5s linear, yBottomTopBottomLeft 0.5s cubic-bezier(0, 1, 1, 1);
      }

      &.l {
        left: calc(50% + 4.5rem);
        bottom: 0.5rem;
      }
      &.l-from-m {
        animation: xMiddle 0.3s linear, yTop 0.3s cubic-bezier(1, 0, 1, 1);
      }
      &.l-from-s {
        animation: xLeft 0.5s linear, yBottomTopBottomRight 0.5s cubic-bezier(0, 1, 1, 1);
      }
    }
  }
</style>
