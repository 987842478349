<template>
  <div id="free-drawing-control" class="google-maps-free-drawing-control">
    <div>
      <md-button :class="['md-icon-button', 'md-raised', { 'md-primary': value }]" @click="startDrawing">
        <md-icon>draw</md-icon>
        <md-tooltip md-direction="right">{{ $t('GOOGLE_MAPS_DRAWING_MANAGER_CONTROL.POLYGON') }}</md-tooltip>
      </md-button>
    </div>
  </div>
</template>

<script>
  /* global google */

  export default {
    name: 'GoogleMapsFreeDrawingControl',
    props: {
      map: {
        type: Object,
        default: null
      },
      value: {
        type: Boolean
      },
      zones: {
        type: Array,
        default: () => []
      }
    },
    data() {
      return {
        polyline: null,
        listeners: []
      }
    },
    watch: {
      value(newVal) {
        if (!newVal) {
          this.toggleMapOptions(true)
          google.maps.event.clearListeners(this.map.getDiv(), 'mousedown')
          this.listeners.forEach(listener => google.maps.event.removeListener(listener))
          this.polyline.setMap(null)
        }
      }
    },
    methods: {
      startDrawing(e) {
        if (!this.value) {
          e.preventDefault()
          this.$emit('input', true)
          this.toggleMapOptions(false)
          google.maps.event.addDomListener(this.map.getDiv(), 'mousedown', this.drawFreeHandPolyline)
        } else {
          this.toggleMapOptions(true)
          google.maps.event.clearListeners(this.map.getDiv(), 'mousedown')
          this.$emit('input', false)
          this.listeners.forEach(listener => google.maps.event.removeListener(listener))
          this.polyline.setMap(null)
        }
      },
      mouseMove(e) {
        try {
          this.polyline.getPath().push(e.latLng)
        } catch (e) {
          console.log("Une erreur s'est produite lors de la construction de la polyline")
        }
      },
      endDrawing() {
        this.listeners.forEach(listener => google.maps.event.removeListener(listener))
        const path = this.polyline.getPath()
        this.polyline.setMap(null)
        try {
          const polygon = new google.maps.Polygon({ path })
          this.$emit('drawPolygon', polygon)
        } catch (ex) {
          console.log("Une erreur s'est produite lors de la finalisation du polygone")
        } finally {
          google.maps.event.clearListeners(this.map.getDiv(), 'mousedown')
          this.toggleMapOptions(true)
          this.$emit('input', false)
        }
      },
      drawFreeHandPolyline() {
        this.polyline = new google.maps.Polyline({ map: this.map, clickable: false, zIndex: 9999 })

        this.listeners = [google.maps.event.addListener(this.map, 'mousemove', this.mouseMove)]
        this.listeners.push(...this.zones.map(zone => google.maps.event.addListener(zone.polygon, 'mousemove', this.mouseMove)))

        google.maps.event.addListenerOnce(this.map, 'mouseup', this.endDrawing)
        google.maps.event.addListenerOnce(this.map, 'mousedown', this.endDrawing)
      },
      toggleMapOptions(enable) {
        this.map.setOptions({
          draggable: !!enable,
          zoomControl: !!enable,
          scrollwheel: !!enable,
          disableDoubleClickZoom: !!enable
        })
      }
    }
  }
</script>

<style scoped lang="scss">
  .google-maps-free-drawing-control {
    margin: 13px;

    div + div {
      margin-top: 8px;
    }
  }
</style>
