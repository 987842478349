<template>
    <div class="analyze-matrix">
        <div class="header">
            <app-title :title="$t($route.meta.title)"></app-title>
        </div>

        <ds-customer-segmentation-matrix class="olympic-segmentation-matrix"/>
    </div>
</template>

<script>
import AppTitle from '@/components/app-title/AppTitle.vue';
import DsCustomerSegmentationMatrix from './components/CustomerSegmentationMatrix.vue';

export default {
  name: 'analyze-customer-profile',
  components: {
    AppTitle, DsCustomerSegmentationMatrix,
  },
};
</script>

<style scoped lang="scss">
    @import "~@/styles/_mixins";

    .analyze-matrix {
        padding: 0 2rem 2rem 2rem;
        height: 100%;
        overflow: hidden;
        display: flex;
        flex-direction: column;

        .header {
            @include display-flex(center, space-between);
        }

        .grid {
            display: grid;
            grid-gap: 1rem;
            grid-template-columns: 25% 1fr;

            .holder-customer-chart {
                grid-column: 1;
                grid-row: 1;
            }
        }
    }
</style>
