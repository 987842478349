<template>
    <h3 class="app-title">
        <span class="title">{{ title }}</span>
        <slot></slot>
    </h3>
</template>

<script>
export default {
  name: 'AppTitle',
  props: {
    title: {
      type: String,
      default: null,
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/styles/_colors";

.app-title {
    display: flex;

    text-transform: uppercase;
    line-height: 32px;

    span.title {
        color: $color-grey;
        margin-right: 2rem;
    }
}
</style>
