import axios from 'axios'
import Vue from 'vue'

class SegmentationService {
  constructor() {
    this.httpClient = axios.create({
      baseURL: `${process.env.VUE_APP_BACKEND}/segmentations`,
    });
  }

  async getAllSegmentation(params) {
    const token = await Vue.prototype.$user.getIdToken()
    return this.httpClient.get('', {params, headers: { Authorization: `Bearer ${token}` }} ).then(res => res.data);
  }
}

export default new SegmentationService();
