<template>
    <div class="activation-easiness-area-chart">
        <md-card>
            <md-card-header class="header">
                <md-card-header-text>
                    <div v-if="!isLivingArea" class="md-title">{{ $t('ACTIVATION_EASINESS_AREA_CHART.LABEL') }}</div>
                    <div v-else class="md-title">{{ $t('ACTIVATION_EASINESS_AREA_CHART.LABEL_ZDV') }}</div>
                    <div class="md-subhead">{{ $t('ACTIVATION_EASINESS_AREA_CHART.SUB_LABEL') }}
                    </div>
                </md-card-header-text>

                <div v-if="isLoading">
                    <md-progress-spinner :md-diameter="20"
                                         :md-stroke="2"
                                         class="md-accent"
                                         md-mode="indeterminate">
                    </md-progress-spinner>
                </div>
            </md-card-header>

            <md-card-content class="content">
                <app-loading v-if="isLoadingForTheTime"></app-loading>
                <app-error-state v-else-if="isInError"></app-error-state>
                <app-empty-state v-else-if="datasets.length === 0"></app-empty-state>

                <app-doughnut-chart :datasets="datasets"
                                    :height="200"
                                    :labels="labels"
                                    :options="options"
                                    v-else>
                </app-doughnut-chart>
            </md-card-content>
        </md-card>
    </div>
</template>

<script>
import AppDoughnutChart from '@/components/app-doughnut-chart/AppDoughnutChart.vue';
import AppEmptyState from '@/components/app-empty-state/AppEmptyState.vue';
import AppErrorState from '@/components/app-error-state/AppErrorState.vue';
import AppLoading from '@/components/app-loading/AppLoading.vue';
import geoMarketingService from '@/services/geoMarketingService';

export default {
  name: 'DsActivationEasinessAreaChart',
  components: {
    AppEmptyState, AppErrorState, AppLoading, AppDoughnutChart,
  },
  props: {
    withGlobalHyper: {
      type: Boolean,
      default: false,
    },
    withNational: {
      type: Boolean,
      default: false,
    },
    isLivingArea: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoadingForTheTime: false,
      isLoading: false,
      isInError: false,
      labels: [],
      datasets: [],
      options: {
        tooltips: {
          callbacks: {
            title: (tooltipItems, data) => {
              const serie = data.datasets[tooltipItems[0].datasetIndex].label.toUpperCase();
              const axe = data.labels[tooltipItems[0].index].toUpperCase();
              return `${serie} - ${axe}`;
            },
            label: (tooltipItem, data) => {
              const current = this.$options.filters.number(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index], 1, 'percent');
              const progression = this.$options.filters.number(data.datasets[tooltipItem.datasetIndex].dataProgression[tooltipItem.index], 1, 'percent');
              return `${current} \u2022 ${progression}`;
            },
          },
          cornerRadius: 2,
        },
        plugins: {
          datalabels: {
            font: {
              weight: 'bold',
              size: 16,
            },
            formatter: (value, context) => {
              const current = this.$options.filters.number(value, 1, 'percent');
              const progression = this.$options.filters.number(context.dataset.dataProgression[context.dataIndex], 1, 'percent');
              return `${current}\n \u2022 \n${progression}`;
            },
            textAlign: 'center',
          },
        },
      },
    };
  },
  created() {
    this.getDatasStoreLevel();
  },
  watch: {
    withGlobalHyper() {
      this.toggleLabelsForStore();
      this.toggleDatasGlobalHyperLevel();
    },
    withNational() {
      this.toggleLabelsForStore();
      this.toggleDatasNationalLevel();
    },
    '$route.query': function () {
      this.getDatasStoreLevel();
      this.toggleDatasNationalLevel();
      this.toggleDatasGlobalHyperLevel();
    },
  },
  methods: {
    getDatasStoreLevel() {
      this.isLoadingForTheTime = true;
      this.isInError = false;
      this.datasets = [];
      const query = this.$route.query;

      const dataset = {
        label: this.$t('ACTIVATION_EASINESS_AREA_CHART.SERIE.STORE'),
        borderWidth: 0,
        backgroundColor: ['rgb(30, 139, 195)', 'rgba(30, 139, 195, 0.38)'],
        datalabels: {
          borderWidth: 0,
          borderRadius: 2,
          color: ['white', 'black'],
        },
      };

      geoMarketingService.getActivationEasinessArea(query.sidSite, query.sidSalesChannel, query.idLivingArea, query.sidDepartments)
        .then((datas) => {
          this.labels = datas.map(data => data.dimension);
          dataset.data = datas.map(data => data.salesAmountWeight);
          dataset.dataProgression = datas.map(data => data.salesAmountProgression);
          this.datasets.push(dataset);
        })
        .catch(() => this.isInError = true)
        .finally(() => this.isLoadingForTheTime = false);
    },
    toggleDatasNationalLevel() {
      if (this.withNational) {
        this.isLoading = true;
        this.isInError = false;
        const query = this.$route.query;

        const dataset = {
          id: 'nat',
          label: this.$t('ACTIVATION_EASINESS_AREA_CHART.SERIE.NATIONAL'),
          borderWidth: 0,
          backgroundColor: ['#F44336', 'rgba(244,67,54,0.38)'],
          datalabels: {
            display: false,
          },
        };

        geoMarketingService.getActivationEasinessArea(undefined, query.sidSalesChannel, undefined, query.sidDepartments)
          .then((datas) => {
            this.labels = datas.map(data => data.dimension);
            dataset.data = datas.map(data => data.salesAmountWeight);
            dataset.dataProgression = datas.map(data => data.salesAmountProgression);
            this.datasets.push(dataset);
          })
          .catch(() => this.isInError = true)
          .finally(() => this.isLoading = false);
      } else {
        this.datasets = this.datasets.filter(dataset => dataset.id !== 'nat');
      }
    },
    toggleDatasGlobalHyperLevel() {
      if (this.withGlobalHyper) {
        this.isLoading = true;
        this.isInError = false;
        const query = this.$route.query;

        const dataset = {
          id: 'gh',
          label: this.$t('ACTIVATION_EASINESS_AREA_CHART.SERIE.GLOBAL_HYPER'),
          borderWidth: 0,
          backgroundColor: ['#4CAF50', 'rgba(76,175,80,0.38)'],
          datalabels: {
            display: false,
          },
        };
        geoMarketingService.getActivationEasinessArea(query.sidSite, undefined, query.idLivingArea)
          .then((datas) => {
            this.labels = datas.map(data => data.dimension);
            dataset.data = datas.map(data => data.salesAmountWeight);
            dataset.dataProgression = datas.map(data => data.salesAmountProgression);
            this.datasets.push(dataset);
          })
          .catch(() => this.isInError = true)
          .finally(() => this.isLoading = false);
      } else {
        this.datasets = this.datasets.filter(dataset => dataset.id !== 'gh');
      }
    },
    toggleLabelsForStore() {
      if (this.withGlobalHyper || this.withNational) {
        this.datasets[0].datalabels.display = false;
        const options = Object.assign({}, this.options);

        this.options = options;
      } else {
        this.datasets[0].datalabels.display = true;
        const options = Object.assign({}, this.options);
        this.options = options;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.activation-easiness-area-chart {
    .header {
        display: flex;
        align-items: center;
    }
}
</style>
