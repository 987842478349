<template>
  <div id="manual-add-control" class="google-maps-manual-add-control">
    <div>
      <md-button :class="['md-icon-button', 'md-raised', { 'md-primary': value }]" @click="toggleAddMode">
        <md-icon>add_location_alt</md-icon>
        <md-tooltip md-direction="right">{{ $t('GOOGLE_MAPS_DRAWING_MANAGER_CONTROL.MANUAL_ADD') }}</md-tooltip>
      </md-button>
    </div>
  </div>
</template>

<script>
  /* global google */

  export default {
    name: 'GoogleMapsManualAddControl',
    props: {
      map: {
        type: Object,
        default: null
      },
      value: {
        type: Boolean
      }
    },
    methods: {
      toggleAddMode() {
        this.$emit('input', !this.value)
      }
    }
  }
</script>

<style scoped lang="scss">
  .google-maps-manual-add-control {
    margin: 13px;

    div + div {
      margin-top: 8px;
    }
  }
</style>
