<template>
    <header :style="{ 'background-color': colorBg }"
            class="app-toolbar">
        <div class="brand">
            <slot name="brand"/>
        </div>
        <div class="navigation">
            <slot name="navigation"/>
        </div>
        <div class="more">
            <slot name="more"/>
        </div>
    </header>
</template>

<script>
export default {
  name: 'AppToolbar',
  props: {
    colorBg: {
      type: String,
      default: 'white',
    },
  },
};
</script>

<style scoped lang="scss">

.app-toolbar {
    display: flex;
    align-items: center;
    .brand,
    .navigation {
        padding: 0 1rem;
    }

    .navigation {
        flex: 1;
        height: 100%;
    }
}
</style>
