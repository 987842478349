<template>
    <div class="sales-formula-pyramid-actions">
        <iframe id="ytplayer" type="text/html" width="640" height="800"
                src="https://www.youtube.com/embed/U7TxCiXm5n0?autoplay=0&theme=white&showinfo=0&autohide=2&modestbranding=1&showsearch=0&origin=http://example.com"
                frameborder="0"/>
    </div>
</template>

<script>
export default {
  name: 'DsSalesFormulaPyramidActions',
};
</script>

<style scoped lang="scss">
.sales-formula-pyramid-actions {
    iframe {
        height: 100%;
    }

    height: 100%;
    background-color: black;
}
</style>
