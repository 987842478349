<template>
    <div class="estimated-target-values">
        <div class="total">

            <div v-if="countTarget && countTarget.numberTargetClientsByBR !== 0 || countTarget.numberTargetClientsBySMS !== 0">
                <div class="total-client">
                    <strong>{{ countTarget.numberTargetClientsTotal | number(0) }}</strong>
                    <p class="text-total">{{ $t('ESTIMATED_TARGET_VALUES.CLIENTS') }}</p>
                </div>

                <div class="animable-client">
                    <strong>{{ countTarget.numberTargetClientsAnim | number(0) }}</strong>
                    <p class="text-total">{{ $t('ESTIMATED_TARGET_VALUES.TOTAL') }}</p>
                </div>

                <div class="detail">
                    <i class="material-icons">message</i>
                    <strong>{{ countTarget.numberTargetClientsBySMS | number(0) }}</strong>
                    <span>{{ $t('ESTIMATED_TARGET_VALUES.SMS') }}</span>
                </div>
                <div class="detail">
                    <i class="material-icons">card_giftcard</i>
                    <strong>{{ countTarget.numberTargetClientsByBR | number(0) }}</strong>
                    <span>{{ $t('ESTIMATED_TARGET_VALUES.COUPONS') }}</span>
                </div>
            </div>

            <div v-if="countTarget && countTarget.numberTargetClients !== 0" class="total-client">
                <strong>{{ countTarget.numberTargetClients | number(0)}}</strong>
                <p class="text-total">{{ $t('ESTIMATED_TARGET_VALUES.TOTAL') }}</p>
            </div>

            <md-snackbar
                    :md-active.sync="showError"
                    :md-duration="Infinity"
                    md-persistent
                    md-position="left">
                {{ $t('ESTIMATED_TARGET_VALUES.ERROR_NO_CLIENTS') }}
            </md-snackbar>
        </div>
    </div>
</template>

<script>
import AppLoading from '@/components/app-loading/AppLoading.vue';

export default {
  name: 'DsEstimatedTargetValues',
  components: { AppLoading },
  props: {
    countTarget: {
      type: [Object, Number],
      default: null,
    },
  },
  computed: {
    showError() {
      return this.countTarget === 0;
    },
  },
  data() {
    return {
      message: {
        active: false,
        value: null,
      },
    };
  },
};
</script>

<style scoped lang="scss">
    @import "~@/styles/_colors";
    @import "~@/styles/_mixins";

    .estimated-target-values {
        align-items: center;

        .total {
            @include display-flex(center, center, column);
            margin: 2em 0 1em 0;
            strong {
                font-size: 2em;
                color: black;
            }

            .text-total {
                font-size: 1.5rem;
                margin: 0 0 1em 0;
                color: $color-grey;
            }

            .animable-client {
                text-align: center;
                font-size: 1.4rem;
                margin-top: 2em;
            }

            .total-client {
                text-align: center;
                font-size: 1.8rem;
            }

            .detail {
                @include display-flex(center, center, row);
                i {
                    font-size: 1em;
                }
                strong {
                    font-size: .8em;
                    margin: 0 .5em;
                }
            }
        }
    }
</style>
