import Vue from 'vue';

function number(input, decimalNumber, unit) {
  if (input === undefined || input === null) {
    return null;
  }

  if (input === 'NaN' || Number.isNaN(input)) {
    return 0;
  }

  let unitToUse = '';
  switch (unit) {
    case 'currency':
      unitToUse = '€';
      break;
    case 'decimal':
      unitToUse = '';
      break;
    case 'percent':
      unitToUse = '%';
      break;
    default:
      unit = '';
      break;
  }

  let inputToUse = input;

  const decimalNumberToUse = decimalNumber || 2;

  let countedUnit = 0;
  let formattedUnit = '';

  while (inputToUse !== (inputToUse % 1000000)) {
    inputToUse /= 1000000;
    countedUnit += 1;
  }

  if (inputToUse !== 0 && countedUnit === 1) {
    formattedUnit = 'm';
  }

  let inputFormat = null;

  if (decimalNumber === 0) {
    inputFormat = new Intl.NumberFormat('fr-FR').format(Math.trunc(inputToUse));
  } else {
    inputFormat = new Intl.NumberFormat('fr-FR', { maximumFractionDigits: decimalNumberToUse }).format(inputToUse);
  }

  return inputFormat + formattedUnit + unitToUse;
}

export default Vue.filter('number', number);
