<template>
  <md-button :href="communityUrl"
             class="md-icon-button"
             target="_blank">
    <img src="@/assets/chat.svg"
         alt="chat"
         class="button-image">
    <md-tooltip>{{ $t('SHARE_BY_COMMUNITY_BTN.LABEL') }}</md-tooltip>
  </md-button>
</template>

<script>
export default {
  name: 'AppShareByCommunityBtn',
  props: {
    communityUrl: {
      type: String,
      default: null,
    },
  },
}
</script>
