import { WKTReader } from 'jsts/org/locationtech/jts/io'

const gmapsPolygonToWkt = polygon => {
  const polyCoords = polygon
    .getPath()
    .getArray()
    .map(el => ({ lat: el.lat(), lng: el.lng() }))

  const coords = polyCoords.map(el => `${el.lng} ${el.lat}`)
  if (coords[0] !== coords[coords.length - 1]) coords.push(coords[0])

  return `POLYGON((${coords.join(',')}))`
}

export const getPolygonsIntersection = (polygon1, polygon2) => {
  const wkt1 = gmapsPolygonToWkt(polygon1)
  const wkt2 = gmapsPolygonToWkt(polygon2)

  const reader = new WKTReader()
  const a = reader.read(wkt1)
  const b = reader.read(wkt2)
  const intersection = a.intersects(b)

  return intersection
}
