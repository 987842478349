<template>
    <div class="sales-formula-pyramid-on-month">
        <app-loading class="loading"
                     v-if="isLoading">
        </app-loading>

        <app-error-state v-else-if="isInError">
            <md-button @click="getPyramid">
                {{ $t('ACTIONS.RETRY') }}
            </md-button>
        </app-error-state>

        <app-empty-state v-else-if="salesFormulas.length === 0"></app-empty-state>

        <div class="main"
             v-if="salesFormulas.length > 0">
            <ds-sales-formula-pyramid-card :current-value="salesFormulas[0].salesAmount"
                                           :label="$t('SALES_FORMULA_PYRAMID.TURNOVER')"
                                           :last-year-value="salesFormulas[0].salesAmountLY"
                                           :progression-value="salesFormulas[0].salesAmountProgression"
                                           class="sales-formula-pyramid-turnover"
                                           icon="local_atm">
            </ds-sales-formula-pyramid-card>

            <ds-sales-formula-pyramid-card :current-value="salesFormulas[0].entries"
                                           :label="$t('SALES_FORMULA_PYRAMID.TICKETS.LABEL')"
                                           :label-format="$tc('SALES_FORMULA_PYRAMID.TICKETS.FORMAT', salesFormulas[0].entries)"
                                           :last-year-value="salesFormulas[0].entriesLY"
                                           :progression-value="salesFormulas[0].entriesProgression"
                                           class="sales-formula-pyramid-tickets"
                                           format="decimal"
                                           icon="description">
            </ds-sales-formula-pyramid-card>

            <ds-sales-formula-pyramid-card
                    :current-value="salesFormulas[0].salesAmountByEntries"
                    :label="$t('SALES_FORMULA_PYRAMID.CART')"
                    :last-year-value="salesFormulas[0].salesAmountByEntriesLY"
                    :progression-value="salesFormulas[0].salesAmountByEntriesProgression"
                    class="sales-formula-pyramid-cart"
                    icon="shopping_cart">
            </ds-sales-formula-pyramid-card>

            <ds-sales-formula-pyramid-card :current-value="salesFormulas[0].totalWorkforce"
                                           :label="$t('SALES_FORMULA_PYRAMID.WORKFORCE.LABEL')"
                                           :label-format="$tc('SALES_FORMULA_PYRAMID.WORKFORCE.FORMAT', salesFormulas[0].totalWorkforce)"
                                           :last-year-value="salesFormulas[0].totalWorkforceLY"
                                           :progression-value="salesFormulas[0].totalWorkforceProgression"
                                           class="sales-formula-pyramid-workforce"
                                           format="decimal">
            </ds-sales-formula-pyramid-card>

            <ds-sales-formula-pyramid-card
                    :current-value="salesFormulas[0].customerByTotalWorkforce"
                    :label="$t('SALES_FORMULA_PYRAMID.AVERAGE_RATE')"
                    :last-year-value="salesFormulas[0].customerByTotalWorkforceLY"
                    :progression-value="salesFormulas[0].customerByTotalWorkforceProgression"
                    class="sales-formula-pyramid-average-rate"
                    format="percent">
            </ds-sales-formula-pyramid-card>

            <ds-sales-formula-pyramid-card
                    :current-value="salesFormulas[0].entriesByCustomers"
                    :label="$t('SALES_FORMULA_PYRAMID.FREQUENCY.LABEL')"
                    :label-format="$t('SALES_FORMULA_PYRAMID.FREQUENCY.FORMAT')"
                    :last-year-value="salesFormulas[0].entriesByCustomersLY"
                    :progression-value="salesFormulas[0].entriesByCustomersProgression"
                    class="sales-formula-pyramid-frequency"
                    format="decimal">
            </ds-sales-formula-pyramid-card>

            <ds-sales-formula-pyramid-card
                    :current-value="salesFormulas[0].quantityByEntries"
                    :label="$t('SALES_FORMULA_PYRAMID.ARTICLES.LABEL')"
                    :label-format="$tc('SALES_FORMULA_PYRAMID.ARTICLES.FORMAT', salesFormulas[0].quantityByEntries)"
                    :last-year-value="salesFormulas[0].quantityByEntriesLY"
                    :progression-value="salesFormulas[0].quantityByEntriesProgression"
                    class="sales-formula-pyramid-articles"
                    format="decimal">
            </ds-sales-formula-pyramid-card>

            <ds-sales-formula-pyramid-card
                    :current-value="salesFormulas[0].salesAmountByQuantities"
                    :label="$t('SALES_FORMULA_PYRAMID.AVERAGE_PRICE')"
                    :last-year-value="salesFormulas[0].salesAmountByQuantitiesLY"
                    :progression-value="salesFormulas[0].salesAmountByQuantitiesProgression"
                    class="sales-formula-pyramid-average-price">
            </ds-sales-formula-pyramid-card>

            <ds-sales-formula-pyramid-actions
                    class="sales-formula-pyramid-actions"></ds-sales-formula-pyramid-actions>
        </div>
    </div>
</template>

<script>
import AppEmptyState from '@/components/app-empty-state/AppEmptyState.vue';
import AppErrorState from '@/components/app-error-state/AppErrorState.vue';
import AppLoading from '@/components/app-loading/AppLoading.vue';
import salesFormulaService from '@/services/salesFormulaService';
import DsSalesFormulaPyramidActions from './SalesFormulaPyramidActions.vue';
import DsSalesFormulaPyramidCard from './SalesFormulaPyramidCard.vue';

export default {
  name: 'DsSalesFormulaPyramidOnMonth',
  components: {
    DsSalesFormulaPyramidCard,
    AppLoading,
    AppErrorState,
    AppEmptyState,
    DsSalesFormulaPyramidActions,
  },
  data() {
    return {
      isLoading: false,
      isInError: false,
      salesFormulas: [],
    };
  },
  watch: {
    '$route.query': function () {
      this.getPyramid();
    },
  },
  created() {
    this.getPyramid();
  },
  methods: {
    getPyramid() {
      const query = this.$route.query;

      this.isInError = false;
      this.isLoading = true;

      salesFormulaService.getPyramid(query.idMonth, query.sidSite, query.sidSalesChannel, query.idLivingArea, query.sidDepartments)
        .then(salesFormulas => this.salesFormulas = salesFormulas)
        .catch(() => this.isInError = true)
        .finally(() => this.isLoading = false);
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/styles/_colors";

.sales-formula-pyramid-on-month {
    border-bottom: 1px solid $color-grey-light;

    .main {
        display: grid;
        grid-template-rows: repeat(3, 1fr);
        grid-template-columns: auto auto auto auto auto 30%;
        height: 400px;

        .sales-formula-pyramid-turnover,
        .sales-formula-pyramid-actions {
            grid-row: 1;
        }

        .sales-formula-pyramid-tickets,
        .sales-formula-pyramid-cart {
            grid-row: 2;
        }

        .sales-formula-pyramid-turnover {
            grid-column: 1 / 6;
        }

        .sales-formula-pyramid-tickets {
            grid-column: 1 / 4;
        }

        .sales-formula-pyramid-cart {
            grid-column: 4 / 6;
            border-left: 1px solid $color-grey-light;
        }

        .sales-formula-pyramid-workforce,
        .sales-formula-pyramid-average-rate,
        .sales-formula-pyramid-frequency,
        .sales-formula-pyramid-articles,
        .sales-formula-pyramid-average-price {
            grid-row: 3;
        }

        .sales-formula-pyramid-workforce {
            grid-column: 1;
        }

        .sales-formula-pyramid-average-rate {
            grid-column: 2;
            border-left: 1px solid $color-grey-light;
        }

        .sales-formula-pyramid-frequency {
            grid-column: 3;
            border-left: 1px solid $color-grey-light;
        }

        .sales-formula-pyramid-articles {
            grid-column: 4;
            border-left: 1px solid $color-grey-light;
        }

        .sales-formula-pyramid-average-price {
            grid-column: 5;
            border-left: 1px solid $color-grey-light;
        }

        .sales-formula-pyramid-actions {
            grid-row: 1 / 4;
            grid-column: 6;
            border-top: 1px solid $color-grey-light;
            border-left: 1px solid $color-grey-light;
        }
    }
}
</style>
