import axios from 'axios'
import Vue from 'vue'


class AnimationService {
  constructor() {
    this.httpClient = axios.create({
      baseURL: `${process.env.VUE_APP_BACKEND}/animations`,
    })
  }

  async create(animation) {
    animation.sidSalesChannel = 99
    animation.createdBy = this.$userInfos && this.$userInfos.employeeNumber ? this.$userInfos.employeeNumber : ''
    const token = await Vue.prototype.$user.getIdToken()

    return this.httpClient
      .post('', animation, { headers: { Authorization: `Bearer ${token}` } })
      .then(res => res.data)
  }

  async save(sid, animation) {
    animation.modifiedBy = this.$userInfos && this.$userInfos.employeeNumber ? this.$userInfos.employeeNumber : ''
    const token = await Vue.prototype.$user.getIdToken()

    return this.httpClient
      .put(`/${sid}`, animation, { headers: { Authorization: `Bearer ${token}` } })
      .then(res => res.data)
  }

  async findById(sid) {
    const token = await Vue.prototype.$user.getIdToken()
    return this.httpClient
      .get(`/${sid}`, { headers: { Authorization: `Bearer ${token}` } })
      .then(res => res.data)
  }

  async findDetailById(sid) {
    const token = await Vue.prototype.$user.getIdToken()
    return this.httpClient
      .get(`/detail/${sid}`, { headers: { Authorization: `Bearer ${token}` } })
      .then(res =>
        res.data.reduce((listAnimation, animationDetail) => {
          listAnimation[animationDetail.dimension] = listAnimation[animationDetail.dimension] || []
          listAnimation[animationDetail.dimension].push(animationDetail.label)
          return listAnimation
        }, Object.create(null)))
  }

  async saveAnimationCompleted(animation) {
    const token = await Vue.prototype.$user.getIdToken()
    return this.httpClient.post(`/completed/${animation.sid}`, { headers: { Authorization: `Bearer ${token}` } })
      .then(res => res.data)
  }
}

export default new AnimationService()
