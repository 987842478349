<template>
  <div class="container">
    <div class="header">
      <md-button v-if="showCommunitySettings" class="md-icon-button" target="_blank" @click="goToCommunitySettings">
        <img src="@/assets/parameters.svg" alt="communities parameters" class="button-image">
        <md-tooltip>{{ $t('ANIMATE_HOME.TOOLTIP_SETTINGS') }}</md-tooltip>
      </md-button>
    </div>
    <div class="title">{{ $t('ANIMATE_HOME.TITLE') }}</div>
    <div class="choices">
      <div :class="[{'isChecked': checked === 'customers'}, 'item']" @click="checked = 'customers'">
        <span>{{ $t('ANIMATE_HOME.ANIMATE_STANDARD') }}</span>
      </div>
      <div :class="[{'isChecked': checked === 'community'}, 'item']" @click="checked = 'community'">
        <span class="community-label">{{ $t('ANIMATE_HOME.ANIMATE_COMMUNITY') }}</span>
      </div>
    </div>

    <div class="actions">
      <app-button
        :disabled="false"
        class="primary"
        @click.native="next">
        {{ $t('ACTIONS.NEXT') }}
      </app-button>
    </div>
  </div>
</template>

<script>

import AppButton from '@/components/app-button/AppButton'
import { isCentral } from '@/helpers/userUtils'
import Vue from 'vue'

export default {
  name: 'AnimateHome',
  components: { AppButton },
  data() {
    return {
      checked: 'customers',
      showCommunitySettings: !isCentral(Vue.prototype.$userInfos)
    }
  },
  methods: {
    next() {
      this.$router.push({ name: 'animate-store', query: {...this.$route.query, mode: this.checked, displayAnimate: true}})
    },
    goToCommunitySettings() {
      this.$router.push({name: 'community-settings', query: {...this.$route.query}} )
    }
  }
}
</script>

<style scoped lang="scss">
.container {
  font-weight: bold;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  border-right: 1px solid #E0E0E0;

  .header {
    display: flex;
    justify-content: flex-end;
  }

  .title {
    font-size: 24px;
    margin-top: 10rem
  }
  .choices {
    display: flex;
    margin-top: 5rem;
    font-size: 18px;
    justify-content: center;

    .item {
      border: 2px solid #2055cd;
      border-radius: 15px;
      width: 25rem;
      height: 15rem;
      display: flex;
      flex-direction: column;
      cursor: pointer;
      padding-top: 5rem;
      margin: 1rem 2rem;

      @font-face {
        font-family: "Super Sans";
      }

      &.isChecked {
        background: rgba(80, 140, 247, 0.2);
      }

      &.disabled {
        background: rgba(80, 140, 247, 0.2);
        cursor: not-allowed;
        color: #2055cd;
        span {
          filter: blur(2px);
        }
      }

      .soon {
        margin-top: -40%;
        height: 100%;
        z-index: 999;
      }
    }
  }

  .actions {
    padding: 2rem;
    justify-content: center;
    display: flex;

    button:not(:last-child) {
      margin-right: 1em;
    }
  .title {
    font-size: 24px;
    margin-top: 10rem
  }
  .choices {
    display: flex;
    flex-direction: row;
  }
}
}

</style>
