<template>
    <span class="app-period">
        {{ period.from | date('MMMM YYYY') }}
        <span v-if="to !== 0">
            &nbsp;-&nbsp;
            {{ period.to | date('MMMM YYYY') }}
        </span>
        &nbsp;
        ({{ $tc('APP_PERIOD.DURATION.LABEL', period.duration, {count: period.duration}) }})
    </span>
</template>

<script>
import moment from 'moment';

export default {
  name: 'AppPeriod',
  props: {
    from: {
      type: Number,
      default: 0,
    },
    to: {
      type: Number,
      default: 0,
    },
    excludeLastMonth: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    period() {
      const period = {};
      period.from = moment().subtract(this.from, 'months');
      period.to = moment().subtract(this.to, 'months');
      period.duration = Math.abs(period.from.diff(period.to, 'months'));

      if (!this.excludeLastMonth) {
        period.duration += 1;
      }

      return period;
    },
  },
};
</script>
