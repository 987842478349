<template>
    <div class="customer-segmentation-matrix">
        <md-card class="card">
            <md-card-header class="card-header">
                <md-card-header-text>
                    <div class="md-title">
                        <p>
                            {{ $t(`CUSTOMER_SEGMENTATION_MATRIX.${selectedCriterions[0]}`) }}
                            <span class="md-subhead">&nbsp;{{ $t('CUSTOMER_SEGMENTATION_MATRIX.BY') }}&nbsp;</span>
                            {{ $t(`CUSTOMER_SEGMENTATION_MATRIX.${selectedCriterions[1]}`) }}
                        </p>
                        <md-menu>
                            <md-button class="md-icon-button"
                                       md-menu-trigger>
                                <md-icon>settings</md-icon>
                            </md-button>

                            <md-menu-content>
                                <md-menu-item @click="reverseCriterions">{{
                                  $t('CUSTOMER_SEGMENTATION_MATRIX.REVERSE') }}
                                </md-menu-item>
                                <md-divider/>
                                <md-menu-item @click="setFirstCriterion">
                                    {{ $t(`CUSTOMER_SEGMENTATION_MATRIX.${lastAvailableCriterion}`) }}
                                    &nbsp;
                                    ({{ $t('CUSTOMER_SEGMENTATION_MATRIX.LINE') }})
                                </md-menu-item>
                                <md-menu-item @click="setSecondCriterion">
                                    {{ $t(`CUSTOMER_SEGMENTATION_MATRIX.${lastAvailableCriterion}`) }}
                                    &nbsp;
                                    ({{ $t('CUSTOMER_SEGMENTATION_MATRIX.COLUMN') }})
                                </md-menu-item>
                            </md-menu-content>
                        </md-menu>
                    </div>
                    <div class="options">
                        <app-datepicker
                                v-if="isSite || isLivingArea"
                                v-model="selectedPeriod"
                                :activeHelpers="false"
                                :max="maxDate"
                                :min="minDate">
                        </app-datepicker>
                        <app-monthpicker v-else
                                         :nb-of-available-months="12"
                                         :value="selectedMonth"
                                         @input="selectMonth">
                        </app-monthpicker>
                        <p>par</p>
                        <app-loading v-if="isLoadingGlobalIndicators"></app-loading>
                        <div v-else-if="globalIndicators" class="chips">
                            <md-chip :class="currentIndicator === 'customers' ? 'md-primary' : ''"
                                     @click="indicatorSelected('customers')" md-clickable>
                                {{ $t('CUSTOMER_SEGMENTATION_MATRIX.CLIENTS') }} /
                                <span class="number">{{ globalIndicators.customers | number(0) }}</span>
                                <span class="progression"
                                      :class="globalIndicators.customersProgression >= 0 ? 'good':'bad'">
                                         {{ globalIndicators.customersProgression | number(2, 'percent') }}
                                    </span>
                            </md-chip>
                            <md-chip :class="currentIndicator === 'salesAmount' ? 'md-primary' : ''"
                                     @click="indicatorSelected('salesAmount')" md-clickable>
                                {{ $t('CUSTOMER_SEGMENTATION_MATRIX.SALES_AMOUNT') }} /
                                <span class="number">{{ globalIndicators.salesAmount | number(1, 'currency')
                                    }}</span>
                                <span class="progression"
                                      :class="globalIndicators.salesAmountProgression >= 0 ? 'good':'bad'">
                                            {{ globalIndicators.salesAmountProgression | number(2, 'percent') }}
                                    </span>
                            </md-chip>
                            <md-chip :class="currentIndicator === 'entries' ? 'md-primary' : ''"
                                     @click="indicatorSelected('entries')" md-clickable>
                                {{ $t('CUSTOMER_SEGMENTATION_MATRIX.ENTRIES') }} /
                                <span class="number">{{ globalIndicators.entries | number(1) }}</span>
                                <span class="progression"
                                      :class="globalIndicators.entriesProgression >= 0 ? 'good':'bad'">
                                            {{ globalIndicators.entriesProgression | number(2, 'percent') }}
                                    </span>
                            </md-chip>
                            <md-chip :class="currentIndicator === 'averageCart' ? 'md-primary' : ''"
                                     @click="indicatorSelected('averageCart')" md-clickable>
                                {{ $t('CUSTOMER_SEGMENTATION_MATRIX.CART') }} /
                                <span class="number">{{ globalIndicators.averageCart | number(2, 'currency') }}</span>
                                <span class="progression"
                                      :class="globalIndicators.averageCartProgression >= 0 ? 'good':'bad'">
                                            {{ globalIndicators.averageCartProgression | number(2, 'percent') }}
                                    </span>
                            </md-chip>
                        </div>
                    </div>
                </md-card-header-text>
            </md-card-header>

            <md-card-content class="content">
                <app-loading v-if="isLoadingHeader"></app-loading>
                <app-error-state v-else-if="errorSnackbar.info || !(lines || resumeCells)">
                    <md-button @click="retry()">
                        {{ $t('ACTIONS.RETRY') }}
                    </md-button>
                </app-error-state>

                <div v-else-if="resumeCells">
                    <app-empty-state v-if="resumeCells.length === 0"></app-empty-state>
                    <div v-else
                         :class="isMore ? 'extended': ''"
                         class="grid">
                        <div class="cell"
                             v-if="isMore"/>
                        <div :key="`resume_cell_${idx}`"
                             class="cell-header cell"
                             v-for="(resumeCell, idx) in resumeCells">
                            <span>{{ resumeCell.labelDimensionX }}</span>
                            <strong>{{ resumeCell[currentIndicator] | number(0) }}</strong>
                            <span :class="resumeCell[currentIndicator + 'Progression'] >= 0 ? 'good' : 'bad'">
                            {{ resumeCell[currentIndicator + 'Progression'] | number(1, 'percent') }}
                        </span>
                        </div>
                    </div>

                    <app-loading v-if="isLoadingLines"></app-loading>
                    <div v-else-if="isMore && lines">
                        <app-empty-state v-if="lines.length === 0"></app-empty-state>
                        <div :key="`line_${idx}`"
                             class="grid extended"
                             v-for="(line, idx) in lines">
                            <div class="cell-header cell right">
                                <span>{{ line.row.labelDimensionX }}</span>
                                <strong>{{ line.row[currentIndicator] | number(0) }}</strong>
                                <span :class="line.row[currentIndicator + 'Progression'] >= 0 ? 'good' : 'bad'">
                                {{ line.row[currentIndicator + 'Progression'] | number(1, 'percent') }}
                            </span>
                            </div>
                            <div :class="backgroundCell(cell)"
                                 :key="`cell_${idx}`"
                                 class="cell"
                                 v-for="(cell, idx) in line.cells">
                                <span>{{ cell[currentIndicator] | number(0) }}</span>
                                <strong>{{ cell[currentIndicator + 'Progression'] | number(1, 'percent') }}</strong>
                            </div>
                        </div>
                    </div>
                </div>

                <md-card-actions v-if="!isLoadingHeader">
                    <md-button class="md-primary md-raised" @click="toggleMore">
                        <span v-if="isMore">{{ $t('ACTIONS.SHOW_LESS') }}</span>
                        <span v-else>{{ $t('ACTIONS.SHOW_MORE') }}</span>
                    </md-button>
                </md-card-actions>
            </md-card-content>
        </md-card>

        <md-snackbar :md-active.sync="errorSnackbar.active"
                     :md-duration="Infinity"
                     md-persistent
                     md-position="left">
            <span>{{ errorSnackbar.message }}</span>
            <md-button @click="errorSnackbar.action"
                       class="md-primary"
                       v-if="errorSnackbar.action !== null">{{ $t('ACTIONS.RETRY') }}
            </md-button>
        </md-snackbar>
    </div>
</template>

<script>
import AppErrorState from '@/components/app-error-state/AppErrorState.vue';
import AppEmptyState from '@/components/app-empty-state/AppEmptyState.vue';
import AppLoading from '@/components/app-loading/AppLoading.vue';
import AppDatepicker from '@/components/app-datepicker/AppDatepicker.vue';
import AppMonthpicker from '@/components/app-monthpicker/AppMonthpicker.vue';
import indicatorsService from '@/services/indicatorsService';
import matrixService from '@/services/matrixService';
import moment from 'moment';
import { orderBy, groupBy } from 'lodash';

export default {
  name: 'DsCustomerSegmentationMatrix',
  components: {
    AppEmptyState,
    AppDatepicker,
    AppLoading,
    AppErrorState,
    AppMonthpicker,
  },
  data() {
    return {
      isLoadingHeader: false,
      isLoadingLines: false,
      isLoadingGlobalIndicators: false,
      isMore: false,
      globalIndicators: null,
      availableCriterions: ['CUSTOMERS_TARGET', 'OLYMPIC_SEGMENTATION', 'ACTIVATION_EASINESS'],
      selectedCriterions: ['OLYMPIC_SEGMENTATION', 'ACTIVATION_EASINESS'],
      resumeCells: null,
      selectedMonth: {},
      lines: [],
      currentIndicator: 'customers',
      comparisonType: 1,
      errorSnackbar: {
        message: '',
        active: false,
        info: null,
      },
      selectedPeriod: {
        start: moment().subtract(1, 'months').startOf('month').toDate(),
        end: moment().subtract(1, 'months').endOf('month').toDate(),
      },
      maxDate: moment().subtract(1, 'days').hours(0).minutes(0)
        .seconds(0)
        .toDate(),
      minDate: moment().subtract(1, 'year').hours(0).minutes(0)
        .seconds(0)
        .toDate(),
    };
  },
  computed: {
    lastAvailableCriterion() {
      return this.availableCriterions.filter(criterion => this.selectedCriterions.indexOf(criterion) === -1)[0];
    },
    isSite() {
      return this.$route.query.sidSite !== undefined;
    },
    isLivingArea() {
      return +this.$route.query.sidSalesChannel === 50 && this.$route.query.sidSite === undefined;
    },
  },
  watch: {
    '$route.query': function () {
      this.isMore = false;
      const params = this.$route.query;

      if (!params.sidSite) {
        this.buildPeriod(this.selectedMonth);
      }

      this.buildGlobalIndicators(params.sidSite, params.sidSalesChannel, params.idLivingArea, params.sidDepartments, this.selectedPeriod.start, this.selectedPeriod.end, this.comparisonType);
      this.buildMatrixHeader(params.sidSite, params.sidSalesChannel, params.idLivingArea, params.sidDepartments, this.selectedPeriod.start, this.selectedPeriod.end, this.comparisonType);
    },
    selectedPeriod: {
      handler() {
        this.isMore = false;
        this.buildComparisonType();
        const params = this.$route.query;
        this.buildGlobalIndicators(params.sidSite, params.sidSalesChannel, params.idLivingArea, params.sidDepartments, this.selectedPeriod.start, this.selectedPeriod.end, this.comparisonType);
        this.buildMatrixHeader(params.sidSite, params.sidSalesChannel, params.idLivingArea, params.sidDepartments, this.selectedPeriod.start, this.selectedPeriod.end, this.comparisonType);
      },
      deep: true,
    },
  },
  created() {
    this.selectDefaultMonth();
  },
  methods: {
    backgroundCell(cell) {
      const formattedCurrentIndicator = this.currentIndicator.charAt(0).toUpperCase() + this.currentIndicator.slice(1);

      if (cell[`${this.currentIndicator}Progression`] >= 0) {
        if (cell[`top80${formattedCurrentIndicator}PositiveApport`]) return 'up80';
        return 'up';
      } else if (cell[`${this.currentIndicator}Progression`] < 0) {
        if (cell[`top80${formattedCurrentIndicator}NegativeApport`]) return 'down80';
        return 'down';
      }

      return '';
    },
    setFirstCriterion() {
      const selectedCriterions = [this.lastAvailableCriterion, this.selectedCriterions[1]];
      this.selectedCriterions = selectedCriterions;
      this.isMore = false;
      const params = this.$route.query;
      this.buildGlobalIndicators(params.sidSite, params.sidSalesChannel, params.idLivingArea, params.sidDepartment, this.selectedPeriod.start, this.selectedPeriod.end, this.comparisonType);
      this.buildMatrixHeader(params.sidSite, params.sidSalesChannel, params.idLivingArea, params.sidDepartment, this.selectedPeriod.start, this.selectedPeriod.end, this.comparisonType);
    },
    setSecondCriterion() {
      const selectedCriterions = [this.selectedCriterions[0], this.lastAvailableCriterion];
      this.selectedCriterions = selectedCriterions;
      this.isMore = false;
      const params = this.$route.query;
      this.buildGlobalIndicators(params.sidSite, params.sidSalesChannel, params.idLivingArea, params.sidDepartment, this.selectedPeriod.start, this.selectedPeriod.end, this.comparisonType);
      this.buildMatrixHeader(params.sidSite, params.sidSalesChannel, params.idLivingArea, params.sidDepartment, this.selectedPeriod.start, this.selectedPeriod.end, this.comparisonType);
    },
    reverseCriterions() {
      this.selectedCriterions.reverse();
      this.isMore = false;
      const params = this.$route.query;
      this.buildMatrixHeader(params.sidSite, params.sidSalesChannel, params.idLivingArea, params.sidDepartments, moment(this.selectedPeriod.start).format('YYYY-MM-DD'), moment(this.selectedPeriod.end).format('YYYY-MM-DD'), this.comparisonType);
    },
    toggleMore() {
      this.isMore = !this.isMore;
      if (this.isMore) {
        const params = this.$route.query;
        this.buildMatrixLines(params.sidSite, params.sidSalesChannel, params.idLivingArea, params.sidDepartments, moment(this.selectedPeriod.start).format('YYYY-MM-DD'), moment(this.selectedPeriod.end).format('YYYY-MM-DD'), this.comparisonType);
      }
    },
    indicatorSelected(indicatorLabel) {
      this.currentIndicator = indicatorLabel;
    },
    buildGlobalIndicators(sidSite, sidSalesChannel, idLivingArea, sidDepartments, startDate, endDate, comparisonType) {
      this.isLoadingGlobalIndicators = true;
      indicatorsService.findGlobalIndicators(sidSite, sidSalesChannel, idLivingArea, sidDepartments, startDate, endDate, comparisonType)
        .then(datas => this.globalIndicators = datas)
        .finally(() => this.isLoadingGlobalIndicators = false);
    },
    buildMatrixHeader(sidSite, sidSalesChannel, idLivingArea, sidDepartments, startDate, endDate, comparisonType) {
      this.isLoadingHeader = true;
      this.errorSnackbar.info = null;
      this.resumeCells = [];
      matrixService.findMatrixIndicators(sidSite, sidSalesChannel, idLivingArea, sidDepartments, startDate, endDate, comparisonType, this.selectedCriterions[0], 'GLOBAL')
        .then(datas => this.resumeCells = orderBy(datas, ['orderX'], ['asc']))
        .catch(() => {
          this.errorSnackbar.info = 'header';
          this.resumeCells = null;
        })
        .finally(() => this.isLoadingHeader = false);
    },
    buildMatrixLines(sidSite, sidSalesChannel, idLivingArea, sidDepartments, startDate, endDate, comparisonType) {
      this.isLoadingLines = true;
      this.errorSnackbar.info = null;
      Promise.all([
        matrixService.findMatrixIndicators(sidSite, sidSalesChannel, idLivingArea, sidDepartments, startDate, endDate, comparisonType, this.selectedCriterions[1], 'GLOBAL'),
        matrixService.findMatrixIndicators(sidSite, sidSalesChannel, idLivingArea, sidDepartments, startDate, endDate, comparisonType, this.selectedCriterions[0], this.selectedCriterions[1]),
      ])
        .then(datas => {
          this.lines = [];
          const indicators = groupBy(datas[1], 'orderY');
          datas[0].forEach(row => {
            const resultLine = {
              row,
              cells: indicators[row.orderX],
            };
            this.lines.push(resultLine);
          });
        })
        .catch(() => {
          this.errorSnackbar.info = 'lines';
          this.lines = null;
        })
        .finally(() => this.isLoadingLines = false);
    },
    selectDefaultMonth() {
      const monthValue = this.$route.query.idMonth;

      if (monthValue) {
        this.selectedMonth = moment(monthValue, 'YYYYMM');
      } else {
        this.selectMonth(moment().subtract(1, 'months'));
      }
      this.buildPeriod(this.selectedMonth);
    },
    selectMonth(month) {
      this.buildPeriod(month);
      this.selectedMonth = month;
      const query = Object.assign({}, this.$route.query);
      query.idMonth = month.format('YYYYMM');

      this.$router.push({ name: this.$route.name, query });
    },
    buildPeriod(period) {
      const buildPeriod = Object.assign({}, period);
      this.selectedPeriod.start = moment(buildPeriod).startOf('month').toDate();
      this.selectedPeriod.end = moment(buildPeriod).endOf('month').toDate();
    },
    buildComparisonType() {
      const startOfMonth = Object.assign({}, moment(this.selectedPeriod.start).startOf('month'));
      const endOfMonth = Object.assign({}, moment(this.selectedPeriod.end).endOf('month'));
      if (moment(this.selectedPeriod.start).isSame(moment(startOfMonth), 'date') && moment(this.selectedPeriod.end).isSame(moment(endOfMonth), 'date')) { this.comparisonType = 1; } else this.comparisonType = 0;
    },
    retry() {
      const params = this.$route.query;
      if (this.errorSnackbar.info === 'header') {
        this.buildMatrixHeader(params.sidSite, params.sidSalesChannel, params.idLivingArea, params.sidDepartments, this.selectedPeriod.start, this.selectedPeriod.end, this.comparisonType);
      } else if (this.errorSnackbar.info === 'lines') {
        this.buildMatrixLines(params.sidSite, params.sidSalesChannel, params.idLivingArea, params.sidDepartments, moment(this.selectedPeriod.start).format('YYYY-MM-DD'), moment(this.selectedPeriod.end).format('YYYY-MM-DD'), this.comparisonType);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
    @import "~@/styles/_colors";
    @import "~@/styles/_mixins";
    @import "~@/styles/_variables";

    .customer-segmentation-matrix {
        flex: 1;
        height: 100%;
        display: flex;

        .card {
            flex: 1;
            height: 100%;
            display: flex;
            flex-direction: column;
            width: 100%;
        }

        .card-header {
            background-color: $color-grey-light-2;
            box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0 2px 0px 0px rgba(0, 0, 0, 0.12), 0 1px 0px 0 rgba(0, 0, 0, 0.2);
            .md-card-header-text {
                @include display-flex(flex-start, flex-start, column);
                .md-title {
                    @include display-flex(center, space-between, row);
                    width: 100%;
                    font-size: 1.3em;
                    p {
                        margin: 0;
                    }
                }
            }
            .options {
                @include display-flex(center, flex-start);
                p {
                    margin: 0 .5em 0 .5em;
                }
                .chips {
                    .md-chip:hover .progression.bad, .md-chip:hover .progression.good {
                        color: #fff;
                    }

                    .number {
                        font-weight: bold;
                        font-size: 1.2em;
                    }
                    .progression {
                        margin-left: 1em;
                        font-weight: bold;
                        &.bad {
                            color: $color-bad;
                        }
                        &.good {
                            color: $color-good;
                        }
                    }

                    .md-primary {
                        .progression {
                            color: #fff;
                        }
                    }
                }
            }
        }

        .content {
            height: 100%;
            overflow: scroll;
        }
        .grid {
            display: grid;
            grid-gap: 5px;
            grid-template-columns: repeat(10, 1fr);
            padding-top: 5px;

            .cell {
                @include display-flex(center, space-between, column);
                padding: 5px;
                border-radius: 2px;
                height: 4.2em;
                width: 8em;

                &.cell-header {
                    flex-direction: column;

                    span:first-child {
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        max-width: 8em;
                        color: rgb(137, 137, 137);
                    }

                    span {
                        font-size: .9em;
                    }

                    span.good {
                        color: $color-good;
                    }

                    span.bad {
                        color: $color-bad;
                    }

                    strong {
                        line-height: 0.2em;
                        font-size: 1em;
                    }
                }

                &.good,
                &.bad {
                    color: white;
                    font-weight: normal;
                }
                &.up {
                    background-color: #79E6A8;
                }
                &.up80 {
                    background-color: #4BB883;
                }
                &.down {
                    background-color: #F49065;
                }
                &.down80 {
                    background-color: #E46254;
                }
            }

            &.extended {
                grid-template-columns: repeat(11, 1fr);

                .cell.header {
                    align-items: center;

                    &.right {
                        align-items: flex-end;
                    }
                }
            }
        }
    }
</style>
