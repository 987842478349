<template>
    <div class="app-context">
        <div class="toolbar">
            <div class="main">
                <app-button
                        :class="activeContextId === 'geographic' ? 'active' : ''"
                        :disabled="isLoadingGeographicContext"
                        @click.native="toggleContextContainer('geographic')"
                        icon="location_on">
                     {{ contextGeoFormatted }}
                    <span v-if="isLivingArea">(Zdv.)</span>
                </app-button>
                <app-button
                        v-if="!(isProxy || isARF || isViewMap || isLivingAreaWithProxy)"
                        :class="activeContextId === 'nomenclature' ? 'active' : ''"
                        @click.native="toggleContextContainer('nomenclature')"
                        icon="local_grocery_store">
                    {{ nomenclatureFormatted }}

                    <md-tooltip v-if="nomenclatures.length > 0" md-direction="bottom">
                        <span :key="'nom_' + idx" v-for="(nomenclature, idx) in nomenclatures">
                            {{ nomenclature.label }} -
                        </span>
                    </md-tooltip>

                </app-button>
                <app-button
                        v-if="format && !(isProxy || isARF || isNational || isDrive || isHyper || isLivingArea)"
                        :class="activeContextId === 'format' ? 'active' : ''"
                        @click.native="toggleContextContainer('format')"
                        icon="store">
                    {{ format.label }}
                </app-button>
            </div>
            <div>
                <app-button
                        :class="activeContextId === 'period' ? 'active' : ''"
                        @click.native="toggleContextContainer('period')"
                        icon="today"
                        v-if="!disablePeriod">
                    {{ periodFormatted }}
                </app-button>
            </div>
        </div>
        <transition
                @after-leave="enableScroll"
                @before-enter="disableScroll"
                name="toggle-box">
            <div
                    class="box"
                    v-show="activeContextId !== null">
                <app-context-geographic
                        :value="contextGeo"
                        @cancel="toggleContextContainer('geographic')"
                        @input="setContextGeo"
                        v-if="activeContextId === 'geographic'">
                </app-context-geographic>

                <app-context-nomenclature
                        :value="nomenclatures"
                        :init-selected-id-markets="initSelectedMarkets"
                        :is-only-one-market="isOnlyOneMarket"
                        @cancel="toggleContextContainer('nomenclature')"
                        @input="setNomenclatures"
                        v-if="activeContextId === 'nomenclature' && !isProxy">
                </app-context-nomenclature>

                <app-context-format
                        :value="format"
                        @input="setFormat"
                        v-if="activeContextId === 'format' && !isProxy">
                </app-context-format>

                <app-context-period
                        :value="period"
                        @cancel="toggleContextContainer('period')"
                        @input="setPeriod"
                        v-if="activeContextId === 'period'">
                </app-context-period>
            </div>
        </transition>
    </div>
</template>

<script>
import AppButton from '@/components/app-button/AppButton.vue';
import nomenclatureGeographicService from '@/services/nomenclatureGeographicService';
import nomenclatureReferentialService from '@/services/nomenclatureReferentialService';
import salesChannelService from '@/services/salesChannelService';
import moment from 'moment';
import AppContextFormat from './format/AppContextFormat.vue';
import AppContextGeographic from './geographic/AppContextGeographic.vue';
import AppContextNomenclature from './nomenclature/AppContextNomenclature.vue';
import AppContextPeriod from './period/AppContextPeriod.vue';

export default {
  name: 'AppContext',
  components: {
    AppContextNomenclature,
    AppContextFormat,
    AppContextPeriod,
    AppContextGeographic,
    AppButton,
  },
  props: {
    disablePeriod: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoadingGeographicContext: false,
      isLoadingNomenclatureContext: false,
      activeContextId: null,
      contextGeo: null,
      isLivingAreaWithProxy: false,
      initSelectedMarkets: this.$route.query.sidDepartments ? this.$route.query.sidDepartments.split(',').map(sid => ({ sid })) : null,
      isProxy: +this.$route.query.sidSalesChannel === 5,
      isSchiever: +this.$route.query.sidSalesChannel === 6,
      isHyper: +this.$route.query.sidSalesChannel === 1 && this.$route.query.sidSite === undefined,
      isDrive: +this.$route.query.sidSalesChannel === 3 && this.$route.query.sidSite === undefined,
      isLivingArea: +this.$route.query.sidSalesChannel === 50 && this.$route.query.sidSite === undefined,
      isNational: +this.$route.query.sidSalesChannel === 99 && this.$route.query.sidSite === undefined && this.$route.query.idLivingArea === undefined,
      isARF: +this.$route.query.sidSalesChannel === 100,
      isViewMap: this.$route.name === 'analyze-map',
      nomenclatures: [],
      format: {},
      period: {
        start: moment(),
        end: moment(),
      },
    };
  },
  computed: {
    isOnlyOneMarket() {
      return this.isARF || this.isNational || this.isHyper || this.isDrive || this.isProxy || this.isLivingAreaWithProxy;
    },
    contextGeoFormatted() {
      if (this.isLoadingGeographicContext) {
        return '...';
      }

      if (typeof (this.contextGeo) === 'number' || this.contextGeo === null) {
        if (+this.$route.query.sidSalesChannel === 5) return this.$t('APP_CONTEXT.ALL_PROXY');
        if (+this.$route.query.sidSalesChannel === 99) return this.$t('APP_CONTEXT.ALL_NATIONAL');
        if (+this.$route.query.sidSalesChannel === 100) return this.$t('APP_CONTEXT.ALL_ARF');
        if (+this.$route.query.sidSalesChannel === 1) return this.$t('APP_CONTEXT.ALL_HYPER');
        if (+this.$route.query.sidSalesChannel === 3) return this.$t('APP_CONTEXT.ALL_DRIVE');
      }

      return this.contextGeo.label;
    },
    nomenclatureFormatted() {
      if (this.isLoadingNomenclatureContext) {
        return '...';
      }

      if (this.nomenclatures.length === 0) {
        return this.$t('APP_CONTEXT.GLOBAL');
      }

      return this.$tc('APP_CONTEXT.NOMENCLATURES', this.nomenclatures.length, {
        label: this.nomenclatures[0].label,
        count: this.nomenclatures.length,
      });
    },
    periodFormatted() {
      const start = moment(this.period.start);
      const end = moment(this.period.end);

      if (start.isSame(end, 'day')) {
        return start.format('ddd Do MMM YYYY');
      }
      return `${start.format('DD/MM/YYYY')} - ${end.format('DD/MM/YYYY')}`;
    },
  },
  created() {
    this.initGeographicContext();
    this.initNomenclatureContext();
    this.initFormatContext();
    this.initPeriodContext();
  },
  watch: {
    $route() {
      this.activeContextId = null;
      this.initSelectedMarkets = this.$route.query.sidDepartments ? this.$route.query.sidDepartments.split(',').map(sid => ({ sid })) : null;
      this.isProxy = +this.$route.query.sidSalesChannel === 5;
      this.isHyper = +this.$route.query.sidSalesChannel === 1 && this.$route.query.sidSite === undefined;
      this.isDrive = +this.$route.query.sidSalesChannel === 3 && this.$route.query.sidSite === undefined;
      this.isLivingArea = +this.$route.query.sidSalesChannel === 50 && this.$route.query.sidSite === undefined;
      this.isNational = +this.$route.query.sidSalesChannel === 99 && this.$route.query.sidSite === undefined && this.$route.query.idLivingArea === undefined;
      this.isARF = +this.$route.query.sidSalesChannel === 100;
      this.isViewMap = this.$route.name === 'analyze-map';
    },
    '$route.query.sidDepartments': function () {
      if (this.isProxy || this.isLivingArea) {
        this.nomenclatures = [];
      } else if (this.isNational || this.isARF) {
        if (this.nomenclatures.length > 1) {
          this.nomenclatures = [];
        }
      }
    },
  },
  methods: {
    initGeographicContext() {
      const query = this.$route.query;

      if (query.sidSite) {
        this.isLoadingGeographicContext = true;
        nomenclatureGeographicService.getSiteBySid(query.sidSite)
          .then(contextGeo => this.contextGeo = contextGeo)
          .finally(() => this.isLoadingGeographicContext = false);
      }

      if (query.idLivingArea) {
        this.isLoadingGeographicContext = true;
        nomenclatureGeographicService.getLivingAreaById(query.idLivingArea)
          .then(contextGeo => {
            this.contextGeo = contextGeo;
            nomenclatureGeographicService.getAllSites().then(sites => {
              sites.forEach(site => {
                if (site.parent.id === contextGeo.id) {
                  if (site.concept.id !== 1) this.isLivingAreaWithProxy = true;
                }
              });
            });
          })
          .finally(() => this.isLoadingGeographicContext = false);
      }
    },
    initNomenclatureContext() {
      const query = this.$route.query;

      if (query.sidDepartments) {
        this.isLoadingNomenclatureContext = true;

        nomenclatureReferentialService.getAllMarketsBySid(query.sidDepartments.split(','))
          .then(nomenclatures => this.nomenclatures = nomenclatures)
          .finally(() => this.isLoadingNomenclatureContext = false);
      }
    },
    initFormatContext() {
      if (this.isSchiever) this.format = null
      if (!this.isProxy || !this.isNational || !this.isARF || !this.isDrive || !this.isHyper) {
        const query = this.$route.query;
        const sidSalesChannel = query.sidSalesChannel ? +query.sidSalesChannel : 99;

        this.format = salesChannelService.getById(sidSalesChannel);
      }

      this.format = null;
    },
    initPeriodContext() {
      const query = this.$route.query;

      this.period.start = query.startDate ? moment(query.startDate) : moment();
      this.period.end = query.endDate ? moment(query.endDate) : moment();
    },
    toggleContextContainer(contextId) {
      if (this.activeContextId === contextId) {
        this.activeContextId = null;
      } else {
        this.activeContextId = contextId;
      }
    },
    enableScroll() {
      document.body.style.overflow = 'auto';
    },
    disableScroll() {
      document.body.style.overflow = 'hidden';
    },
    setContextGeo(contextGeo) {
      this.contextGeo = contextGeo;
      this.isLivingAreaWithProxy = false;
      const query = Object.assign({}, this.$route.query);

      if (this.contextGeo !== null) {
        if (this.contextGeo.level === 32) {
          query.idLivingArea = this.contextGeo.id;
          query.sidSalesChannel = 50;
          delete query.sidDepartments;
          delete query.sidSite;

          nomenclatureGeographicService.getAllSites().then(sites => {
            sites.forEach(site => {
              if (site.parent.id === contextGeo.id) {
                if (site.concept.id !== 1 && this.isLivingArea) this.isLivingAreaWithProxy = true;
              }
            });
          });
        } else if (typeof this.contextGeo === 'number') {
          this.isLivingAreaWithProxy = false;
          delete query.sidSite;
          delete query.idLivingArea;
          delete query.sidDepartments;
          query.sidSalesChannel = this.contextGeo;
        } else {
          query.sidSite = this.contextGeo.sid;
          if (this.contextGeo.concept.id === 18) {
            delete query.idLivingArea;
            query.sidSalesChannel = 6;
            this.format = null
          } else if (query.sidSalesChannel === 5 || contextGeo.format.id !== 4) {
            delete query.sidSalesChannel;
            delete query.idLivingArea;
            this.format = salesChannelService.getById(99);
          } else if (contextGeo.format.id === 4) {
            query.sidSalesChannel = 5;
          }
        }
      }
      if (this.contextGeo?.concept?.id === 2) query.sidSalesChannel = 1

      this.$router.push({ name: this.$route.name, query });
      this.toggleContextContainer('geographic');
    },
    setNomenclatures(nomenclatures) {
      this.nomenclatures = nomenclatures;
      const query = Object.assign({}, this.$route.query);

      if (this.nomenclatures.length > 0) {
        query.sidDepartments = this.nomenclatures.map(nomenclature => nomenclature.sid).join(',');
      } else {
        delete query.sidDepartments;
      }

      this.$router.push({ name: this.$route.name, query });
      this.toggleContextContainer('nomenclature');
    },
    setFormat(format) {
      this.format = format;
      const query = Object.assign({}, this.$route.query);

      if (this.format.id !== 99) {
        query.sidSalesChannel = this.format.id;
      } else {
        delete query.sidSalesChannel;
      }

      this.$router.push({ name: this.$route.name, query });
      this.toggleContextContainer('format');
    },
    setPeriod(period) {
      this.period = period;

      const query = Object.assign({}, this.$route.query);
      query.startDate = moment(this.period.start).format('YYYY-MM-DD');

      if (this.period.end) {
        query.endDate = moment(this.period.end).format('YYYY-MM-DD');
      }

      this.$router.push({ name: this.$route.name, query });

      this.toggleContextContainer('period');
    },
  },
};
</script>

<style scoped lang="scss">
    @import "~@/styles/_variables";
    @import "~@/styles/_mixins";

    .app-context {
        $toolbar-height: 48px;

        .toolbar {
            @include display-flex(center, space-between);
            height: $toolbar-height;
            background-color: white;
            box-shadow: $box-shadow;
            padding: 0 1rem;

            .main {
                display: flex;

                button + button {
                    margin-left: 0.5rem;
                }
            }
        }

        .box {
            position: absolute;
            z-index: 99;
            left: 0;
            right: 0;
            top: auto;
            overflow: auto;
            height: calc(100% - 96px);
            background-color: white;
        }

        .toggle-box-enter-active,
        .toggle-box-leave-active {
            transition: all 300ms ease-in-out;
        }

        .toggle-box-enter,
        .toggle-box-leave-to {
            opacity: 0;
            transform: translateY(-30px);
        }

    }
</style>
