class DebugDolmenService {
  static DEBUG_DOLMEN_MODE = 'ANIMATION';

  usingMode = {
    isStandalone: true,
    isRead: true,
    isAnimation: false,
    isSupervisor: false,
  };

  constructor() {
    window.addEventListener('message', (event) => {
      if (event.data !== 'loaded') {
        let paramsFromDolmen = {};

        try {
          paramsFromDolmen = JSON.parse(event.data);
        } catch (e) {
          paramsFromDolmen.profile = 'READ';
        }

        const usingMode = {
          isStandalone: false,
          isRead: paramsFromDolmen.profile === 'READ',
          isAnimation: paramsFromDolmen.profile === 'ANIMATION',
          isSupervisor: paramsFromDolmen.profile === 'SUPERVISOR',
        };

        this.setUsingMode(usingMode);
      }
    }, false);

    window.parent.postMessage('loaded', '*');
  }

  /**
   * Return a boolean if debugDolmen is on;
   */
  canAnimateDolmen() {
    const usingMode = this.getUsingMode();

    if (process.env.VUE_APP_BUILD_ENV === 'development') {
      return DebugDolmenService.DEBUG_DOLMEN_MODE === 'ANIMATION';
    }

    return usingMode.isAnimation;
  }


  /**
   * Set the using mode send by Dolmen.
   * @param usingMode
   */
  setUsingMode(usingMode) {
    this.usingMode = usingMode;
  }

  /**
   * Retrieve the using mode.
   * @return {}
   */
  getUsingMode() {
    return this.usingMode;
  }
}

export default new DebugDolmenService();
