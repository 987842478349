class SalesChannelService {
  constructor() {
    this.salesChannels = [
      { id: 99, label: 'Hyper + Drive', displayOrder: 1 },
      { id: 1, label: 'Hypermarche', displayOrder: 2 },
      { id: 3, label: 'Drive', displayOrder: 3 },
    ];
    this.salesChannels.sort((s1, s2) => s1.displayOrder - s2.displayOrder);
  }

  getAll() {
    return this.salesChannels;
  }

  getAllDistinct() {
    return [this.salesChannels[1], this.salesChannels[2]];
  }

  getById(id) {
    return this.salesChannels.find(salesChannel => salesChannel.id === id);
  }
}

export default new SalesChannelService();
