<template>
    <div class="google-maps-zoom-control"
         id="zoom-control">
        <div>
            <md-button @click="zoomIn"
                       class="md-icon-button md-raised">
                <md-icon>add</md-icon>
            </md-button>
        </div>
        <div>
            <md-button @click="zoomOut"
                       class="md-icon-button md-raised">
                <md-icon>remove</md-icon>
            </md-button>
        </div>
    </div>
</template>

<script>
export default {
  name: 'GoogleMapsZoomControl',
  props: {
    map: {
      type: Object,
      default: null,
    },
  },
  methods: {
    zoomIn() {
      this.map.setZoom(this.map.getZoom() + 1);
    },
    zoomOut() {
      this.map.setZoom(this.map.getZoom() - 1);
    },
  },
};
</script>

<style scoped lang="scss">
.google-maps-zoom-control {
    margin: 13px;

    div + div {
        margin-top: 8px;
    }
}
</style>
