<template>
  <div class="container">
    <app-loading v-if="isLoading" />
    <div class="title">{{ $t('ANIMATE_COMMUNITY.TITLE') }}</div>
    <div class="container-cards">
      <div class="cards">
        <div
          v-for="(community, idx) in communities"
          :key="'community' + idx"
          :class="[{ active: community.isSelected }, 'card']"
          @click="selectCommunity(community)">
          <div class="card-title">{{ community.communityLabel }}</div>
          <div class="card-value">{{ community.customers }} clients</div>
        </div>
      </div>
    </div>

    <div class="actions">
      <app-button :disabled="false" @click.native="back">
        {{ $t('ACTIONS.BACK') }}
      </app-button>

      <app-button :disabled="false" class="primary" @click.native="next">
        {{ $t('ACTIONS.NEXT') }}
      </app-button>
    </div>
  </div>
</template>

<script>
  import AppButton from '@/components/app-button/AppButton'
  import AppLoading from '@/components/app-loading/AppLoading'
  import animationService from '@/services/animationService'
  import communitiesService from '@/services/communitiesService'
  import { debounce } from 'lodash'

  export default {
    name: 'AnimateCommunity',
    components: { AppButton, AppLoading },
    data() {
      return {
        checked: 'community',
        isLoading: false,
        communities: [],
        animation: {}
      }
    },
    async created() {
      await animationService.findById(this.$route.params.sid).then(animation => {
        this.animation = animation
        this.$emit('init', this.animation)
      })
      this.getDatas()
    },
    methods: {
      getDatas() {
        this.isLoading = true
        communitiesService.findCommunitiesBySite(this.$route.query.sidSite, this.$route.params.sid).then(communities => {
          this.isLoading = false
          this.communities = communities
            .filter(item => item.isActive)
            .map(community => ({
              ...community,
              isSelected: false
            }))
        })
      },
      back() {
        this.$router.push({ name: 'animate-media', params: this.$route.params, query: { ...this.$route.query, mode: this.checked } })
      },
      next() {
        const communityCodes = this.communities.filter(community => community.isSelected).map(community => community.communityCode)
        this.animation.groupTypes = communityCodes.length > 0 ? communityCodes : null
        animationService
          .save(this.animation.sid, this.animation)
          .then(() =>
            this.$router.push({
              name: 'animate-resume',
              params: this.$route.params,
              query: this.$route.query
            })
          )
          .catch(() => {
            this.message.active = true
            this.message.value = this.$t('ANIMATE_MARKET.ERROR.SAVING')
          })
          .finally(() => (this.isSaving = false))
      },
      selectCommunity(community) {
        community.isSelected = !community.isSelected
        this.updateAnimation()
      },
      updateAnimation: debounce(function() {
        const communityCodes = this.communities.filter(community => community.isSelected).map(community => community.communityCode)
        this.animation.groupTypes = communityCodes.length > 0 ? communityCodes : null
        this.$emit('update', this.animation)
      }, 1000)
    }
  }
</script>

<style scoped lang="scss">
  .container {
    border-top: 1px solid #e0e0e0;
    flex: 1;
    background: white;
    border-radius: 2px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    font-weight: bold;

    .title {
      padding: 2rem;
      margin: 0;
    }
    .container-cards {
      padding: 1em;
      width: 100%;
      height: 100%;
      overflow: auto;

      .cards {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 1vw;
        margin-left: 10%;
        width: 80%;
        .card {
          height: 5em;
          cursor: pointer;
          display: flex;
          align-items: center;
          padding: 1em;
          box-shadow: $box-shadow-hight;
          transition: 0.25s ease all;
          justify-content: space-between;

          &:hover {
            background-color: $color-grey-light-2;
          }

          &.active {
            background-color: $primary-color-with-opacity;
          }

          p {
            margin: 0;
            text-transform: lowercase;
          }

          p:first-letter {
            text-transform: uppercase;
          }

          .card-title {
            height: 100%;
            font-size: 14px;
          }
          .card-value {
            color: #2055cd;
            height: 100%;
            font-size: 14px;
          }
          .card-description {
            font-size: 12px;
            color: $color-grey-dark;
          }

          .right {
            height: 100%;
            display: flex;
            align-items: center;
            width: 3.1em;
            text-align: right;
            justify-content: flex-end;
          }

          .weight {
            font-weight: bold;
            color: $primary-color;
            font-size: 0.8em;
          }
        }
      }
    }

    .actions {
      padding: 2rem;
      justify-content: end;
      display: flex;

      button:not(:last-child) {
        margin-right: 1em;
      }
    }
  }
</style>
