export default {
  activation_easiness_areas_1: '#15680f',
  opacity_activation_easiness_areas_1: 'rgba(21,104,105, 0.2)',
  activation_easiness_areas_2: '#59BC3E',
  opacity_activation_easiness_areas_2: 'rgba(89, 188, 62, 0.2)',
  activation_easiness_areas_3: '#EAE850',
  opacity_activation_easiness_areas_3: 'rgba(234, 232, 80, 0.2)',
  activation_easiness_areas_4: '#DB673E',
  opacity_activation_easiness_areas_4: 'rgba(219, 103, 62, 0.2)',
  activation_easiness_areas_5: '#92251C',
  opacity_activation_easiness_areas_5: 'rgba(146, 37, 28, 0.2)',
};

