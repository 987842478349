<template>
  <div id="app">
    <app-splashscreen-new-version v-if="showSplashcreen" :logo="logoForSlashscreen" />

    <div v-else class="app-content">
      <app-toolbar color-bg="#2055cd">
        <template slot="brand">
          <app-name :logo="logoForToolbar" :name="$t('APP_NAME.LABEL')" />
        </template>

        <template slot="navigation">
          <app-navigation :menus="menus" />
        </template>

        <template slot="more">
          <div class="header-more">
            <div class="settings" />
            <notification :user="$user" />
            <app-fullscreen-btn />
            <app-share-by-community-btn community-url="https://chat.google.com/room/AAAA5azng1A?cls=7" />
            <app-google-site-btn site-url="https://sites.google.com/auchan.fr/datastore-formation/accueil" />
            <app-site-selector />
            <app-logout-btn />
          </div>
        </template>
      </app-toolbar>
      <main>
        <router-view />
      </main>
    </div>
  </div>
</template>

<script>
  import logoForToolbar from '@/assets/logo-datastore-auchan.svg'
  import logoForSplashcreen from '@/assets/logo-datastore.svg'
  import AppFullscreenBtn from '@/components/app-fullscreen-btn/AppFullscreenBtn.vue'
  import AppLogoutBtn from '@/components/app-logout-btn/AppLogoutBtn.vue'
  import AppName from '@/components/app-name/AppName.vue'
  import AppNavigation from '@/components/app-navigation/AppNavigation.vue'
  import AppShareByCommunityBtn from '@/components/app-share-by-community-btn/AppShareByCommunityBtn.vue'
  import AppGoogleSiteBtn from '@/components/app-google-site-btn/AppGoogleSiteBtn.vue'
  import AppSplashscreenNewVersion from '@/components/app-splashscreen/AppSplashscreenNewVersion.vue'
  import AppToolbar from '@/components/app-toolbar/AppToolbar.vue'
  import AppSiteSelector from './components/app-site-selector/AppSiteSelector.vue'
  import Notification from './components/base/Notification.vue'

  export default {
    components: {
      AppSiteSelector,
      AppSplashscreenNewVersion,
      AppShareByCommunityBtn,
      AppGoogleSiteBtn,
      AppFullscreenBtn,
      AppNavigation,
      AppLogoutBtn,
      AppName,
      AppToolbar,
      Notification
    },
    data() {
      return {
        route: this.$route,
        showSplashcreen: true,
        logoForToolbar,
        logoForSlashscreen: logoForSplashcreen
      }
    },
    computed: {
      menus() {
        const menus = []

        menus.push({
          id: 'analyze',
          label: this.$t('APP.MENUS.ANALYZE.LABEL'),
          children: [
            {
              id: 'analyze-sales-formula',
              icon: 'store',
              label: this.$t('APP.MENUS.ANALYZE.SALES_FORMULA'),
              to: { name: 'analyze-sales-formula-pyramid' },
              withCurrentQuery: true
            },
            {
              id: 'analyze-geo-marketing',
              icon: 'my_location',
              label: this.$t('APP.MENUS.ANALYZE.GEO_MARKETING'),
              to: { name: 'analyze-geo-marketing' },
              withCurrentQuery: true
            },
            {
              id: 'analyze-customer-profile',
              icon: 'account_circle',
              label: this.$t('APP.MENUS.ANALYZE.CUSTOMER_PROFILE'),
              to: { name: 'analyze-customer-profile' },
              withCurrentQuery: true
            },
            {
              id: 'analyze-matrix',
              icon: 'assignment',
              label: this.$t('APP.MENUS.ANALYZE.MATRIX'),
              to: { name: 'analyze-matrix' },
              withCurrentQuery: true
            },
            {
              id: 'analyze-map',
              icon: 'map',
              label: this.$t('APP.MENUS.ANALYZE.MAP'),
              to: { name: 'analyze-map' },
              withCurrentQuery: true
            }
          ]
        })

        menus.push({
          id: 'animate',
          label: this.$t('APP.MENUS.ANIMATE.LABEL'),
          to: { name: 'animate-home' },
          withCurrentQuery: true
        })

        menus.push({
          id: 'tract-zones',
          label: this.$t('APP.MENUS.TRACT_ZONES.LABEL'),
          to: { name: 'tract-zones' },
          withCurrentQuery: true
        })

        return menus
      }
    },
    mounted() {
      setTimeout(() => (this.showSplashcreen = false), 4000)
    }
  }
</script>

<style scoped lang="scss">
  #app {
    height: 100%;
    .app-content {
      display: flex;
      flex-direction: column;
      height: 100%;
      main {
        flex: 1;
        display: flex;
        flex-direction: column;
      }
    }
    .header-more {
      display: flex;
    }
  }
  .header-more {
    display: flex;
  }
</style>
