<template>
  <div class="animate-geographic">
    <div class="map">
      <google-maps :center="center" :markers="marker" @load="setMap">
        <transition name="bounce">
          <google-maps-drawing-manager-control v-show="selectedMap.id === 'free'" :map="map" @update="getPolygonCoordinates" />
        </transition>

        <google-maps-zoom-control :map="map" />
        <google-maps-center-me-control :center="center" :map="map" />
      </google-maps>
    </div>
    <div class="criterions">
      <app-loading v-if="isLoading || isSaving" />
      <div class="choice">
        <h4>{{ $t('ANIMATE_GEOGRAPHIC.CHOOSE_METHOD') }}</h4>
        <app-button-toggle :small="true" :value="selectedMap" :values="selectionsMap" @input="setMethodSelectionMap" />
      </div>

      <md-list v-if="!isLoading && selectedMap.id === 'segmentations'" class="list">
        <md-list-item md-expand>
          <span class="md-list-item-text title">
            {{ $t('ANIMATE_GEOGRAPHIC.ACTIVATION_EASINESS_AREAS') }}
          </span>
          <md-list slot="md-expand">
            <md-list-item v-for="activationEasinessArea in activationEasinessAreas" :key="activationEasinessArea.id" class="child-item">
              <span class="md-list-item-text">{{ activationEasinessArea.properties.label }}</span>
              <md-checkbox
                v-model="selectedActivationEasinessAreas"
                :value="activationEasinessArea.id"
                @change="toggleAreaOnMap(activationEasinessAreas, selectedActivationEasinessAreas)" />
            </md-list-item>
          </md-list>
        </md-list-item>

        <md-list-item md-expand>
          <span class="md-list-item-text title">
            {{ $t('ANIMATE_GEOGRAPHIC.NEIGHBORHOOD_TYPES') }}
          </span>

          <md-list slot="md-expand">
            <md-list-item v-for="neighborhoodType in neighborhoodTypes" :key="neighborhoodType.id" class="child-item">
              <span class="md-list-item-text">{{ neighborhoodType.properties.label }}</span>
              <md-checkbox
                v-model="selectedNeighborhoodTypes"
                :value="neighborhoodType.id"
                @change="toggleAreaOnMap(neighborhoodTypes, selectedNeighborhoodTypes)" />
            </md-list-item>
          </md-list>
        </md-list-item>

        <md-list-item md-expand>
          <span class="md-list-item-text title">
            {{ $t('ANIMATE_GEOGRAPHIC.OUI_PUB_ZONES') }}
          </span>
          <md-list slot="md-expand">
            <md-list-item v-for="item in ouiPub" :key="item.id" class="child-item">
              <span class="md-list-item-text">{{ item.properties.label }}</span>
              <md-checkbox v-model="selectedOuiPub" :value="item.id" @change="toggleAreaOnMap(ouiPub, selectedOuiPub)" />
            </md-list-item>
          </md-list>
        </md-list-item>
      </md-list>

      <p v-if="selectedMap.id === 'free'" class="legend">{{ $t('ANIMATE_GEOGRAPHIC.LEGEND_FREE') }}</p>
    </div>

    <div class="actions">
      <div class="left">
        <app-button class="link" :disabled="isSaving" @click.native="ignore">
          {{ $t('ACTIONS.IGNORE') }}
        </app-button>
      </div>
      <div class="right">
        <app-button :disabled="isSaving" @click.native="back">
          {{ $t('ACTIONS.BACK') }}
        </app-button>
        <app-button
          :disabled="((!selectedActivationEasinessAreas || !selectedNeighborhoodTypes) && selectedSquares.length === 0) || isSaving"
          class="primary"
          @click.native="next">
          {{ $t('ACTIONS.NEXT') }}
        </app-button>
      </div>
    </div>

    <md-snackbar :md-active.sync="message.active" :md-duration="Infinity" md-persistent md-position="left">
      {{ message.value }}
    </md-snackbar>
  </div>
</template>

<script>
  /* global google */
  import AppButton from '@/components/app-button/AppButton.vue'
  import AppButtonToggle from '@/components/app-button-toggle/AppButtonToggle.vue'
  import AppLoading from '@/components/app-loading/AppLoading.vue'
  import GoogleMaps from '@/components/google-maps/GoogleMaps.vue'
  import GoogleMapsCenterMeControl from '@/components/google-maps/GoogleMapsCenterMeControl.vue'
  import GoogleMapsZoomControl from '@/components/google-maps/GoogleMapsZoomControl.vue'
  import animationService from '@/services/animationService'
  import nomenclatureGeographicService from '@/services/nomenclatureGeographicService'
  import MarketCluster from '@google/markerclusterer'
  import GoogleMapsDrawingManagerControl from '@/components/google-maps/GoogleMapsDrawingManagerControl.vue'
  import { debounce } from 'lodash'

  export default {
    name: 'DsAnimateGeographic',
    components: {
      GoogleMapsDrawingManagerControl,
      AppButton,
      GoogleMapsCenterMeControl,
      GoogleMapsZoomControl,
      GoogleMaps,
      AppLoading,
      AppButtonToggle
    },
    data() {
      return {
        isLoading: false,
        isInError: false,
        isSaving: false,
        animation: null,
        map: null,
        center: null,
        marker: null,
        activationEasinessAreas: [],
        selectedActivationEasinessAreas: null,
        neighborhoodTypes: [],
        selectedNeighborhoodTypes: null,
        polygonCoordinates: null,
        draw: false,
        selectionsMap: [
          {
            id: 'segmentations',
            label: this.$t('ANIMATE_GEOGRAPHIC.SEGMENTATIONS'),
            icon: 'book'
          },
          {
            id: 'free',
            label: this.$t('ANIMATE_GEOGRAPHIC.FREE'),
            icon: 'edit'
          }
        ],
        selectedMap: {},
        selectedSquares: [],
        overlay: null,
        message: {
          active: false,
          value: null
        },
        ouiPub: [],
        selectedOuiPub: null
      }
    },
    watch: {
      selectedSquares() {
        this.updateTarget()
      },
      selectedActivationEasinessAreas() {
        this.updateTarget()
      },
      selectedNeighborhoodTypes() {
        this.updateTarget()
      },
      selectedOuiPub() {
        this.updateTarget()
      }
    },
    async created() {
      animationService.findById(this.$route.params.sid).then(animation => {
        this.animation = animation
        this.$emit('init', this.animation)

        if (animation.activationEasinessAreas) {
          this.selectedActivationEasinessAreas = animation.activationEasinessAreas.split(',').map(id => `activation_easiness_areas_${id}`)
        }

        if (animation.neighborhoodTypes) {
          this.selectedNeighborhoodTypes = animation.neighborhoodTypes.split(',').map(id => `neighborhood_types_${id}`)
        }

        if (animation.ouiPub) {
          this.selectedOuiPub = animation.ouiPub.split(',').map(id => `oui_pub_types_${id}`)
        }

        if (animation.shape) {
          let shape = animation.shape.replace('POLYGON ((', '')
          shape = shape.replace('))', '')
          shape = shape.split(',')

          const promises = []
          shape.forEach(coordinate => {
            promises.push(coordinate.split(' ').map(Number))
            return coordinate.split(' ').map(Number)
          })

          Promise.all(promises).then(results => {
            this.getPolygonCoordinates([results[0]])
          })
        }

        this.getDatas()
      })
    },
    methods: {
      setMap(map) {
        this.map = map
      },
      formatShapeToStringPolygon(shape) {
        let formatShape = 'POLYGON (('
        shape.forEach((polygon, idx) => {
          formatShape += polygon.join(' ')
          if (idx !== shape.length) {
            formatShape += ','
          }
          if (idx === shape.length - 1) {
            formatShape += shape[0].join(' ')
          }
        })
        formatShape += '))'

        return formatShape
      },
      transformAnimation() {
        if (
          this.activationEasinessAreas &&
          this.activationEasinessAreas.length > 0 &&
          this.selectedActivationEasinessAreas &&
          this.selectedActivationEasinessAreas.length > 0
        ) {
          this.animation.activationEasinessAreas = this.activationEasinessAreas
            .filter(activationEasinessArea => this.selectedActivationEasinessAreas.indexOf(activationEasinessArea.id) !== -1)
            .map(activationEasinessArea => activationEasinessArea.properties.id)
            .join(',')
        } else {
          this.animation.activationEasinessAreas = null
        }

        if (
          this.neighborhoodTypes &&
          this.neighborhoodTypes.length > 0 &&
          this.selectedNeighborhoodTypes &&
          this.selectedNeighborhoodTypes.length > 0
        ) {
          this.animation.neighborhoodTypes = this.neighborhoodTypes
            .filter(neighborhoodType => this.selectedNeighborhoodTypes.indexOf(neighborhoodType.id) !== -1)
            .map(neighborhoodType => neighborhoodType.properties.id)
            .join(',')
        } else {
          this.animation.neighborhoodTypes = null
        }

        this.animation.ouiPubZone = this.selectedOuiPub !== null

        if (this.polygonCoordinates && this.polygonCoordinates.length > 0) {
          this.animation.shape = this.formatShapeToStringPolygon(this.polygonCoordinates)
        }
      },
      updateTarget: debounce(function() {
        this.transformAnimation()
        this.$emit('update', this.animation)
      }, 1000),
      getDatas() {
        this.message.active = false
        this.isLoading = true

        nomenclatureGeographicService
          .getSiteBySid(this.animation.sidSite)
          .then(site => {
            site.id = site.id.toString()
            if (site.id.length === 1) site.id = `00${site.id}`
            if (site.id.length === 2) site.id = `0${site.id}`
            Promise.all([
              nomenclatureGeographicService.getCompetitorsById(site.id, site.concept.id),
              nomenclatureGeographicService.getActivationEasinessAreasById(site.id),
              nomenclatureGeographicService.getNeighborhoodAreasBySid(this.animation.sidSite),
              nomenclatureGeographicService.getSquaresOuiPub(this.animation.sidSite)
            ])
              .then(datas => {
                const coordinates = {
                  lng: site.shape.coordinates[0],
                  lat: site.shape.coordinates[1]
                }

                this.center = coordinates
                this.marker = [{ position: coordinates }]
                this.activationEasinessAreas = datas[1].features.map(feature => {
                  feature.id = `activation_easiness_areas_${feature.properties.id}`
                  return feature
                })

                this.neighborhoodTypes = datas[2].features.map(feature => {
                  feature.id = `neighborhood_types_${feature.properties.id}`
                  return feature
                })

                this.ouiPub = datas[3].features.map(feature => {
                  feature.id = `oui_pub_types_${feature.properties.id}`
                  return feature
                })

                this.map.data.setStyle({
                  fillColor: '#1E8BC3',
                  fillOpacity: 0.5,
                  strokeColor: '#1458ae',
                  strokeWeight: 1
                })

                this.drawCompetitorsOnMap(datas[0])
                this.toggleAreaOnMap(this.activationEasinessAreas, this.selectedActivationEasinessAreas)
                this.toggleAreaOnMap(this.neighborhoodTypes, this.selectedNeighborhoodTypes)
                this.toggleAreaOnMap(this.ouiPub, this.selectedOuiPub)
              })
              .catch(() => {
                this.message.active = true
                this.message.value = this.$t('ANIMATE_GEOGRAPHIC.ERROR.GET_DATAS')
              })
              .finally(() => (this.isLoading = false))
          })
          .catch(() => {
            this.message.active = true
            this.message.value = this.$t('ANALYZE_MAP.ERROR_GET_DATAS')
          })
      },
      drawCompetitorsOnMap(competitors) {
        let format

        if (this.$userInfos.format === 1) {
          format = 'HYP'
        } else if (this.$userInfos.format === 2) {
          format = 'PRO'
        }

        const indirectCompetitorMarkers = []

        competitors.features.forEach(feature => {
          let icon = require('../../../assets/competitors/DEFAULT.svg')

          try {
            icon = require(`../../../assets/competitors/${feature.properties.signCode}.svg`)
          } catch (e) {
            console.warn(`Marker's icon not found for ${feature.properties.signCode}, use default.`)
          }

          const marker = new google.maps.Marker({
            position: {
              lat: feature.geometry.coordinates[1],
              lng: feature.geometry.coordinates[0]
            },
            icon: {
              url: icon,
              scaledSize: new google.maps.Size(41, 41)
            },
            animation: google.maps.Animation.DROP
          })

          if (feature.properties.signType === format) {
            marker.setZIndex(2)
            marker.setMap(this.map)
          } else {
            indirectCompetitorMarkers.push(marker)
          }
        })

        // Clustering indirect competitors
        new MarketCluster(this.map, indirectCompetitorMarkers, {
          imagePath: '../../../assets/clusters/m'
        })
      },
      toggleAreaOnMap(areas, selectedAreas) {
        if (selectedAreas) {
          areas.forEach(area => {
            if (selectedAreas.indexOf(area.id) !== -1) {
              this.map.data.addGeoJson(area)
            } else if (this.map.data.getFeatureById(area.id)) {
              this.map.data.remove(this.map.data.getFeatureById(area.id))
            }
          })
        }
      },
      getPolygonCoordinates(polygonCoordinates, overlay) {
        this.isLoading = true
        this.resetMap()
        this.overlay = overlay
        this.polygonCoordinates = polygonCoordinates
        this.selectedSquares = []

        nomenclatureGeographicService
          .getSquaresInAreaBySid(this.$route.query.sidSite, { type: 'Polygon', coordinates: [polygonCoordinates] })
          .then(res => {
            this.isLoading = false

            this.selectedSquares.push(
              ...res.features.map(feature => {
                feature.id = feature.properties.idInspire
                return feature
              })
            )

            this.selectedSquares.forEach(square => this.map.data.addGeoJson(square))
          })
      },
      next() {
        this.transformAnimation()
        this.isSaving = true
        this.message.active = false
        this.animation.ouiPubZone = this.selectedOuiPub !== null

        if (this.polygonCoordinates && this.polygonCoordinates.length > 0) {
          this.animation.shape = this.formatShapeToStringPolygon(this.polygonCoordinates)
        }

        animationService
          .save(this.animation.sid, this.animation)
          .then(() =>
            this.$router.push({
              name: 'animate-top-client',
              params: this.$route.params,
              query: this.$route.query
            })
          )
          .catch(() => {
            this.message.active = true
            this.message.value = this.$t('ANIMATE_MARKET.ERROR.SAVING')
          })
          .finally(() => (this.isSaving = false))
      },
      back() {
        this.$router.push({ name: 'animate-segmentation', params: this.$route.params, query: this.$route.query })
      },
      ignore() {
        this.$router.push({ name: 'animate-top-client', params: this.$route.params, query: this.$route.query })
      },
      resetMap() {
        if (this.activationEasinessAreas && this.activationEasinessAreas.length > 0) {
          this.activationEasinessAreas.forEach(feature => {
            if (this.map.data.getFeatureById(feature.id)) {
              this.map.data.remove(this.map.data.getFeatureById(feature.id))
            }
          })
        }

        if (this.neighborhoodTypes && this.neighborhoodTypes.length > 0) {
          this.neighborhoodTypes.forEach(feature => {
            if (this.map.data.getFeatureById(feature.id)) {
              this.map.data.remove(this.map.data.getFeatureById(feature.id))
            }
          })
        }

        if (this.selectedSquares && this.selectedSquares.length > 0) {
          this.selectedSquares.forEach(feature => {
            if (this.map.data.getFeatureById(feature.id)) {
              this.map.data.remove(this.map.data.getFeatureById(feature.id))
            }
          })
        }
      },
      setMethodSelectionMap(type) {
        this.selectedMap = type
        if (this.overlay) this.overlay.setMap(null)

        this.selectedActivationEasinessAreas = []
        this.selectedNeighborhoodTypes = []

        this.resetMap()
        this.selectedSquares = []
      }
    }
  }
</script>

<style scoped lang="scss">
  @import '~@/styles/_colors';

  .bounce-enter-active {
    animation: bounce-in 0.5s;
  }

  .bounce-leave-active {
    animation: bounce-in 0.5s reverse;
  }

  @keyframes bounce-in {
    0% {
      transform: scale(0);
    }
    50% {
      transform: scale(1.5);
    }
    100% {
      transform: scale(1);
    }
  }

  .animate-geographic {
    display: grid;
    grid-template-columns: 70% 30%;
    background-color: white;

    .md-list {
      overflow: auto;
      padding: 0;
    }

    :global(.md-list-item-text.title) {
      font-weight: 500;
    }

    .child-item {
      :global(.md-list-item-container:not(last-child)) {
        padding-left: 1em;
        :global(.md-list-item-content) {
          border-top: 1px solid $color-grey-light;
        }
      }
    }

    .map,
    .criterions {
      height: calc(100vh - 208px);
    }

    .map {
      grid-row: 1;
      grid-column: 1;
      border-top: 1px solid $color-grey-with-opacity;
    }

    .criterions {
      grid-row: 1;
      grid-column: 2;
      overflow: auto;
      border-left: 1px solid $color-grey-with-opacity;
      border-top: 1px solid $color-grey-with-opacity;
      .choice {
        padding: 0 1rem 2rem 1rem;
        .app-button-toggle {
          justify-content: center;
        }
      }

      .app-button-toggle {
        justify-content: center;
      }
      .ui-pub {
        width: 100%;
        text-align: center;
        margin-top: 2rem;
        .ui-pub-btn {
          display: flex;
          margin-left: 10%;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          color: #6b6b6b;
          height: 80px;
          min-width: 150px;
          width: 80%;
          text-transform: uppercase;
          font-weight: 500;
          font-size: 0.9em;
          text-overflow: ellipsis;
          overflow: hidden;
          border-radius: 4px;
          border: 1px solid rgba(0, 0, 0, 0.12);
          padding: 0 2rem;
          background: white;
          cursor: pointer;

          &.disabled {
            background-color: #efefef;
          }

          &.active {
            color: $primary-color;
            background-color: $primary-color-with-opacity;
            background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path fill="%231e8bc3" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"/></svg>');
            background-position: 95% 5%;
            background-repeat: no-repeat;
            box-shadow: $box-shadow;
            &.inline {
              background-image: none !important;
            }
            .md-icon {
              color: $primary-color;
            }
          }
        }
      }
      .legend {
        font-size: 12px;
        color: $color-grey;
        padding: 0 1rem 1rem 1rem;
      }
    }

    .actions {
      grid-row: 2;
      grid-column: 1 / 3;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top: 1px solid $color-grey-with-opacity;
      padding: 1rem;

      .right {
        display: flex;
      }
      button + button {
        margin-left: 1rem;
      }
    }
  }
</style>
