<template>
  <div class="tract-zones-layout">
    <h2>{{ $t('TRACT_ZONES.TITLE') }}</h2>

    <div class="main-content">
      <div v-if="loading" class="loader-ctnr">
        <div class="lds-ring">
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>

      <div class="tracts-actions">
        <div>
          <span>{{ $t('TRACT_ZONES.DISTRIBUTOR_SELECTION') }}</span>
          <div ref="distributorSelector" class="selector" @click="showDistributors = !showDistributors">
            <span>{{ selectedDistributor.distributorLabel }}{{ selectedDistributor.isActif ? ` (${$t('TRACT_ZONES.BY_DEFAULT')})` : '' }}</span>
            <i class="material-icons">{{ showDistributors ? 'expand_more' : 'chevron_right' }}</i>
            <ul v-if="showDistributors" class="suggestions">
              <li v-for="distributor in distributors" :key="`site-operation-${distributor.distributorCode}`" @click="changeDistributor(distributor)">
                {{ distributor.distributorLabel }}{{ distributor.isActif ? ` (${$t('TRACT_ZONES.BY_DEFAULT')})` : '' }}
              </li>
            </ul>
          </div>
          <span v-if="!selectedDistributor.isActif" class="distributor-not-default">{{ $t('TRACT_ZONES.NOT_DEFAULT_DISTRIBUTOR') }}</span>
        </div>
        <md-button class="md-raised md-primary" @click="exportSelectedZones">
          {{ $t('TRACT_ZONES.EXPORT_SELECTION') }}
        </md-button>
      </div>

      <div class="map-ctnr">
        <google-maps :map-style="mapStyle" :class="[{ fullscreen: isFullScreen }]" @load="setMap">
          <google-maps-zoom-control :map="map" />
          <google-maps-free-drawing-control v-model="drawPolygonMode" :map="map" :zones="zones" @drawPolygon="drawPolygon" />
          <google-maps-manual-add-control v-model="unitAddingZoneMode" :map="map" />
        </google-maps>
        <div v-if="!isEditingHypothesis" class="map-overlay">
          <google-maps-full-screen-control :map="map" @updateFullScreen="updateFullScreen" />
          <h3>{{ $t('TRACT_ZONES.HYPOTHESIS.TITLE') }}</h3>
          <div class="search-filter">
            <div class="search">
              <i class="material-icons">search</i>
              <input v-model.trim="searchTerm" :placeholder="$t('TRACT_ZONES.SEARCH')" type="text" class="search-input">
            </div>
            <button ref="filterHypothesisSelector" class="filter" @click="showFilterHypothesis = !showFilterHypothesis">
              <i class="material-icons">filter_alt</i>
            </button>
            <div v-if="showFilterHypothesis" ref="filterHypothesisList" class="filter-overlay">
              <div class="filter-overlay-group">
                <h3>{{ $t('TRACT_ZONES.HYPOTHESIS_TYPE') }}</h3>
                <div class="checkbox-group">
                  <input id="preconizedHypothesis" v-model="preconizedSelected" type="checkbox">
                  <label for="preconizedHypothesis">{{ $t('TRACT_ZONES.PRECONIZED') }}</label>
                </div>
                <div class="checkbox-group">
                  <input id="personalizedHypothesis" v-model="personnalizedSelected" type="checkbox">
                  <label for="personalizedHypothesis">{{ $t('TRACT_ZONES.PERSONALIZED') }}</label>
                </div>
              </div>
            </div>
          </div>
          <div class="hypothesis">
            <hypothesis-card
              v-for="scenario in filteredScenarios"
              :key="`scenario-${scenario.month}-${scenario.scenarioLabel}-${scenario.scenarioSize}`"
              :scenario="scenario"
              :selected="scenario == selectedScenario"
              @selectHypothesis="selectHypothesis" />
          </div>
          <div class="hypothesis-size-ctnr">
            <h3>{{ $t('TRACT_ZONES.HYPOTHESIS.SIZE') }}</h3>
            <hypothesis-size :selected-size="selectedSize" />
          </div>
        </div>
        <div v-else class="map-overlay">
          <google-maps-full-screen-control :map="map" @updateFullScreen="updateFullScreen" />
          <h3 class="map-overlay-title">
            <i class="material-icons hypothesis-back" @click="cancelEditHypothesis">arrow_back</i>
            <span>Créer une hypothèse</span>
          </h3>
          <div class="map-overlay-input-group">
            <label for="hypothesisName">Nom de l'hypothèse</label>
            <input id="hypothesisName" type="text" disabled :value="hypothesisName">
          </div>
          <div class="map-overlay-input-group">
            <label>Thème concerné</label>
            <div class="selector" @click="showSiteOperations = !showSiteOperations">
              <span>{{ selectedSiteOperation.commercialOperationLabel }}</span>
              <i class="material-icons">{{ showSiteOperations ? 'expand_more' : 'chevron_right' }}</i>
              <ul v-if="showSiteOperations" class="suggestions">
                <li v-for="op in siteOperations" :key="`site-operation-${op.commercialOperationSid}`" @click="selectedSiteOperation = op">
                  {{ op.commercialOperationLabel }}
                </li>
              </ul>
            </div>
          </div>
          <div class="hypothesis-size-ctnr">
            <h3>{{ $t('TRACT_ZONES.HYPOTHESIS.SIZE') }}</h3>
            <hypothesis-size :selected-size="selectedSize" />
          </div>
          <div class="map-overlay-buttons">
            <md-button class="md-raised" @click="cancelEditHypothesis">
              Annuler
            </md-button>
            <md-button class="md-raised md-primary" @click="createHypothesis">
              Valider
            </md-button>
          </div>
        </div>
        <div v-if="!isHistoricalSufficient" class="map-hover-historical">
          {{ $t('TRACT_ZONES.UNSUFFICIENT_HISTORICAL') }}
        </div>
        <div v-show="hoverZoneData" class="map-hover-kpis">
          <h4>{{ $t('TRACT_ZONES.KPIS.HOVER_ZONE') }}</h4>
          <p class="kpi-line">
            <span class="text" style="font-weight: bold;">{{ $t('TRACT_ZONES.KPIS.CARRIED_TURNOVER.TITLE') }} :</span>
            <span class="figure">{{ ((hoverZoneData ? hoverZoneData.salesAmount : 0) / 100) | number(0, 'currency') }}</span>
          </p>
          <p class="kpi-line">
            <span class="text" span style="font-weight: bold;">{{ $t('TRACT_ZONES.KPIS.INCREMENTAL_TURNOVER.TITLE') }} :</span>
            <span class="figure">{{ ((hoverZoneData ? hoverZoneData.salesAmountEnrolment : 0) / 100) | number(0, 'currency') }}</span>
          </p>
          <p class="kpi-line">
            <span class="text" style="font-weight: bold;">{{ $t('TRACT_ZONES.KPIS.MAIL_BOXES.TITLE') }} :</span>
            <span class="figure">{{ hoverZoneData ? hoverZoneData.distributableLetterBoxNb : 0 }}</span>
          </p>
          <p class="kpi-line">
            <span class="text" style="font-weight: bold;">{{ $t('TRACT_ZONES.KPIS.MAIL_BOXES.SUBTEXT') }} :</span>
            <span class="figure">
              {{ ((hoverZoneData ? hoverZoneData.salesAmountEnrolment : 0) / 100 / (hoverZoneData && hoverZoneData.distributableLetterBoxNb ? hoverZoneData.distributableLetterBoxNb : 1)) | number(2, 'currency') }}
            </span>
          </p>
        </div>
        <div class="map-kpis">
          <div class="kpi" :title="$t('TRACT_ZONES.KPIS.CARRIED_TURNOVER.HELP')">
            <span class="material-icons kpi-info">info</span>
            <h3>{{ $t('TRACT_ZONES.KPIS.CARRIED_TURNOVER.TITLE') }}</h3>
            <div class="kpi-content">
              <div class="line">{{ kpis.carriedTurnover | number(2, 'currency') }}</div>
              <div class="line">
                {{ kpis.carriedTurnoverRate | number(2, 'percent') }}
                <span class="subtext">{{ $t('TRACT_ZONES.KPIS.CARRIED_TURNOVER.SUBTEXT') }}</span>
              </div>
            </div>
          </div>
          <div class="kpi" :title="$t('TRACT_ZONES.KPIS.INCREMENTAL_TURNOVER.HELP')">
            <span class="material-icons kpi-info">info</span>
            <h3>{{ $t('TRACT_ZONES.KPIS.INCREMENTAL_TURNOVER.TITLE') }}</h3>
            <div class="kpi-content">
              <div class="line">{{ kpis.enrolmentTurnover | number(2, 'currency') }}</div>
              <div class="line">
                {{ kpis.enrolmentTurnoverRate | number(2, 'percent') }}
                <span class="subtext">{{ $t('TRACT_ZONES.KPIS.INCREMENTAL_TURNOVER.SUBTEXT') }}</span>
              </div>
            </div>
          </div>
          <div class="kpi">
            <h3>{{ $t('TRACT_ZONES.KPIS.MAIL_BOXES.TITLE') }}</h3>
            <div class="kpi-content">
              <div class="line">{{ kpis.letterBoxes }}</div>
              <div class="line">
                {{ kpis.letterBoxesRate | number(2, 'currency') }}
                <span class="subtext">{{ $t('TRACT_ZONES.KPIS.MAIL_BOXES.SUBTEXT') }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  /* global google */
  import Vue from 'vue'
  import { DateTime } from 'luxon'

  import GoogleMaps from '@/components/google-maps/GoogleMaps.vue'
  import GoogleMapsZoomControl from '@/components/google-maps/GoogleMapsZoomControl.vue'
  import GoogleMapsFullScreenControl from '@/components/google-maps/GoogleMapsFullScreenControl.vue'
  import HypothesisSize from '@/views/tract-zones/hypothesis-size/HypothesisSize.vue'
  import HypothesisCard from '@/views/tract-zones/hypothesis-card/HypothesisCard.vue'
  import GoogleMapsFreeDrawingControl from '@/components/google-maps/GoogleMapsFreeDrawingControl.vue'
  import GoogleMapsManualAddControl from '@/components/google-maps/GoogleMapsManualAddControl.vue'

  import tractsService from '@/services/tractsService'
  import nomenclatureGeographicService from '@/services/nomenclatureGeographicService'
  import { getPolygonsIntersection } from '@/helpers/mapUtils'

  export default {
    name: 'DsTractZonesLayout',
    components: {
      GoogleMaps,
      GoogleMapsZoomControl,
      GoogleMapsFullScreenControl,
      HypothesisSize,
      HypothesisCard,
      GoogleMapsFreeDrawingControl,
      GoogleMapsManualAddControl
    },
    data() {
      return {
        map: null,
        mapStyle: [
          {
            elementType: 'labels.icon',
            stylers: [{ visibility: 'off' }]
          }
        ],
        searchTerm: '',
        isFullScreen: false,
        selectedZoneUnits: [],
        scenarios: [],
        filteredScenarios: [],
        selectedScenario: null,
        zoneUnits: [],
        zones: [],
        drawPolygonMode: false,
        unitAddingZoneMode: false,
        unitRemovingZoneMode: false,
        isEditingHypothesis: false,
        lowLetterBoxesThreshold: 0,
        highLetterBoxesThreshold: 0,
        distributors: [],
        siteOperations: [],
        selectedSiteOperation: {},
        selectedDistributor: {},
        showSiteOperations: false,
        showDistributors: false,
        userSiteRdo: null,
        preconizedSelected: true,
        personnalizedSelected: true,
        showFilterHypothesis: false,
        infowindow: null,
        loading: false,
        hoverZoneData: null,
        isHistoricalSufficient: true
      }
    },
    computed: {
      kpis() {
        const { zones: selectedZones, total } = this.zones.reduce(
          (acc, cur) => {
            if (cur.metadata.selected && !acc.ids.includes(cur.metadata.leafletZoneUnit)) {
              acc.zones.push(cur)
              acc.ids.push(cur.metadata.leafletZoneUnit)
            }
            if (!acc.globalIds.includes(cur.metadata.leafletZoneUnit)) {
              acc.total += cur.metadata.salesAmount
              acc.globalIds.push(cur.metadata.leafletZoneUnit)
            }
            return acc
          },
          { zones: [], ids: [], globalIds: [], total: 0 }
        )
        const reducedVal = selectedZones.reduce(
          (acc, cur) => {
            acc.carriedTurnover += cur.metadata.salesAmount
            acc.enrolmentTurnover += cur.metadata.salesAmountEnrolment
            acc.letterBoxes += cur.metadata.distributableLetterBoxNb
            return acc
          },
          { carriedTurnover: 0, total: 0, enrolmentTurnover: 0, letterBoxes: 0 }
        )
        return {
          carriedTurnover: reducedVal.carriedTurnover / 100,
          carriedTurnoverRate: (reducedVal.carriedTurnover * 100) / total,
          enrolmentTurnover: reducedVal.enrolmentTurnover / 100,
          enrolmentTurnoverRate: (reducedVal.enrolmentTurnover * 100) / reducedVal.carriedTurnover,
          letterBoxes: reducedVal.letterBoxes,
          letterBoxesRate: reducedVal.enrolmentTurnover / 100 / reducedVal.letterBoxes
        }
      },
      selectedSize() {
        return this.kpis.letterBoxes < this.lowLetterBoxesThreshold ? 's' : this.kpis.letterBoxes > this.highLetterBoxesThreshold ? 'l' : 'm'
      },
      hypothesisName() {
        const date = new Date()
        let month = date.getMonth() + 1
        month = month < 10 ? `0${month}` : month
        let name = `${date.getFullYear()}${month}-${this.selectedSiteOperation.commercialOperationLabel}-${this.selectedSize.toUpperCase()}-${
          this.selectedDistributor.distributorLabel
        }`
        while (this.scenarios.find(sc => sc.scenarioLabel === name)) {
          const endString = name.substr(name.lastIndexOf('-') + 1)
          const increment = Number(endString)
          if (!Number.isNaN(increment)) {
            name = name.substring(0, name.lastIndexOf('-') + 1) + (increment + 1)
          } else {
            name += '-1'
          }
        }
        return name
      }
    },
    watch: {
      drawPolygonMode(mode) {
        if (mode) {
          this.unitAddingZoneMode = false
          this.unitRemovingZoneMode = false
        }
      },
      unitAddingZoneMode(mode) {
        if (mode) {
          this.drawPolygonMode = false
          this.unitRemovingZoneMode = false
        }
      },
      unitRemovingZoneMode(mode) {
        if (mode) {
          this.drawPolygonMode = false
          this.unitAddingZoneMode = false
        }
      },
      searchTerm(term) {
        const filteredScenariosThemes = []
        if (this.personnalizedSelected) filteredScenariosThemes.push('Personnalisé')
        if (this.preconizedSelected) filteredScenariosThemes.push('Préconisé')
        this.filteredScenarios = this.scenarios.filter(sc => {
          return (
            filteredScenariosThemes.includes(sc.sourceTheme) &&
            (sc.scenarioLabel.toLowerCase().includes(term.toLowerCase()) ||
              sc.month.toString().includes(term) ||
              sc.scenarioSize.toLowerCase().includes(term.toLowerCase()))
          )
        })
      },
      preconizedSelected(newVal) {
        const filteredScenariosThemes = []
        if (newVal) filteredScenariosThemes.push('Préconisé')
        if (this.personnalizedSelected) filteredScenariosThemes.push('Personnalisé')
        this.filteredScenarios = this.scenarios.filter(sc => {
          return (
            filteredScenariosThemes.includes(sc.sourceTheme) &&
            (sc.scenarioLabel.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
              sc.month.toString().includes(this.searchTerm) ||
              sc.scenarioSize.toLowerCase().includes(this.searchTerm.toLowerCase()))
          )
        })
      },
      personnalizedSelected(newVal) {
        const filteredScenariosThemes = []
        if (newVal) filteredScenariosThemes.push('Personnalisé')
        if (this.preconizedSelected) filteredScenariosThemes.push('Préconisé')
        this.filteredScenarios = this.scenarios.filter(sc => {
          return (
            filteredScenariosThemes.includes(sc.sourceTheme) &&
            (sc.scenarioLabel.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
              sc.month.toString().includes(this.searchTerm) ||
              sc.scenarioSize.toLowerCase().includes(this.searchTerm.toLowerCase()))
          )
        })
      }
    },
    async created() {
      this.loading = true
      // Get user site and add marker at its position
      const sites = await nomenclatureGeographicService.getAllSites()
      this.userSiteRdo = Vue.prototype.$userInfos.site.siteRdo
      const userSite = sites.find(s => s.idRdo === this.userSiteRdo)
      let userSiteLoc
      if (userSite) {
        userSiteLoc = { lat: userSite.shape.coordinates[1], lng: userSite.shape.coordinates[0] }
      }

      const urlSearchParams = new URLSearchParams(window.location.search)
      this.userSiteRdo = urlSearchParams.get('siteRdo')
      const paramsSite = sites.find(s => s.idRdo === this.userSiteRdo)
      if (paramsSite) {
        userSiteLoc = { lat: paramsSite.shape.coordinates[1], lng: paramsSite.shape.coordinates[0] }
      }

      userSiteLoc = !userSiteLoc ? { lat: 50.6, lng: 3.1 } : userSiteLoc
      const icon = require(`../../assets/competitors/AUCHAN.svg`)
      new google.maps.Marker({
        position: userSiteLoc,
        map: this.map,
        icon: {
          url: icon,
          scaledSize: new google.maps.Size(50, 50)
        }
      })
      this.map.setCenter(userSiteLoc)

      // Get site operations and distributors
      tractsService.getUserSiteHistorical(this.userSiteRdo).then(siteHistorical => {
        this.isHistoricalSufficient = siteHistorical.find(historical => 
          historical.siteStatusCode === 'OPE' 
            && DateTime.fromISO(historical.startDate.value) < DateTime.now().minus({ month: 11 })
        )
      })
      const { siteOperations, distributors } = await tractsService.getSiteOperationsAndDistributors(this.userSiteRdo)
      this.siteOperations = siteOperations
      this.distributors = distributors
      this.selectedSiteOperation = siteOperations?.[0] ?? {}
      this.selectedDistributor = distributors.find(distributor => distributor.isActif) ?? distributors?.[0] ?? {}

      // Get site zone units and add to map
      this.zoneUnits = (await tractsService.getZoneUnits(this.userSiteRdo, this.selectedDistributor)) ?? {}
      this.addPolygonsToMap(this.formatZoneUnits(this.zoneUnits))

      // Get scenarios
      this.scenarios = (await tractsService.getScenarios(this.userSiteRdo, this.selectedDistributor)) ?? []
      this.filteredScenarios = [...this.scenarios]
      this.filteredScenarios.sort((a, b) => {
        let sortValue
        if (a.sourceTheme === b.sourceTheme) {
          if (a.sourceTheme === 'Préconisé') {
            sortValue = a.scenarioLabel < b.scenarioLabel ? -1 : a.scenarioLabel > b.scenarioLabel ? 1 : 0
          } else {
            sortValue = a.month - b.month
          }
        } else {
          sortValue = a.sourceTheme === 'Préconisé' ? -1 : 1
        }
        return sortValue
      })
      this.initScenariosThresholds()
      if (this.scenarios.length) {
        this.selectHypothesis(this.scenarios.find(sc => sc.sourceTheme === 'Préconisé' && sc.scenarioSize === 'M') || this.scenarios[0])
      }

      this.loading = false

      window.addEventListener('click', e => {
        if (this.showDistributors && !this.$refs?.distributorSelector?.contains(e.target)) {
          this.showDistributors = false
        }
        if (
          this.showFilterHypothesis &&
          !this.$refs?.filterHypothesisSelector?.contains(e.target) &&
          !this.$refs?.filterHypothesisList?.contains(e.target)
        ) {
          this.showFilterHypothesis = false
        }
      })
    },
    methods: {
      initScenariosThresholds() {
        if (this.scenarios.length) {
          const sScenario = this.scenarios.find(sc => sc.sourceTheme === 'Préconisé' && sc.scenarioSize === 'S') ?? {}
          const midScenario = this.scenarios.find(sc => sc.sourceTheme === 'Préconisé' && sc.scenarioSize === 'M') ?? {}
          const lScenario = this.scenarios.find(sc => sc.sourceTheme === 'Préconisé' && sc.scenarioSize === 'L') ?? {}
          const sScenarioLetterBoxNb = this.getScenarioLetterBoxNb(sScenario)
          const mScenarioLetterBoxNb = this.getScenarioLetterBoxNb(midScenario)
          const lScenarioLetterBoxNb = this.getScenarioLetterBoxNb(lScenario)

          const minDiff = mScenarioLetterBoxNb - sScenarioLetterBoxNb
          const maxDiff = lScenarioLetterBoxNb - mScenarioLetterBoxNb
          this.lowLetterBoxesThreshold = mScenarioLetterBoxNb - minDiff * 0.4
          this.highLetterBoxesThreshold = mScenarioLetterBoxNb + maxDiff * 0.4
        }
      },
      formatZoneUnits(zoneUnits) {
        const bounds = new google.maps.LatLngBounds()
        let boundsExtended = false
        let zones = []
        zoneUnits.features?.forEach(el => {
          switch (el.geometry.type) {
            case 'Polygon':
              zones.push({
                path: el.geometry.coordinates[0].map(coord => {
                  const loc = { lat: coord[1], lng: coord[0] }
                  bounds.extend(loc)
                  boundsExtended = true
                  return loc
                }),
                metadata: { ...el.properties }
              })
              break
            case 'MultiPolygon':
              el.geometry.coordinates.forEach(coords => {
                zones.push({
                  path: coords[0].map(coord => {
                    const loc = { lat: coord[1], lng: coord[0] }
                    bounds.extend(loc)
                    boundsExtended = true
                    return loc
                  }),
                  metadata: { ...el.properties }
                })
              })
              break
            default:
              break
          }
        })
        if (boundsExtended) this.map.fitBounds(bounds)
        return zones
      },
      addPolygonsToMap(zones) {
        this.zones = zones.map(zone => {
          const polygon = new google.maps.Polygon({
            paths: zone.path,
            strokeColor: '#000',
            strokeOpacity: 0.8,
            strokeWeight: 1,
            fillColor: '#FFF',
            fillOpacity: 0.2,
            zoneUnit: zone.metadata.leafletZoneUnit
          })

          polygon.setMap(this.map)
          google.maps.event.addListener(polygon, 'click', () => this.polygonClick(polygon, zone.metadata))
          google.maps.event.addListener(polygon, 'mouseover', () => (this.hoverZoneData = zone.metadata))
          google.maps.event.addListener(polygon, 'mouseout', () => (this.hoverZoneData = null))
          zone.polygon = polygon
          return zone
        }) ?? []
      },
      polygonClick(polygon) {
        const zoneUnit = polygon.zoneUnit
        if (this.unitAddingZoneMode) {
          this.selectedScenario = null
          this.zones = this.zones.map(curZone => {
            if (curZone.metadata.leafletZoneUnit === zoneUnit) {
              if (curZone.metadata.selected) {
                curZone.metadata.selected = false
                curZone.polygon.setOptions({
                  fillColor: '#FFF',
                  fillOpacity: 0.2,
                  strokeColor: '#000'
                })
              } else {
                curZone.metadata.selected = true
                curZone.polygon.setOptions({
                  fillColor: '#508df7',
                  fillOpacity: 0.4,
                  strokeColor: '#2055cd'
                })
              }
              this.isEditingHypothesis = true
            }
            return curZone
          })
        }
      },
      cancelEditHypothesis() {
        this.zones = this.zones.map(zone => {
          zone.metadata.selected = false
          zone.polygon.setOptions({
            fillColor: '#FFF',
            fillOpacity: 0.2,
            strokeColor: '#000'
          })
          return zone
        })
        this.isEditingHypothesis = false
        this.drawPolygonMode = false
        this.unitAddingZoneMode = false
        this.selectedScenario = null
      },
      clearMap() {
        for (const zone of this.zones) {
          google.maps.event.clearInstanceListeners(zone.polygon)
          zone.polygon.setMap(null)
        }
      },
      async changeDistributor(distributor) {
        this.loading = true
        this.selectedDistributor = distributor
        this.selectedScenario = null

        this.clearMap()

        this.zoneUnits = (await tractsService.getZoneUnits(this.userSiteRdo, this.selectedDistributor)) ?? {}
        this.addPolygonsToMap(this.formatZoneUnits(this.zoneUnits))

        // Get scenarios
        this.scenarios = (await tractsService.getScenarios(this.userSiteRdo, this.selectedDistributor)) ?? []
        this.filteredScenarios = [...this.scenarios]
        this.filteredScenarios.sort((a, b) => {
          let sortValue
          if (a.sourceTheme === b.sourceTheme) {
            if (a.sourceTheme === 'Préconisé') {
              sortValue = a.scenarioLabel < b.scenarioLabel ? -1 : a.scenarioLabel > b.scenarioLabel ? 1 : 0
            } else {
              sortValue = a.month - b.month
            }
          } else {
            sortValue = a.sourceTheme === 'Préconisé' ? -1 : 1
          }
          return sortValue
        })
        this.initScenariosThresholds()
        if (this.scenarios.length) {
          this.selectHypothesis(this.scenarios.find(sc => sc.sourceTheme === 'Préconisé' && sc.scenarioSize === 'M') || this.scenarios[0])
        }

        this.loading = false
      },
      getScenarioLetterBoxNb(scenario) {
        const temp = []
        return this.zones
          .filter(z => scenario.zoneUnits?.find(zu => zu.leafletZoneUnits === z.metadata.leafletZoneUnit))
          .reduce((acc, cur) => {
            if (!temp.includes(cur.metadata.leafletZoneUnit)) {
              acc += cur.metadata.distributableLetterBoxNb
              temp.push(cur.metadata.leafletZoneUnit)
            }
            return acc
          }, 0)
      },
      setMap(map) {
        this.map = map
      },
      updateFullScreen(isFullScreen) {
        this.isFullScreen = isFullScreen
      },
      drawPolygon(polygon) {
        let hasIntersection = false
        const intersectZones = this.zones.filter(zone => getPolygonsIntersection(polygon, zone.polygon))
        const intersectIds = [...new Set(intersectZones.map(z => z.metadata.leafletZoneUnit))]
        this.zones.forEach(zone => {
          if (intersectIds.includes(zone.metadata.leafletZoneUnit)) {
            zone.polygon.setOptions({
              fillColor: '#508df7',
              fillOpacity: 0.4,
              strokeColor: '#2055cd'
            })
            if (!zone.metadata.selected) {
              hasIntersection = true
            }
            zone.metadata.selected = true
          }
        })
        if (hasIntersection) {
          this.selectedScenario = null
          this.isEditingHypothesis = true
        }
        this.zones = [...this.zones]
      },
      selectHypothesis(scenario) {
        this.selectedScenario = this.selectedScenario != scenario ? scenario : null
        const scenarioZoneUnits = this.selectedScenario?.zoneUnits?.map(zu => zu.leafletZoneUnits) ?? []
        const bounds = new google.maps.LatLngBounds()
        let isBoundsUpdated = false
        this.zones = this.zones.map(zone => {
          if (scenarioZoneUnits.includes(zone.metadata.leafletZoneUnit)) {
            zone.polygon.getPath().forEach(loc => bounds.extend({ lat: loc.lat(), lng: loc.lng() }))
            isBoundsUpdated = true
            zone.polygon.setOptions({
              fillColor: '#508df7',
              fillOpacity: 0.4,
              strokeColor: '#2055cd'
            })
            zone.metadata.selected = true
            this.isEditingHypothesis = false
          } else {
            zone.polygon.setOptions({
              strokeColor: '#000',
              fillColor: '#FFF',
              fillOpacity: 0.2
            })
            zone.metadata.selected = false
          }
          return zone
        })

        if (isBoundsUpdated) this.map.fitBounds(bounds)
      },
      async createHypothesis() {
        this.loading = true
        await tractsService.createHypothesis(
          this.userSiteRdo,
          this.hypothesisName,
          this.selectedSiteOperation.commercialOperationLabel,
          this.selectedDistributor.distributorCode,
          this.selectedSize,
          [...new Set(this.zones.filter(zone => zone.metadata.selected).map(zone => zone.metadata.leafletZoneUnit))]
        )
        const hypothesisName = this.hypothesisName
        this.isEditingHypothesis = false
        this.selectedSiteOperation = this.siteOperations?.[0] ?? {}
        this.scenarios = [
          {
            distributorCode: this.selectedDistributor.distributorCode,
            distributorLabel: this.selectedDistributor.distributorLabel,
            linkedSiteRdo: this.userSiteRdo,
            month: 202208,
            scenarioCode: 3,
            scenarioLabel: hypothesisName,
            scenarioSize: this.selectedSize.toUpperCase(),
            sourceTheme: 'Personnalisé',
            zoneUnits: [...new Set(this.zones.filter(zone => zone.metadata.selected).map(zone => zone.metadata.leafletZoneUnit))].map(el => ({
              leafletZoneUnits: el
            }))
          },
          ...this.scenarios
        ]
        this.filteredScenarios = this.scenarios
        this.selectHypothesis(this.scenarios.find(sc => sc.scenarioLabel === hypothesisName))
        this.loading = false
      },
      exportSelectedZones() {
        const selectedZones = [...new Set(this.zones.filter(zone => zone.metadata.selected).map(zone => zone.metadata.leafletZoneUnit))]
        const selectedZonesUnits = this.zoneUnits.features.filter(feature => selectedZones.includes(feature.properties.leafletZoneUnit))

        const rows = [['', 'Point de contact', 'Mois', 'Hypothese', 'Distributeur', 'Release code', 'zoneUnit', 'Boites aux lettres']]
        selectedZonesUnits.forEach(zu => {
          rows.push([
            this.userSiteRdo,
            this.selectedScenario?.month || null,
            this.selectedScenario?.scenarioLabel || null,
            zu.properties.distributorLabel,
            zu.properties.releaseCode,
            zu.properties.leafletZoneUnit,
            zu.properties.distributableLetterBoxNb
          ])
        })

        let csvContent = 'data:text/csv;charset=utf-8;' + rows.map(e => e.join(',')).join('\n')

        var encodedUri = encodeURI(csvContent)
        var link = document.createElement('a')
        link.setAttribute('href', encodedUri)
        link.setAttribute('download', 'export_datastore_tracts.csv')
        document.body.appendChild(link)
        link.click()
      }
    }
  }
</script>

<style scoped lang="scss">
  @import '~@/styles/_variables';

  .tract-zones-layout {
    margin: 2rem;

    h2 {
      margin: 2rem 0;
    }

    .main-content {
      position: relative;
    }

    .loader-ctnr {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 999;
      backdrop-filter: blur(12px);
      background-color: rgba(0, 0, 0, 0.2);

      .lds-ring {
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 80px;
        height: 80px;
      }
      .lds-ring div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 64px;
        height: 64px;
        margin: 8px;
        border: 8px solid #2055cd;
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #2055cd transparent transparent transparent;
      }
      .lds-ring div:nth-child(1) {
        animation-delay: -0.45s;
      }
      .lds-ring div:nth-child(2) {
        animation-delay: -0.3s;
      }
      .lds-ring div:nth-child(3) {
        animation-delay: -0.15s;
      }
      @keyframes lds-ring {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }

    .tracts-actions {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .distributor-not-default {
      color: #e16d6d;
    }

    .selector {
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
      border-radius: 5px;
      padding: 0.5rem;
      position: relative;
      font-weight: bold;
      cursor: pointer;
      font-size: 1.2rem;
      width: 20rem;
      margin: 1rem;

      .suggestions {
        position: absolute;
        top: 3rem;
        left: 0;
        right: 0;
        background: white;
        padding: 0;
        list-style: none;
        max-height: 20rem;
        overflow-y: auto;
        z-index: 9;
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
        border-radius: 5px;

        li {
          padding: 0.5rem;

          &:nth-child(2n) {
            background: #f8f8f8;
          }

          &:hover {
            background: #e3e3e3;
          }
        }
      }
    }

    .map-ctnr {
      position: relative;

      .google-maps {
        height: 80vh;
        border-radius: 2rem;
        overflow: hidden;

        &.fullscreen {
          height: 100vh;
        }
      }

      .map-overlay {
        position: absolute;
        top: 2rem;
        bottom: 2rem;
        right: 2rem;
        background: white;
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
        padding: 2rem 0;
        border-radius: 2rem;
        width: 20%;

        & > h3 {
          padding: 0 3rem;
        }

        .google-maps-full-screen-control {
          position: absolute;
          top: 0;
          left: -2rem;
          transform: translateX(-100%);
        }

        .search-filter {
          display: flex;
          justify-content: space-between;
          padding: 0 3rem;
          position: relative;

          .search {
            display: flex;
            align-items: center;
            box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
            padding: 0.5rem;
            width: 80%;
            border-radius: 0.5rem;

            i {
              font-size: 2rem;
            }

            &-input {
              border: none;
              outline: none;
              padding-left: 0.5rem;
            }
          }

          .filter {
            background: white;
            border-radius: 0.5rem;
            border: none;
            outline: none;
            box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
            cursor: pointer;
          }

          .filter-overlay {
            position: absolute;
            background: white;
            z-index: 100;
            top: 4rem;
            left: 3rem;
            right: 3rem;
            padding: 1rem;
            box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
            border-radius: 5px;
            display: flex;
            flex-direction: column;
            gap: 2rem;

            &-group {
              h3 {
                margin: 0.5rem 0;
              }
              .checkbox-group {
                display: flex;
                align-items: center;
                gap: 1rem;
                margin: 1rem 0;

                input[type='checkbox'] {
                  opacity: 0;

                  & + label {
                    position: relative;
                    cursor: pointer;
                    padding: 0;
                  }

                  & + label:before {
                    content: '';
                    margin-right: 10px;
                    display: inline-block;
                    vertical-align: text-top;
                    width: 20px;
                    height: 20px;
                    background: white;
                    border: 1px solid #2055cd;
                  }

                  &:checked + label:after {
                    content: '';
                    position: absolute;
                    left: 2px;
                    top: 8px;
                    background: white;
                    width: 3px;
                    height: 3px;
                    box-shadow: 3px 0 0 #2055cd, 6px 0 0 #2055cd, 6px -3px 0 #2055cd, 6px -6px 0 #2055cd, 6px -9px 0 #2055cd, 6px -12px 0 #2055cd;
                    transform: rotate(45deg);
                  }
                }
              }
            }
          }
        }

        .hypothesis {
          display: flex;
          flex-direction: column;
          margin-top: 2rem;
          max-height: calc(100% - 250px);
          overflow-y: auto;
        }

        .hypothesis-size-ctnr {
          width: 100%;
          padding: 0 3rem;
        }

        &-title {
          display: flex;
          align-items: center;
          gap: 1rem;

          .hypothesis-back {
            cursor: pointer;
          }
        }

        &-input-group {
          padding: 0 3rem;
          display: flex;
          flex-direction: column;
          margin: 2rem 0;

          .selector {
            display: flex;
            width: auto;
            margin: 0;
          }
        }
        &-buttons {
          display: flex;
          justify-content: space-around;
          margin: 2rem 0;
        }
      }

      .map-hover-historical {
        position: absolute;
        top: 1rem;
        left: 37%;
        transform: translateX(-50%);
        padding: 0.25rem 1rem;
        background: white;
        color: $color-bad;
        font-weight: bold;
        border-radius: 0.5rem;
      }

      .map-hover-kpis {
        position: absolute;
        top: 4rem;
        width: 20%;
        left: 40%;
        transform: translateX(-50%);
        background: white;
        border-radius: 2rem;
        padding: 1rem 2rem;
        border: 2px solid $primary-color;

        h4 {
          margin: 0 0 0.2rem 0;
          text-align: center;
          color: $primary-color;
        }

        .kpi-line {
          margin: 0;

          .text {
            font-weight: bold;
          }

          .figure {
            margin-left: 0.5rem;
          }
        }
      }

      .map-kpis {
        position: absolute;
        bottom: 2rem;
        display: flex;
        width: 50%;
        left: 40%;
        transform: translateX(-50%);
        justify-content: space-between;

        .kpi {
          background: white;
          width: 28%;
          border-radius: 2rem;
          overflow: hidden;
          position: relative;

          &-info {
            position: absolute;
            top: 5px;
            right: 5px;
            font-size: 15px;
          }

          h3 {
            background: $primary-color-lightened;
            margin: 0;
            text-align: center;
            padding: 0.5rem 1rem;
          }

          &-content {
            padding: 0.5rem 1rem;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            font-size: 2rem;
            color: $primary-color;
            font-weight: bold;
            gap: 0.5rem;

            .line {
              display: flex;
              align-items: center;
              gap: 0.5rem;

              .subtext {
                font-size: 1.3rem;
                color: $color-grey;
                font-weight: normal;
              }
            }
          }
        }
      }
    }
  }
</style>
