<template>
    <div class="analyze-customer-profile">
        <div class="header">
            <app-title :title="$t($route.meta.title)">
                <app-period :from="5"
                            :to="1"/>
            </app-title>
        </div>
        <div class="grid">
            <ds-holder-customer-chart class="holder-customer-chart"/>
        </div>
    </div>
</template>

<script>
import AppPeriod from '@/components/app-period/AppPeriod.vue';
import AppTitle from '@/components/app-title/AppTitle.vue';
import DsHolderCustomerChart from './components/HolderCustomerChart.vue';

export default {
  name: 'analyze-customer-profile',
  components: {
    AppTitle, AppPeriod, DsHolderCustomerChart,
  },
};
</script>

<style scoped lang="scss">
@import "~@/styles/_mixins";

.analyze-customer-profile {
    padding: 0 2rem 2rem 2rem;

    .header {
        @include display-flex(center, space-between);
    }

    .grid {
        display: grid;
        grid-gap: 1rem;
        grid-template-columns: 25% 1fr;

        .holder-customer-chart {
            grid-column: 1;
            grid-row: 1;
        }
    }
}
</style>
