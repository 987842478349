<template>
    <div class="holder-customer-chart">
        <md-card>
            <md-card-header class="header">
                <md-card-header-text>
                    <div class="md-title">{{ $t('HOLDER_CUSTOMER_CHART.LABEL') }}</div>
                    <div class="md-subhead">
                        <app-period :from="5"
                                    :to="1"/>
                    </div>
                    <div class="md-subhead">{{ $t('HOLDER_CUSTOMER_CHART.SUB_LABEL') }}</div>
                </md-card-header-text>
            </md-card-header>

            <md-card-content class="content">
                <app-loading v-if="isLoading"></app-loading>
                <app-error-state v-else-if="isInError"></app-error-state>
                <app-empty-state v-else-if="datasets.length === 0"></app-empty-state>

                <app-doughnut-chart :datasets="datasets"
                                    :height="200"
                                    :labels="labels"
                                    :options="options"
                                    v-else>
                </app-doughnut-chart>
            </md-card-content>
        </md-card>
    </div>
</template>

<script>
import AppDoughnutChart from '@/components/app-doughnut-chart/AppDoughnutChart.vue';
import AppEmptyState from '@/components/app-empty-state/AppEmptyState.vue';
import AppErrorState from '@/components/app-error-state/AppErrorState.vue';
import AppLoading from '@/components/app-loading/AppLoading.vue';
import customerProfileService from '@/services/customerProfileService';
import AppPeriod from '@/components/app-period/AppPeriod.vue';

export default {
  name: 'DsHolderCustomerChart',
  components: {
    AppEmptyState, AppErrorState, AppLoading, AppDoughnutChart, AppPeriod,
  },
  data() {
    return {
      isLoading: false,
      isInError: false,
      labels: [],
      datasets: [],
      options: {
        tooltips: {
          enabled: false,
        },
        plugins: {
          datalabels: {
            formatter: value => this.$options.filters.number(value, 1, '%'),
            textAlign: 'center',
          },
        },
      },
    };
  },
  created() {
    this.getHolderCustomer();
  },
  watch: {
    '$route.query': function () {
      this.getHolderCustomer();
    },
  },
  methods: {
    getHolderCustomer() {
      const query = this.$route.query;
      this.isLoading = true;
      this.datasets = [];

      const dataset = {
        borderWidth: 0,
        backgroundColor: ['#448aff', 'rgba(68,138,255,0.38)'],
        datalabels: {
          borderWidth: 0,
          borderRadius: 2,
          color: ['white', 'black'],
          formatter: value => this.$options.filters.number(value, 1, 'percent'),
          textAlign: 'center',
        },
      };

      customerProfileService
        .getCustomerWeight(query.sidSite, query.sidSalesChannel, query.idLivingArea, query.sidDepartments)
        .then((customerWeight) => {
          this.labels = [this.$t('HOLDER_CUSTOMER_CHART.SERIE.HOLDER'), this.$t('HOLDER_CUSTOMER_CHART.SERIE.NOT_HOLDER')];
          dataset.data = [customerWeight.salesAmountCardedCustomersWeight, customerWeight.salesAmountNoCardedCustomersWeight];
          this.datasets.push(dataset);
        })
        .catch(() => this.isInError = true)
        .finally(() => this.isLoading = false);
    },
  },
};
</script>
