<template>
    <div class="app-name">
        <div class="home">
            <img :src="logo"
                 alt=""/>
            <span v-html="name"/>
        </div>
    </div>
</template>

<script>
export default {
  name: 'AppName',
  props: {
    logo: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss">
@import "~@/styles/_mixins";

.app-name {
    .home {
        @include display-flex();
        margin-right: 1rem;
        color: #fff;

        img {
            height: 24px;
            max-width: 100%;
        }

        span {
            text-transform: uppercase;
            position: relative;
            font-size: 1.2rem;
        }
    }
}
</style>
