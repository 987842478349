import Vue from 'vue'
import Router from 'vue-router'

import AnalyzeLayout from './views/analyze/AnalyzeLayout.vue'
import AnalyzeCustomerProfile from './views/analyze/customer-profile/AnalyzeCustomerProfile.vue'
import AnalyzeMatrix from './views/analyze/matrix/AnalyzeMatrix.vue'
import AnalyzeGeoMarketing from './views/analyze/geo-marketing/AnalyzeGeoMarketing.vue'
import AnalyzeSalesFormulaLayout from './views/analyze/sales-formula/AnalyzeSalesFormulaLayout.vue'
import AnalyzeMap from './views/analyze/map/AnalyzeMap.vue'
import SalesFormulaMap from './views/analyze/sales-formula/sales-formula-map/SalesFormulaMap.vue'
import SalesFormulaMatrix from './views/analyze/sales-formula/sales-formula-matrix/SalesFormulaMatrix.vue'
import SalesFormulaPyramid from './views/analyze/sales-formula/sales-formula-pyramid/SalesFormulaPyramid.vue'
import AnimateLayout from './views/animate/AnimateLayout.vue'
import AnimateGeographic from './views/animate/geographic/AnimateGeographic.vue'
import AnimateMarket from './views/animate/market/AnimateMarket.vue'
import AnimateMedia from './views/animate/media/AnimateMedia.vue'
import AnimateSegmentation from './views/animate/segmentation/AnimateSegmentation.vue'
import AnimateTopClient from './views/animate/top-client/AnimateTopClient.vue'
import AnimateResume from './views/animate/resume/AnimateResume.vue'
import AnimateStore from './views/animate/store/AnimateStore.vue'
import AnimateHome from './views/animate/home/AnimateHome.vue'
import AnimateCommunity from './views/animate/community/AnimateCommunity.vue'
import CommunitySettings from './views/animate/CommunitySettings'
import DolmenView from './views/Dolmen.vue'
import TractZones from './views/tract-zones/TractZonesLayout.vue'
import { isCentral } from './helpers/userUtils'

Vue.use(Router)

const initializeDefaultRouteStore = (to, from, next) => {
  const query = Object.assign({}, to.query)

  if (query.sidSite === undefined && !isCentral(Vue.prototype.$userInfos)) query.sidSite = Vue.prototype.$userInfos.site.siteSid
  if (Vue.prototype.$userInfos.site.nomenclatures?.includes('N_SI_STD')) query.sidSalesChannel = 1 // N_SI_STD -> nomenclature hyper
  if (query.sidSalesChannel === undefined) query.sidSalesChannel = 99

  next({ name: 'analyze-sales-formula-pyramid', query })
}

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/analyze',
      component: AnalyzeLayout,
      children: [
        {
          path: 'sales-formula',
          component: AnalyzeSalesFormulaLayout,
          children: [
            {
              path: 'pyramid',
              name: 'analyze-sales-formula-pyramid',
              component: SalesFormulaPyramid,
              meta: {
                title: 'VIEWS.ANALYZE.SALES_FORMULA',
                isWithoutPeriod: true,
                tutorialId: 'analyze-sales-formula-tutorial'
              }
            },
            {
              path: 'matrix',
              name: 'analyze-sales-formula-matrix',
              component: SalesFormulaMatrix,
              meta: {
                title: 'VIEWS.ANALYZE.SALES_FORMULA',
                isWithoutPeriod: true,
                tutorialId: 'analyze-sales-formula-tutorial'
              }
            },
            {
              path: 'map',
              name: 'analyze-sales-formula-map',
              component: SalesFormulaMap,
              meta: {
                title: 'VIEWS.ANALYZE.SALES_FORMULA',
                isWithoutPeriod: true,
                tutorialId: 'analyze-sales-formula-tutorial'
              }
            }
          ]
        },
        {
          path: 'geo-marketing',
          name: 'analyze-geo-marketing',
          meta: {
            title: 'VIEWS.ANALYZE.GEO_MARKETING',
            isWithoutPeriod: true,
            tutorialId: 'analyze-geo-marketing-tutorial'
          },
          component: AnalyzeGeoMarketing
        },
        {
          path: 'map',
          name: 'analyze-map',
          meta: {
            title: 'VIEWS.ANALYZE.MAP',
            isWithoutPeriod: true,
            tutorialId: 'analyze-map-tutorial'
          },
          component: AnalyzeMap
        },
        {
          path: 'customer-profile',
          name: 'analyze-customer-profile',
          meta: {
            title: 'VIEWS.ANALYZE.CUSTOMER_PROFILE',
            isWithoutPeriod: true,
            tutorialId: 'analyze-customer-profile-tutorial'
          },
          component: AnalyzeCustomerProfile
        },
        {
          path: 'matrix',
          name: 'analyze-matrix',
          meta: {
            isWithoutPeriod: true,
            title: 'VIEWS.ANALYZE.MATRIX',
            tutorialId: 'analyze-matrix-tutorial'
          },
          component: AnalyzeMatrix
        }
      ]
    },
    {
      path: '/settings/community',
      name: 'community-settings',
      component: CommunitySettings
    },
    {
      path: '/animate',
      component: AnimateLayout,
      children: [
        {
          path: 'home/',
          name: 'animate-home',
          component: AnimateHome
        },
        {
          path: 'store/:sid?',
          name: 'animate-store',
          component: AnimateStore
        },
        {
          path: 'media/:sid?',
          name: 'animate-media',
          component: AnimateMedia
        },
        {
          path: 'comminity/:sid',
          name: 'animate-community',
          component: AnimateCommunity
        },
        {
          path: 'market/:sid',
          name: 'animate-market',
          component: AnimateMarket
        },
        {
          path: 'segmentation/:sid',
          name: 'animate-segmentation',
          component: AnimateSegmentation
        },
        {
          path: 'geographic/:sid',
          name: 'animate-geographic',
          component: AnimateGeographic
        },
        {
          path: 'top-client/:sid',
          name: 'animate-top-client',
          component: AnimateTopClient
        }
      ]
    },
    {
      path: '/dolmen/site/:site',
      name: 'DolmenView',
      component: DolmenView
    },
    {
      path: '/animate-resume/:sid',
      name: 'animate-resume',
      component: AnimateResume
    },
    {
      path: '/tract-zones',
      name: 'tract-zones',
      component: TractZones
    },
    {
      path: '**',
      beforeEnter: initializeDefaultRouteStore
    }
  ]
})

router.beforeEach((to, from, next) => {
  const query = Object.assign({}, to.query)
  if (query.sidSalesChannel === undefined) {
    query.sidSalesChannel = 99
  }

  if (JSON.stringify(to.query) !== JSON.stringify(query)) {
    next({
      name: to.name,
      params: to.params,
      query,
      replace: true
    })
  } else {
    next()
  }
})

export default router
