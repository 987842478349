<template>
    <md-empty-state :md-description="descriptionFormatted"
                    :md-label="labelFormatted"
                    md-icon="error">
        <slot/>
    </md-empty-state>
</template>

<script>
export default {
  name: 'AppErrorState',
  props: {
    label: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
  },
  computed: {
    labelFormatted() {
      return this.label === null ? this.$t('APP_ERROR_STATE.LABEL') : this.label;
    },
    descriptionFormatted() {
      return this.description === null ? this.$t('APP_ERROR_STATE.DESCRIPTION') : this.description;
    },
  },
};
</script>
