<template>
    <div class="app-nomenclature-selector">
        <app-loading v-if="isLoading"></app-loading>
        <div class="app-nomenclature-selector-main" v-else>
            <div class="hierarchy-selector">
                <md-list :class="selectedUniverse ? 'slim': ''">
                    <md-list-item @click="globalHyper = !globalHyper">
                        {{ $t('APP_NOMENCLATURE_SELECTOR.GLOBAL_HYPER') }}
                        <md-checkbox v-model="globalHyper"/>
                    </md-list-item>

                    <md-divider class="md-inset"></md-divider>

                    <div :key="`universe_${universe.id}`"
                         v-for="universe in nomenclatures.universes">
                        <md-list-item @click="selectUniverse(universe)"
                                      :class="selectedUniverse && selectedUniverse.id === universe.id ? 'selected' : ''">
                            <p>{{ universe.label }}</p>

                            <div class="actions">
                                <md-badge v-if="numberSelectedDepartmentsIcon(universe.id) !== 0"
                                          :md-content="numberSelectedDepartmentsIcon(universe.id)"
                                          class="md-accent"/>
                                <md-button class="md-icon-button md-list-action">
                                    <md-icon class="md-primary">keyboard_arrow_right</md-icon>
                                </md-button>
                            </div>
                        </md-list-item>
                        <md-divider class="md-inset"></md-divider>
                    </div>
                </md-list>

                <md-list v-if="selectedUniverse" :class="selectedDepartment ? 'slim': ''">
                    <div :key="`department_${department.id}`"
                         v-for="department in filteredDepartments">
                        <md-list-item @click="selectDepartment(department)"
                                      :class="selectedDepartment && selectedDepartment.id === department.id ? 'selected' : ''">
                            <p>{{ department.label }}</p>

                            <div class="actions">
                                <md-badge v-if="numberSelectedMarketsIcon(department) !== 0"
                                          :md-content="numberSelectedMarketsIcon(department)"
                                          class="md-accent"/>
                                <md-button class="md-icon-button md-list-action">
                                    <md-icon class="md-primary">keyboard_arrow_right</md-icon>
                                </md-button>
                            </div>
                        </md-list-item>
                        <md-divider class="md-inset"></md-divider>
                    </div>
                </md-list>

                <md-list class="md-dense sites"
                         v-if="selectedDepartment">
                    <md-list-item :key="`market_${idx}`"
                                  v-for="(market, idx) in filteredMarkets">
                        <p>{{ market.label }}</p>
                        <md-radio v-if="isOnlyOneMarket" v-model="selectedMarket" :value="market"/>
                        <md-checkbox v-else v-model="selectedMarkets" :value="market"/>
                    </md-list-item>
                </md-list>
            </div>
        </div>

        <md-snackbar
                :md-active.sync="disableMultiMarket"
                :md-duration="Infinity"
                md-persistent
                md-position="left">
            <span>{{ $t('APP_NOMENCLATURE_SELECTOR.WARNING_IS_ONLY_ONE_MARKET') }}</span>
            <md-button class="md-primary" @click="disableMultiMarket = false">OK</md-button>
        </md-snackbar>
    </div>
</template>

<script>
import AppSearch from '@/components/app-search/AppSearch.vue';
import nomenclatureReferentialService from '@/services/nomenclatureReferentialService';
import AppLoading from '@/components/app-loading/AppLoading.vue';

export default {
  name: 'AppNomenclatureSelector',
  components: { AppLoading, AppSearch },
  props: {
    value: {
      type: Array,
      default: () => ([]),
    },
    selectedMarkets: {
      type: Array || Object,
      default: () => ([]),
    },
    initSelectedIdMarkets: {
      type: Array,
      default: () => ([]),
    },
    isOnlyOneMarket: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      disableMultiMarket: this.isOnlyOneMarket,
      isLoading: false,
      isInError: false,
      nomenclatures: null,
      selectedUniverse: null,
      selectedDepartment: null,
      selectedDepartments: [],
      globalHyper: false,
      selectedMarket: {},
    };
  },
  created() {
    this.getAllNomenclatures();
  },
  watch: {
    globalHyper() {
      if (this.globalHyper) {
        if (this.selectedMarkets.length > 0 && this.globalHyper) {
          this.selectedMarkets = [];
          this.selectedMarket = null;
        }

        this.$emit('input', this.selectedMarkets);
      }
    },
    selectedMarket() {
      this.$emit('input', [this.selectedMarket]);
    },
    selectedMarkets() {
      this.selectedDepartments = [];
      this.selectedMarkets.forEach(selectedMarket => {
        const selectedDepartmentWithParentExist =
            this.nomenclatures.departments.find(selectedDepartment => selectedDepartment.id === selectedMarket.parent.id);

        const selectedDepartmentExist =
            this.selectedDepartments.find(selectedDepartment => selectedDepartment.id === selectedDepartmentWithParentExist.id);
        if (!selectedDepartmentExist) this.selectedDepartments.push(selectedDepartmentWithParentExist);
      });

      if (this.selectedMarkets.length > 0 && this.globalHyper) this.globalHyper = false;
      this.$emit('input', this.selectedMarkets);
    },
  },
  computed: {
    filteredDepartments() {
      if (this.selectedUniverse === null) {
        return [];
      }

      return this.nomenclatures.departments.filter(department => department.parent.id === this.selectedUniverse.id);
    },
    filteredMarkets() {
      if (this.selectedDepartment === null) {
        return [];
      }

      return this.nomenclatures.markets.filter(market => market.parent.id === this.selectedDepartment.id);
    },
  },
  methods: {
    numberSelectedMarketsIcon(department) {
      let count = 0;

      this.selectedMarkets.forEach(market => {
        if (market.parent.id === department.id) count++;
      });

      return count;
    },
    numberSelectedDepartmentsIcon(universeId) {
      let count = 0;

      this.selectedDepartments.forEach(department => {
        if (department.parent.id === universeId) count++;
      });

      return count;
    },
    selectUniverse(selectedUniverse) {
      this.selectedUniverse = selectedUniverse;
      this.selectedDepartment = null;
    },
    selectDepartment(selectedDepartment) {
      this.selectedDepartment = selectedDepartment;
    },
    getAllNomenclatures() {
      this.isLoading = true;
      this.isInError = false;

      nomenclatureReferentialService.getUniverseDepartmentMarket()
        .then(nomenclatures => this.nomenclatures = nomenclatures)
        .catch(() => this.isInError = true)
        .finally(() => {
          if (this.initSelectedIdMarkets && this.initSelectedIdMarkets.length > 0) {
            this.initSelectedIdMarkets.forEach(selectedMarket => {
              if (this.nomenclatures) {
                const marketExist = this.nomenclatures.markets.find(market => market.sid === +selectedMarket.sid);
                if (marketExist) this.selectedMarkets.push(marketExist);
                if (this.isOnlyOneMarket) this.selectedMarket = marketExist;
              }
            });
          }

          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
    @import "~@/styles/_colors";

    :global(.md-snackbar) {
        z-index: 99;
    }

    .app-nomenclature-selector {
        overflow: hidden;
        height: 100%;

        &-main {
            overflow: hidden;
            height: 100%;
            .hierarchy-selector {
                display: flex;
                height: 100%;
                .selected {
                    background-color: $primary-color-with-opacity;
                }

                .md-list {
                    flex: 1;
                    &:not(:last-child) {
                        border-right: 1px solid $color-grey-light;
                    }
                    &.slim {
                        width: 30%;
                    }
                    .md-list-action:last-of-type {
                        margin-left: 0;
                    }
                    .actions {
                        display: flex;
                        align-items: center;
                    }
                    :global(.md-list-item-content) {
                        cursor: pointer;
                        > .md-checkbox:first-child {
                            margin: 0;
                            flex-direction: row-reverse;
                            justify-content: space-between;
                            display: flex;
                            width: 100%;
                        }
                    }
                    p {
                        margin: 0 .5em 0 0;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                }
                .markets {
                    overflow: auto;
                }
            }
        }
    }
</style>
