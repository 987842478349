<template>
    <div class="app-progress-funny">
        <svg width="190" height="100">
            <g id="progress"
               fill="none"
               fill-rule="evenodd"
               stroke="none"
               stroke-width="1">
                <g transform="translate(-1110, -150)">
                    <g transform="translate(959.000000, 50.000000)">
                        <g transform="translate(59.000000, 114.000000)">
                            <g transform="translate(105.000000, 0.000000)">
                                <g>
                                    <path d="M0,72 C0,32.235498 34.026359,1.42108547e-14 76,1.42108547e-14 C117.973641,1.42108547e-14 152,32.235498 152,72"
                                          id="pathGrey"
                                          stroke="#E4E4E4"
                                          stroke-linecap="round"
                                          stroke-width="6" />

                                    <path d="M0,72 C0,32.235498 34.026359,1.42108547e-14 76,1.42108547e-14 C117.973641,1.42108547e-14 152,32.235498 152,72"
                                          id="pathRed"
                                          stroke="#FF858E"
                                          stroke-linecap="round"
                                          fill-opacity="0"
                                          stroke-width="7" />

                                    <ellipse ref="circle"
                                             cx="0"
                                             cy="68"
                                             fill="#FFFFFF"
                                             id="circle"
                                             rx="7.5"
                                             ry="7.21052632"
                                             stroke="#FF858E"
                                             stroke-width="4" />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>

        <img class="smiley" :src="logo" >
        <p class="label" :style="{color: step.color}">{{ step.label }}</p>
        <p class="description">{{ step.description }}</p>
    </div>
</template>

<script>
import * as ProgressBar from 'progressbar.js'

export default {
  name: 'AppProgressFunny',
  props: {
    value: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      bar: null,
      pathLen: 0,
      steps: {
        customers: [
        {
          id: 'bad2',
          from: 0,
          to: 0.2,
          color: '#FF858E',
          label: this.$t('ANIMATE_LAYOUT.NO_OPTIMIZED_TARGET'),
          description: this.$t('ANIMATE_LAYOUT.BAD_TARGET'),
        },
        {
          id: 'bad',
          from: 0.2,
          to: 0.4,
          color: '#FEB1B7',
          label: this.$t('ANIMATE_LAYOUT.NO_OPTIMIZED_TARGET'),
          description: this.$t('ANIMATE_LAYOUT.BAD_TARGET'),
        },
        {
          id: 'neutral',
          from: 0.4,
          to: 0.6,
          color: '#FFB583',
          label: this.$t('ANIMATE_LAYOUT.NEUTRAL_OPTIMIZED_TARGET'),
          description: this.$t('ANIMATE_LAYOUT.NEUTRAL_TARGET'),
        },
        {
          id: 'good',
          from: 0.6,
          to: 0.8,
          color: '#B5F1DA',
          label: this.$t('ANIMATE_LAYOUT.OPTIMIZED_TARGET'),
          description: this.$t('ANIMATE_LAYOUT.GOOD_TARGET'),
        },
        {
          id: 'good2',
          from: 0.8,
          to: 1,
          color: '#25D691',
          label: this.$t('ANIMATE_LAYOUT.OPTIMIZED_TARGET'),
          description: this.$t('ANIMATE_LAYOUT.GOOD_TARGET'),
        }
      ],
      community: [
        {
          id: 'bad2',
          from: 0,
          to: 0.3,
          color: '#FF858E',
          label: this.$t('ANIMATE_LAYOUT.NO_OPTIMIZED_TARGET'),
          description: this.$t('ANIMATE_LAYOUT.BAD_TARGET'),
        },
        {
          id: 'bad',
          from: 0.3,
          to: 0.6,
          color: '#FF858E',
          label: this.$t('ANIMATE_LAYOUT.NO_OPTIMIZED_TARGET'),
          description: this.$t('ANIMATE_LAYOUT.BAD_COMMUNITY_TARGET'),
        },
        {
          id: 'neutral',
          from: 0.6,
          to: 0.8,
          color: '#FFB583',
          label: this.$t('ANIMATE_LAYOUT.NEUTRAL_OPTIMIZED_TARGET'),
          description: this.$t('ANIMATE_LAYOUT.NEUTRAL_COMMUNITY_TARGET'),
        },
        {
          id: 'good2',
          from: 0.8,
          to: 1,
          color: '#25D691',
          label: this.$t('ANIMATE_LAYOUT.OPTIMIZED_TARGET'),
          description: this.$t('ANIMATE_LAYOUT.GOOD_COMMUNITY_TARGET'),
        },
      ],}
    }
  },
  computed: {
    logo() {
      if (this.step) { return require(`@/assets/smiley/smiley_${this.step.id}.svg`) }
      return require('@/assets/smiley/smiley_bad2.svg')
    },
    step() {
      const step = this.steps[this.$route.query.mode].find(step => step.from <= this.value && step.to > this.value)
      if (!step) {
        console.warn(`Step not found for value ${this.value}, please contact your administrator.`)
      }

      return step
    },
  },
  watch: {
    value() {
      this.progress(this.value)
    },
  },
  mounted() {
    this.bar = new ProgressBar.Path('#pathRed')
    this.barLength = this.bar.path.getTotalLength()
    this.bar.set(0)
    this.progress(this.value)
  },

  methods: {
    progress(value) {
      this.bar.animate(value, {
        easing: 'easeInOut',
        duration: 500,
        attachment: this.$refs.circle,
        step: (state, shape, attachment) => {
          const pos = shape.path.getPointAtLength(this.barLength * value)
          attachment.attributes.cx.value = pos.x
          attachment.attributes.cy.value = pos.y
        },
      })

      const progressLine = document.querySelector('#pathRed')
      const circle = document.querySelector('#circle')
      progressLine.setAttribute('stroke', this.step.color)
      circle.setAttribute('stroke', this.step.color)
    },
  },
}
</script>

<style scoped lang="scss">
    @import "~@/styles/_colors";

    .app-progress-funny {
        text-align : center;
        position   : relative;

        .smiley {
            position  : absolute;
            top       : 24%;
            left      : 50%;
            transform : translate(-60%);
        }

        .label,
        .description {
            margin    : 0;
            padding   : 0 2rem;
            font-size : 1em;
        }

        .description {
            font-size : .8em;
        }

        .label {
            font-weight : bold;
            &.darkgreen {
                color : darkgreen;
            }
            &.green {
                color : green;
            }
            &.yellow {
                color : #FFE14A;
            }
            &.orange {
                color : orange;
            }
            &.red {
                color : $color-bad;
            }
        }

        #circle {
            transition : 500ms cubic-bezier(0.68, -0.55, 0.27, 1.55) all;
        }
    }
</style>
