<template>
  <md-button id="ga-logout" class="md-icon-button" @click="logout">
    <md-icon :style="{ color: colorFont }">logout</md-icon>
    <md-tooltip>{{ $t('LOGOUT_BTN.LABEL') }}</md-tooltip>
  </md-button>
</template>

<script>
  import { getAuth, signOut } from 'firebase/auth'
  export default {
    name: 'AppLogoutBtn',
    props: {
      colorFont: {
        type: String,
        default: 'white'
      }
    },
    methods: {
      async logout() {
        const auth = getAuth()
        await signOut(auth).then(() => {
          window.location.replace('https://eu.auth.auchan.com/idp/startSLO.ping')
        })
      }
    }
  }
</script>
