<template>
    <div class="sales-formula-map">
        <div class="map">
            <google-maps :center="center"
                         :markers="markers"
                         :layers="layers"
                         @load="setMap">
                <google-maps-zoom-control :map="map"/>
                <google-maps-center-me-control :center="center"
                                               :map="map"/>
                <google-maps-legend v-if="activeType.id === 'progression'" :opened="!isLoading"
                                    class="legend">
                    <table>
                        <tr :key="`colorChart_${idx}`"
                            v-for="(colorChart, idx) in colorCharts">
                            <td :style="{ 'background-color': colorChart.color, opacity: colorChart.opacity }"/>
                            <td>
                                {{ colorChart.min | number(0, activeType.id === 'value' ? 'decimal' : 'percent') }}
                            </td>
                            <td>
                                {{ colorChart.max | number(0, activeType.id === 'value' ? 'decimal' : 'percent') }}
                            </td>
                        </tr>
                    </table>
                </google-maps-legend>
            </google-maps>
        </div>
        <div class="criterions">
            <app-loading v-if="isLoading"></app-loading>
            <md-subheader>{{ $t('SALES_FORMULA_MAP.INDICATORS.LABEL') }}</md-subheader>

            <md-list class="md-dense">
                <md-list-item :disabled="activeCategory === 'salesAmount' || isLoading"
                              @click="setCategory('salesAmount')">
                    <md-icon>local_atm</md-icon>
                    <span class="md-list-item-text">{{ $t('SALES_FORMULA_MAP.INDICATORS.TURNOVER') }}</span>
                </md-list-item>
                <md-list-item :disabled="activeCategory === 'entries' || isLoading"
                              @click="setCategory('entries')">
                    <md-icon>description</md-icon>
                    <span class="md-list-item-text">{{ $t('SALES_FORMULA_MAP.INDICATORS.TICKETS') }}</span>
                </md-list-item>
                <md-list-item :disabled="activeCategory === 'totalWorkforce' || isLoading"
                              @click="setCategory('totalWorkforce')">
                    <md-icon>group</md-icon>
                    <span class="md-list-item-text">{{ $t('SALES_FORMULA_MAP.INDICATORS.WORKFORCE') }}</span>
                </md-list-item>
                <div v-if="this.activeType.id === 'progression'">
                    <md-list-item :disabled="activeCategory === 'customerByTotalWorkforce' || isLoading"
                                  @click="setCategory('customerByTotalWorkforce')">
                        <md-icon>add_shopping_cart</md-icon>
                        <span class="md-list-item-text">{{ $t('SALES_FORMULA_MAP.INDICATORS.AVERAGE_RATE') }}</span>
                    </md-list-item>
                    <md-list-item :disabled="activeCategory === 'entriesByCustomers' || isLoading"
                                  @click="setCategory('entriesByCustomers')">
                        <md-icon>all_inclusive</md-icon>
                        <span class="md-list-item-text">{{ $t('SALES_FORMULA_MAP.INDICATORS.FREQUENCY') }}</span>
                    </md-list-item>
                    <md-list-item :disabled="activeCategory === 'salesAmountByEntries' || isLoading"
                                  @click="setCategory('salesAmountByEntries')">
                        <md-icon>shopping_cart</md-icon>
                        <span class="md-list-item-text">{{ $t('SALES_FORMULA_MAP.INDICATORS.CART') }}</span>
                    </md-list-item>
                    <md-list-item :disabled="activeCategory === 'quantityByEntries' || isLoading"
                                  @click="setCategory('quantityByEntries')">
                        <md-icon>videogame_asset</md-icon>
                        <span class="md-list-item-text">{{ $t('SALES_FORMULA_MAP.INDICATORS.ARTICLES') }}</span>
                    </md-list-item>
                    <md-list-item :disabled="activeCategory === 'salesAmountByQuantities' || isLoading"
                                  @click="setCategory('salesAmountByQuantities')">
                        <md-icon>money</md-icon>
                        <span class="md-list-item-text">{{ $t('SALES_FORMULA_MAP.INDICATORS.AVERAGE_PRICE') }}</span>
                    </md-list-item>
                </div>
            </md-list>

            <md-subheader>{{ $t('SALES_FORMULA_MAP.TYPE.LABEL') }}</md-subheader>

            <div class="types">
                <app-button-toggle :value="activeType"
                                   :values="types"
                                   @input="setType">
                </app-button-toggle>
            </div>
        </div>

        <md-snackbar :md-active.sync="message.active"
                     :md-duration="Infinity"
                     md-persistent
                     md-position="left">
            {{ message.value }}
        </md-snackbar>
    </div>
</template>

<script>
/* global google */
import AppButtonToggle from '@/components/app-button-toggle/AppButtonToggle.vue';
import AppLoading from '@/components/app-loading/AppLoading.vue';
import GoogleMaps from '@/components/google-maps/GoogleMaps.vue';
import GoogleMapsCenterMeControl from '@/components/google-maps/GoogleMapsCenterMeControl.vue';
import GoogleMapsLegend from '@/components/google-maps/GoogleMapsLegend.vue';
import GoogleMapsZoomControl from '@/components/google-maps/GoogleMapsZoomControl.vue';
import nomenclatureGeographicService from '@/services/nomenclatureGeographicService';
import salesFormulaService from '@/services/salesFormulaService';

export default {
  name: 'DsSalesFormulaMap',
  components: {
    GoogleMapsLegend,
    AppButtonToggle,
    AppLoading,
    GoogleMapsCenterMeControl,
    GoogleMapsZoomControl,
    GoogleMaps,
  },
  data() {
    return {
      isLoading: false,
      layers: null,
      map: null,
      center: null,
      markers: [],
      activeCategory: 'salesAmount',
      activeType: { id: 'progression' },
      types: [
        {
          id: 'progression',
          label: this.$t('SALES_FORMULA_MAP.TYPE.PROGRESSION'),
        },
        {
          id: 'value',
          label: this.$t('SALES_FORMULA_MAP.TYPE.VALUE'),
        },
      ],
      featureCollection: null,
      message: {
        active: false,
        value: null,
      },
    };
  },
  computed: {
    colorCharts() {
      const colorCharts = [];

      if (this.featureCollection === null) {
        return colorCharts;
      }

      let property = `${this.activeCategory}Progression`;
      let opacities = [0.80, 0.55, 0.30, 0.30, 0.55, 0.80];
      let nbOfRange = 3;
      let colors = ['#F49166', '#4BB984'];

      if (this.activeType.id === 'value') {
        property = this.activeCategory;
        opacities = [0.80, 0.70, 0.60, 0.50, 0.40, 0.30];
        nbOfRange = 6;
        colors = ['#1E8BC3', '#1E8BC3'];
      }

      const max = this.featureCollection.features.reduce((max, feature) => {
        const value = Math.abs(feature.properties.salesFormula[property]);
        if (value > max) return value;
        return max;
      }, 0);

      const range = max / nbOfRange;

      for (let idx = 0; idx < opacities.length; idx++) {
        colorCharts[idx] = {
          max: max - (range * idx),
          min: max - (range * (idx + 1)),
          color: idx > 2 ? colors[0] : colors[1],
          opacity: opacities[idx],
        };
      }

      colorCharts[0].max += 1;
      colorCharts[colorCharts.length - 1].min -= 1;

      return colorCharts;
    },
  },
  watch: {
    '$route.query': function () {
      this.getDatas(this.activeType);
    },
    colorCharts() {
      this.layers = [];
      if (this.activeType.id === 'value') {
        if (this.markers) {
          this.markers.forEach(marker => {
            if (!marker.metadata) marker.setMap(null);
          });
        }
        this.drawCircleValue();
      } else {
        this.map.data.setStyle(feature => {
          let color = 'gray';
          let opacity = 0.3;
          const property = `${this.activeCategory}Progression`;

          const needColorChart = this.colorCharts.find(colorChart => {
            const value = Math.ceil(feature.getProperty('salesFormula')[property]);
            return value <= colorChart.max && value > colorChart.min;
          });

          if (needColorChart) {
            color = needColorChart.color;
            opacity = needColorChart.opacity;
          }

          return {
            fillColor: color,
            fillOpacity: opacity,
            strokeColor: color,
            strokeWeight: 0,
          };
        });
      }
    },
  },
  methods: {
    setMap(map) {
      this.map = map;
      this.getDatas();
    },
    setType(type) {
      this.getDatas(type);
    },
    setCategory(category) {
      this.activeCategory = category;
    },
    getDatas(type = null) {
      const query = this.$route.query;
      this.message.active = false;
      this.isLoading = true;
      const promiseGetSites = query.idLivingArea ? nomenclatureGeographicService.getSitesByIdLivingArea(query.idLivingArea) : nomenclatureGeographicService.getSiteBySid(query.sidSite);

      let typeMap = null;
      if (type) {
        typeMap = type.id === 'progression' ? 1 : 2;
      } else {
        typeMap = this.activeType.id === 'progression' ? 1 : 2;
      }

      if (this.featureCollection !== null) {
        this.featureCollection.features.forEach(feature => {
          if (this.map.data.getFeatureById(feature.id)) {
            this.map.data.remove(this.map.data.getFeatureById(feature.id));
          }
        });
      }

      this.map.data.forEach((feature) => {
        this.map.data.remove(feature);
      });

      Promise
        .all([
          promiseGetSites,
          salesFormulaService.getMap(query.idMonth, query.sidSite, query.sidSalesChannel, query.idLivingArea, query.sidDepartments, typeMap),
        ])
        .then(datas => {
          const positions = [];
          this.layers = [];

          if (datas[0].length > 0) {
            datas[0].forEach(site => {
              positions.push({
                metadata: { id: 'store', label: site.label },
                position: {
                  lng: site.shape.coordinates[0],
                  lat: site.shape.coordinates[1],
                },
              });
            });
          } else {
            positions.push({
              metadata: { id: 'store', label: datas[0].label },
              position: {
                lng: datas[0].shape.coordinates[0],
                lat: datas[0].shape.coordinates[1],
              },
            });
          }

          this.center = positions[0].position;
          this.markers = positions;
          this.featureCollection = datas[1];

          if (typeMap !== 2) {
            this.map.data.addGeoJson(this.featureCollection);
            this.map.data.setStyle({
              fillColor: 'gray',
              fillOpacity: 0.3,
              strokeColor: 'gray',
              strokeWeight: 1,
            });
          }
        })
        .catch(() => {
          this.message.active = true;
          this.message.value = this.$t('SALES_FORMULA_MAP.ERROR.GET_DATAS', [query.sidSite]);
        })
        .finally(() => {
          this.isLoading = false;
          if (type) this.activeType = type;
        });
    },
    drawCircleValue() {
      const max = this.featureCollection.features.reduce((max, feature) => {
        const value = Math.abs(feature.properties.salesFormula[this.activeCategory]);
        if (value > max) return value;
        return max;
      }, 0);

      this.featureCollection.features.forEach(feature => {
        const circle = new google.maps.Circle({
          center: {
            lng: feature.geometry.coordinates[0][0][0],
            lat: feature.geometry.coordinates[0][1][1],
          },
          fillColor: '#9c27b0',
          fillOpacity: 0.5,
          strokeColor: '#240a29',
          strokeOpacity: 1,
          strokeWeight: 1,
          radius: feature.properties.formattedObject ? (Math.sqrt(feature.properties.salesFormula[this.activeCategory] / 3.14116) / Math.sqrt(max / 3.14116)) * 750 : (Math.sqrt(feature.properties.salesFormula[this.activeCategory] / 3.14116) / Math.sqrt(max / 3.14116)) * 150,
        });
        this.layers.push(circle);

        google.maps.event.addListener(circle, 'click', event => {
          const infowindow = new google.maps.InfoWindow({
            content: this.$options.filters.number(feature.properties.salesFormula[this.activeCategory], 0).toString(),
          });

          const marker = new google.maps.Marker({
            position: event.latLng,
          });

          this.markers.push(marker);
          marker.setVisible(false);
          infowindow.open(this.map, marker);
          if (marker) marker.setMap(this.map);
        });
      });
    },
  },
};
</script>

<style scoped lang="scss">
    .sales-formula-map {
        display: flex;
        flex-direction: row;
        min-height: 500px;

        .map {
            flex: 1;

            .legend {
                table {
                    width: 100%;

                    td {
                        width: calc(100% / 3);
                        text-align: center;
                        border-radius: 2px;
                    }
                }
            }
        }

        .criterions {
            flex: 0.3;

            .types {
                padding: 0 1rem 1rem 1rem;
            }
        }
    }
</style>
