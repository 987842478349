import { render, staticRenderFns } from "./SalesFormulaPyramid.vue?vue&type=template&id=241a6439&scoped=true"
import script from "./SalesFormulaPyramid.vue?vue&type=script&lang=js"
export * from "./SalesFormulaPyramid.vue?vue&type=script&lang=js"
import style0 from "./SalesFormulaPyramid.vue?vue&type=style&index=0&id=241a6439&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "241a6439",
  null
  
)

export default component.exports