<template>
    <div class="app-grid">
        <ag-grid-vue :alwaysShowVerticalScroll="showVerticalScroll"
                     :animateRows="true"
                     :autoGroupColumnDef="groupColumn"
                     :columnDefs="columns"
                     :defaultColDef="defaultColumnsDef"
                     :defaultGroupSortComparator="defaultGroupSortComparator"
                     :enableFilter="true"
                     :enableSorting="true"
                     :excelStyles="excelStyles"
                     :gridReady="onGridReady"
                     :groupDefaultExpanded="expandToLevel"
                     :groupIncludeFooter="true"
                     :groupRemoveSingleChildren="!showSingleGroup"
                     :groupSuppressBlankHeader="true"
                     :headerHeight="35"
                     :rowData="values"
                     :rowHeight="35"
                     :style="height !== 0 ? `height: ${height}px` : ''"
                     :suppressAggFuncInHeader="true"
                     :suppressMenuHide="true"
                     class="ag-theme-material md-elevation-2"
                     style="width: 100%"/>
    </div>
</template>

<script>
import { LicenseManager } from 'ag-grid-enterprise';
import { AgGridVue } from 'ag-grid-vue';

LicenseManager.setLicenseKey(process.env.VUE_APP_AGGRID_LICENCE_KEY);

export default {
  name: 'AppGrid',
  components: { AgGridVue },
  props: {
    values: {
      type: Array,
      default: () => ([]),
    },
    columns: {
      type: Array,
      default: () => ([]),
    },
    total: {
      type: Array,
      default: () => ([]),
    },
    totalPosition: {
      type: String,
      default: 'bottom',
    },
    defaultColumnsDef: {
      type: Object,
      default: null,
    },
    groupColumn: {
      type: Object,
      default: null,
    },
    expandToLevel: {
      type: Number,
      default: 0,
    },
    showSingleGroup: {
      type: Boolean,
      default: true,
    },
    showVerticalScroll: {
      type: Boolean,
      default: true,
    },
    primaryColor: {
      type: String,
      default: '#fafafa',
    },
    defaultGroupSortComparator: {
      type: Function,
      default: null,
    },
    height: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      gridApi: null,
      excelStyles: [
        {
          id: 'noDecimalPlaces',
          numberFormat: { format: '#,##0' },
          alignment: {
            horizontal: 'Right',
            vertical: 'Bottom',
          },
          data: {
            type: 'Number',
          },
        },
        {
          id: 'oneDecimalPlaces',
          numberFormat: { format: '#,##0.0' },
          alignment: {
            horizontal: 'Right',
            vertical: 'Bottom',
          },
          data: {
            type: 'Number',
          },
        },
        {
          id: 'oneDecimalPercent',
          numberFormat: { format: '#,##0.0' },
          alignment: {
            horizontal: 'Right',
            vertical: 'Bottom',
          },
          data: {
            type: 'Number',
          },
        },
        {
          id: 'twoDecimalPlaces',
          numberFormat: { format: '#,##0.00' },
          alignment: {
            horizontal: 'Right',
            vertical: 'Bottom',
          },
          data: {
            type: 'Number',
          },
        },
        {
          id: 'header',
          font: {
            color: '#FFFFFF',
            bold: true,
            size: 14,
          },
          interior: {
            pattern: 'Solid',
          },
        },
      ],
    };
  },
  created() {
    this.excelStyles.find(style => style.id === 'header').interior.color = this.primaryColor;
  },
  methods: {
    onGridReady(params) {
      this.gridApi = params.api;

      if (this.total.length > 0) {
        if (this.totalPosition === 'bottom') {
          this.gridApi.setPinnedBottomRowData(this.total);
        } else {
          this.gridApi.setPinnedTopRowData(this.total);
        }
      }

      this.$emit('load', params);
    },
  },
};
</script>

<style lang="scss">
$ag-icons-path: '~ag-grid-community/src/styles/icons/';
$ag-mat-icons-path: '~ag-grid-community/src/styles/material-icons/';

$grid-size: 5px;

@import '~ag-grid-community/src/styles/ag-grid.scss';
@import '~ag-grid-community/src/styles/ag-theme-material.scss';

.app-grid {
    width: 100%;
    overflow: hidden;
    flex: 1;
    display: flex;
    flex-direction: column;
}

.ag-row-group {
    background-color: #FAFAFA;

    .ag-row-group-indent-0 {
        display: flex;
        align-items: center;
        height: 35px;
    }
}

.ag-cell {
    text-align: left;
    display: flex;
    align-items: center;
    border-left: 1px solid #CCCCCC;
}
</style>
