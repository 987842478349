<template>
  <div class="animate-resume">
    <div class="content">
      <div class="resume">
        <app-loading v-if="isSavingTarget" />
        <app-loading v-if="isLoadingCount || isSavingTarget" />
        <div v-else>
          <app-progress-funny v-model="progression" />
          <ds-estimated-target-values :count-target="countTarget" />
        </div>

        <app-loading v-if="isLoadingAnimation" :text="$t('ACTIONS.ANIMATE_LOADING')" />
        <ds-animation-detail v-else :animation-detail="animationDetail" />
      </div>

      <div class="actions">
        <p v-if="!canAnimate">{{ $t('ANIMATE_RESUME.CANT_ANIMATE') }}</p>
        <app-button class="primary" :disabled="isSaving" @click.native="next()">
          {{ $t('ACTIONS.ANIMATE') }}
        </app-button>
        <p class="back" @click="cancel()">{{ $t('ACTIONS.CANCEL') }}</p>
      </div>

      <md-snackbar :md-active.sync="message.active" :md-duration="Infinity" md-persistent md-position="left">
        {{ message.value }}
      </md-snackbar>
    </div>
  </div>
</template>

<script>
  import moment from 'moment'
  import AppButton from '@/components/app-button/AppButton.vue'
  import AppProgressFunny from '@/components/app-progress-funny/AppProgressFunny.vue'
  import AppLoading from '@/components/app-loading/AppLoading.vue'
  import targetCountService from '@/services/targetCountService'
  import debugDolmenService from '@/services/debugDolmenService'
  import animationService from '@/services/animationService'
  import DsEstimatedTargetValues from '../components/DsEstimatedTargetValues.vue'
  import DsAnimationDetail from '../components/DsAnimationDetail.vue'

  export default {
    name: 'AnimateResume',
    components: {
      AppButton,
      DsEstimatedTargetValues,
      DsAnimationDetail,
      AppProgressFunny,
      AppLoading
    },
    data() {
      return {
        isSaving: false,
        isLoadingCount: true,
        isLoadingAnimation: true,
        animation: null,
        countTarget: null,
        progression: null,
        animationDetail: null,
        isSavingTarget: false,
        canDebugDolmen: null,
        message: {
          active: false,
          value: null
        }
      }
    },
    computed: {
      canAnimate() {
        if (process.env.VUE_APP_BUILD_ENV === 'development') {
          return true
        }
        return +this.$route.query.sidSite === +this.$userInfos.site.siteSid
      }
    },
    async created() {
      await animationService.findById(this.$route.params.sid).then(animation => {
        this.animation = animation
        this.getTargetCount()
        this.getAnimationDetail()
      })

      this.canDebugDolmen = debugDolmenService.canAnimateDolmen()
    },
    methods: {
      getTargetCount() {
        this.isLoadingCount = true

        if (this.animation) {
          targetCountService
            .getTargetCount(
              this.$route.query.mode === 'community',
              this.animation.groupTypes,
              this.animation.sidSite,
              this.animation.sidSalesChannel,
              this.animation.nomenclatures,
              this.animation.idSegType || null,
              this.animation.segmentsOly || null,
              this.animation.segmentsCdv || null,
              this.animation.activationEasinessAreas || null,
              this.animation.neighborhoodTypes || null,
              this.animation.shape || null,
              this.animation.startDate || null,
              this.animation.endDate || null,
              this.animation.startRankTopCustomers || null,
              this.animation.sid,
              0,
              this.animation.ouiPubZone
            )
            .then(count => {
              this.countTarget = count[0]
              this.isLoadingCount = false

              if (this.$route.query.mode === 'customers') {
                const prog = this.countTarget.numberTargetClients / this.countTarget.numberTargetClientsTotal

                /* eslint-disable */
                if (prog < 0.05) return (this.progression = 0.9)
                if (prog <= 0.1 > 0.05) return (this.progression = 0.7)
                if (prog <= 0.15 > 0.1) return (this.progression = 0.5)
                if (prog <= 0.2 > 0.15) return (this.progression = 0.3)
                if (prog > 0.2) return (this.progression = 0.1)
              } else {
                if (this.animation && this.animation.groupTypes && this.animation.groupTypes.length > 0)
                  return (this.progression = this.animation.groupTypes.length)
                else return (this.progression = 999)
              }
              return (this.progression = 0)
            })
        }
      },
      getAnimationDetail() {
        if (this.animation) {
          this.isLoadingAnimation = true
          animationService.findDetailById(this.animation.sid).then(animationDetail => {
            this.animationDetail = []
            this.isLoadingAnimation = false
            Object.keys(animationDetail).forEach(key => {
              if (key === 'DTS') {
                this.animationDetail.push({
                  name: key,
                  value: moment(animationDetail['DTS'][0]).format('DD/MM/YYYY') + ' - ' + moment(animationDetail['DTE'][0]).format('DD/MM/YYYY')
                })
              } else if (key !== 'DTE') {
                this.animationDetail.push({ name: key, value: animationDetail[key].join(', ') })
              }
            })
          })
        }
      },
      next() {
        this.isSavingTarget = true
        if (this.animation.startDate)
          this.animation.startDate = moment(this.animation.startDate)
            .format('YYYY-MM-DD')
            .toString()
        if (this.animation.endDate)
          this.animation.endDate = moment(this.animation.endDate)
            .format('YYYY-MM-DD')
            .toString()

        targetCountService
          .getTargetCount(
            this.$route.query.mode === 'community',
            this.animation.groupTypes,
            this.animation.sidSite,
            this.animation.sidSalesChannel,
            this.animation.nomenclatures,
            this.animation.idSegType || null,
            this.animation.segmentsOly || null,
            this.animation.segmentsCdv || null,
            this.animation.activationEasinessAreas || null,
            this.animation.neighborhoodTypes || null,
            this.animation.shape || null,
            this.animation.startDate || null,
            this.animation.endDate || null,
            this.animation.startRankTopCustomers || null,
            this.animation.sid,
            1,
            this.animation.ouiPubZone
          )
          .then(count => {
            this.postSaveMessage(count)
          })
          .catch(() => {
            this.message.active = true
            this.message.value = this.$t('ACTIONS.SAVE_FAIL')
            this.isSavingTarget = false
          })
      },
      postSaveMessage(result) {
        if (result !== null) {
          this.message.active = true
          this.message.value = this.$t('ACTIONS.SAVE_SUCCESS')
        } else {
          this.message.active = true
          this.message.value = this.$t('ACTIONS.SAVE_FAIL')
        }

        this.isSavingTarget = false
        const parameter = {
          targetId: this.animation.sid,
          targetTextualRepr: this.animation.name
        }

        parameter.numberOfContactableByBR = this.animation.communicationChannel === 'br' ? this.countTarget.numberTargetClients : 0
        parameter.numberOfContactableBySMS = this.animation.communicationChannel === 'sms' ? this.countTarget.numberTargetClients : 0
        parameter.numberOfContactableByPUSH = 0

        document.webkitExitFullscreen()
        //console.log('DOLMEN PARAMETER', parameter);
        window.parent.postMessage(JSON.stringify(parameter), '*')
      },
      cancel() {
        this.$router.push({ name: 'animate-store', query: this.$route.query })
      }
    }
  }
</script>

<style scoped lang="scss">
  @import '~@/styles/_colors';
  @import '~@/styles/_variables';
  @import '~@/styles/_mixins';

  .animate-resume {
    @include display-flex(center, center);
    flex: 1;
    background: $color-grey-light-2;
    display: flex;
    flex-direction: column;
    text-align: center;
    .content {
      @include display-flex(center, space-between, column);
      overflow: auto;
      width: 30%;
      background-color: #ffffff;
      border-radius: $border-radius;
      height: 90%;
      .resume {
        width: 100%;
        .app-progress-funny {
          padding-top: 1rem;
        }
      }
    }

    .actions {
      @include display-flex(center, center, column);
      width: 100%;
      padding: 0 1em;
      .primary {
        @include display-flex(center, center);
        width: 100%;
      }

      p {
        font-size: 14px;
        color: $color-grey-dark;
        margin: 0.5em 0;
      }

      .back {
        cursor: pointer;
        text-transform: uppercase;
        color: $color-bad;
        &:hover {
          font-weight: 500;
        }
      }
    }
  }
</style>
