<template>
    <div class="app-splashscreen">
        <div class="title">
            <h1>NOUVELLE VERSION !</h1>

            <p>
                Regardez les tutoriels et l'aide en ligne
                <md-icon>help</md-icon>
                pour en savoir plus en haut à droite du site :-)
            </p>
        </div>

        <div class="content">
            <img :src="logo"
                 class="splashscreen-logo">
            <div class="fireworks">
                <div class="firework">
                    <div class="explosion">
                        <div class="spark green"></div>
                    </div>
                    <div class="explosion">
                        <div class="spark blue"></div>
                    </div>
                    <div class="explosion">
                        <div class="spark red"></div>
                    </div>
                    <div class="explosion">
                        <div class="spark red"></div>
                    </div>
                    <div class="explosion">
                        <div class="spark yellow"></div>
                    </div>
                    <div class="explosion">
                        <div class="spark blue"></div>
                    </div>
                    <div class="explosion">
                        <div class="spark green"></div>
                    </div>
                    <div class="explosion">
                        <div class="spark yellow"></div>
                    </div>
                </div>
                <div class="firework" style="margin-top: -70px">
                    <div class="explosion">
                        <div class="spark green"></div>
                    </div>
                    <div class="explosion">
                        <div class="spark blue"></div>
                    </div>
                    <div class="explosion">
                        <div class="spark red"></div>
                    </div>
                    <div class="explosion">
                        <div class="spark red"></div>
                    </div>
                    <div class="explosion">
                        <div class="spark yellow"></div>
                    </div>
                    <div class="explosion">
                        <div class="spark blue"></div>
                    </div>
                    <div class="explosion">
                        <div class="spark green"></div>
                    </div>
                    <div class="explosion">
                        <div class="spark yellow"></div>
                    </div>
                </div>
                <div class="firework">
                    <div class="explosion">
                        <div class="spark green"></div>
                    </div>
                    <div class="explosion">
                        <div class="spark blue"></div>
                    </div>
                    <div class="explosion">
                        <div class="spark red"></div>
                    </div>
                    <div class="explosion">
                        <div class="spark red"></div>
                    </div>
                    <div class="explosion">
                        <div class="spark yellow"></div>
                    </div>
                    <div class="explosion">
                        <div class="spark blue"></div>
                    </div>
                    <div class="explosion">
                        <div class="spark green"></div>
                    </div>
                    <div class="explosion">
                        <div class="spark yellow"></div>
                    </div>
                </div>
                <div class="firework" style="margin-top: 70px">
                    <div class="explosion">
                        <div class="spark green"></div>
                    </div>
                    <div class="explosion">
                        <div class="spark blue"></div>
                    </div>
                    <div class="explosion">
                        <div class="spark red"></div>
                    </div>
                    <div class="explosion">
                        <div class="spark red"></div>
                    </div>
                    <div class="explosion">
                        <div class="spark yellow"></div>
                    </div>
                    <div class="explosion">
                        <div class="spark blue"></div>
                    </div>
                    <div class="explosion">
                        <div class="spark green"></div>
                    </div>
                    <div class="explosion">
                        <div class="spark yellow"></div>
                    </div>
                </div>
                <div class="firework">
                    <div class="explosion">
                        <div class="spark green"></div>
                    </div>
                    <div class="explosion">
                        <div class="spark blue"></div>
                    </div>
                    <div class="explosion">
                        <div class="spark red"></div>
                    </div>
                    <div class="explosion">
                        <div class="spark red"></div>
                    </div>
                    <div class="explosion">
                        <div class="spark yellow"></div>
                    </div>
                    <div class="explosion">
                        <div class="spark blue"></div>
                    </div>
                    <div class="explosion">
                        <div class="spark green"></div>
                    </div>
                    <div class="explosion">
                        <div class="spark yellow"></div>
                    </div>
                </div>
                <div class="firework" style="margin-top: -70px">
                    <div class="explosion">
                        <div class="spark green"></div>
                    </div>
                    <div class="explosion">
                        <div class="spark blue"></div>
                    </div>
                    <div class="explosion">
                        <div class="spark red"></div>
                    </div>
                    <div class="explosion">
                        <div class="spark red"></div>
                    </div>
                    <div class="explosion">
                        <div class="spark yellow"></div>
                    </div>
                    <div class="explosion">
                        <div class="spark blue"></div>
                    </div>
                    <div class="explosion">
                        <div class="spark green"></div>
                    </div>
                    <div class="explosion">
                        <div class="spark yellow"></div>
                    </div>
                </div>
                <div class="firework">
                    <div class="explosion">
                        <div class="spark green"></div>
                    </div>
                    <div class="explosion">
                        <div class="spark blue"></div>
                    </div>
                    <div class="explosion">
                        <div class="spark red"></div>
                    </div>
                    <div class="explosion">
                        <div class="spark red"></div>
                    </div>
                    <div class="explosion">
                        <div class="spark yellow"></div>
                    </div>
                    <div class="explosion">
                        <div class="spark blue"></div>
                    </div>
                    <div class="explosion">
                        <div class="spark green"></div>
                    </div>
                    <div class="explosion">
                        <div class="spark yellow"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'AppSplashscreenNewVersion',
  props: {
    logo: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped lang="scss">
    @import "~@/styles/_mixins";

    $square: 20px;
    $duration: 8s;

    .app-splashscreen {
        /*background-color: #446883;*/
        $grid: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NUY1RjdCNkVFOTkyMTFFMjhGMDdFMEEyQjQ0NDQ1RjYiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NUY1RjdCNkZFOTkyMTFFMjhGMDdFMEEyQjQ0NDQ1RjYiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo1RjVGN0I2Q0U5OTIxMUUyOEYwN0UwQTJCNDQ0NDVGNiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo1RjVGN0I2REU5OTIxMUUyOEYwN0UwQTJCNDQ0NDVGNiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PgQ7RJEAAABkSURBVHjaYvj//z8DEDtDaRAWpwebEWQpAwODIBC/Z4AA+rAHysdMDAMEWIBYHOp9caiYKJI8zdggi19Cw/8lkiTN2aNBPRrUo0E9GtSjQT0a1KNBPRrUo0E9GtSjQU05GyDAAD0qLnnflqfDAAAAAElFTkSuQmCC");
        $lightBlue: rgba(40, 131, 182, 1);
        $darkBlue: rgba(8, 70, 136, 1);

        color: white;
        background-color: #2883B6;
        background-image: $grid, radial-gradient(ellipse at center, $lightBlue 0%, $darkBlue 100%);
        background-repeat: repeat, no-repeat;

        height: 100%;
        width: 100%;
        overflow: hidden;
        @include display-flex(center, center, column);

        .splashscreen-logo {
            width: 30%;
        }

        .title {
            height: 5em;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            h1 {
                color: #FFF;
                font-family: sans-serif;
                margin: 0;
                letter-spacing: 9.5px;
            }
            p {
                font-size: 1.2em;
                img {
                    height: 1.5em;
                }
            }
        }

        .content {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 2em;
        }

        .fireworks {
            width: 100vw;
            display: flex;
            align-items: center;
            justify-content: center;

        }
        .fireworks .firework {
            position: relative;
            top: 0px;
            left: 0px;
            margin: 0px 50px;
        }
        .fireworks .firework:before {
            content: "";
            display: block;
            border-radius: 5px;
            background-color: skyblue;
            width: 5px;
            height: 0px;
            will-change: transform;
            transform: translateY(1000px);
            animation: fireworkstart 2s ease-out infinite;

        }
        .fireworks .firework .explosion {
            position: absolute;
            top: 0;
            width: 5px;
            height: 20px;
            opacity: 0;
            transform-origin: top center;
            will-change: transform;
            animation: explosion 0.7s linear 2s infinite;
        }

        .fireworks .firework .explosion:nth-child(1) {
            transform: rotate(0deg);
        }
        .fireworks .firework .explosion:nth-child(2) {
            transform: rotate(90deg);
        }
        .fireworks .firework .explosion:nth-child(3) {
            transform: rotate(180deg);
        }
        .fireworks .firework .explosion:nth-child(4) {
            transform: rotate(-90deg);
        }
        .fireworks .firework .explosion:nth-child(5) {
            transform: rotate(45deg);
        }
        .fireworks .firework .explosion:nth-child(6) {
            transform: rotate(-45deg);
        }
        .fireworks .firework .explosion:nth-child(7) {
            transform: rotate(135deg);
        }
        .fireworks .firework .explosion:nth-child(8) {
            transform: rotate(225deg);
        }

        .fireworks .firework .explosion .spark {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            border-radius: 5px;
            will-change: transform;
            animation: explosion2 0.7s ease-in-out 2s infinite;
        }

        .fireworks .firework .explosion .spark.red {
            background-color: #E91E63;
        }
        .fireworks .firework .explosion .spark.blue {
            background-color: skyblue;
        }
        .fireworks .firework .explosion .spark.green {
            background-color: limegreen;
        }
        .fireworks .firework .explosion .spark.yellow {
            background-color: yellow;
        }
        .fireworks .firework .explosion .spark.purple {
            background-color: purple;
        }
        @keyframes fireworkstart {
            0% {
                height: 0px;
                transform: translateY(1000px);
            }
            50% {
                height: 50px;
            }
            75% {
                height: 30px;
            }
            100% {
                height: 0;
                transform: translateY(0);
            }
        }

        @keyframes explosion {
            0% {
                height: 0px;
                opacity: 0;
            }
            50% {
                height: 25px;
                opacity: 1;
            }
            100% {
                height: 0px;
                opacity: 0;
            }
        }
        @keyframes explosion2 {
            0% {
                transform: translateY(0px);
            }
            50% {
                transform: translateY(5px);
            }
            75% {
                transform: translateY(50px);
            }
            100% {
                transform: translateY(70px);
            }
        }
    }
</style>
