<template>
    <div class="app-loading">
        <md-progress-bar v-if="!text" class="md-accent"
                         md-mode="indeterminate"/>

        <p v-else class="loading-text">{{ text }} <span>.</span><span>.</span><span>.</span></p>
    </div>
</template>

<script>
export default {
  name: 'AppLoading',
  props: {
    text: {
      type: String,
      default: null,
    },
  },
};
</script>

<style scoped lang="scss">
    @import "~@/styles/_colors";
    @import "~@/styles/_variables";

    p {
        color: $color-grey-dark;
        font-size: 1.2rem;
        text-align: center;
    }

    @keyframes blink {
        0% {
            opacity: .2;
        }
        20% {
            opacity: 1;
        }
        100% {
            opacity: .2;
        }
    }

    .loading-text span {
        font-size: 2em;
        animation-name: blink;
        animation-duration: 1.4s;
        animation-iteration-count: infinite;
        animation-fill-mode: both;
    }

    .loading-text span:nth-child(2) {
        animation-delay: .2s;
    }

    .loading-text span:nth-child(3) {
        animation-delay: .4s;
    }
</style>
