<template>
    <div class="analyze-geo-marketing">
        <div class="header">
            <app-title :title="$t($route.meta.title)">
                <app-period
                        :from="5"
                        :to="1">
                </app-period>
            </app-title>
            <div v-if="!(isNational || isLivingArea)" class="options">
                <span>{{ $t('ANALYZE_GEO_MARKETING.COMPARE.LABEL') }}</span>
                <md-switch
                        class="switch-global-hyper"
                        v-if="!isGlobalHyper"
                        v-model="compareWithGlobalHyper">
                    {{ $t('ANALYZE_GEO_MARKETING.COMPARE.GLOBAL_SITE') }}
                </md-switch>
                <md-switch
                        v-if="!isNational"
                        v-model="compareWithNational">
                    {{ $t('ANALYZE_GEO_MARKETING.COMPARE.NATIONAL') }}
                </md-switch>
            </div>
        </div>
        <div class="grid">
            <ds-activation-easiness-chart
                    :with-global-hyper="compareWithGlobalHyper"
                    :is-living-area="isLivingArea"
                    :with-national="compareWithNational"
                    class="activation-easiness-chart">
            </ds-activation-easiness-chart>

            <ds-activation-easiness-area-chart
                    :with-global-hyper="compareWithGlobalHyper"
                    :is-living-area="isLivingArea"
                    :with-national="compareWithNational"
                    class="activation-easiness-area-chart">
            </ds-activation-easiness-area-chart>

            <ds-neighborhood-type-chart
                    :with-global-hyper="compareWithGlobalHyper"
                    :is-living-area="isLivingArea"
                    :with-national="compareWithNational"
                    class="neighborhood-type-chart">
            </ds-neighborhood-type-chart>
        </div>
    </div>
</template>

<script>
import AppPeriod from '@/components/app-period/AppPeriod.vue';
import AppTitle from '@/components/app-title/AppTitle.vue';
import DsActivationEasinessAreaChart from './components/ActivationEasinessAreaChart.vue';
import DsActivationEasinessChart from './components/ActivationEasinessChart.vue';
import DsNeighborhoodTypeChart from './components/NeighborhoodTypeChart.vue';

export default {
  name: 'analyze-geo-marketing',
  components: {
    AppTitle,
    AppPeriod,
    DsNeighborhoodTypeChart,
    DsActivationEasinessAreaChart,
    DsActivationEasinessChart,
  },
  data() {
    return {
      compareWithGlobalHyper: false,
      compareWithNational: false,
    };
  },
  watch: {
    '$route.query': function () {
      this.compareWithGlobalHyper = false;
      this.compareWithNational = false;
    },
  },
  computed: {
    isGlobalHyper() {
      return this.$route.query.sidDepartments === undefined && this.$route.query.sidSalesChannel === 99 && this.$route.query.idLivingArea === undefined;
    },
    isLivingArea() {
      return this.$route.query.sidSite === undefined && this.$route.query.sidSalesChannel === 50;
    },
    isNational() {
      return this.$route.query.sidSite === undefined && this.$route.query.idLivingArea === undefined;
    },
  },
};
</script>

<style scoped lang="scss">
    @import "~@/styles/_mixins";
    @import "~@/styles/_colors";

    .analyze-geo-marketing {
        padding : 0 2rem 2rem 2rem;

        .header {
            @include display-flex(center, space-between);

            .options {
                @include display-flex(center, flex-start);

                span {
                    margin-right : 15px;
                }

                :global(.switch-global-hyper.md-switch.md-theme-default.md-checked .md-switch-thumb) {
                    background-color : $color-global-hyper;
                }

                :global(.switch-global-hyper.md-switch.md-theme-default.md-checked .md-switch-container) {
                    background-color : $color-global-hyper-with-opacity;
                }
            }
        }

        .grid {
            display               : grid;
            grid-gap              : 1rem;
            grid-template-columns : repeat(2, 1fr);

            .activation-easiness-chart {
                grid-column : 1;
                grid-row    : 2;
            }

            .activation-easiness-area-chart {
                grid-column : 1;
                grid-row    : 1;
            }

            .neighborhood-type-chart {
                grid-column : 2;
                grid-row    : 1 / 3;
            }
        }
    }
</style>
