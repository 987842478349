<template>
    <canvas :height="height"
            :width="width"
            ref="doughnutChart">
    </canvas>
</template>

<script>
import Chart from 'chart.js';
import 'chartjs-plugin-datalabels';

export default {
  name: 'AppDoughnutChart',
  props: {
    width: {
      type: Number,
      default: 300,
    },
    height: {
      type: Number,
      default: 300,
    },
    labels: {
      type: Array,
      default: () => ([]),
    },
    datasets: {
      type: Array,
      default: () => ([]),
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      context: null,
      chart: null,
    };
  },
  mounted() {
    this.initChart();
  },
  watch: {
    datasets() {
      this.chart.data.datasets = this.datasets;
      this.chart.update();
    },
    options() {
      this.chart.options = this.options;
      this.chart.update();
    },
  },
  methods: {
    initChart() {
      this.context = this.$refs.doughnutChart;
      this.chart = new Chart(
        this.context,
        {
          type: 'doughnut',
          data: {
            labels: this.labels,
            datasets: this.datasets,
          },
          options: this.options,
        },
      );
    },
  },
};
</script>
