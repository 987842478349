import axios from 'axios'
import Vue from 'vue'

class SalesFormulaService {
  constructor() {
    this.httpClient = axios.create({
      baseURL: `${process.env.VUE_APP_BACKEND}/sales-formula`,
    });
  }

  async getPyramid(idMonth, sidSite = 9999, sidSalesChannel = 99, idLivingArea = '9999', sidDepartment = 'ALL') {
    const params = {
      sidSite,
      sidSalesChannel,
      idLivingArea,
      sidDepartment: sidDepartment === 'ALL' ? 999 : sidDepartment,
    };

    if (idMonth) {
      params.idMonth = idMonth;
    }

    const token = await Vue.prototype.$user.getIdToken()
    return this.httpClient.get('/pyramid', { params, headers: { Authorization: `Bearer ${token}` } }).then(res => res.data);
  }

  async getMap(idMonth, sidSite = 9999, sidSalesChannel = 99, idLivingArea = '9999', sidDepartment = 'ALL', mapType = 1) {
    const params = {
      sidSite,
      sidSalesChannel,
      idLivingArea,
      sidDepartment: sidDepartment === 'ALL' ? 999 : sidDepartment,
      mapType,
      idMonth,
    };

    const token = await Vue.prototype.$user.getIdToken()
    return this.httpClient.get('/map', { params, headers: { Authorization: `Bearer ${token}` } }).then(res => res.data);
  }

  async getMatrix(idType, idMonth, sidSite = 9999, sidSalesChannel = 99, idLivingArea = '9999') {
    const params = {
      sidSite,
      sidSalesChannel,
      idLivingArea,
      idType,
      idMonth,
    };

    const token = await Vue.prototype.$user.getIdToken()
    return this.httpClient.get('/matrix', { params, headers: { Authorization: `Bearer ${token}` } }).then(res => res.data);
  }
}

export default new SalesFormulaService();
