import axios from 'axios'
import Vue from 'vue'

class TargetCountService {
  constructor() {
    this.httpClient = axios.create({
      baseURL: `${process.env.VUE_APP_BACKEND}/target-count`,
    });
  }

  async getTargetCount(isCommunity, groupTypes, sidSite, sidSalesChannel, nomenclatures, idSegType, segmentsOly, segmentsCdv, activationEasinessAreas, neighborhoodTypes, shape, startDate, endDate, startRankTopCustomers, sidTarget, idCountORSave = 0, ouiPubZone= false) {
    const body = {
      isCommunityTarget: isCommunity
    }
    const url = `/sites/${sidSite}/salesChannels/${sidSalesChannel}/idCountORSave/${idCountORSave}`

    if (sidTarget !== undefined) {
      body.groupTypes = groupTypes || null
      body.sidDepartment = nomenclatures || 999
      body.idSegType = idSegType || null
      body.sidSegmentOly = segmentsOly || null
      body.sidSegmentCdv = segmentsCdv || null
      body.sidActivationEasy = activationEasinessAreas || null
      body.sidSegmentQuartier = neighborhoodTypes || null
      body.dimensionIncludedAreaValue = shape || null
      body.startDate = startDate || null
      body.endDate = endDate || null
      body.rank = startRankTopCustomers || null
      body.sidTarget = sidTarget
      body.ouiPubZone = ouiPubZone
    }

    const token = await Vue.prototype.$user.getIdToken()
    return this.httpClient
      .post(url, body, { headers: { Authorization: `Bearer ${token}` }})
      .then(res => res.data)
  }
}

export default new TargetCountService()
