import axios from 'axios'
import moment from 'moment';
import Vue from 'vue'

class IndicatorsService {
  constructor() {
    this.httpClient = axios.create({
      baseURL: `${process.env.VUE_APP_BACKEND}/global`,
    });
  }

  async findGlobalIndicators(sidSite = 9999, sidSalesChannel = 99, idLivingArea = '9999', sidDepartment = 'ALL', startDate, endDate, comparisonType) {
    const params = {
      sidSite,
      sidSalesChannel,
      idLivingArea,
      sidDepartment: sidDepartment === 'ALL' ? 999 : sidDepartment,
      startDate: moment(startDate).format('YYYY-MM-DD'),
      endDate: moment(endDate).format('YYYY-MM-DD'),
      comparisonType,
    };

    const token = await Vue.prototype.$user.getIdToken()
    return this.httpClient.get('/indicators', { params, headers: { Authorization: `Bearer ${token}` }}).then(res => res.data);
  }
}

export default new IndicatorsService();
