<template>
  <div class="container">
    <app-loading v-if="isLoading || isSaving" />
    <div class="title">{{ $t('COMMUNITY_SETTINGS.TITLE') }}</div>
    <div v-if="!isLoading" class="container-cards">
      <div class="cards">
        <div v-for="(community, idx) in communities"
             :key="'community' + idx"
             :class="[{'active': community.isActive, 'inactive': !community.isActive},'card']"
             @click="updateActiveCommunity(community)">
          <div class="card-title">{{ community.communityLabel }}</div>

          <div class="card-value">
            {{ community.isActive ? 'Active' : 'Inactive' }}
            <img v-if="!community.isActive" src="@/assets/toggle.svg" alt="communities parameters" class="button-image">
            <img v-if="community.isActive" src="@/assets/toggle-on.svg" alt="communities parameters" class="button-image">
          </div>
        </div>
      </div>
    </div>

    <md-snackbar
      :md-active.sync="message.active"
      :md-duration="Infinity"
      md-persistent
      md-position="left">
      {{ message.value }}
    </md-snackbar>

    <div v-if="!isLoading" class="actions">
      <app-button
        :disabled="false"
        @click.native="back">
        {{ $t('ACTIONS.BACK') }}
      </app-button>

      <app-button
        :disabled="updatedCommunities.length === 0"
        :class="[{'isSaving': isSaving},{'primary': !isSaving}]"
        @click.native="validate">
        {{ $t('ACTIONS.VALID') }}
      </app-button>
    </div>
  </div>
</template>

<script>

import Vue from 'vue'
import communitiesService from '@/services/communitiesService'
import AppButton from '@/components/app-button/AppButton'
import AppLoading from '@/components/app-loading/AppLoading'
import { isCentral } from '@/helpers/userUtils'

export default {
  name: 'CommunitySettings',
  components: { AppButton, AppLoading },
  data() {
    return {
      isLoading: false,
      isSaving: false,
      communities: [],
      message: {
        active: false,
        value: null,
      },
      siteSid: !isCentral(Vue.prototype.$userInfos) ? Vue.prototype.$userInfos.site.siteSid : null,
      updatedCommunities: []
    }
  },
  created() {
    this.getDatas()
  },
  methods: {
    getDatas() {
      this.isLoading = true
      communitiesService.findCommunitiesBySite(this.siteSid, null, true)
        .then(communities => {
          this.isLoading = false
          this.communities = communities.map(community => ({
            ...community,
            isSelected: false
          }))
        })
    },
    updateActiveCommunity(community) {
      community.isActive = !community.isActive
      community.updated ? community.updated = !community.updated : community.updated = true
      this.updatedCommunities = this.communities.filter(community => community.updated)
    },
    back() {
      this.$router.push({ name: 'animate-home', params: this.$route.params, query: this.$route.query })
    },
    validate() {
      this.isSaving = true
      communitiesService.updateCommunities(this.updatedCommunities, this.siteSid)
        .then(() => {
          this.message.active = true
          this.message.value = this.$t('COMMUNITY_SETTINGS.SAVE_SUCCESS')
          this.$router.push({ name: 'animate-home', params: this.$route.params, query: this.$route.query })
        })
        .catch(() => {
         this.message.active = true
         this.message.value = this.$t('ANIMATE_MEDIA.ERROR.SAVING')
        })
        .finally(() => this.isSaving = false)
    }
  },
}
</script>

<style scoped lang="scss">
.container {
  border-top: 1px solid #E0E0E0;
  flex: 1;
  background: white;
  border-radius: 2px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  font-weight: bold;

  .title {
    padding: 2rem;
    margin: 0;
    display: flex;
    justify-content: center;
    font-size: 16px;
  }
  .container-cards {
    padding: 1em;
    width: 100%;
    height: 100%;
    overflow: auto;

    .cards {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 1vw;
      margin-left: 20%;
      width: 60%;
      .card {
        height: 4em;
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 1em;
        box-shadow: $box-shadow-hight;
        transition: .25s ease all;
        justify-content: space-between;

        &.active {
          color: #2055cd;
        }
        &.inactive {
          background-color: #ededed;
          color: #8b8787;
        }

        p {
          margin: 0;
          text-transform: lowercase;
        }

        p:first-letter {
          text-transform: uppercase;
        }

        .card-title {
          height: 100%;
          font-size: 16px;
        }
        .card-value {
          height: 100%;
          font-size: 16px;
        }

        .right {
          height: 100%;
          display: flex;
          align-items: center;
          width: 3.1em;
          text-align: right;
          justify-content: flex-end;
        }

        .weight {
          font-weight: bold;
          color: $primary-color;
          font-size: .8em;
        }
      }
    }
  }

  .actions {
    padding: 2rem;
    justify-content: end;
    display: flex;

    button:not(:last-child) {
      margin-right: 1em;
    }

    .isSaving {
      cursor: none;
      background-color: #EFEFEF;
    }
  }
}
</style>
