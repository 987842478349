import axios from 'axios'
import Vue from 'vue'

class CommunitiesService {
  constructor() {
    this.httpClient = axios.create({
      baseURL: `${process.env.VUE_APP_BACKEND}/communities`,
    })
  }

  async findCommunitiesBySite(sidSite = 9999, sidTarget = null, isSettings = false) {
    const token = await Vue.prototype.$user.getIdToken()

    const params = {
      sidSite,
      sidTarget,
      isSettings
    }
    return this.httpClient.get('/', {
      params,
      headers: {Authorization: `Bearer ${token}`}
    }).then(res =>  res.data)
  }

  async updateCommunities(params, siteSid) {
    const token = await Vue.prototype.$user.getIdToken()
    const body = { siteSid, communities: params }

    return this.httpClient
      .post('/', body, {headers: { Authorization: `Bearer ${token}` }})
      .then(res => res.data)
  }
}

export default new CommunitiesService()
