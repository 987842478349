<template>
  <div class="google-maps">
    <div id="maps"></div>
    <slot></slot>
  </div>
</template>

<script>
  /* global google */
  const icon = require('./assets/marker-auchan.svg')
  const defaultMapStyle = [
    { elementType: 'geometry', stylers: [{ color: '#f5f5f5' }] },
    {
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off'
        }
      ]
    },
    { elementType: 'labels.text.fill', stylers: [{ color: '#616161' }] },
    { elementType: 'labels.text.stroke', stylers: [{ color: '#f5f5f5' }] },
    {
      featureType: 'administrative.land_parcel',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#bdbdbd' }]
    },
    {
      featureType: 'poi',
      elementType: 'geometry',
      stylers: [{ color: '#eeeeee' }]
    },
    {
      featureType: 'poi',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#757575' }]
    },
    {
      featureType: 'poi.park',
      elementType: 'geometry',
      stylers: [{ color: '#e5e5e5' }]
    },
    {
      featureType: 'poi.park',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#9e9e9e' }]
    },
    {
      featureType: 'road',
      elementType: 'geometry',
      stylers: [{ color: '#ffffff' }]
    },
    {
      featureType: 'road.arterial',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#757575' }]
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry',
      stylers: [{ color: '#dadada' }]
    },
    {
      featureType: 'road.highway',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#616161' }]
    },
    {
      featureType: 'road.local',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#9e9e9e' }]
    },
    {
      featureType: 'transit.line',
      elementType: 'geometry',
      stylers: [{ color: '#e5e5e5' }]
    },
    {
      featureType: 'transit.station',
      elementType: 'geometry',
      stylers: [{ color: '#eeeeee' }]
    },
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [{ color: '#c9c9c9' }]
    },
    {
      featureType: 'water',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#9e9e9e' }]
    }
  ]

  export default {
    name: 'GoogleMaps',
    props: {
      center: {
        type: Object,
        default: null
      },
      markers: {
        type: Array,
        default: null
      },
      layers: {
        type: Array,
        default: () => []
      },
      mapStyle: {
        type: Array,
        default: null
      }
    },
    data() {
      return {
        map: null,
        googleCenter: null,
        googleMarker: [],
        googleLayers: [],
        competitors: []
      }
    },
    mounted() {
      this.initMap()
    },
    watch: {
      center() {
        this.setCenter()
      },
      markers() {
        this.putMarker()
      },
      layers() {
        this.putLayers()
      }
    },
    methods: {
      initMap() {
        if (this.center === undefined || this.center === null) {
          if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
              position => {
                this.googleCenter = {
                  lat: position.coords.latitude,
                  lng: position.coords.longitude
                }
                this.map.setCenter(this.googleCenter)
              },
              () => {
                this.googleCenter = { lat: 50.649828, lng: 3.151131 }
              }
            )
          } else {
            this.googleCenter = { lat: 50.649828, lng: 3.151131 }
          }
        }

        this.map = new google.maps.Map(document.getElementById('maps'), {
          center: this.googleCenter,
          zoom: 12,
          streetViewControl: false,
          scaleControl: false,
          rotateControl: false,
          fullscreenControl: false,
          mapTypeControl: false,
          zoomControl: false,
          styles: this.mapStyle || defaultMapStyle
        })

        this.map.controls[google.maps.ControlPosition.LEFT_BOTTOM].push(document.getElementById('zoom-control'))
        this.map.controls[google.maps.ControlPosition.LEFT_BOTTOM].push(document.getElementById('center-me-control'))
        this.map.controls[google.maps.ControlPosition.LEFT_BOTTOM].push(document.getElementById('competitors-dialog'))

        this.map.controls[google.maps.ControlPosition.LEFT_TOP].push(document.getElementById('drawing-manager-control'))
        this.map.controls[google.maps.ControlPosition.LEFT_TOP].push(document.getElementById('free-drawing-control'))
        this.map.controls[google.maps.ControlPosition.LEFT_TOP].push(document.getElementById('manual-add-control'))
        this.map.controls[google.maps.ControlPosition.LEFT_TOP].push(document.getElementById('manual-remove-control'))

        this.map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(document.getElementById('legend'))

        this.$emit('load', this.map)
      },
      putMarker() {
        if (this.googleMarker !== null && this.googleMarker.length > 0) {
          this.googleMarker.forEach(feature => feature.setMap(null))
        }

        this.markers.forEach(marker => {
          const infowindow = new google.maps.InfoWindow({
            content: marker.metadata ? `AUCHAN - ${marker.metadata.label}` || '' : 'AUCHAN'
          })

          const newMarker = new google.maps.Marker({
            position: {
              lat: marker.position.lat,
              lng: marker.position.lng
            },
            icon: {
              url: icon,
              scaledSize: new google.maps.Size(41, 41),
              labelOrigin: new google.maps.Point(0, -10)
            },
            animation: google.maps.Animation.DROP
          })

          newMarker.addListener('mouseover', function() {
            infowindow.open(this.map, newMarker)
          })

          newMarker.setZIndex(99)
          newMarker.setMap(this.map)
          this.googleMarker.push(newMarker)
        })
      },
      putLayers() {
        this.googleLayers.filter(layer => this.layers.indexOf(layer) === -1).forEach(layer => layer.setMap(null))

        this.layers.forEach(layer => layer.setMap(this.map))
        this.googleLayers = this.layers
      },
      setCenter() {
        this.googleCenter = this.center

        this.map.setZoom(12)
        this.map.setCenter(this.googleCenter)
      }
    }
  }
</script>

<style scoped lang="scss">
  .google-maps {
    &,
    #maps {
      height: 100%;
    }
  }
</style>
