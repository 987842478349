<template>
  <div :class="['hypothesis-card', { selected: selected }]" @click="selectScenario">
    <div v-if="selected" class="material-icons check">check_circle</div>
    <div class="content">
      <div class="badges">
        <div v-if="scenario.sourceTheme === 'Préconisé'" class="badge recommended">
          <span class="material-icons">workspace_premium</span>
          Préconisée
        </div>
        <div v-if="scenario.sourceTheme === 'Personnalisé'" class="badge personalized">
          <span class="material-icons">bookmark</span>
          Personnalisée
        </div>
        <div class="badge dispenser">{{ scenario.distributorLabel }}</div>
      </div>
      <div class="name">
        {{ scenario.sourceTheme === 'Préconisé' && scenario.month ? `${scenario.month} - ` : '' }}{{ scenario.scenarioLabel }} -
        {{ scenario.scenarioSize }}
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'HypothesisCard',
    props: {
      scenario: {
        type: Object,
        default: () => {}
      },
      selected: {
        type: Boolean,
        default: false
      }
    },
    emits: ['selectHypothesis'],
    methods: {
      selectScenario() {
        this.$emit('selectHypothesis', this.scenario)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .hypothesis-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.4rem;
    padding: 1rem 3rem;
    position: relative;

    .check {
      position: absolute;
      left: 0.5rem;
      font-size: 1.8rem;
    }

    &.selected {
      background: #f5f5f5;
    }

    .content {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .badges {
        display: flex;
        align-items: center;
        gap: 0.5rem;

        .badge {
          padding: 0.5rem 1rem;
          display: flex;
          align-items: center;
          gap: 0.5rem;
          border-radius: 2rem;
          color: white;
          // font-weight: bold;

          &.recommended {
            background: $primary-color;
          }

          &.personalized {
            background: $color-green;
          }

          &.dispenser {
            background: $color-red;
          }
        }
      }

      .name {
        font-size: 1.8rem;
      }
    }

    .edit {
      cursor: pointer;
    }
  }
</style>
