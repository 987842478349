import axios from 'axios'
import moment from 'moment';
import Vue from 'vue'

const DIMENSIONS = {
  OLYMPIC_SEGMENTATION: 1,
  CUSTOMERS_TARGET: 2,
  ACTIVATION_EASINESS: 3,
  GLOBAL: 0,
};

class MatrixService {
  constructor() {
    this.httpClient = axios.create({
      baseURL: `${process.env.VUE_APP_BACKEND}/matrix`,
    });
  }

  async findMatrixIndicators(sidSite = 9999, sidSalesChannel = 99, idLivingArea = '9999', sidDepartment = 'ALL', startDate, endDate, comparisonType, x, y) {
    const dimensionX = DIMENSIONS[x];
    const dimensionY = DIMENSIONS[y];

    const params = {
      sidSite,
      sidSalesChannel,
      idLivingArea,
      sidDepartment: sidDepartment === 'ALL' ? 999 : sidDepartment,
      startDate : moment(startDate).format('YYYY-MM-DD'),
      endDate: moment(endDate).format('YYYY-MM-DD'),
      comparisonType,
      dimensionX,
      dimensionY,
    };
    const token = await Vue.prototype.$user.getIdToken()
    return this.httpClient.get('/indicators', { params, headers: { Authorization: `Bearer ${token}` } }).then(res => res.data);
  }
}

export default new MatrixService();
