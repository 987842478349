<template>
  <div class="sales-formula-matrix-grid">
    <app-loading class="loading"
                 v-if="isLoading">
    </app-loading>

    <app-error-state v-else-if="isInError">
      <md-button @click="getMatrix">
        {{ $t('ACTIONS.RETRY') }}
      </md-button>
    </app-error-state>

    <app-empty-state v-else-if="salesFormulas.length === 0"></app-empty-state>

    <div class="main"
         v-if="salesFormulas.length > 0">
      <app-grid :columns="columns"
                :default-columns-def="defaultColumnDef"
                :height="height"
                :show-vertical-scroll="false"
                :values="salesFormulas">
      </app-grid>
    </div>
  </div>
</template>

<script>
  import AppEmptyState from '@/components/app-empty-state/AppEmptyState.vue'
  import AppErrorState from '@/components/app-error-state/AppErrorState.vue'
  import AppGrid from '@/components/app-grid/AppGrid.vue'
  import AppLoading from '@/components/app-loading/AppLoading.vue'
  import nomenclatureGeographicService from '@/services/nomenclatureGeographicService'

  export default {
    name: 'DsSalesFormulaMatrixGrid',
    components: {
      AppGrid,
      AppLoading,
      AppErrorState,
      AppEmptyState
    },
    props: {
      dataFunc: {
        type: Function,
        default: () => {}
      },
      isStore: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        isLoading: false,
        isInError: false,
        salesFormulas: [],
        defaultColumnDef: {
          suppressMenu: true,
          filter: false,
          sortable: true,
          resizable: true,
          width: 100
        },
        columns: [
          {
            colId: 'id',
            headerName: this.$t('SALES_FORMULA_MATRIX_GRID.COLUMNS.ID'),
            field: 'dimensions.id',
            pinned: 'left',
            menuTabs: ['filterMenuTab'],
            cellStyle: { 'font-weight': 'bold', 'text-transform': 'uppercase' },
            width: 250
          },
          {
            colId: 'salesAmount',
            headerName: this.$t('SALES_FORMULA_MATRIX_GRID.COLUMNS.SALES_AMOUNT.CURRENT'),
            field: 'salesAmount',
            suppressMenu: true,
            valueFormatter: data => this.format(data.value, 'currency', 2)
          },
          {
            colId: 'salesAmountProgression',
            headerName: this.$t('SALES_FORMULA_MATRIX_GRID.COLUMNS.SALES_AMOUNT.PROGRESSION'),
            field: 'salesAmountProgression',
            suppressMenu: true,
            valueFormatter: data => this.format(data.value, 'percent', 1),
            cellClassRules: {
              'cell-good': 'x >= 0',
              'cell-bad': 'x < 0'
            }
          },
          {
            colId: 'entries',
            headerName: this.$t('SALES_FORMULA_MATRIX_GRID.COLUMNS.TICKETS.CURRENT'),
            field: 'entries',
            suppressMenu: true,
            valueFormatter: data => this.format(data.value, 2)
          },
          {
            colId: 'entriesProgression',
            headerName: this.$t('SALES_FORMULA_MATRIX_GRID.COLUMNS.TICKETS.PROGRESSION'),
            field: 'entriesProgression',
            suppressMenu: true,
            valueFormatter: data => this.format(data.value, 'percent', 1),
            cellClassRules: {
              'cell-good': 'x >= 0',
              'cell-bad': 'x < 0'
            }
          },
          {
            colId: 'totalWorkforce',
            headerName: this.$t('SALES_FORMULA_MATRIX_GRID.COLUMNS.WORKFORCE.CURRENT'),
            field: 'totalWorkforce',
            suppressMenu: true,
            valueFormatter: data => this.format(data.value, 2)
          },
          {
            colId: 'totalWorkforceProgression',
            headerName: this.$t('SALES_FORMULA_MATRIX_GRID.COLUMNS.WORKFORCE.PROGRESSION'),
            field: 'totalWorkforceProgression',
            suppressMenu: true,
            valueFormatter: data => this.format(data.value, 'percent', 1),
            cellClassRules: {
              'cell-good': 'x >= 0',
              'cell-bad': 'x < 0'
            }
          },
          {
            colId: 'customerByTotalWorkforce',
            headerName: this.$t('SALES_FORMULA_MATRIX_GRID.COLUMNS.AVERAGE_RATE.CURRENT'),
            field: 'customerByTotalWorkforce',
            suppressMenu: true,
            valueFormatter: data => this.format(data.value, 'percent', 2)
          },
          {
            colId: 'customerByTotalWorkforceProgression',
            headerName: this.$t('SALES_FORMULA_MATRIX_GRID.COLUMNS.AVERAGE_RATE.PROGRESSION'),
            field: 'customerByTotalWorkforceProgression',
            suppressMenu: true,
            valueFormatter: data => this.format(data.value, 'percent', 1),
            cellClassRules: {
              'cell-good': 'x >= 0',
              'cell-bad': 'x < 0'
            }
          },
          {
            colId: 'entriesByCustomers',
            headerName: this.$t('SALES_FORMULA_MATRIX_GRID.COLUMNS.FREQUENCY.CURRENT'),
            field: 'entriesByCustomers',
            suppressMenu: true,
            valueFormatter: data => this.format(data.value, 2)
          },
          {
            colId: 'entriesByCustomersProgression',
            headerName: this.$t('SALES_FORMULA_MATRIX_GRID.COLUMNS.FREQUENCY.PROGRESSION'),
            field: 'entriesByCustomersProgression',
            suppressMenu: true,
            valueFormatter: data => this.format(data.value, 'percent', 1),
            cellClassRules: {
              'cell-good': 'x >= 0',
              'cell-bad': 'x < 0'
            }
          },
          {
            colId: 'salesAmountByEntries',
            headerName: this.$t('SALES_FORMULA_MATRIX_GRID.COLUMNS.CART.CURRENT'),
            field: 'salesAmountByEntries',
            suppressMenu: true,
            valueFormatter: data => this.format(data.value, 'currency', 2)
          },
          {
            colId: 'entriesByCustomersProgression',
            headerName: this.$t('SALES_FORMULA_MATRIX_GRID.COLUMNS.CART.PROGRESSION'),
            field: 'salesAmountByEntriesProgression',
            suppressMenu: true,
            valueFormatter: data => this.format(data.value, 'percent', 1),
            cellClassRules: {
              'cell-good': 'x >= 0',
              'cell-bad': 'x < 0'
            }
          },
          {
            colId: 'quantityByEntries',
            headerName: this.$t('SALES_FORMULA_MATRIX_GRID.COLUMNS.ARTICLES.CURRENT'),
            field: 'quantityByEntries',
            suppressMenu: true,
            valueFormatter: data => this.format(data.value, 2)
          },
          {
            colId: 'quantityByEntriesProgression',
            headerName: this.$t('SALES_FORMULA_MATRIX_GRID.COLUMNS.ARTICLES.PROGRESSION'),
            field: 'quantityByEntriesProgression',
            suppressMenu: true,
            valueFormatter: data => this.format(data.value, 'percent', 1),
            cellClassRules: {
              'cell-good': 'x >= 0',
              'cell-cell-bad': 'x < 0'
            }
          },
          {
            colId: 'salesAmountByQuantities',
            headerName: this.$t('SALES_FORMULA_MATRIX_GRID.COLUMNS.AVERAGE_PRICE.CURRENT'),
            field: 'salesAmountByQuantities',
            suppressMenu: true,
            valueFormatter: data => this.format(data.value, 'currency', 2)
          },
          {
            colId: 'salesAmountByQuantitiesProgression',
            headerName: this.$t('SALES_FORMULA_MATRIX_GRID.COLUMNS.AVERAGE_PRICE.PROGRESSION'),
            field: 'salesAmountByQuantitiesProgression',
            suppressMenu: true,
            valueFormatter: data => this.format(data.value, 'percent', 1),
            cellClassRules: {
              'cell-good': 'x >= 0',
              'cell-bad': 'x < 0'
            }
          }
        ]
      }
    },
    computed: {
      height() {
        return (35 * (this.salesFormulas.length + 1)) + 6 // (height of each row * nb of rows) + scroll height;
      }
    },
    watch: {
      '$route.query': function() {
        this.getMatrix()
      }
    },
    created() {
      this.getMatrix()
    },
    methods: {
      getMatrix() {
        this.isInError = false
        this.isLoading = true

        this.dataFunc()
            .then(salesFormulas => {
              if (this.isStore) {
                nomenclatureGeographicService.getAllSites().then(sites => {
                  salesFormulas.forEach(saleFormula => {
                    const siteLabel = saleFormula.dimensions.id.replace(/\[.*]/, '')
                    const siteFormat = saleFormula.dimensions.geometry === 'SUPER' ? 'AUCHAN PROXIMITE' : `AUCHAN ${ saleFormula.dimensions.geometry }`
                    const siteTmp = sites.find(site => site.concept.label === siteFormat && site.label.trim() === siteLabel.trim())
                    if (siteTmp) saleFormula.dimensions.id = `(${ siteTmp.id }) [${ saleFormula.dimensions.geometry }] - ${ siteTmp.label }`

                  })
                  this.salesFormulas = salesFormulas
                  this.defaultColumnDef.suppressMenu = this.salesFormulas.length <= 10
                })
              } else {
                this.salesFormulas = salesFormulas
                this.defaultColumnDef.suppressMenu = this.salesFormulas.length <= 10
              }
            })
            .catch(() => this.isInError = true)
            .finally(() => this.isLoading = false)
      },
      format(val, unit, decimal = 0) {
        return this.$options.filters.number(val, decimal, unit)
      }
    }
  }
</script>

<style scoped lang="scss">
  .sales-formula-matrix-grid {
    .main {
      height: 100%;
      display: flex;
    }

    :global(.ag-theme-material .ag-cell) {
      padding-left: 10px;
      padding-right: 0;
      text-align: left;
      display: flex;
      align-items: center;
      border-left: 1px solid #ccc;
    }

    :global(.ag-theme-material .ag-header-cell) {
      padding-left: 10px;
      padding-right: 10px;
      border-left: 1px solid #ccc;
    }

    :global(.ag-theme-material .ag-header-group-cell) {
      padding-left: 10px;
      padding-right: 10px;
      border-left: 1px solid #ccc;
    }

  }
</style>
