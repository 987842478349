<template>
    <div :class="isGood ? 'good' : 'bad'"
         class="sales-formula-pyramid-card">
        <div class="header">
            <md-icon v-if="icon !== null">{{ icon }}</md-icon>
            &nbsp;
            <span>{{ label }}</span>
        </div>
        <div class="content">
            <div class="main">
                <strong v-if="label === $t('SALES_FORMULA_PYRAMID.CART') ||
                              label === $t('SALES_FORMULA_PYRAMID.FREQUENCY.LABEL') ||
                              label === $t('SALES_FORMULA_PYRAMID.ARTICLES.LABEL') ">
                    {{ currentValue | number(1, format) }}
                </strong>
                <strong v-else>{{ currentValue | number(2, format) }}</strong>
                <span v-if="labelFormat !== null">&nbsp;{{ labelFormat }}</span>
            </div>
            <div class="secondary">
                <md-icon class="icon-trending">
                    <span v-if="isGood">trending_up</span>
                    <span v-else>trending_down</span>
                </md-icon>
                <span>&nbsp;{{ progressionValue | number(1, 'percent') }}</span>
                <span>&nbsp;/&nbsp;{{ $t('SALES_FORMULA_PYRAMID_CARD.LAST_YEAR') }}</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'DsSalesFormulaPyramidCard',
  props: {
    icon: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    labelFormat: {
      type: String,
      default: null,
    },
    currentValue: {
      type: Number,
      default: 0,
    },
    lastYearValue: {
      type: Number,
      default: 0,
    },
    progressionValue: {
      type: Number,
      default: 0,
    },
    format: {
      type: String,
      default: 'currency',
    },
  },
  computed: {
    isGood() {
      return this.progressionValue >= 0;
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/styles/_colors";

.sales-formula-pyramid-card {
    .header {
        border-top: 3px solid transparent;
        padding: 1rem;
        font-size: 1.5rem;
    }

    &.good {
        .header {
            border-color: $color-good;
        }

        .content {
            .icon-trending {
                color: $color-good;
            }
        }
    }

    &.bad {
        .header {
            border-color: $color-bad;
        }

        .content {
            .icon-trending {
                color: $color-bad;
            }
        }
    }

    .content {
        text-align: center;
        padding: 1rem;

        .main {
            strong {
                font-size: 3rem;
                line-height: 3rem;
            }

            span {
                font-size: 1rem;
            }
        }

        .secondary {
            font-size: 1.5rem;
            line-height: 1.5rem;

            span:nth-child(3) {
                color: rgba(0, 0, 0, 0.54);
            }
        }
    }
}
</style>
