<template>
    <app-context-container :imageId="imageId"
                           class="app-context-format">
        <template slot="title">{{ $t('APP_CONTEXT_FORMAT.LABEL') }}</template>
        <template slot="main">
            <div class="select-format">
                <app-button-toggle :value="selectedFormat"
                                   :values="formats"
                                   @input="setFormat">
                </app-button-toggle>
            </div>
        </template>
    </app-context-container>
</template>

<script>
import salesChannelService from '@/services/salesChannelService';
import AppButtonToggle from '@/components/app-button-toggle/AppButtonToggle.vue';
import AppContextContainer from '../container/AppContextContainer.vue';

export default {
  name: 'AppContextFormat',
  components: { AppContextContainer, AppButtonToggle },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      formats: [],
      selectedFormat: {},
    };
  },
  computed: {
    imageId() {
      const id = this.value.id === 99 ? 'default' : this.value.id;
      return `format/${id}`;
    },
  },
  created() {
    this.getFormats();
    this.selectedFormat = this.value;
  },
  methods: {
    getFormats() {
      this.formats = salesChannelService.getAll();
    },
    setFormat(format) {
      this.selectedFormat = format;
      this.$emit('input', this.selectedFormat);
    },
  },
};
</script>

<style scoped lang="scss">
.app-context-format {
    .select-format {
        padding: 1rem;
    }
}
</style>
