<template>
    <div class="app-context-container"
         ref="appContextContainer">
        <div :style="{ 'background-image': `url(${imageURL})` }"
             class="image"
             v-if="imageId !== null">
        </div>
        <div class="content">
            <h3 class="title">
                <slot name="title"></slot>
            </h3>
            <div class="main">
                <slot name="main"></slot>
            </div>
            <div class="actions">
                <slot name="actions"></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'AppContextContainer',
  props: {
    imageId: {
      type: String,
      default: null,
    },
  },
  computed: {
    imageURL() {
      try {
        return require(`./assets/${this.imageId}.webp`);
      } catch (e) {
        console.warn(`Failed to find image "${this.imageId}", take the default.`);
        return require(`./assets/${this.imageId.split('/')[0]}/default.webp`);
      }
    },
  },
  mounted() {
    if (this.imageId === null) {
      this.$refs.appContextContainer.style.gridTemplateColumns = '1fr';
    }
  },
};
</script>

<style scoped lang="scss">
@import "~@/styles/_colors";
@import "~@/styles/_mixins";
@import "~@/styles/_variables";

.app-context-container {
    display: grid;
    grid-template-columns: 25% 75%;
    height: 100%;
    box-shadow: $box-shadow inset;

    .image {
        background-size: cover;
        background-position: center;
    }

    .content {
        overflow: hidden;
        display: grid;
        grid-template-rows: 7% 83% 10%;

        .actions, .title {
            padding: 1em;
        }

        .title {
            margin: 0;
        }

        .main {
            overflow: hidden;
            border-top: 1px solid $color-grey-light;
        }

        .actions {
            @include display-flex(center, flex-end);
            border-top: 1px solid $color-grey-light;

            button + button {
                margin-left: 1rem;
            }
        }
    }
}
</style>
