<template>
    <div class="sales-formula-pyramid-on-sliding-year">
        <md-subheader class="header">
            <app-period :from="12"
                        :to="1">
            </app-period>
        </md-subheader>

        <app-loading class="loading"
                     v-if="isLoading">
        </app-loading>

        <app-error-state v-else-if="isInError">
            <md-button @click="getPyramid">
                {{ $t('ACTIONS.RETRY') }}
            </md-button>
        </app-error-state>

        <app-empty-state
                v-else-if="isNational && salesFormulasOnNational.length === 0">
        </app-empty-state>

        <app-empty-state v-else-if="!isNational && salesFormulas.length === 0"></app-empty-state>

        <div class="main"
             v-else>
            <div class="categories">
                <md-chip :class="activeCategory === 'salesAmount' ? 'md-primary' : ''"
                         @click="setCategory('salesAmount')"
                         md-clickable>
                    {{ $t('SALES_FORMULA_PYRAMID.TURNOVER') }}
                </md-chip>
                <md-chip :class="activeCategory === 'entries' ? 'md-primary' : ''"
                         @click="setCategory('entries')"
                         md-clickable>
                    {{ $t('SALES_FORMULA_PYRAMID.TICKETS.LABEL') }}
                </md-chip>
                <md-chip :class="activeCategory === 'totalWorkforce' ? 'md-primary' : ''"
                         @click="setCategory('totalWorkforce')"
                         md-clickable>
                    {{ $t('SALES_FORMULA_PYRAMID.WORKFORCE.LABEL') }}
                </md-chip>
                <md-chip
                        :class="activeCategory === 'customerByTotalWorkforce' ? 'md-primary' : ''"
                        @click="setCategory('customerByTotalWorkforce')"
                        md-clickable>
                    {{ $t('SALES_FORMULA_PYRAMID.AVERAGE_RATE') }}
                </md-chip>
                <md-chip :class="activeCategory === 'entriesByCustomers' ? 'md-primary' : ''"
                         @click="setCategory('entriesByCustomers')"
                         md-clickable>
                    {{ $t('SALES_FORMULA_PYRAMID.FREQUENCY.LABEL') }}
                </md-chip>
                <md-chip :class="activeCategory === 'salesAmountByEntries' ? 'md-primary' : ''"
                         @click="setCategory('salesAmountByEntries')"
                         md-clickable>
                    {{ $t('SALES_FORMULA_PYRAMID.CART') }}
                </md-chip>
                <md-chip :class="activeCategory === 'quantityByEntries' ? 'md-primary' : ''"
                         @click="setCategory('quantityByEntries')"
                         md-clickable>
                    {{ $t('SALES_FORMULA_PYRAMID.ARTICLES.LABEL') }}
                </md-chip>
                <md-chip
                        :class="activeCategory === 'salesAmountByQuantities' ? 'md-primary' : ''"
                        @click="setCategory('salesAmountByQuantities')"
                        md-clickable>
                    {{ $t('SALES_FORMULA_PYRAMID.AVERAGE_PRICE') }}
                </md-chip>
            </div>
            <app-line-chart :datasets="datasets"
                            :height="100"
                            :labels="labels"
                            :options="options">
            </app-line-chart>
        </div>
    </div>
</template>

<script>
import AppEmptyState from '@/components/app-empty-state/AppEmptyState.vue';
import AppErrorState from '@/components/app-error-state/AppErrorState.vue';
import AppLineChart from '@/components/app-line-chart/AppLineChart.vue';
import AppLoading from '@/components/app-loading/AppLoading.vue';
import AppPeriod from '@/components/app-period/AppPeriod.vue';
import salesFormulaService from '@/services/salesFormulaService';
import moment from 'moment';

export default {
  name: 'DsSalesFormulaPyramidOnSlidingYear',
  components: {
    AppLineChart,
    AppPeriod,
    AppLoading,
    AppErrorState,
    AppEmptyState,
  },
  data() {
    return {
      isLoading: false,
      isInError: false,
      salesFormulas: [],
      salesFormulasOnNational: [],
      activeCategory: 'salesAmount',
      options: {
        responsive: true,
        tooltips: {
          callbacks: {
            label: tooltipItem => this.$options.filters.number(tooltipItem.yLabel, 2, 'percent'),
          },
        },
        scales: {
          xAxes: [{
            ticks: {
              autoSkip: false,
              callback: value => moment(value, 'YYYYMM').format('MMMM'),
            },
          }],
          yAxes: [{
            ticks: {
              callback: value => this.$options.filters.number(value, 2, 'percent'),
            },
          }],
        },
        plugins: {
          datalabels: {
            display: false,
          },
        },
      },
    };
  },
  computed: {
    labels() {
      return this.salesFormulas.map(salesFormula => salesFormula.dimensions.id);
    },
    datasets() {
      const datasets = [];

      const evolutions = {
        label: this.$t('SALES_FORMULA_PYRAMID_ON_SLIDING_YEAR.EVOLUTION'),
        borderColor: 'grey',
      };

      if (this.isNational) {
        evolutions.data = this.salesFormulasOnNational.map(salesFormula => salesFormula[`${this.activeCategory}Progression`]);
      } else {
        evolutions.data = this.salesFormulas.map(salesFormula => salesFormula[`${this.activeCategory}Progression`]);
      }

      datasets.push(evolutions);

      if (this.$route.query.sidDeparments === undefined || this.$route.query.sidDepartments.split(',').length() === 1) {
        const slidingAverageOnNational = {
          label: this.$t('SALES_FORMULA_PYRAMID_ON_SLIDING_YEAR.SLIDING_AVERAGE.NATIONAL'),
          borderColor: 'red',
          backgroundColor: 'transparent',
        };

        const slidingAveragesOnNational = [null, null, null, null, null];

        for (let idx = this.salesFormulasOnNational.length - 1; idx >= 5; idx--) {
          const slidingAverage = this.salesFormulasOnNational.slice(idx - 4, idx + 1).reduce((acc, current) => acc += current[`${this.activeCategory}Progression`], 0);
          slidingAveragesOnNational[idx] = slidingAverage / 5;
        }

        slidingAverageOnNational.data = slidingAveragesOnNational;
        datasets.push(slidingAverageOnNational);
      }

      if (!this.isNational) {
        const slidingAverage = {
          label: this.$t('SALES_FORMULA_PYRAMID_ON_SLIDING_YEAR.SLIDING_AVERAGE.SITE'),
          borderColor: 'rgb(30, 139, 195)',
          backgroundColor: 'transparent',
        };

        const slidingAverages = [null, null, null, null, null];

        for (let idx = this.salesFormulas.length - 1; idx >= 5; idx--) {
          const slidingAverage = this.salesFormulas.slice(idx - 4, idx + 1).reduce((acc, current) => acc += current[`${this.activeCategory}Progression`], 0);
          slidingAverages[idx] = slidingAverage / 5;
        }

        slidingAverage.data = slidingAverages;
        datasets.push(slidingAverage);
      }

      return datasets;
    },
    isNational() {
      return this.$route.query.sidSite === undefined && this.$route.query.idLivingArea === undefined && this.$route.query.sidSalesChannel === 100;
    },
  },
  watch: {
    '$route.query': function () {
      this.getPyramid();
    },
  },
  created() {
    this.getPyramid();
  },
  methods: {
    getPyramid() {
      const query = this.$route.query;

      const promises = [
        salesFormulaService.getPyramid(undefined, undefined, 100, undefined, query.sidDepartments), // National what ever you do
      ];

      if (!this.isNational) {
        promises.push(salesFormulaService.getPyramid(undefined, query.sidSite, query.sidSalesChannel, query.idLivingArea, query.sidDepartments));
      }

      this.isInError = false;
      this.isLoading = true;

      Promise
        .all(promises)
        .then(datas => {
          this.salesFormulasOnNational = datas[0];

          if (datas.length === 2) {
            this.salesFormulas = datas[1];
          }
        })
        .catch(() => this.isInError = true)
        .finally(() => this.isLoading = false);
    },
    setCategory(category) {
      this.activeCategory = category;
    },
  },
};
</script>

<style scoped lang="scss">
.sales-formula-pyramid-on-sliding-year {
    .header {
        text-transform: capitalize;
    }

    .categories {
        padding: 1rem;
    }
}
</style>
