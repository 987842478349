export default {
  APP_NAME: {
    LABEL: 'Data<strong>Store</strong>'
  },
  APP: {
    MENUS: {
      ANALYZE: {
        LABEL: 'Analyser',
        SALES_FORMULA: 'Ma formule de vente',
        GEO_MARKETING: 'Ma fiche géo-marketing',
        CUSTOMER_PROFILE: 'Mon profil client',
        MAP: 'Ma carte',
        MATRIX: 'Mes segmentations'
      },
      ANIMATE: {
        LABEL: 'Animer'
      },
      TRACT_ZONES: {
        LABEL: 'Zones de tract'
      }
    }
  },
  APP_PERIOD: {
    DURATION: {
      LABEL: 'Oups | Mois dernier | {count} derniers mois'
    }
  },
  APP_MONTHPICKER: {
    DURATION: {
      LABEL: 'Oups | Mois dernier | Il y a {count} mois'
    }
  },
  ACTIONS: {
    ANIMATE: 'Animer',
    NEXT: 'Suivant',
    BACK: 'Retour',
    ESTIMATE: 'Estimer',
    CANCEL: 'Annuler',
    CLOSE: 'Fermer',
    RETRY: 'Réessayer',
    VALID: 'Valider',
    CLEAR: 'Effacer',
    IGNORE: 'Ignorer cette étape',
    MORE: 'Plus',
    SHOW_MORE: 'Afficher plus',
    SHOW_LESS: 'Afficher moins',
    LESS: 'Moins',
    NO_RESULTS: 'Aucun résultat',
    CANCEL_ANIMATION: "Annuler l'animation",
    SAVE_SUCCESS: 'Sauvegarde réussie',
    SAVE_FAIL: 'La sauvegarde a échoué, veuillez réessayer',
    ANIMATE_LOADING: "Chargement du détail de l'animation",
    REFRESH_TARGET: 'Mettre à jour le comptage',
    ERROR: 'Une erreur est survenue, veuillez réessayer',
    GO_TO_TOP_CLIENT: 'Passer à la dernière étape',
    DOCUMENTATION: 'Aide en ligne // Clique droit -> "Ouvrir le lien dans un nouvel onglet"'
  },
  VIEWS: {
    HOME: 'Accueil',
    ANIMATE: {
      MAP: 'Carte'
    },
    ANALYZE: {
      SALES_FORMULA: 'Ma formule de vente',
      GEO_MARKETING: 'Ma fiche géo-marketing',
      CUSTOMER_PROFILE: 'Mon profil client',
      MAP: 'Ma carte',
      MATRIX: 'Mes segmentations'
    }
  },
  NAVIGATION: {
    ANALYZE: 'Analyser',
    ANIMATE: 'Animer'
  },
  SHARE_BY_COMMUNITY_BTN: {
    LABEL: 'Accéder à smart club // Clique droit -> "Ouvrir le lien dans un nouvel onglet"'
  },
  FULLSCREEN_BTN: {
    LABEL: {
      ON: 'Passer en mode plein écran',
      OFF: 'Quitter le mode plein écran'
    }
  },
  LOGOUT_BTN: {
    LABEL: 'Se déconnecter'
  },
  TUTORIAL_BTN: {
    LABEL: 'Jouer le tutoriel'
  },
  APP_SELECTOR: {
    LABEL: 'Accéder aux applications de la DataSuite',
    DATASTORE: {
      LABEL: 'Data<strong>Store</strong>',
      DESCRIPTION: 'Animation client'
    },
    DATACAMP: {
      LABEL: 'Data<strong>Camp</strong>',
      DESCRIPTION: 'Débrief des campagnes'
    },
    DATAPERF: {
      LABEL: 'Data<strong>Perf</strong>',
      DESCRIPTION: 'Performance budgétaire'
    }
  },
  CONTEXT: {
    LABEL: 'Changer de contexte',
    SALES_CHANNEL: 'Canal de ventes',
    SITE: 'Magasin',
    MARKET: 'Marché',
    PERIOD: 'Période'
  },
  SITE_SELECTOR: {
    REGION_SELECTOR: 'Région'
  },
  CUSTOMER_PODIUM_CARD: {
    LABEL: 'Top Client',
    SUB_LABEL: 'Visualiser la répartition par niveau de fidélité, cycle de vie ou facilité d’activation et constituer une cible de clients à animer.'
  },
  MAP_CARD: {
    LABEL: 'Carte',
    SUB_LABEL: 'Visualiser la zone de chalandise et constituer une cible géographique de clients à animer.'
  },
  MATRIX_CARD: {
    LABEL: 'Tableau',
    SUB_LABEL: 'Visualiser la zone de chalandise et constituer une cible géographique de clients à animer.'
  },
  MAP_VIEW: {
    LOADING_SITE: 'Chargement du site {0} en cours ...',
    ERROR_SITE: 'Erreur lors de la récupération du site {0}.',
    STEPPER: {
      FIRST: 'Sélectionner',
      SECOND: 'Estimer',
      THIRD: 'Animer'
    }
  },
  ANALYZE_MAP: {
    ERROR_GET_DATAS: 'Erreur lors de la récupération des données. Veuillez sélectionner un site.',
    SIGN: 'Enseigne',
    CITY: 'Ville',
    AREA: 'Surface',
    SIGN_TYPE: 'Type'
  },
  GOOGLE_MAPS_DRAWING_MANAGER_CONTROL: {
    POLYGON: 'Dessiner une forme',
    RECTANGLE: 'Dessiner un rectangle',
    CLEAR: 'Arrêter et effacer les tracés',
    MANUAL_ADD: 'Ajouter manuellement des zones',
    MANUAL_REMOVE: 'Déselectionner manuellement des zones'
  },
  ANIMATE_MAP_SELECTOR: {
    SELECTION: 'Pas de sélection | 1 sélectionné | {count} sélectionnés'
  },
  ANIMATE_MAP_SELECTOR_ACTIVATION_AREAS: {
    LABEL: 'Zone de chalandise',
    VERY_EASY: 'Très facile',
    EASY: 'Facile',
    MIDDLE: 'Moyen',
    HARD: 'Difficile',
    VERY_HARD: 'Très difficile'
  },
  ANIMATE_MAP_SELECTOR_NEIGHBORHOOD_TYPES: {
    LABEL: 'Type de quartier'
  },
  ANIMATE_MAP_SELECTOR_OLYMPIC_SEGMENTATIONS: {
    LABEL: 'Segmentation olympique'
  },
  ANIMATE_MAP_SELECTOR_LIFE_CYCLES: {
    LABEL: 'Cycle de vie'
  },
  ANIMATE_MAP_SELECTOR_CUSTOMERS_PODIUM: {
    LABEL: 'Top Client'
  },
  ANIMATE_MAP_ESTIMATOR_RESUME: {
    ACTIVATION_AREAS: {
      LABEL: '@:ANIMATE_MAP_SELECTOR_ACTIVATION_AREAS.LABEL'
    },
    NEIGHBORHOOD_TYPES: {
      LABEL: '@:ANIMATE_MAP_SELECTOR_NEIGHBORHOOD_TYPES.LABEL'
    },
    OLYMPIC_SEGMENTATIONS: {
      LABEL: '@:ANIMATE_MAP_SELECTOR_OLYMPIC_SEGMENTATIONS.LABEL'
    },
    LIFE_CYCLES: {
      LABEL: '@:ANIMATE_MAP_SELECTOR_LIFE_CYCLES.LABEL'
    },
    CUSTOMERS_PODIUM: {
      LABEL: '@:ANIMATE_MAP_SELECTOR_CUSTOMERS_PODIUM.LABEL'
    }
  },
  ANIMATE_MAP_ESTIMATOR: {
    IS_ESTIMATING: 'Estimation en cours ...'
  },
  ANIMATE_MAP_ESTIMATOR_ESTIMATION: {
    TOTAL: 'Aucun client animable :/ | 1 client animable | {count} clients animables',
    SMS: 'Aucun client animable par SMS | 1 client animable par SMS | {count} cliens animables par SMS',
    BR: 'Aucun client animable par BR | 1 client animable par BR | {count} cliens animables par BR'
  },
  ANIMATE_MAP_ANIMATOR: {
    IS_ANIMATING: "Création de l'animation en cours ...",
    DESCRIPTION: {
      LABEL: 'Intitulé',
      REQUIRED: 'Obligatoire'
    }
  },
  ACTIVATION_EASINESS_AREA_CHART: {
    LABEL: 'Zone de chalandise et résiduel',
    LABEL_ZDV: 'Poids du CA réalisé dans la zone de vie',
    SUB_LABEL: "En poids de chiffre d'affaires",
    SERIE: {
      STORE: 'Mon magasin',
      GLOBAL_HYPER: 'Global Hyper',
      NATIONAL: 'National'
    },
    ERROR: {
      STORE: 'Erreur lors de la récupération des données du magasin pour le graphe "@:ACTIVATION_EASINESS_AREA_CHART.LABEL"',
      NATIONAL: 'Erreur lors de la récupération des données nationales pour le graphe "@:ACTIVATION_EASINESS_AREA_CHART.LABEL"',
      GLOBAL_HYPER: 'Erreur lors de la récupération des données global hyper pour le graphe "@:ACTIVATION_EASINESS_AREA_CHART.LABEL"'
    }
  },
  ACTIVATION_EASINESS_CHART: {
    LABEL: "Facilité d'activation",
    LABEL_ZDV: 'Poids du CA par zone de facilité d’activation',
    SUB_LABEL: "En poids de chiffre d'affaires",
    MODE_PROGRESSION: 'Evolution',
    SERIE: {
      STORE: 'Mon magasin',
      GLOBAL_HYPER: 'Global Hyper',
      NATIONAL: 'National'
    },
    ERROR: {
      STORE: 'Erreur lors de la récupération des données du magasin pour le graphe "@:ACTIVATION_EASINESS_CHART.LABEL"',
      NATIONAL: 'Erreur lors de la récupération des données nationales pour le graphe "@:ACTIVATION_EASINESS_CHART.LABEL"',
      GLOBAL_HYPER: 'Erreur lors de la récupération des données global hyper pour le graphe "@:ACTIVATION_EASINESS_CHART.LABEL"'
    }
  },
  NEIGHBORHOOD_TYPE_CHART: {
    LABEL: 'Type de quartier',
    LABEL_ZDV: 'Poids du CA par typo quartier',
    SUB_LABEL: "En poids de chiffre d'affaires",
    MODE_PROGRESSION: 'Evolution',
    SERIE: {
      STORE: 'Mon magasin',
      GLOBAL_HYPER: 'Global Hyper',
      NATIONAL: 'National'
    },
    ERROR: {
      STORE: 'Erreur lors de la récupération des données du magasin pour le graphe "@:NEIGHBORHOOD_TYPE_CHART.LABEL"',
      NATIONAL: 'Erreur lors de la récupération des données nationales pour le graphe "@:NEIGHBORHOOD_TYPE_CHART.LABEL"',
      GLOBAL_HYPER: 'Erreur lors de la récupération des données global hyper pour le graphe "@:NEIGHBORHOOD_TYPE_CHART.LABEL"'
    }
  },
  ANALYSE_LAYOUT: {
    TUTORIAL: 'Lancer le tutoriel'
  },
  ANALYZE_GEO_MARKETING: {
    COMPARE: {
      LABEL: 'Comparer avec',
      GLOBAL_SITE: 'Global Site',
      NATIONAL: 'National'
    }
  },
  ANALYZE_SALES_FORMULA: {
    PYRAMID: {
      LABEL: 'Pyramide'
    },
    MATRIX: {
      LABEL: 'Matrice'
    },
    MAP: {
      LABEL: 'Carte'
    }
  },
  SALES_FORMULA_PYRAMID: {
    TURNOVER: "Chiffre d'affaires",
    TICKETS: {
      LABEL: 'Nombre de tickets',
      FORMAT: 'ticket | ticket | tickets'
    },
    CART: 'Panier moyen',
    WORKFORCE: {
      LABEL: 'Portefeuille',
      FORMAT: 'client | client | clients'
    },
    AVERAGE_RATE: "Taux d'actif",
    FREQUENCY: {
      LABEL: 'Fréquence',
      FORMAT: 'par mois'
    },
    ARTICLES: {
      LABEL: "Nombre d'articles",
      FORMAT: 'article | article | articles'
    },
    AVERAGE_PRICE: 'Prix moyen'
  },
  SALES_FORMULA_PYRAMID_ON_SLIDING_YEAR: {
    EVOLUTION: 'Evolution mois par mois',
    SLIDING_AVERAGE: {
      SITE: 'Moyenne glissante de mon contexte : tendance (5 mois)',
      NATIONAL: 'Moyenne glissante national : tendance (5 mois)'
    }
  },
  SALES_FORMULA_PYRAMID_ACTIONS: {
    VIDEO_ENABLE: 'Votre navigateur ne permet pas la lecture de vidéos.'
  },
  SALES_FORMULA_MATRIX: {
    GLOBAL: 'Global',
    UNIVERS: 'Univers',
    SHELVES: 'Rayons',
    MARKETS: 'Marchés',
    STORES: 'Magasins'
  },
  SALES_FORMULA_MATRIX_GRID: {
    COLUMNS: {
      ID: '',
      SALES_AMOUNT: {
        CURRENT: 'CA',
        PROGRESSION: 'Evolution'
      },
      TICKETS: {
        CURRENT: 'Tickets',
        PROGRESSION: 'Evolution'
      },
      WORKFORCE: {
        CURRENT: 'Portefeuille',
        PROGRESSION: 'Evolution'
      },
      AVERAGE_RATE: {
        CURRENT: "Taux d'actifs",
        PROGRESSION: 'Evolution'
      },
      FREQUENCY: {
        CURRENT: 'Fréquence',
        PROGRESSION: 'Evolution'
      },
      CART: {
        CURRENT: 'Panier',
        PROGRESSION: 'Evolution'
      },
      ARTICLES: {
        CURRENT: "Nb d'articles",
        PROGRESSION: 'Evolution'
      },
      AVERAGE_PRICE: {
        CURRENT: 'Prix moyen',
        PROGRESSION: 'Evolution'
      }
    }
  },
  HOLDER_CUSTOMER_CHART: {
    LABEL: 'Clients cartés',
    SUB_LABEL: "En poids de chiffre d'affaires",
    SERIE: {
      HOLDER: 'Cartés',
      NOT_HOLDER: 'Non cartés'
    },
    ERROR: 'Erreur lors de la récupération des données pour le graphe "@:HOLDER_CUSTOMER_CHART.LABEL"'
  },
  CUSTOMER_SEGMENTATION_MATRIX: {
    BY: 'par',
    LINE: 'En ligne',
    COLUMN: 'En colonne',
    REVERSE: 'Inverser',
    CUSTOMERS_TARGET: 'Cible affinitaire',
    OLYMPIC_SEGMENTATION: 'Segmentation olympique',
    ACTIVATION_EASINESS: 'Zone de chalandise',
    ERROR: 'Erreur lors de la récupération des données',
    CLIENTS: 'Clients',
    SALES_AMOUNT: 'CA',
    ENTRIES: 'Tickets',
    CART: 'Panier'
  },
  SALES_FORMULA_MAP: {
    INDICATORS: {
      LABEL: 'Indicateurs',
      TURNOVER: "Chiffre d'affaires",
      TICKETS: 'Nombre de tickets',
      CART: 'Panier moyen',
      WORKFORCE: 'Portefeuille',
      AVERAGE_RATE: "Taux d'actif",
      FREQUENCY: 'Fréquence',
      ARTICLES: "Nombre d'articles",
      AVERAGE_PRICE: 'Prix moyen'
    },
    TYPE: {
      LABEL: "Mode d'affichage",
      VALUE: 'Valeur',
      PROGRESSION: 'Evolution'
    },
    ERROR: {
      GET_DATAS: 'Erreur lors de la récupération des données du site {0}.',
      GET_LAYERS: 'Pas de données.',
      GET_INDICATORS: 'Erreur lors de la récupération de la formule de vente pour cet indicateur.'
    }
  },
  ANIMATE_LAYOUT: {
    DELETE_ANIMATE: 'Réinitialiser la cible',
    STORE: 'Magasin',
    MEDIA: 'Mode de contact',
    MARKETS: 'Contexte Nomenclature',
    SEGMENTATION: 'Typologie Client',
    GEOGRAPHIC: 'Contexte Géographique',
    PERIOD: 'Période',
    TOP_CLIENT: 'Top Clients',
    YOUR_TARGET: 'Votre cible',
    OPTIMIZED_TARGET: 'Ciblage optimisé',
    NEUTRAL_OPTIMIZED_TARGET: 'Ciblage peu optimisé',
    NO_OPTIMIZED_TARGET: 'Ciblage non optimisé',
    BAD_TARGET: 'Ciblage très large : tous ces clients sont-ils vraiment concernés par votre message et votre offre ?',
    NEUTRAL_TARGET: 'Ciblage large : tous ces clients sont-ils vraiment concernés par votre message et votre offre ?',
    GOOD_TARGET: 'Ciblage affiné : avec un message adapté, votre communication personnalisée sera percutante.',
    COMMUNITY: 'Communautés',
    BAD_COMMUNITY_TARGET: 'Ciblage très large : Plus de 2 communautés sont sélectionnées',
    NEUTRAL_COMMUNITY_TARGET: 'Ciblage large : 2 communautés sont sélectionnées',
    GOOD_COMMUNITY_TARGET: 'Ciblage affiné : 1 communauté est sélectionnée'
  },
  APP_ERROR_STATE: {
    LABEL: 'Oups',
    DESCRIPTION: "Une erreur s'est produite lors de la récupération des données."
  },
  APP_EMPTY_STATE: {
    LABEL: 'Pas de données'
  },
  ESTIMATED_TARGET_VALUES: {
    CLIENTS: 'clients',
    TOTAL: 'clients animables',
    SMS: 'SMS',
    COUPONS: 'BR',
    ERROR_NO_CLIENTS: 'Attention, avec les filtres que vous avez choisi aucun client est animable !'
  },
  APP_CONTEXT: {
    ALL_NATIONAL: 'Tous les Hyper + Drive',
    ALL_PROXY: 'Tous les Proxy',
    ALL_ARF: 'ARF',
    ALL_DRIVE: 'Tous les Drives',
    ALL_HYPER: 'Tous les Hypers',
    SITES: 'Tous les sites | {label} | {count} sites',
    GLOBAL: 'Tous les marchés',
    NOMENCLATURES: 'Tous les marchés | {label} | {count} marchés'
  },
  APP_CONTEXT_GEOGRAPHIC: {
    LABEL: 'Sélection du contexte géographique',
    NATIONAL: 'Tous les sites',
    OR: 'Ou'
  },
  APP_CONTEXT_PERIOD: {
    LABEL: 'Sélectionne une période'
  },
  APP_CONTEXT_FORMAT: {
    LABEL: 'Sélectionne un canal de vente'
  },
  APP_DATEPICKER: {
    HELPERS: {
      YESTERDAY: 'Hier',
      LAST_WEEK: 'Semaine dernière',
      SLIDING_WEEK: 'Semaine glissante',
      LAST_MONTH: 'Mois dernier',
      SLIDING_MONTH: 'Mois glissant',
      LAST_YEAR: 'Année dernière'
    }
  },
  SALES_FORMULA_PYRAMID_CARD: {
    LAST_YEAR: 'N-1'
  },
  GOOGLE_MAPS_LEGEND: {
    LABEL: 'Légende'
  },
  APP_GEOGRAPHIC_SELECTOR: {
    PLACEHOLDER: 'Rechercher un site',
    NATIONAL: 'Hyper + drive',
    ALL_SUPER: 'Proxy',
    ARF: 'ARF',
    HYPER: 'Hyper',
    DRIVE: 'Drive',
    RESULTS_SITES: 'Résultats des sites ou des zones de vies',
    SUPER: 'Proxy',
    ERROR: 'Une erreur est survenue, veuillez réessayer.',
    ALL_FRANCE: 'Toute la France',
    SELECT_TERRITORY: 'Sélectionner le Territoire',
    SELECT_LIVING_AREA: 'Sélectionner la Zone de Vie',
    SELECT_SITE: 'Sélectionner un magasin'
  },
  APP_CONTEXT_NOMENCLATURE: {
    LABEL: 'Sélectionne un ou plusieurs marchés',
    GLOBAL: 'Tous les marchés',
    OR: 'Ou'
  },
  APP_NOMENCLATURE_SELECTOR: {
    GLOBAL_HYPER: 'Global Hyper',
    WARNING_IS_ONLY_ONE_MARKET: "Attention, en contexte ARF / National / Zone de Vie, vous ne pouvez sélectionner qu'un seul marché."
  },
  ANIMATE_STORE: {
    TITLE: 'Sur quel magasin ?',
    ERROR: {
      SAVING: "Erreur à la création de l'animation, merci de réessayer ultérieurement."
    }
  },
  ANIMATE_MEDIA: {
    TITLE: 'Avec quel média ?',
    CAMPAIGN_NAME: 'Nom de la campagne',
    WARNING_NAME: "La saisie du contenu du SMS s'effectuera en fin de parcours.",
    PRICE_FRANCE: '3.6 ct / SMS en France',
    PRICE_INTERNATIONAL: "5.7 ct / SMS à l'international",
    SMS: 'SMS',
    BR: 'BR',
    ERROR: {
      SAVING: "Erreur à la création de l'animation, merci de réessayer ultérieurement."
    }
  },
  ANIMATE_MARKET: {
    TITLE: 'Les clients de quel(s) marché(s) ?',
    NOM_PROXY: "La nomenclature marché pour les proxy n'est pas encore disponible ! Vous pouvez continuer votre animation.",
    ERROR: {
      SAVING: "Erreur à la sauvegarde de l'animation, merci de réessayer ultérieurement."
    }
  },
  ANIMATE_GEOGRAPHIC: {
    ACTIVATION_EASINESS_AREAS: 'Zone de chalandises',
    NEIGHBORHOOD_TYPES: 'Typologie de quartier',
    OUI_PUB_ZONES: 'Zones Oui Pub',
    ERROR: {
      GET_DATAS: 'Erreur lors de la récupération des données.'
    },
    SEGMENTATIONS: 'Guidée',
    FREE: 'Libre',
    CHOOSE_METHOD: 'Les clients de quelle(s) zone(s) géograhique(s) ? -- Choisis ta méthode: ',
    LEGEND_FREE: 'Tu peux utiliser la forme sur la carte en haut à gauche ! :-)'
  },
  ANIMATE_COMMUNITY: {
    TITLE: 'Quelles communautés animer ?'
  },
  ANIMATE_HOME: {
    TOOLTIP_SETTINGS: 'Je parametre les communautés de mon magasin',
    TITLE: "Que veux-tu faire aujourd'hui ?",
    ANIMATE_STANDARD: 'Je souhaite animer mon portefeuille client',
    ANIMATE_COMMUNITY: 'Je souhaite animer mes communautés'
  },
  NOTIFICATIONS: {
    LABEL: 'Notifications'
  },
  COMPETITORS_DIALOG_MAPS: {
    TITLE: 'Type de concurrent affiché par défaut',
    TYPE: {
      SUP: 'Super',
      PLS: 'Petit libre service',
      DIS: 'Discount',
      PRX: 'Proxy',
      HYP: 'Hyper',
      DRV: 'Drive',
      BIO: 'Bio'
    }
  },
  ANIMATE_SEGMENTATION: {
    TITLE: 'Quel type de client ?',
    ERROR: {
      SAVING: "Erreur à la sauvegarde de l'animation, merci de réessayer ultérieurement."
    }
  },
  ANIMATE_TOP_CLIENT: {
    TITLE: 'Sur quelle période ?',
    ALL_CLIENT: 'Rester sur les actifs 5 mois',
    CUSTOM_CLIENT: 'Période personnalisée',
    CHOOSE_CLIENT_ON_CRITERIA: 'Choisis ton type de client sur la période :',
    CHOOSE_CLIENT_NUMBER: 'Choisis le nombre de clients',
    TOP_CLIENT: 'Top Clients',
    INACTIVE: 'Clients Inactifs sur la période',
    ACTIVE_PERIOD: 'Clients Actifs sur la période',
    ACTIVE: 'Actif',
    TOP: 'Top',
    CLIENT: 'Clients',
    ALL: 'Tous clients actifs',
    ERROR: {
      SAVING: "Erreur à la sauvegarde de l'animation, merci de réessayer ultérieurement."
    }
  },
  ANIMATION_DETAIL: {
    MEDIA: 'Media',
    RNK: 'Rang',
    OLY: 'Seg. Olympique',
    DTS: 'Date',
    CDV: 'Cycle de vie',
    NAME: 'Nom',
    ACT: 'Zone(s)',
    NOM: 'Marché(s)',
    QUA: 'Quartier(s)',
    SHAPE: 'Carte',
    ANIMATION: 'Animation',
    COMMUNITY: 'Communauté(s)',
    OUIPUB: 'Zones Oui Pub'
  },
  ANIMATE_RESUME: {
    CANT_ANIMATE: 'Vous devez être sur votre magasin pour créer une animation !'
  },
  TRACT_ZONES: {
    TITLE: 'Préconisation des zones de tract',
    HYPOTHESIS: {
      TITLE: 'Mes hypothèses',
      SIZE: "Taille de l'hypothèse",
      SMALL: 'Petite (S)',
      MEDIUM: 'Moyenne (M)',
      LARGE: 'Grande (L)'
    },
    SEARCH: 'Rechercher',
    UNSUFFICIENT_HISTORICAL: 'Vous n\'avez pas un historique suffisant pour définir une zone de tracts précise. 11 mois d\'historique sont nécessaires.',
    DISTRIBUTOR_SELECTION: 'Sélection du ditributeur',
    BY_DEFAULT: 'par défaut',
    NOT_DEFAULT_DISTRIBUTOR: "Attention, ce n'est pas le distributeur associé.",
    EXPORT_SELECTION: 'Exporter la sélection',
    HYPOTHESIS_TYPE: "Type d'hypothèse",
    PRECONIZED: 'Préconisée',
    PERSONALIZED: 'Personnalisée',
    KPIS: {
      CARRIED_TURNOVER: {
        TITLE: 'CA couvert',
        SUBTEXT: 'du CA tractable',
        HELP:
          "CA couvert : CA brut (cumul 12) hyper+drive tout client (identifiés + non identifiés - un redressement est effectué pour avoir par zone la totalité du CA). Correspond à la zone sélectionnée (en bleu).\r\nCA tractable : Une zone n'est tractable par un magasin que si le magasin est leader de son format sur la zone, et que le distributeur la dessert. Correspond à la zone en transparence + zone en bleu."
      },
      INCREMENTAL_TURNOVER: {
        TITLE: 'Additionnel de CA',
        SUBTEXT: 'du CA couvert',
        HELP:
          "Estimation d'additionnelle de CA (cumul 12) pour 70 distributions annuelles. L'additionnel de CA varie en fonction des caractéristiques de la zone en termes de distance au magasin, d'intensité concurrentielle, de taux de pénétration client et de type d'habitat."
      },
      MAIL_BOXES: {
        TITLE: 'Boîtes aux lettres',
        SUBTEXT: 'Add. par boîte aux lettres'
      },
      HOVER_ZONE: 'KPIs de la zone survolée'
    }
  },
  COMMUNITY_SETTINGS: {
    TITLE: 'Je paramétre les communautés de mon magasin',
    SAVE_SUCCESS: 'La mise à jour des communautés à été réalisée avec succès'
  }
}
