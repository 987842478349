import axios from 'axios'
import Vue from 'vue'

class TractsService {
  constructor() {
    this.httpClient = axios.create({
      baseURL: `${process.env.VUE_APP_BACKEND}/tracts`
    })
    this.sitesHttpClient = axios.create({
      baseURL: `${process.env.VUE_APP_BACKEND}/geo-ref/sites`
    })
  }

  async getZoneUnits(siteRdo, distributor) {
    const url = `/${siteRdo ?? Vue.prototype.$userInfos.site.siteRdo}/zoneUnits?distributor=${distributor?.distributorCode}`
    const token = await Vue.prototype.$user.getIdToken()
    return this.sitesHttpClient.get(url, { headers: { Authorization: `Bearer ${token}` } }).then(res => res.data)
  }

  async getScenarios(siteRdo, distributor) {
    const url = `/${siteRdo ?? Vue.prototype.$userInfos.site.siteRdo}/scenarios?distributor=${distributor?.distributorCode}`

    const token = await Vue.prototype.$user.getIdToken()

    return this.httpClient.get(url, { headers: { Authorization: `Bearer ${token}` } }).then(res => res.data)
  }

  async getSiteOperationsAndDistributors(siteRdo) {
    const url = `/${siteRdo ?? Vue.prototype.$userInfos.site.siteRdo}/commercialOperationsAndDistributors`

    const token = await Vue.prototype.$user.getIdToken()

    return this.httpClient.get(url, { headers: { Authorization: `Bearer ${token}` } }).then(res => res.data)
  }

  async createHypothesis(siteRdo, name, siteOperation, distributor, size, zoneUnits) {
    const url = `/${siteRdo ?? Vue.prototype.$userInfos.site.siteRdo}/hypothesis`

    const token = await Vue.prototype.$user.getIdToken()

    const params = {
      hypothesisLabel: name,
      commercialOperationLabel: siteOperation,
      distributeurCode: distributor,
      hypothesisSize: size.toUpperCase(),
      leafletZoneUnits: zoneUnits
    }

    return this.httpClient.put(url, params, { headers: { Authorization: `Bearer ${token}` } }).then(res => res.data)
  }

  async getUserSiteHistorical(siteRdo) {
    const url = `/${siteRdo ?? Vue.prototype.$userInfos.site.siteRdo}/siteHistorical`
    const token = await Vue.prototype.$user.getIdToken()
    return this.httpClient.get(url, { headers: { Authorization: `Bearer ${token}` } }).then(res => res.data)
  }
}

export default new TractsService()
