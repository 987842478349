<template>
    <div class="app-text-field">
        <label :class="active ? 'filled focused': ''" for="input">{{ label }}</label>
        <input
                :value="inputVal" @blur="setBlurActive()" @focus="setFocusActive()"
                @input="setInput"
                id="input" type="text"/>
    </div>
</template>

<script>
export default {
  name: 'AppTextField',
  props: {
    label: {
      type: String,
      default: null,
    },
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      active: false,
    };
  },
  computed: {
    inputVal() {
      if (this.value.length !== 0 && this.value !== '') {
        if (!this.active) { this.setFocusActive(); }
      }
      return this.value;
    },
  },
  methods: {
    setBlurActive() {
      this.active = this.value.length > 0;
    },
    setFocusActive() {
      this.active = !this.active;
    },
    setInput($event) {
      this.$emit('input', $event.target.value);
    },
  },
};
</script>

<style scoped lang="scss">
    @import "~@/styles/_colors";

    .app-text-field {
        border          : 2px solid $primary-color;
        position        : relative;
        padding         : 0 1em;
        border-radius   : 4px;
        display         : flex;
        flex-direction  : column;
        justify-content : center;
        width           : 25em;
        height          : 3em;

        label {
            transition  : all 450ms cubic-bezier(0.23, 1, 0.32, 1);
            cursor      : text;
            color       : $color-grey;
            font-weight : bold;
            font-size   : 14px;
            width       : fit-content;
            position    : absolute;
            top         : 50%;
            transform   : translateY(-50%);

            &.filled {
                top        : -.1rem;
                font-size  : 11px;
                padding    : 0 .5em;
                background : white;
            }

            &.focused {
                color : $primary-color;
            }
        }

        input {
            transition    : all 450ms cubic-bezier(0.23, 1, 0.32, 1);
            border        : none;
            border-radius : 0px;
            font-size     : 14px;
            outline       : none;
            width         : 100%;
            background    : transparent;
            padding       : 0;
            font-weight   : bold;

            &:focus {
                border-color : $primary-color;
            }
        }
    }
</style>
