<template>
    <button :disabled="disabled"
            class="app-button"
            type="button">
        <i class="material-icons"
           v-if="icon !== null">
            {{ icon }}
        </i>
        <div class="label">
            <slot/>
        </div>
    </button>
</template>

<script>
export default {
  name: 'AppButton',
  props: {
    icon: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
    @import "~@/styles/_colors";

    .app-button {
        display: flex;
        align-items: center;
        padding: 0 1em;

        height: 32px;
        color: $primary-color;

        border-radius: 4px;
        border: 1px solid $color-grey-with-opacity;

        transition: .25s all ease;

        &:hover {
            cursor: pointer;
            background-color: $primary-color-with-opacity;
        }

        &:disabled, &:disabled.primary {
            cursor: not-allowed;
            border: 2px solid transparent;
            background-color: $color-grey-light;
            background-image: linear-gradient(45deg, $color-grey-light,$color-grey-light);
        }

        &.link {
            color: $color-grey-dark;
        }

        &.full-width {
            width: 100%;
            text-align: center;
        }

        &.active {
            background-color: $primary-color-with-opacity;
        }

        &.primary {
            color: white;
            border: 2px solid transparent;
            background-image: linear-gradient(45deg, $primary-color-light, var(--md-theme-default-primary));
            transition: .3s ease all;
            &:hover {
                border: 2px solid $primary-color;
                color: $color-grey-dark;
                background: #fff;
            }
        }

        .material-icons {
            margin-right: 1rem;
        }

        .label {
            text-transform: uppercase;
            font-weight: bold;
            font-size: 14px;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
</style>
