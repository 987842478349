<template>
    <div class="google-maps-legend"
         id="legend">
        <md-button :disabled="disabled"
                   @click="toggleLegend"
                   class="md-icon-button md-raised legend-btn"
                   v-if="!isLegendOpened">
            <md-icon>info</md-icon>
        </md-button>

        <md-card class="card"
                 v-else>
            <md-card-header>
                <div class="md-title title">
                    <md-icon>info</md-icon>
                    <span>{{ $t('GOOGLE_MAPS_LEGEND.LABEL') }}</span>
                    <md-button
                            @click="toggleLegend"
                            class="md-icon-button">
                        <md-icon>close</md-icon>
                    </md-button>
                </div>
            </md-card-header>
            <md-card-content class="content">
                <slot/>
            </md-card-content>
        </md-card>

    </div>
</template>

<script>
export default {
  name: 'GoogleMapsLegend',
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLegendOpened: !this.disabled && this.opened,
    };
  },
  watch: {
    opened() {
      this.isLegendOpened = !this.disabled && this.opened;
    },
    disabled() {
      this.isLegendOpened = !this.disabled && this.opened;
    },
  },
  methods: {
    toggleLegend() {
      this.isLegendOpened = !this.isLegendOpened;
    },
  },
};
</script>

<style lang="scss" scoped>
.google-maps-legend {
    .legend-btn {
        margin: 13px;
    }

    .card {
        min-width: 250px;
        margin: 13px;

        .title {
            display: flex;
            align-items: center;

            span {
                padding-left: 10px;
                flex: 1;
            }
        }
    }
}
</style>

