<template>
    <div class="analyze-sales-formula-layout">
        <div class="header">
            <app-title :title="$t($route.meta.title)">
                <app-monthpicker :nb-of-available-months="12"
                                 :value="selectedMonth"
                                 @input="selectMonth">
                </app-monthpicker>
            </app-title>
        </div>
        <md-card>
            <md-tabs md-alignment="left"
                     :md-active-tab="routeName"
                     md-dynamic-height>
                <md-tab id="tab-pyramid"
                        md-label="Vision Pyramidale"
                        @click="goTo('analyze-sales-formula-pyramid')">
                </md-tab>

                <md-tab id="tab-matrix"
                        md-label="Vision Nomenclature / Magasin"
                        @click="goTo('analyze-sales-formula-matrix')">
                </md-tab>

                <md-tab id="tab-map"
                        v-if="!isNational"
                        md-label="Vision Cartographique"
                        @click="goTo('analyze-sales-formula-map')">
                </md-tab>
            </md-tabs>

            <router-view/>
        </md-card>
    </div>
</template>

<script>
import AppMonthpicker from '@/components/app-monthpicker/AppMonthpicker.vue';
import moment from 'moment';
import AppTitle from '../../../components/app-title/AppTitle.vue';

export default {
  name: 'analyze-sales-formula-layout',
  components: { AppTitle, AppMonthpicker },
  data() {
    return {
      months: [],
      selectedMonth: {},
      routeName: this.$router.name,
    };
  },
  computed: {
    isNational() {
      return this.$route.query.sidSite === undefined && this.$route.query.idLivingArea === undefined;
    },
  },
  created() {
    this.selectDefaultMonth();
  },
  watch: {
    $route() {
      this.routeName = this.$router.name;
    },
  },
  methods: {
    goTo(routeName) {
      this.$router.push({ name: routeName, query: this.$route.query });
    },
    selectDefaultMonth() {
      const monthValue = this.$route.query.idMonth;

      if (monthValue) {
        this.selectedMonth = moment(monthValue, 'YYYYMM');
      } else {
        this.selectMonth(moment().subtract(1, 'months'));
      }
    },
    selectMonth(month) {
      this.selectedMonth = month;

      const query = Object.assign({}, this.$route.query);
      query.idMonth = this.selectedMonth.format('YYYYMM');

      this.$router.push({ name: this.$route.name, query });
    },
  },
};
</script>

<style scoped lang="scss">
.analyze-sales-formula-layout {
    padding: 0 2rem 2rem 2rem;

    .md-tab {
        padding: 0;
    }
}
</style>
