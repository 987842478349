<template>
    <div class="sales-formula-pyramid">
        <ds-sales-formula-pyramid-on-month></ds-sales-formula-pyramid-on-month>
        <ds-sales-formula-pyramid-on-sliding-year
                class="sales-formula-pyramid-on-sliding-year"></ds-sales-formula-pyramid-on-sliding-year>
    </div>
</template>

<script>
import DsSalesFormulaPyramidOnMonth from './components/SalesFormulaPyramidOnMonth.vue';
import DsSalesFormulaPyramidOnSlidingYear from './components/SalesFormulaPyramidOnSlidingYear.vue';

export default {
  name: 'DsSalesFormulaPyramid',
  components: { DsSalesFormulaPyramidOnSlidingYear, DsSalesFormulaPyramidOnMonth },
};
</script>

<style scoped lang="scss">
.sales-formula-pyramid {
    .sales-formula-pyramid-on-sliding-year {
        margin-top: 2rem;
    }
}
</style>
