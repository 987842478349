import axios from 'axios'
import { find, remove } from 'lodash';
import Vue from 'vue'

class NomenclatureGeographicService {
  constructor() {
    this.httpClient = axios.create({
      baseURL: process.env.VUE_APP_NOMENCLATURE_GEOGRAPHIC_URI,
    });

    this.httpClientGeoRef = axios.create({
      baseURL: process.env.VUE_APP_GEO_REFERENTIAL_URI,
    });

    this.httpBackClient = axios.create({
      baseURL: process.env.VUE_APP_BACKEND,
    });
  }

  async getAllTerritories() {
    const params = {
      geoLevel: 31,
      shape: 1,
      geoVersion: 261,
    };

    const token = await Vue.prototype.$user.getIdToken()
    return this.httpClient.get('/values', {params, headers: { Authorization: `Bearer ${token}`, gcloud_project: process.env.VUE_APP_FIREBASE_PROJECT_ID }})
      .then(res => res.data
      )
      .then(territories => {
        territories.sort((s1, s2) => s1.label.localeCompare(s2.label));
        return territories;
      });
  }

  async getAllLivingAreas() {
    const params = {
      geoLevel: 32,
      shape: 1,
      geoVersion: 261,
    };

    const token = await Vue.prototype.$user.getIdToken()
    return this.httpClient.get('/values', { params, headers: { Authorization: `Bearer ${token}`, gcloud_project: process.env.VUE_APP_FIREBASE_PROJECT_ID }})
      .then(res => res.data)
      .then(livingAreas => {
        livingAreas.sort((s1, s2) => s1.label.localeCompare(s2.label));
        return livingAreas;
      });
  }

  async getLivingAreaById(id) {
    const params = {
      geoLevel: 32,
      geoVersion: 261,
      outputGeoLevel: 32,
      shape: 1,
      geoValueFilter: id,
      geoJSON: 1
    };

    const token = await Vue.prototype.$user.getIdToken()
    return this.httpClient.get('/values', { params, headers: { Authorization: `Bearer ${token}`, gcloud_project: process.env.VUE_APP_FIREBASE_PROJECT_ID }})
      .then(res => res.data[0]);
  }

  async getAllSites() {
    const params = {
      geoLevel: 40,
      shape: 1,
      geoVersion: 261,
      geoChannel: '1,3'
    };

    const token = await Vue.prototype.$user.getIdToken()

    return this.httpClient.get('/values', { params, headers: { Authorization: `Bearer ${token}`, gcloud_project: process.env.VUE_APP_FIREBASE_PROJECT_ID }})
      .then(res => res.data)
      .then(sites => {
        sites = remove(sites, site => +site.format.id !== 5 && +site.format.id !== 3);
        sites.sort((s1, s2) => s1.label.localeCompare(s2.label));
        return sites;
      });
  }

  async getSiteBySid(sid) {
    const params = {
      geoLevel: 40,
      geoVersion: 261,
      outputGeoLevel: 40,
      shape: 1,
      geoValueFilter: sid,
    };
    const token = await Vue.prototype.$user.getIdToken()

    return this.httpClient.get('/values', { params, headers: { Authorization: `Bearer ${token}`, gcloud_project: process.env.VUE_APP_FIREBASE_PROJECT_ID }})
      .then(res => {
        if (res.data && res.data.length > 0) return res.data[0]
      });
  }

  getSiteById(id) {
    return this.getAllSites().then(sites => find(sites, site => site.id === String(id).padStart(3, '0')));
  }

  async getSitesByIdLivingArea(id) {
    const params = {
      geoLevel: 40,
      geoVersion: 261,
      outputGeoLevel: 32,
      shape: 1,
      geoValueFilter: id,
      geoChannel: '1,3'
    };

    const token = await Vue.prototype.$user.getIdToken()

    return this.httpClient.get('/values', { params, headers: { Authorization: `Bearer ${token}`, gcloud_project: process.env.VUE_APP_FIREBASE_PROJECT_ID }})
      .then(res => res.data)
               .then(sites => {
                 sites = remove(sites, site => +site.format.id !== 5 && +site.format.id !== 3);
                 sites.sort((s1, s2) => s1.label.localeCompare(s2.label));

                 return sites;
               });
  }

  async getCompetitorsById(idSite, idConcept) {
    const token = await Vue.prototype.$user.getIdToken()
    return this.httpClientGeoRef.get(`/competitor/site`, { params: { idSite, idConcept }, headers: { Authorization: `Bearer ${token}`, gcloud_project: process.env.VUE_APP_FIREBASE_PROJECT_ID }})
      .then(res => res.data )
    .catch(error => console.log('--- eror', error));
  }

  async getActivationEasinessAreasById(idSite) {
    const token = await Vue.prototype.$user.getIdToken()
    return this.httpClientGeoRef.get(`/activation-easiness/level/site`, { params: { idSite },headers: { Authorization: `Bearer ${token}`, gcloud_project: process.env.VUE_APP_FIREBASE_PROJECT_ID }})
      .then(res => res.data);
  }

  async getCompetitorsByIdLivingArea(id) {
    const token = await Vue.prototype.$user.getIdToken()
    return this.httpClientGeoRef.get('/competitor/living-area', { params: { idLivingArea: id },headers: { Authorization: `Bearer ${token}`, gcloud_project: process.env.VUE_APP_FIREBASE_PROJECT_ID }}).then(res => res.data);
  }

  async getActivationEasinessAreasByIdLivingArea(id) {
    const token = await Vue.prototype.$user.getIdToken()
    return this.httpClientGeoRef.get('/activation-easiness/level/living-area', { params: { idLivingArea: id },headers: { Authorization: `Bearer ${token}`, gcloud_project: process.env.VUE_APP_FIREBASE_PROJECT_ID }}).then(res => res.data);
  }

  async getNeighborhoodAreasBySid(sid) {
    const token = await Vue.prototype.$user.getIdToken()
    return this.httpBackClient.get(`/sites/${sid}/neighborhoodAreas`, {headers: { Authorization: `Bearer ${token}`, gcloud_project: process.env.VUE_APP_FIREBASE_PROJECT_ID }})
      .then(res => res.data);
  }

  async getSquaresOuiPub(siteSid) {
    const token = await Vue.prototype.$user.getIdToken()
    return this.httpBackClient.get(`/sites/${siteSid}/ouiPub`, { headers: { Authorization: `Bearer ${ token }`, gcloud_project: process.env.VUE_APP_FIREBASE_PROJECT_ID }})
      .then(res => res.data)
  }

  async getSquaresInAreaBySid(sid, polygon) {
    const token = await Vue.prototype.$user.getIdToken()
    return this.httpClientGeoRef.post(`/sites/${sid}/squares/insideArea`, polygon, {headers: { Authorization: `Bearer ${token}`, gcloud_project: process.env.VUE_APP_FIREBASE_PROJECT_ID }})
      .then(res => res.data);
  }
}

export default new NomenclatureGeographicService();
