<template>
    <md-empty-state :md-label="labelFormatted"
                    md-icon="cloud_queue">
        <slot/>
    </md-empty-state>
</template>

<script>
export default {
  name: 'AppEmptyState',
  props: {
    label: {
      type: String,
      default: null,
    },
  },
  computed: {
    labelFormatted() {
      return this.label === null ? this.$t('APP_EMPTY_STATE.LABEL') : this.label;
    },
  },
};
</script>
