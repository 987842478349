<template>
    <div class="app-button-toggle">
        <button :class="[val.id === value.id ? 'active' : '', inline ? 'inline': '', small ? 'small': '']"
                :key="`button-toggle-${val.id}`"
                :disabled="val.disabled"
                @click="setValue(val)"
                type="button"
                v-for="val in values">
            <md-icon v-if="val.icon">{{ val.icon }}</md-icon>
            <span v-html="val.label"></span>
        </button>
    </div>
</template>

<script>
export default {
  name: 'AppButtonToggle',
  props: {
    values: {
      type: Array,
      default: () => ([]),
    },
    value: {
      type: Object,
      default: () => ({}),
    },
    inline: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    setValue(value) {
      if (!value.disabled) { this.$emit('input', value); }
    },
  },
};
</script>

<style scoped lang="scss">
    @import "~@/styles/_colors";
    @import "~@/styles/_variables";
    @import "~@/styles/_mixins";

    .app-button-toggle {
        display: flex;
        align-items: center;

        button {
            @include display-flex(center, center, column);
            color: $color-grey-dark;
            height: 80px;
            min-width: 150px;
            text-transform: uppercase;
            font-weight: 500;
            font-size: .9em;
            text-overflow: ellipsis;
            overflow: hidden;
            border-radius: 4px;
            border: 1px solid $color-grey-with-opacity;
            padding: 0 2rem;
            background: white;

            &.inline {
                height: 40px;
                width: auto;
            }

            &.small {
                min-width: 100px;
                width: auto;
            }

            .md-icon {
                width: 20px;
                margin: 0 0 0.5rem 0;
            }

            &:hover {
                cursor: pointer;
                background-color: $primary-color-with-opacity;
                transition: .25s ease background-color;
                transform: scale(1.03);
            }

            &:disabled {
                background-color: $color-grey-light;
                border-color: $color-grey-light;
                transform: scale(1);
                cursor: not-allowed;
            }

            &.active {
                color: $primary-color;
                background-color: $primary-color-with-opacity;
                background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path fill="%231e8bc3" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"/></svg>');
                background-position: 95% 5%;
                background-repeat: no-repeat;
                box-shadow: $box-shadow;
                &.inline {
                    background-image: none !important;
                }
                .md-icon {
                    color: $primary-color;
                }
            }
        }

        button + button {
            margin-left: 1rem;
        }
    }
</style>
