<template>
    <div class="neighborhood-type-chart">
        <md-card>
            <md-card-header class="header">
                <md-card-header-text>
                    <div v-if="!isLivingArea" class="md-title">{{ $t('NEIGHBORHOOD_TYPE_CHART.LABEL') }}</div>
                    <div v-else class="md-title">{{ $t('NEIGHBORHOOD_TYPE_CHART.LABEL_ZDV') }}</div>
                    <div class="md-subhead">{{ $t('NEIGHBORHOOD_TYPE_CHART.SUB_LABEL') }}</div>
                </md-card-header-text>

                <md-switch :disabled="isLoading || isLoadingForTheTime"
                           @change="toggleProgressionMode"
                           class="md-primary"
                           v-model="isInProgression">
                    {{ $t('NEIGHBORHOOD_TYPE_CHART.MODE_PROGRESSION') }}
                </md-switch>

                <div v-if="isLoading">
                    <md-progress-spinner :md-diameter="20"
                                         :md-stroke="2"
                                         class="md-accent"
                                         md-mode="indeterminate">
                    </md-progress-spinner>
                </div>
            </md-card-header>

            <md-card-content class="content">
                <app-loading v-if="isLoadingForTheTime"></app-loading>
                <app-error-state v-else-if="isInError"></app-error-state>
                <app-empty-state v-else-if="datasets.length === 0"></app-empty-state>

                <app-bar-chart :datasets="datasets"
                               :labels="labels"
                               :options="options"
                               type="horizontalBar"
                               v-else>
                </app-bar-chart>
            </md-card-content>
        </md-card>
    </div>
</template>

<script>
import AppBarChart from '@/components/app-bar-chart/AppBarChart.vue';
import AppEmptyState from '@/components/app-empty-state/AppEmptyState.vue';
import AppErrorState from '@/components/app-error-state/AppErrorState.vue';
import AppLoading from '@/components/app-loading/AppLoading.vue';
import geoMarketingService from '@/services/geoMarketingService';

export default {
  name: 'DsNeighborhoodTypeChart',
  components: {
    AppEmptyState, AppErrorState, AppLoading, AppBarChart,
  },
  props: {
    withGlobalHyper: {
      type: Boolean,
      default: false,
    },
    withNational: {
      type: Boolean,
      default: false,
    },
    isLivingArea: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoadingForTheTime: false,
      isLoading: false,
      isInError: false,
      isInProgression: false,
      salesAmountWeight: {
        store: null,
        national: null,
        globalHyper: null,
      },
      labels: [],
      datasets: [],
      options: {
        tooltips: {
          callbacks: {
            title: tooltipItems => tooltipItems[0].yLabel.toUpperCase(),
            label: (tooltipItem, data) => {
              const serie = data.datasets[tooltipItem.datasetIndex].label.toUpperCase();
              const current = this.$options.filters.number(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index], 1, 'percent');
              const progression = this.$options.filters.number(data.datasets[tooltipItem.datasetIndex].dataProgression[tooltipItem.index], 1, 'percent');
              return `${serie} : ${current} \u2022 ${progression}`;
            },
          },
          cornerRadius: 2,
        },
        scales: {
          xAxes: [{
            position: 'top',
            ticks: {
              beginAtZero: true,
              fontSize: 14,
            },
          }],
          yAxes: [{
            ticks: {
              beginAtZero: true,
              fontSize: 14,
            },
          }],
        },
        plugins: {
          datalabels: {
            display: false,
          },
        },
      },
    };
  },
  created() {
    this.getDatasStoreLevel();
  },
  watch: {
    withGlobalHyper() {
      this.toggleDatasGlobalHyperLevel();
    },
    withNational() {
      this.toggleDatasNationalLevel();
    },
    '$route.query': function () {
      this.getDatasStoreLevel();
      this.toggleDatasNationalLevel();
      this.toggleDatasGlobalHyperLevel();
    },
    salesAmountWeight: {
      handler() {
        this.renderChart();
      },
      deep: true,
    },
  },
  methods: {
    getDatasStoreLevel() {
      this.isLoadingForTheTime = true;
      this.isInError = false;
      this.datasets = [];
      const query = this.$route.query;

      geoMarketingService.getNeighborhoodType(query.sidSite, query.sidSalesChannel, query.idLivingArea, query.sidDepartments)
        .then((datas) => {
          datas.sort((gm1, gm2) => gm2.salesAmountWeight - gm1.salesAmountWeight);

          this.salesAmountWeight.store = {
            labels: datas.map(data => data.dimension),
            data: datas.map(data => data.salesAmountWeight),
            dataProgression: datas.map(data => data.salesAmountProgression),
          };
        })
        .catch(() => this.isInError = true)
        .finally(() => this.isLoadingForTheTime = false);
    },
    toggleDatasNationalLevel() {
      if (this.withNational) {
        this.isLoading = true;
        this.isInError = false;
        const query = this.$route.query;

        geoMarketingService.getNeighborhoodType(undefined, query.sidSalesChannel, undefined, query.sidDepartments)
          .then((datas) => {
            this.salesAmountWeight.national = {
              labels: datas.map(data => data.dimension),
              data: datas.map(data => data.salesAmountWeight),
              dataProgression: datas.map(data => data.salesAmountProgression),
            };
          })
          .catch(() => this.isInError = true)
          .finally(() => this.isLoading = false);
      } else {
        this.datasets = this.datasets.filter(dataset => dataset.id !== 'nat');
      }
    },
    toggleDatasGlobalHyperLevel() {
      if (this.withGlobalHyper) {
        this.isLoading = true;
        this.isInError = false;
        const query = this.$route.query;

        geoMarketingService.getNeighborhoodType(query.sidSite, undefined, query.idLivingArea)
          .then((datas) => {
            this.salesAmountWeight.globalHyper = {
              labels: datas.map(data => data.dimension),
              data: datas.map(data => data.salesAmountWeight),
              dataProgression: datas.map(data => data.salesAmountProgression),
            };
          })
          .catch(() => this.isInError = true)
          .finally(() => this.isLoading = false);
      } else {
        this.datasets = this.datasets.filter(dataset => dataset.id !== 'gh');
      }
    },
    renderChart() {
      this.labels = this.extractLabels();
      this.datasets = this.extractDatasetsByLabels(this.labels);
    },
    extractLabels() {
      let labels = Object.assign([], this.salesAmountWeight.store.labels);

      if (this.salesAmountWeight.globalHyper !== null) {
        labels = labels.concat(this.salesAmountWeight.globalHyper.labels.filter(label => labels.indexOf(label) === -1));
      }

      if (this.salesAmountWeight.national !== null) {
        labels = labels.concat(this.salesAmountWeight.national.labels.filter(label => labels.indexOf(label) === -1));
      }

      return labels;
    },
    extractDatasetsByLabels(labels) {
      const datasets = [];
      datasets.push(this.extractDatasetForStore(labels));

      if (this.salesAmountWeight.globalHyper !== null && this.withGlobalHyper) {
        datasets.push(this.extractDatasetForGlobalHyper(labels));
      }

      if (this.salesAmountWeight.national !== null && this.withNational) {
        datasets.push(this.extractDatasetForNational(labels));
      }

      return datasets;
    },
    extractDatasetForStore(labels) {
      const dataset = {
        label: this.$t('NEIGHBORHOOD_TYPE_CHART.SERIE.STORE'),
        data: Array(labels.length).fill(0),
        dataProgression: Array(labels.length).fill(0),
        borderWidth: 0,
        backgroundColor: 'rgb(30, 139, 195)',
      };

      labels.forEach((label, idx) => {
        const idxInData = this.salesAmountWeight.store.labels.indexOf(label);

        if (idxInData !== -1) {
          dataset.data[idx] = this.salesAmountWeight.store.data[idxInData];
          dataset.dataProgression[idx] = this.salesAmountWeight.store.dataProgression[idxInData];
        }
      });

      return dataset;
    },
    extractDatasetForGlobalHyper(labels) {
      const dataset = {
        id: 'gh',
        label: this.$t('NEIGHBORHOOD_TYPE_CHART.SERIE.GLOBAL_HYPER'),
        data: Array(labels.length).fill(0),
        dataProgression: Array(labels.length).fill(0),
        borderWidth: 0,
        backgroundColor: '#4CAF50',
      };

      labels.forEach((label, idx) => {
        const idxInData = this.salesAmountWeight.globalHyper.labels.indexOf(label);

        if (idxInData !== -1) {
          dataset.data[idx] = this.salesAmountWeight.globalHyper.data[idxInData];
          dataset.dataProgression[idx] = this.salesAmountWeight.globalHyper.dataProgression[idxInData];
        }
      });

      return dataset;
    },
    extractDatasetForNational(labels) {
      const dataset = {
        id: 'nat',
        label: this.$t('NEIGHBORHOOD_TYPE_CHART.SERIE.NATIONAL'),
        data: Array(labels.length).fill(0),
        dataProgression: Array(labels.length).fill(0),
        borderWidth: 0,
        backgroundColor: '#F44336',
      };

      labels.forEach((label, idx) => {
        const idxInData = this.salesAmountWeight.national.labels.indexOf(label);

        if (idxInData !== -1) {
          dataset.data[idx] = this.salesAmountWeight.national.data[idxInData];
          dataset.dataProgression[idx] = this.salesAmountWeight.national.dataProgression[idxInData];
        }
      });

      return dataset;
    },
    toggleProgressionMode() {
      const datasets = Object.assign([], this.datasets);
      datasets.forEach(datasets => {
        const data = datasets.data;
        datasets.data = datasets.dataProgression;
        datasets.dataProgression = data;
      });
      this.datasets = datasets;
    },
  },
};
</script>

<style lang="scss" scoped>
    .neighborhood-type-chart {
        .header {
            display: flex;
            align-items: center;
        }
    }
</style>
