<template>
    <app-context-container class="app-context-period">
        <template slot="title">{{ $t('APP_CONTEXT_PERIOD.LABEL') }}</template>
        <template slot="main">
            <div class="select-period">
                <app-datepicker class="inline"
                                v-model="selectedPeriod">
                </app-datepicker>
            </div>
        </template>
        <template slot="actions">
            <app-button @click.native="cancel"
                        class="link">
                {{ $t('ACTIONS.CANCEL') }}
            </app-button>
            <app-button @click.native="valid"
                        class="primary">
                {{ $t('ACTIONS.VALID') }}
            </app-button>
        </template>
    </app-context-container>
</template>

<script>
import AppButton from '../../app-button/AppButton.vue';
import AppDatepicker from '../../app-datepicker/AppDatepicker.vue';
import AppContextContainer from '../container/AppContextContainer.vue';

export default {
  name: 'AppContextPeriod',
  components: { AppContextContainer, AppButton, AppDatepicker },
  data() {
    return {
      selectedPeriod: {},
    };
  },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  created() {
    this.selectedPeriod = this.value;
  },
  methods: {
    cancel() {
      this.$emit('cancel');
    },
    valid() {
      this.$emit('input', this.selectedPeriod);
    },
  },
};
</script>

<style scoped lang="scss">
.app-context-period {
    .select-period {
        padding: 1rem;
    }
}
</style>
