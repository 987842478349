<template>
    <md-button @click="toggleFullscreenMode"
               class="md-icon-button"
               id="ga-fullscreen">
        <md-icon :style="{ 'color': colorFont }"
                 v-if="showFullscreenMode">
            fullscreen_exit
        </md-icon>
        <md-icon :style="{ 'color': colorFont }"
                 v-else>
            fullscreen
        </md-icon>
        <md-tooltip v-if="showFullscreenMode">{{ $t('FULLSCREEN_BTN.LABEL.OFF') }}</md-tooltip>
        <md-tooltip v-else>{{ $t('FULLSCREEN_BTN.LABEL.ON') }}</md-tooltip>
    </md-button>
</template>

<script>
export default {
  name: 'AppFullscreenBtn',
  props: {
    colorFont: {
      type: String,
      default: 'white',
    },
  },
  data() {
    return {
      showFullscreenMode: false,
    };
  },
  created() {
    return this.initFullscreenMode();
  },
  methods: {
    initFullscreenMode() {
      document.addEventListener('webkitfullscreenchange', () => {
        this.showFullscreenMode = document.webkitIsFullScreen;
        return true;
      });
    },
    toggleFullscreenMode() {
      this.showFullscreenMode = !this.showFullscreenMode;
      //        window.parent.postMessage(JSON.stringify({fullscreen: this.showFullscreenMode}), '*');

      if (this.showFullscreenMode) {
        document.getElementById('fullscreen-flag').webkitRequestFullScreen();
      } else {
        document.webkitExitFullscreen();
      }
    },
  },
};
</script>
