<template>
    <div class="competitors-dialog-maps"
         id="competitors-dialog">
        <md-button @click="showDialog = !showDialog"
                   class="md-icon-button md-raised">
            <md-icon>store</md-icon>
        </md-button>

        <md-dialog :md-active.sync="showDialog">
            <md-dialog-title>{{ $t('COMPETITORS_DIALOG_MAPS.TITLE') }}</md-dialog-title>

            <md-list>
                <md-list-item :key="'competitor_' + index" v-for="(competitorType, index) in competitorsType">
                    <md-checkbox v-model="competitorType.active">{{ $t('COMPETITORS_DIALOG_MAPS.TYPE.' + competitorType.label) }}</md-checkbox>
                </md-list-item>
            </md-list>

            <md-dialog-actions>
                <md-button class="md-primary" @click="validate()">{{ $t('ACTIONS.VALID') }}</md-button>
            </md-dialog-actions>
        </md-dialog>
    </div>
</template>

<script>

export default {
  name: 'GoogleMapsCenterMeControl',
  props: {
    map: {
      type: Object,
      default: null,
    },
    competitorsType: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      showDialog: false,
    };
  },
  methods: {
    validate() {
      this.$emit('update', this.competitorsType);
      this.showDialog = false;
    },
  },
};
</script>

<style scoped lang="scss">
.competitors-dialog-maps {
    margin: 13px;
}
</style>
