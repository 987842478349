<template>
    <div class="google-maps-center-me-control"
         id="center-me-control">
        <md-button @click="centerMe"
                   class="md-icon-button md-raised">
            <md-icon>gps_fixed</md-icon>
        </md-button>
    </div>
</template>

<script>
export default {
  name: 'GoogleMapsCenterMeControl',
  props: {
    map: {
      type: Object,
      default: null,
    },
    center: {
      type: Object,
      default: null,
    },
  },
  methods: {
    centerMe() {
      this.map.setCenter(this.center);
    },
  },
};
</script>

<style scoped lang="scss">
.google-maps-center-me-control {
    margin: 13px;
}
</style>
