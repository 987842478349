<template>
    <div class="analyze-map">
        <app-loading v-if="isLoading"></app-loading>
        <google-maps
                :center="center"
                :markers="markers"
                @load="setMap">
            <google-maps-zoom-control :map="map"/>
            <google-maps-center-me-control
                    :center="center"
                    :map="map"/>
            <google-maps-legend
                    :opened="!isLoading"
                    class="legend">
                <table>
                    <tr
                            :key="activationEasinessArea.id"
                            v-for="activationEasinessArea in legendActivationEasinessAreas">
                        <td :style="{
                              'background-color': activationEasinessArea.properties.colorWithOpacity,
                              'border': '3px solid' + activationEasinessArea.properties.color,
                            }"/>
                        <td>{{ activationEasinessArea.properties.label }}</td>
                    </tr>
                </table>
            </google-maps-legend>
            <competitors-dialog-maps
                    :competitors-type="competitorsType"
                    @update="updateCompetitors(competitorsType)"></competitors-dialog-maps>
        </google-maps>
        <md-snackbar
                :md-active.sync="message.active"
                :md-duration="Infinity"
                md-persistent
                md-position="left">
            {{ message.value }}
        </md-snackbar>
    </div>
</template>

<script>
/* global google */
import { uniqBy } from 'lodash';
import AppLoading from '@/components/app-loading/AppLoading.vue';
import AppTitle from '@/components/app-title/AppTitle.vue';
import GoogleMaps from '@/components/google-maps/GoogleMaps.vue';
import GoogleMapsCenterMeControl from '@/components/google-maps/GoogleMapsCenterMeControl.vue';
import GoogleMapsLegend from '@/components/google-maps/GoogleMapsLegend.vue';
import GoogleMapsZoomControl from '@/components/google-maps/GoogleMapsZoomControl.vue';
import CompetitorsDialogMaps from '@/components/google-maps/CompetitorsDialogMaps.vue';
import nomenclatureGeographicService from '@/services/nomenclatureGeographicService';
import MarketCluster from '@google/markerclusterer';
import ACTIVATION_EASINESS_AREA_COLOR_CONSTANT from './activationEasinessAreaColorConstant';

export default {
  name: 'analyze-map',
  components: {
    GoogleMapsLegend,
    AppTitle,
    GoogleMapsCenterMeControl,
    GoogleMapsZoomControl,
    GoogleMaps,
    CompetitorsDialogMaps,
    AppLoading,
  },
  data() {
    return {
      isLoading: false,
      isInError: false,
      map: null,
      center: null,
      markers: null,
      activationEasinessAreas: {},
      legendActivationEasinessAreas: [],
      competitorsType: [],
      competitors: [],
      directCompetitors: [],
      indirectCompetitors: null,
      message: {
        active: false,
        value: null,
      },
    };
  },

  created() {  },
  watch: {
    '$route.query': function () {
      this.getDatas();
    },
  },
  methods: {
    updateCompetitors(filteredCompetitorsType) {
      this.resetCompetitors();
      this.drawCompetitorsOnMap(this.competitors, filteredCompetitorsType);
    },
    setMap(map) {
      this.map = map;
      this.getDatas();
    },
    resetMap() {
      this.map.data.forEach((feature) => {
        this.map.data.remove(feature);
      });

      if (this.activationEasinessAreas.features) {
        this.activationEasinessAreas.features.forEach(feature => {
          if (this.map.data.getFeatureById(feature.id)) {
            this.map.data.remove(this.map.data.getFeatureById(feature.id));
          }
        });
      }

      this.markers = [];
      this.legendActivationEasinessAreas = [];
      this.resetCompetitors();
    },
    resetCompetitors() {
      if (this.indirectCompetitors !== null) {
        this.indirectCompetitors.clearMarkers();
      }

      this.directCompetitors.forEach(feature => feature.setMap(null));
    },
    getDatas() {
      const query = this.$route.query;
      this.message.active = false;
      this.isLoading = true;
      this.resetMap();

      if (query.sidSite) {
        this.getMapForSite(query);
      } else if (query.idLivingArea) {
        this.getMapForLivingArea(query);
      }
    },
    getMapForSite(query) {
      this.isloading = true;
      nomenclatureGeographicService.getSiteBySid(query.sidSite).then(site => {

        site.id = site.id.toString();
        if (site.id.length === 1) site.id = `00${site.id}`;
        if (site.id.length === 2) site.id = `0${site.id}`;

        Promise
          .all([
            nomenclatureGeographicService.getCompetitorsById(site.id, site.concept.id),
            nomenclatureGeographicService.getActivationEasinessAreasById(site.id),
          ])
          .then(datas => {
            this.competitorsType = [...new Set(datas[0].features.map(item => item.properties.signType))]
              .map(label => ({
                label,
                active: (this.$userInfos.format === 2 && label === 'PRO') || (this.$userInfos.format === 1 && label === 'HYP'),
              }));

            const coordinates = {
              lng: site.shape.coordinates[0],
              lat: site.shape.coordinates[1],
            };

            this.center = coordinates;
            this.markers = [{ position: coordinates }];
            this.competitors = datas[0];

            this.drawCompetitorsOnMap(this.competitors);
            this.drawActivationEasinessAreasOnMap(datas[1]);
          })
          .catch(() => {
            this.message.active = true;
            this.message.value = this.$t('ANALYZE_MAP.ERROR_GET_DATAS');
          })
          .finally(() => this.isLoading = false);
      }).catch(() => {
        this.message.active = true;
        this.message.value = this.$t('ANALYZE_MAP.ERROR_GET_DATAS');
      }).finally(() => this.isLoading = false);
    },
    getMapForLivingArea(query) {
      Promise
        .all([
          nomenclatureGeographicService.getSitesByIdLivingArea(query.idLivingArea),
          nomenclatureGeographicService.getCompetitorsByIdLivingArea(query.idLivingArea),
          nomenclatureGeographicService.getLivingAreaById(query.idLivingArea),
          nomenclatureGeographicService.getActivationEasinessAreasByIdLivingArea(query.idLivingArea),
        ])
        .then(datas => {
          const positions = [];
          this.map.data.addGeoJson(datas[2].shape);
          this.map.data.setStyle({
            fillColor: 'grey',
            fillOpacity: 0.3,
            strokeColor: 'grey',
            strokeWeight: 1,
            zIndex: 0,
          });

          this.competitorsType = [...new Set(datas[1].features.map(item => item.properties.signType))]
            .map(label => ({
              label,
              active: (this.$userInfos.format === 2 && label === 'PRX') || (this.$userInfos.format === 1 && label === 'HYP'),
            }));

          datas[0].forEach(site => {
            positions.push({
              metadata: { id: 'store', label: site.label },
              position: {
                lng: site.shape.coordinates[0],
                lat: site.shape.coordinates[1],
              },
            });
          });

          this.center = positions[0].position;
          this.markers = positions;
          this.competitors = datas[1];

          this.drawCompetitorsOnMap(this.competitors);
          this.drawActivationEasinessAreasOnMap(datas[3]);
        })
        .catch(() => {
          this.message.active = true;
          this.message.value = this.$t('ANALYZE_MAP.ERROR_GET_DATAS');
        })
        .finally(() => this.isLoading = false);
    },
    drawCompetitorsOnMap(competitors, filteredCompetitorsType = null) {
      let format;

      if (this.$userInfos.format === 1) {
        format = 'HYP';
      } else if (this.$userInfos.format === 2) {
        format = 'PRX';
      }

      const indirectCompetitorMarkers = [];

      competitors.features.forEach(feature => {
        let icon = require('../../../assets/competitors/DEFAULT.svg');

        try {
          icon = require(`../../../assets/competitors/${feature.properties.signCode}.svg`);
        } catch (e) {
          console.warn(`Marker's icon not found for ${feature.properties.signCode}, use default.`);
        }

        const contentTooltipStore = `${'<div class="popup-marker">' +
            '<p><b>'}${this.$t('ANALYZE_MAP.SIGN')}</b>${feature.properties.signLabel}</p>` +
            `<p><b>${this.$t('ANALYZE_MAP.CITY')}</b>${feature.properties.communeLabel}</p>` +
            `<p><b>${this.$t('ANALYZE_MAP.AREA')}</b>${feature.properties.referenceArea}m²</p>` +
            `<p><b>${this.$t('ANALYZE_MAP.SIGN_TYPE')}</b>${feature.properties.signType}</p></div>`;

        const infowindow = new google.maps.InfoWindow({
          content: contentTooltipStore,
        });

        const marker = new google.maps.Marker({
          position: {
            lat: feature.geometry.coordinates[1],
            lng: feature.geometry.coordinates[0],
          },
          icon: {
            url: icon,
            scaledSize: new google.maps.Size(35, 35),
          },
          animation: google.maps.Animation.DROP,
        });

        marker.addListener('click', function () {
          infowindow.open(this.map, marker);
        });

        if (filteredCompetitorsType) {
          filteredCompetitorsType.forEach(competitorType => {
            if (competitorType.active && competitorType.label === feature.properties.signType) {
              marker.setZIndex(2);
              marker.setMap(this.map);
              this.directCompetitors.push(marker);
            }
          });
        } else if (feature.properties.signType === format) {
          marker.setZIndex(2);
          marker.setMap(this.map);
          this.directCompetitors.push(marker);
        } else {
          indirectCompetitorMarkers.push(marker);
        }
      });

      // Clustering indirect competitors
      this.indirectCompetitors = new MarketCluster(
        this.map,
        indirectCompetitorMarkers,
        {
          imagePath: '../../../assets/clusters/m',
        },
      );
    },
    drawActivationEasinessAreasOnMap(activationEasinessAreas) {
      activationEasinessAreas.features.sort((a, b) => ((a.properties.id > b.properties.id) ? 1 : ((b.properties.id > a.properties.id) ? -1 : 0)));
      this.activationEasinessAreas = activationEasinessAreas;
      this.activationEasinessAreas.features.forEach(feature => {
        switch (+feature.properties.id) {
          case 1:
            feature.properties.zIndex = 5;
            break;
          case 2:
            feature.properties.zIndex = 4;
            break;
          case 3:
            feature.properties.zIndex = 3;
            break;
          case 4:
            feature.properties.zIndex = 2;
            break;
          case 5:
            feature.properties.zIndex = 1;
            break;
          default:
            feature.properties.zIndex = 1;
        }

        feature.id = +this.$route.query.sidSalesChannel !== 50 ? `activation_easiness_areas_${feature.properties.id}` : `activation_easiness_areas_${feature.properties.id}_${feature.properties.sidSite}`;
        feature.properties.color = ACTIVATION_EASINESS_AREA_COLOR_CONSTANT[`activation_easiness_areas_${feature.properties.id}`];
        feature.properties.colorWithOpacity = ACTIVATION_EASINESS_AREA_COLOR_CONSTANT[`opacity_activation_easiness_areas_${feature.properties.id}`];
        return feature;
      });

      this.legendActivationEasinessAreas = uniqBy(this.activationEasinessAreas.features, 'properties.id');
      this.map.data.setStyle((feature) => ({
        weight: 2,
        opacity: 0.5,
        strokeColor: feature.getProperty('color'),
        fillOpacity: 0.2,
        fillColor: feature.getProperty('color'),
        zIndex: feature.getProperty('zIndex'),
      }));

      this.map.data.addGeoJson(this.activationEasinessAreas);
    },
  },
};
</script>

<style scoped lang="scss">
    @import "~@/styles/_mixins";
    @import "~@/styles/_colors";

    :global(.popup-marker) {
        margin: 0;
        font-size: 1em;
        @include display-flex(flex-start, space-betwwen, column);
        :global(p) {
            @include display-flex(flex-start, space-between);
            margin: 0;
            width: 100%;
            :global(b) {
                margin-right: 2em;
            }
        }
    }

    .analyze-map {
        flex: 1;
        display: flex;
        flex-direction: column;

        .header {
            padding: 0 2rem;
            @include display-flex(center, space-between);
        }

        .legend {
            table {
                width: 100%;

                td {
                    width: calc(100% / 3);
                    text-align: right;
                    border-radius: 2px;
                }
            }
        }
    }
</style>
