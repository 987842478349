import 'hopscotch/dist/css/hopscotch.min.css'
import moment from 'moment'
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import VueMaterial from 'vue-material'
import 'vue-material/dist/theme/default.css'

import 'vue-material/dist/vue-material.min.css'
import VueLidate from 'vuelidate'
import App from './App.vue'
import './filters'
import fr from './i18n/fr'
import './registerServiceWorker'
import router from './router'
import './styles/main.scss'
import { cleanupSite, cleanupJob  } from './helpers/userUtils'


Vue.config.productionTip = false
Vue.use(VueMaterial)
Vue.use(VueI18n)
Vue.use(VueLidate)

moment.locale('fr')

export default function createVueApp({firebaseUser, user, roles}) {
  addPropertiesOnVuejsPrototype({ firebaseUser, user, roles })

  return new Vue({
    router,
    i18n,
    render: h => h(App)
  }).$mount('#app')
}

const i18n = new VueI18n({
  locale: 'fr',
  messages: {
    fr,
  },
})

function addPropertiesOnVuejsPrototype({ firebaseUser, user, roles }) {
  Vue.prototype.$user = firebaseUser
  Vue.prototype.$userInfos = cleanupSite(cleanupJob(user))
  Vue.prototype.$roles = roles
}
